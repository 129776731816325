import React, { useEffect, useState } from 'react';
import Container from '../Container';
import { PagerBanner } from '../../../src/pages/Pager/PagerStyle';
import BrowseSubCategory from './components/BrowseSubCategory';
import { BannerHeading } from '../../global/styles/style-component';
import { useNavigate, useParams } from 'react-router-dom';
import { errorToast } from '../../utils/toast';
import { getAllSubCampaign } from '../../services/campaign';

const BrowseSubCampaign = () => {
  return (
    <>
      <Container>
        <PagerBanner
          imgUrl={require('../../assets/nft-details/nft-details-banner.png')}
        >
          <BannerHeading>BROWSE SUB-CAMPAIGNS</BannerHeading>
        </PagerBanner>
        <BrowseSubCategory />
      </Container>
    </>
  );
};

export default BrowseSubCampaign;
