import { Typography } from "@mui/material";
import React from "react";
import { CommonBtn, Heading } from "../../../global/styles/style-component";
import './ImpactNFT.css'
const ImpactNFT = () => {
  
  return (
    <div
      style={{
        width: "70%",
        maxWidth: "1200px",
        margin: "80px auto",
        position: "relative",
        borderRadius: 10,
        background: "#fff",
        overflow: "hidden",
        boxShadow: "10px 7px 32px rgba(0, 0, 0, 0.25)",
      }}
      className="impact-nft-container"
    >
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 200,
          background: "#209AB7",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          background: "#F8AA52",
          bottom: 10,
          left: 500,
          width: "200px",
          height: "200px",
          borderRadius: "50%",
        }}
      ></div>
      {/* content */}
      <div
        style={{
          background: "#fff9",
          backdropFilter: "blur(32px)",
          padding: "40px",
          position: "relative",
        }}
      >
        <Heading>IMPACT NFT MARKETPLACE</Heading>
        <Typography
          sx={{
            color: "#023047",
            textAlign: "justify",
            my: 3,
          }}
          className="typo-text-align"
        >
          Our NFT Impact Marketplace powers brand cause marketing campaigns to
          drive audience engagement, increase brand loyalty and marketing ROI
          with innovative new tools like NFTs and tokens. We do this by making
          it easy for brands to support causes their audience cares while
          increasing visibility and earnings.
        </Typography>
      </div>
    </div>
  );
};

export default ImpactNFT;
