import React from 'react';
import { useNavigate } from 'react-router';
import { Card } from './show-case-cad-style';

const ShowCaseCard = ({
  url,
  title,
  price,
  id,
  campaign,
  campaignId,
  creator,
  creatorId,
  width = '300px',
  height = '400px',
}) => {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() => {
        navigate(`/nft-details/${id}`);
      }}
      role='button'
      bg={`'${url}'`}
      width={width}
      height={height}
    >
      <div>
        <h3 className='singleLineText'>{title}</h3>
        <span style={{ fontWeight: 'normal' }}>
          price: <b>${price}</b>
        </span>
        <h6
          style={{ fontWeight: 'normal' }}
          id='campaignNFTclick'
          onClick={(e) => {
            e.stopPropagation();
            if (campaignId) {
              navigate(`/campaign-details/${campaignId}`);
            } else if (creatorId) {
              navigate(`/creator-portfolio/${creatorId}`);
            }
          }}
        >
          {creator ? 'Creator' : 'Campaign'}: {campaign ? campaign : creator}
        </h6>
        <button
          onClick={() => {
            navigate(`/nft-details/${id}`);
          }}
        >
          view
        </button>
      </div>
    </Card>
  );
};

export default ShowCaseCard;
