import React from 'react';
import { Link } from 'react-router-dom';
import elips from '../../assets/images/elips.png';
import elips2 from '../../assets/images/elips2.png';
import workElips from '../../assets/images/Ellipse 19.png';
import workElips2 from '../../assets/images/Ellipse 20.png';
import workImg from '../../assets/images/npos_creator.png';
import '../Influencer/Influencer.css';
import { PagerBanner } from '../Pager/PagerStyle';
import { BenefitContainer } from '../nPos/NposCreatorStyle';
import Container from '../Container';
import { BannerHeading } from '../../global/styles/style-component';
const imgUrl = require('../../assets/nft-details/nft-details-banner.png');

const CreatorPage = () => {
  return (
    <Container>
      <PagerBanner imgUrl={imgUrl}>
        <BannerHeading style={{ margin: '0' }}>
          OPPORTUNITY FOR CREATORS
        </BannerHeading>
      </PagerBanner>

      <BenefitContainer>
        <h2>demo BENEFITS FOR CREATORS</h2>
        <br />
        <p style={{ textAlign: 'left' }}>Benefits of the demoreators:</p>
        <div>
          <ul>
            <li>
              A branded, interactive Creator Page where you can tell your brand
              story and build your community by interacting with fans.
            </li>
            <li>
              Minting tools for NFTs and tokens, plus campaign performance
              reports for your drops.
            </li>
          </ul>

          <ul>
            <li>
              Exposure to businesses, influencers, and diverse NPOs looking for
              creative partners can lead to new business and collab
              opportunities.
            </li>
            <li>
              Membership in a growing community of creators known for their
              passion for making a difference!{' '}
            </li>
          </ul>
        </div>
      </BenefitContainer>
      <div className='HowWeWork-section' style={{ paddingTop: '20px' }}>
        <div className='row work-align'>
          <div className='col-md-5'>
            <div className='work-img'>
              <img className='work-main-img' src={workImg} alt='' />
              <img
                className='workElips'
                style={{ top: '555px', left: '-22px' }}
                src={workElips}
                alt=''
              />
              <img
                className='workElips2'
                style={{ top: '565px', left: '5px' }}
                src={workElips2}
                alt=''
              />
            </div>
          </div>
          <div className='col-md-7'>
            <div className='how-we-work-content'>
              <img className='contentElips' src={elips} alt='' />
              <img className='contentElips2' src={elips2} alt='' />

              <h4 style={{ lineHeight: '44px', marginTop: '30px' }}>
                ACCESS OUR ECOSYSTEM
              </h4>

              <div className='single-work-content'>
                <h4>DIV</h4>
                <p>
                  Creators and artists can set up an account, explore your
                  creative genius and scale earnings and impact! Get started
                  <Link
                    to='/registration'
                    style={{ color: '#FFB703', textDecoration: 'none' }}
                  >
                    here
                  </Link>
                </p>
              </div>
              <div className='single-work-content'>
                <h4>DONE FOR YOU</h4>
                <p>
                  Interested in expanding your horizons in the space and don't
                  know where to start? Let us help. Get in touch.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreatorPage;
