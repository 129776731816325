import axios from 'axios';

export const baseURL = `${process.env.REACT_APP_DOMAIN}api`;

const publicApiCall = axios.create({
  baseURL,
});

publicApiCall.interceptors.request.use((request) => {
  document.querySelector('.overlay').style.display = 'block';
  return request;
});

publicApiCall.interceptors.response.use(
  (response) => {
    document.querySelector('.overlay').style.display = 'none';
    return response;
  },
  (error) => {
    document.querySelector('.overlay').style.display = 'none';
    // errorToast(error.response.data.message);
    return Promise.reject(error);
  }
);

export default publicApiCall;
