import React from 'react';
import { StatementHeading } from '../../../global/styles/style-component';

const DoneForYouIntro = () => {
  return (
    <>
      <StatementHeading>
        We offer fair, transparent retainer and rolling contract rates that help
        you build your product and your team without the stress of recruitment,
        AND it all helps to power impact. Get your developments powered by demo.
        Check out these options!
      </StatementHeading>
    </>
  );
};

export default DoneForYouIntro;
