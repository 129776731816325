import React from 'react';
import { Btn, SubHeading } from '../../../global/styles/style-component';
import { FiSearch } from 'react-icons/fi';
import { Grid } from '@mui/material';
import browseCard from '../../../assets/new-browse-campaign/browse-by-category.png';
import { BiFilterAlt } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllSubCampaign } from '../../../services/campaign';
import { errorToast } from '../../../utils/toast';

const BrowseSubCategory = () => {
  const { id } = useParams();
  const [campaign, setCampaign] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllSubCampaign(id)
      .then((res) => {
        setCampaign(res.data.subCampaigns);
      })
      .catch((err) => errorToast(err.response.data.message));
  }, []);
  return (
    <div className='browse-category-section'>
      {/* <div style={{ textAlign: 'center' }} className='browse-bttn'>
        <Btn color={'#023047'} marginRight='15px' marginLeft='15px'>
          {' '}
          <FiSearch className='browse-search-icon' /> Browse By Super Campaign{' '}
        </Btn>
        <Btn color={'#023047'} marginRight='15px' marginLeft='15px'>
          {' '}
          <FiSearch className='browse-search-icon' /> Browse by Impact Category{' '}
        </Btn>

        <Btn color={'#023047'} marginRight='15px' marginLeft='15px'>
          {' '}
          <FiSearch className='browse-search-icon' /> Browse by Industry{' '}
        </Btn>
        <BiFilterAlt className='filter-icon' />
      </div> */}

      <div className='browse-cards'>
        <Grid container spacing={3}>
          {campaign.length ? (
            campaign.map(({ overview, _id }) => (
              <Grid item xs={12} md={6} lg={3}>
                <div
                  style={{
                    height: '100%',
                  }}
                  onClick={() => {
                    navigate(`/campaign-details/${_id}`);
                  }}
                  className='browse-card-container'
                >
                  <img
                    style={{
                      width: '100%',
                      height: '250px',
                      objectFit: 'contain',
                    }}
                    src={`${process.env.REACT_APP_DOMAIN}${overview?.bannerImageUrl}`}
                    alt=''
                  />
                  <SubHeading>{overview.campaignName}</SubHeading>
                  <div className='browse-card-content'>
                    <span>Description</span>
                    <p id='campaignCardDescription'>{overview.description}</p>
                    {/* <span>Ending In</span>
                  <p>08 h : 11 m : 22 s</p> */}
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <h3
              style={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              NO SUB CAMPAIGN ASSOCIATE YET
            </h3>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default BrowseSubCategory;
