import ApiCall from '../utils/axiosCall';
import publicApiCall from '../utils/publicAxiosCall';
import { errorToast, successToast } from '../utils/toast';

const verifyEmail =
  ({ token, navigate }) =>
  (dispatch) => {
    console.log(token);
    publicApiCall
      .post(
        'user/verify-email',
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        successToast(res.data.message);
        navigate('/');
      })
      .catch((err) => {
        navigate('/');
        errorToast(err?.response?.data.message);
      });
  };
const recoverPassword = (data, token, navigate) => (dispatch) => {
  publicApiCall
    .post('user/password/recover-password', data, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      successToast(res.data.message);
      navigate('/login');
    })
    .catch((err) => {
      errorToast(err?.response?.data.message);
    });
};
const recoverPasswordUsingEmail = (data, navigate) => (dispatch) => {
  publicApiCall
    .post('user/password/recover/email', data)
    .then((res) => {
      successToast(res.data.message);
      navigate(
        `${
          window.location.pathname === '/reset-credential-verification'
            ? `/enter-verification-code?email=${data.email}&token=${res.data.magicLinkToken}`
            : `${window.location.pathname}?email=${data.email}&token=${res.data.magicLinkToken}`
        }`
      );
    })
    .catch((err) => {
      errorToast(err?.response?.data.message);
    });
};
const verifyOtp = (data, token, navigate) => (dispatch) => {
  console.log(token);
  publicApiCall
    .post('user/password/verify-otp', data, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      successToast(res.data.message);
      navigate(`/password-reset?token=${res.data?.recoveryToken}`);
    })
    .catch((err) => {
      errorToast(err?.response.data?.message);
      if (window.location.pathname === '/recover-password') navigate('/');
    });
};

const resendVerification = (email) => (dispatch) => {
  publicApiCall
    .post('user/verify-registered-email', { email })
    .then((res) => {
      successToast(res.data?.message);
    })
    .catch((err) => {
      errorToast(err?.response.data?.message);
    });
};

export const paymentVerification = (sessionId, navigate) => {
  ApiCall.post(`/creator/verify-gift-payment?sessionId=${sessionId}`)
    .then((res) => {
      successToast(res.data?.message);
      navigate('/dashboard/create-nft');
    })
    .catch((err) => {
      errorToast(err?.response.data?.message);
      navigate('/dashboard/create-nft');
    });
};

export const setUsernameApi = (username, token, navigate) => {
  publicApiCall
    .patch(
      `/user/set-username`,
      {
        username,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => {
      successToast(res.data?.message);
      // navigate('/dashboard');
    })
    .catch((err) => {
      errorToast(err.response.data?.message);
    });
};

export {
  verifyEmail,
  verifyOtp,
  recoverPassword,
  recoverPasswordUsingEmail,
  resendVerification,
};
