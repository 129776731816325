import React from 'react';
import Container from '../Container';
import NftDetailsContent from './components/NftDetailsContent';
import './NewNftDetails.css';

const NewNftDetails = () => {
  return (
    <Container>
      <NftDetailsContent />
    </Container>
  );
};

export default NewNftDetails;
