import publicApiCall from '../utils/publicAxiosCall';

export const getFeatureCampaign = () => {
  return publicApiCall.get('app/active-campaigns?isFeatured=true');
};

export const getFeatureNFT = () => {
  return publicApiCall.get('app/list-assets?limit=10&offset=0');
};

export const getNFT = (
  limit = 10,
  offset = 0,
  minPrice = 0,
  maxPrice = 100000000000000000,
  percentageMin = 0,
  percentageMax = 100,
  impactCategory = '',
  nftCategory = ''
) => {
  return publicApiCall.get(
    `app/list-assets?limit=${limit}&offset=${offset}&priceMin=${
      minPrice || 0
    }&priceMax=${maxPrice || 100000000000000}&percentageMin=${
      percentageMin || 0
    }&percentageMax=${
      percentageMax || 100
    }&impactCategory=${impactCategory}&nftCategory=${nftCategory}`
  );
};
