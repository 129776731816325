import React, { useEffect, useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';
import logo from '../../assets/loginInWhite.png';
import messageIcon from '../../assets/message-icon 1.png';
import '../../global/styles/LoginConfirmation.css';

const ResetPasswordEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  useEffect(() => {
    setEmail(window.location.search.split('=')[1]);
  }, []);
  return (
    <>
      <div className='login-confirmation-section'>
        <div className='login-confirmation-container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='confirmation-left'>
                <img src={logo} alt='' />
                <p></p>

                <div className='lets-get-started'>
                  <p>Let's Get Started</p>
                  <FaLongArrowAltRight className='forward-arrow' />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <img src={logo} className='responsive-logo' alt='' />
              <div className='reset-password-container'>
                <img src={messageIcon} alt='' />
                <h5>Check your Email</h5>
                <p>
                  We sent a password reset link to
                  <br /> <span style={{ fontWeight: 600 }}>{email}</span>
                </p>
                {/* <div style={{ textAlign: 'center' }}>
                  <button onClick={() => navigate('/set-new-password')}>
                    Reset Password
                  </button>
                </div> */}
                <span className='dont-recive-otp'>
                  DON’T RECEIVE THE EMAIL ?{' '}
                  <span style={{ color: '#F8991D' }}>CLICK TO RESEND</span>
                </span>

                <div className='back-to-login'>
                  <BiLeftArrowAlt className='back-arrow' />
                  <span>Back to log in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordEmail;
