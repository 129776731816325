import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { SubHeading } from '../../../global/styles/style-component';
import { getAllCampaign } from '../../../services/campaign';
import { getCategory, getSuperCategory } from '../../../services/vetting';
import { errorToast } from '../../../utils/toast';

const BrowseCategoryIndustry = () => {
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState([]);
  const [campaignFilter, setCampaignFilter] = useState([]);
  const [filter, setFilter] = useState({
    superCampaign: [],
    campaign: [],
    impactCategory: [],
    // industry: [],
  });
  const [filterValue, setFilterValue] = useState({
    superCampaign: '',
    campaign: '',
    impactCategory: '',
    // industry: '',
  });

  useEffect(() => {
    getAllCampaign()
      .then((res) => {
        setCampaign(res.data.ActiveCampaigns);
        setCampaignFilter(res.data.ActiveCampaigns);
      })
      .catch((err) => errorToast(err.response.data.message));
  }, []);

  useEffect(() => {
    getFilter();
  }, [campaign]);

  const getFilter = async () => {
    // const nftCategory = await getCategory('NFT');

    const superCategory = await getSuperCategory();

    const campaignCategory = await getCategory('CAMPAIGN');

    setFilter({
      ...filter,
      campaign: campaign
        .filter((i) => !i.isSuperCampaign)
        .map(({ overview }) => ({
          value: overview.campaignName,
          label: overview.campaignName,
        })),
      impactCategory: campaignCategory.data.categoriesList.map((item) => ({
        value: item,
        label: item,
      })),
      // industry: nftCategory.data.categoriesList.map((item) => ({
      //   value: item,
      //   label: item,
      // })),
      superCampaign: superCategory.data.superCampaignNames.map((item) => ({
        value: item.superCampaignName,
        label: item.superCampaignName,
      })),
    });
  };

  useEffect(() => {
    if (
      filterValue.campaign?.value ||
      filterValue.superCampaign?.value ||
      filterValue.impactCategory?.value
    ) {
      const tempData = campaign.filter(
        (item) =>
          item.overview.campaignName === filterValue.campaign?.value ||
          item.overview.superCampaignName ===
            filterValue.superCampaign?.value ||
          item.impact.category === filterValue.impactCategory?.value
      );
      setCampaignFilter(tempData);
    } else {
      setCampaignFilter(campaign);
    }
  }, [campaign, filterValue]);

  return (
    <div className='browse-category-section'>
      <div className='browseSearch'>
        <div>
          <span>Browse by campaign</span>
          <Select
            options={filter.campaign}
            value={filterValue.campaign}
            placeholder='Browse by Campaign'
            className='reactSelect'
            onChange={(e) => {
              setFilterValue({
                ...filterValue,
                campaign: e,
              });
            }}
          />
        </div>
        <div>
          <span>Browse by super campaign</span>
          <Select
            options={filter.superCampaign}
            value={filterValue.superCampaign}
            placeholder='Browse by Super Campaign'
            className='reactSelect'
            onChange={(e) => {
              setFilterValue({
                ...filterValue,
                superCampaign: e,
              });
            }}
          />
        </div>
        <div>
          <span>Browse by impact category</span>
          <Select
            options={filter.impactCategory}
            value={filterValue.impactCategory}
            placeholder='Browse by Impact Category'
            className='reactSelect'
            onChange={(e) => {
              setFilterValue({
                ...filterValue,
                impactCategory: e,
              });
            }}
          />
        </div>
        {/* <Select
          options={filter.industry}
          value={filterValue.industry}
          placeholder='Browse by Industry'
          className='reactSelect'
          onChange={(e) => {
            setFilterValue({
              ...filterValue,
              industry: e,
            });
          }}
        /> */}
        <MdCancel
          onClick={() => {
            setFilterValue({
              superCampaign: '',
              campaign: '',
              impactCategory: '',
              industry: '',
            });
          }}
          className='filter-icon'
        />
      </div>

      <div className='browse-cards'>
        <Grid container spacing={3}>
          {campaignFilter.map(({ overview, _id }) => (
            <Grid item xs={12} md={6} lg={3}>
              <div
                style={{
                  height: '100%',
                }}
                onClick={() => {
                  navigate(`/campaign-details/${_id}`);
                }}
                className='browse-card-container'
              >
                <img
                  style={{
                    width: '100%',
                    height: '250px',
                    objectFit: 'contain',
                  }}
                  src={`${process.env.REACT_APP_DOMAIN}${
                    overview?.bannerImageUrl[0] || ''
                  }`}
                  alt=''
                />
                <SubHeading>{overview.campaignName}</SubHeading>
                <div className='browse-card-content'>
                  <span>Description</span>
                  <p className='globalDescription'>{overview.description}</p>
                  {/* <span>Ending In</span>
                  <p>08 h : 11 m : 22 s</p> */}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default BrowseCategoryIndustry;
