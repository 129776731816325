import React from "react";
import { SubHeading } from "../../../global/styles/style-component";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import showcase1 from "../../../assets/influencer-details/showcase1.png";
import showcase2 from "../../../assets/influencer-details/showcase2.png";
import showcase3 from "../../../assets/influencer-details/showcase3.png";
import showcase4 from "../../../assets/influencer-details/showcase4.png";
import { AiFillTag, AiOutlineGift, AiOutlineFundView} from "react-icons/ai";
import { IoColorPaletteOutline  } from "react-icons/io5";
import { MdOutlineCloudDownload,MdOutlineDomainVerification } from "react-icons/md";
import { VscTag } from "react-icons/vsc";
import { TfiControlShuffle,TfiGallery } from "react-icons/tfi";
import { HiOutlineCollection } from "react-icons/hi";
import { RiSuitcaseLine, RiHandHeartLine } from "react-icons/ri";
import { GiMusicalNotes } from "react-icons/gi";
import { TbWorldUpload } from "react-icons/tb";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InfluencerShowcase = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="influencer-showcase-section">
      <div className="influencer-showcase-container">
        <div className="showcase-filter-tabs">
          <SubHeading>SHOWCASE</SubHeading>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  icon={<AiFillTag />}
                  iconPosition="start"
                  label="On Sale"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<MdOutlineCloudDownload />}
                  iconPosition="start"
                  label="Owned"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<IoColorPaletteOutline />}
                  iconPosition="start"
                  label="Created(20)"
                  {...a11yProps(2)}
                />
                <Tab
                  icon={<VscTag />}
                  iconPosition="start"
                  label="Collections"
                  {...a11yProps(3)}
                />
                <Tab
                  icon={<TfiControlShuffle />}
                  iconPosition="start"
                  label="Activity"
                  {...a11yProps(4)}
                />
                <Tab
                  icon={<AiOutlineGift />}
                  iconPosition="start"
                  label="Gifts"
                  {...a11yProps(5)}
                />
                <Tab
                  icon={<RiHandHeartLine />}
                  iconPosition="start"
                  label="Contributions"
                  {...a11yProps(6)}
                />
                <Tab
                  icon={<RiSuitcaseLine />}
                  iconPosition="start"
                  label="Personal Portfolio"
                  {...a11yProps(7)}
                  className="single-showcase-tab"
                />

                <div className="tabs-border"></div>

                <Tab
                className="tabs-icon"
                  icon={<AiOutlineFundView />}
                  iconPosition="start"
                  label="All"
                  {...a11yProps(9)}
                />
                <Tab
                className="tabs-icon"
                  icon={<IoColorPaletteOutline />}
                  iconPosition="start"
                  label="Art"
                  {...a11yProps(10)}
                />
                <Tab
                className="tabs-icon"
                  icon={<HiOutlineCollection />}
                  iconPosition="start"
                  label="Collectibles"
                  {...a11yProps(11)}
                />
                <Tab
                className="tabs-icon"
                  icon={<MdOutlineDomainVerification />}
                  iconPosition="start"
                  label="Domain"
                  {...a11yProps(12)}
                />
                <Tab
                className="tabs-icon"
                  icon={<GiMusicalNotes />}
                  iconPosition="start"
                  label="Music"
                  {...a11yProps(13)}
                />
                <Tab
                className="tabs-icon"
                  icon={<TfiGallery />}
                  iconPosition="start"
                  label="Photography"
                  {...a11yProps(14)}
                />
                <Tab
                className="tabs-icon"
                  icon={<TbWorldUpload />}
                  iconPosition="start"
                  label="Virtual World"
                  {...a11yProps(15)}
                />

                <select name="trending" id="trending">
                <option value="volvo">Trending</option>
                  <option value="volvo">Hello 1</option>
                  <option value="saab">Hello 2</option>
                  <option value="mercedes">Hello 2</option>
                  <option value="audi">Hello 3</option>
                </select>
              </Tabs>
            </Box>
          </Box>
        </div>

        <div className="showcase-filter-tab-panel">
          <TabPanel value={value} index={0} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase3} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase4} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase3} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase4} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={2} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={3} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={4} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={5} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={6} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={8} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase3} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase4} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase3} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase4} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={9} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={10} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={11} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={12} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={13} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
              <Grid xs={12} md={3}>
                <img src={showcase2} alt="" />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={14} className="filter-tab-panel">
            <Grid container>
              <Grid xs={12} md={3}>
                <img src={showcase1} alt="" />
              </Grid>
            </Grid>
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default InfluencerShowcase;
