import { Grid, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import React from 'react';
import SliderCard from '../../landing/components/SliderCard';

const NftList = ({ data = [], getNft }) => {
  return (
    <Grid container spacing={'1em'}>
      {data.length ? (
        data.map((item, index) => (
          <Grid key={index} item lg={3} md={4} sm={12}>
            <SliderCard data={item} getNFT={getNft} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <h3>NO NFT</h3>
        </Grid>
      )}
      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        <Pagination
          count={10}
          variant='outlined'
          shape='rounded'
          disabled={!(data.length > 9)}
          onChange={(e, p) => getNft(p - 1)}
        />
      </Grid>
    </Grid>
  );
};

export default NftList;
