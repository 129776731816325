import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { AiFillProfile, AiOutlineReload, AiTwotoneLike } from 'react-icons/ai';
import { BiBarChart } from 'react-icons/bi';
import { BsEyeFill, BsFillShareFill, BsFillTagFill } from 'react-icons/bs';
import { FiMoreVertical } from 'react-icons/fi';
import { MdCategory, MdDescription, MdStickyNote2 } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Menu } from '../../../global/components/Header/headerStyled';
import { Error } from '../../../global/components/vetting/vetting-style';
import { Btn, SubHeading } from '../../../global/styles/style-component';
import {
  addToFav,
  buyNft,
  getNftAssetHistory,
  getNftDetail,
} from '../../../services/campaign';
import ApiCall from '../../../utils/axiosCall';
import { errorToast, successToast } from '../../../utils/toast';

const NftDetailsContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const [nftDetail, setNftDetail] = useState();
  const [nftFlagReason, setNftFlagReason] = useState({
    value: '',
    error: '',
  });
  const [nftMoreOption, setNftMoreOption] = useState(false);
  const [isFlag, setIsFlag] = useState(false);
  const [nftAssetHistory, setNftAssetHistory] = useState([]);
  useEffect(() => {
    getNFT();
    getNftHistory();
  }, []);
  const getNftHistory = () => {
    getNftAssetHistory(id)
      .then((res) => {
        setNftAssetHistory(res?.data?.assetHistory);
      })
      .catch((err) => errorToast(err?.response?.data?.message));
  };
  const flagNft = () => {
    ApiCall.post(`user/marketplace/report/${id}`, {
      reason: nftFlagReason.value,
    })
      .then((res) => {
        successToast(res.data.message);
        setIsFlag(false);
        setNftFlagReason({
          value: '',
          error: '',
        });
      })
      .catch((err) => {
        errorToast(err?.response?.data?.message);
      });
  };
  const getNFT = () => {
    getNftDetail(id)
      .then((res) => {
        setNftDetail(res.data.asset);
      })
      .catch((err) => errorToast(err.response.data.message));
  };
  return (
    <div className='nft-details-container'>
      <Dialog
        open={isFlag}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Reason'}</DialogTitle>
        <DialogContent
          sx={{
            width: '20vw',
          }}
        >
          <textarea
            rows='7'
            style={{
              width: '100%',
            }}
            maxLength={'600'}
            value={nftFlagReason.value}
            onChange={(e) =>
              setNftFlagReason({
                value: e.target.value,
                error: e.target.value ? '' : 'reason cant be empty',
              })
            }
          />
          <Error>{nftFlagReason?.error}</Error>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsFlag(false);
              setNftFlagReason({
                value: '',
                error: '',
              });
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              flagNft();
            }}
            autoFocus
          >
            Send report
          </Button>
        </DialogActions>
      </Dialog>
      <div className='nft-details-left'>
        <img
          src={`${process.env.REACT_APP_DOMAIN}${nftDetail?.imageUrl}`}
          alt=''
          style={{
            height: '600px',
            objectFit: 'contain',
            marginBottom: '2em',
          }}
        />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>
              <MdDescription /> DESCRIPTION
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{nftDetail?.description}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography>
              <MdCategory /> CATEGORY
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{nftDetail?.nftCategory}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography>
              <AiFillProfile /> PROPERTIES
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography></Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography>
              <MdStickyNote2 /> DETAILS
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography></Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className='nft-details-right'>
        <div className='maggie-container'>
          <div className='maggie-heading'>
            <div>
              <SubHeading className='singleLineText'>
                {nftDetail?.itemName}
              </SubHeading>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <h6>Owner</h6>
                <span
                  onClick={() => {
                    navigate(
                      `/creator-portfolio/${
                        nftDetail?.owner?.ownerId ||
                        nftDetail?.creator?.creatorId
                      }`
                    );
                  }}
                >
                  {nftDetail?.owner?.username || nftDetail?.creator?.username}
                </span>
                <div
                  style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <BsEyeFill size={20} /> <b>{nftDetail?.viewsCount} views</b>
                </div>
                <div
                  style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <AiTwotoneLike
                    onClick={() => {
                      addToFav(id).then((res) => {
                        successToast(res.data.message);
                        getNFT();
                      });
                    }}
                    size={20}
                  />{' '}
                  <b>{nftDetail?.favouritesCount}</b>
                </div>
              </div>
            </div>
            <div className='action-icons'>
              <AiOutlineReload onClick={() => getNFT()} />
              <BsFillShareFill
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  successToast('Successfully copy link');
                }}
              />

              <div
                style={{
                  position: 'relative',
                }}
              >
                <FiMoreVertical
                  onClick={() => {
                    setNftMoreOption(!nftMoreOption);
                  }}
                />
                <Menu
                  style={{
                    top: '50px',
                  }}
                  active={nftMoreOption}
                >
                  <a
                    href='_'
                    role='button'
                    className='navBtn'
                    onClick={(e) => {
                      e.preventDefault();
                      setIsFlag(true);
                    }}
                  >
                    Flag
                  </a>
                </Menu>
              </div>
            </div>
          </div>

          <div className='impact-initiative'>
            <div>
              <h5>{nftDetail?.impactInitiativeName}</h5>
              <ul>
                <li>
                  <span>Impact Category: </span>
                  {nftDetail?.impactCategory}
                </li>
                <li>
                  <span>Percentage to Impact:</span>{' '}
                  {nftDetail?.percentageToImpact}%
                </li>
              </ul>
            </div>
          </div>

          {/* <div className='sales-ends'>
            <p>Sale ends on October 23, 2022 at 10:26am UTC</p>
            <div>
              <div className='sales-hours'>
                <div>
                  <h5>11</h5>
                  <p>Hours</p>
                </div>
                <div>
                  <h5>:</h5>
                </div>
              </div>
              <div className='sales-hours'>
                <div>
                  <h5>02</h5>
                  <p>Minutes</p>
                </div>
                <div>
                  <h5>:</h5>
                </div>
              </div>
              <div className='sales-hours'>
                <div>
                  <h5>27</h5>
                  <p>Seconds</p>
                </div>
              </div>
            </div>
          </div> */}

          <div className='current-price'>
            <p>Current price</p>
            <SubHeading>
              {!nftDetail?.priceUsd
                ? 'GIFT'
                : nftDetail?.owner?.ownerId
                ? 'SOLD'
                : `$${nftDetail?.priceUsd}`}
            </SubHeading>
            {!nftDetail?.owner?.ownerId && (
              <Btn style={{ background: '#AEAEAE' }}>ADD TO CART</Btn>
            )}
            {nftDetail?.isListed && (
              <Btn
                onClick={() => {
                  if (isLoggedIn && !nftDetail?.owner?.ownerId) {
                    if (nftDetail?.priceUsd) {
                      buyNft(id)
                        .then((res) => {
                          successToast(res.data.message);
                          const stripePromise = loadStripe(
                            'pk_test_51Ll8XBKBvYFYDRD4KK8fJYK6T7n0ItwT9boFJlvAeKJK8CxlCokg8E2qBPwWaT4WzoPRZuSsFQmMpl7UIIqMj73m00jFLPj7Wv'
                          );
                          stripePromise.then((stripe) => {
                            stripe.redirectToCheckout({
                              sessionId: res.data.sessionId,
                            });
                          });
                        })
                        .catch((err) => {
                          errorToast(err.response.data.message);
                        });
                    }
                  } else if (!nftDetail?.owner?.ownerId) {
                    navigate('/login');
                  }
                }}
              >
                {!nftDetail?.owner?.ownerId ? 'BUY NOW' : 'SOLD'}
              </Btn>
            )}
          </div>
        </div>

        <div className='record-listing-accordion'>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                defaultChecked
              >
                <Typography>
                  <BiBarChart /> Ownership Record{' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={'1em'}>
                  <Grid
                    xs={6}
                    item
                    role='button'
                    display={'flex'}
                    onClick={() => {
                      navigate(
                        `/creator-portfolio/${nftDetail?.creator?.creatorId}`
                      );
                    }}
                  >
                    <img
                      src={
                        nftDetail?.creator?.avatarUrl ||
                        require('../../../assets/avatar.png')
                      }
                      alt=''
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '100%',
                        marginRight: '1em',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <h5>Creator: {nftDetail?.creator?.username}</h5>
                      <h6>
                        Wallet address:{' '}
                        {nftDetail?.creator?.walletAddress?.slice(0, 4)}....
                        {nftDetail?.creator?.walletAddress?.slice(-4, -1)}
                      </h6>
                    </div>
                  </Grid>
                  {nftDetail?.owner?.ownerId && (
                    <Grid
                      xs={6}
                      item
                      display={'flex'}
                      role='button'
                      onClick={() => {
                        navigate(
                          `/creator-portfolio/${nftDetail?.owner?.ownerId}`
                        );
                      }}
                    >
                      <img
                        src={
                          nftDetail?.owner?.avatarUrl ||
                          require('../../../assets/avatar.png')
                        }
                        alt=''
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '100%',
                          marginRight: '1em',
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <h5>Owner: {nftDetail?.owner?.username}</h5>
                        <h6>
                          Wallet address:{' '}
                          {nftDetail?.owner?.walletAddress?.slice(0, 4)}....
                          {nftDetail?.owner?.walletAddress?.slice(-5, -1)}
                        </h6>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>

          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography>
                  {' '}
                  <BsFillTagFill /> LISTINGS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <table id='customers'>
                  <thead>
                    <tr>
                      <th>From</th>
                      <th>From (wallet)</th>
                      <th>To</th>
                      <th>To (wallet)</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nftAssetHistory.map((item, index) => (
                      <tr>
                        <td>{item?.from?.username}</td>
                        <td>
                          {item?.from?.walletAddress?.slice(0, 4)}
                          ...............
                          {item?.from?.walletAddress?.slice(-5, -1)}
                        </td>
                        <td>{item?.to?.username}</td>
                        <td>
                          {item?.to?.walletAddress?.slice(0, 4)}
                          ...............
                          {item?.to?.walletAddress?.slice(-5, -1)}
                        </td>
                        <td>{item.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftDetailsContent;
