import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import { BannerHeading } from '../../../global/styles/style-component';

const BrowseInfluencerBanner = () => {
  return (
    <>
      <PagerBanner
        imgUrl={require('../../../assets/nft-details/nft-details-banner.png')}
      >
        <BannerHeading>BROWSE INFLUENCER CAMPAIGNS</BannerHeading>
      </PagerBanner>
    </>
  );
};

export default BrowseInfluencerBanner;
