import { Box, Typography } from '@mui/material';
import { Heading, MainContainer } from '../../../global/styles/style-component';

import { useRef, useState } from 'react';
import YouTube from 'react-youtube';

const MeetTheFounder = () => {
  const [vdoplaying, setvdoplaying] = useState(true);

  const vdoref = useRef();

  function pause(ref) {
    ref.current.pause();
  }
  function play(ref) {
    ref.current.play();
  }

  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };
  return (
    <MainContainer>
      <Box
        sx={{
          py: { xs: '20px', md: '40px' },
        }}
      >
        <Heading marginBottom='50px'>MEET THE FOUNDERS</Heading>

        <Box
          sx={{
            display: { md: 'flex' },
            gap: '16px',
            alignItems: 'stretch',
          }}
        >
          {/* left */}
          <div
            style={{
              position: 'relative',
              padding: '20px',
              flex: 2,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 40,
                left: 32,
                background: '#209AB7',
                width: '160px',
                height: '160px',
                borderRadius: '50%',
              }}
            ></div>

            <div
              style={{
                position: 'absolute',
                background: '#F8AA52',
                bottom: 40,
                right: 80,
                width: '160px',
                height: '160px',
                borderRadius: '50%',
              }}
            ></div>
            {/* content */}
            <div
              style={{
                boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.20)',
                borderRadius: '8px',
                background: '#fff9',
                backdropFilter: 'blur(32px)',
                padding: '32px',
                position: 'relative',
                height: '100%',
              }}
            >
              <Typography
                sx={{
                  borderLeft: '5px solid #F8AA52',
                  pl: 2,
                  color: '#219EBC',
                  fontSize: '32px',
                  fontWeight: 600,
                  marginBottom: '40px',
                }}
                className='text-fonts'
              >
                Noleen Mariappen & Kala Philo
              </Typography>
              <Typography
                sx={{
                  color: '#023047',
                  textAlign: 'justify',
                  my: 3,
                  fontSize: '24px',
                }}
                className='typo-text-align type-text-size'
              >
                From a chance meeting to purposeful collaboration. Meet the
                Founders of demo and find out a little more about why they’re
                doing what they’re doing in this space.
              </Typography>
            </div>
          </div>
          {/* right */}

          <div
            style={{
              padding: '20px',
              flex: 1.5,
              position: 'relative',
            }}
          >
            {/* <div
              style={{
                position: 'absolute',
                inset: 0,
                zIndex: 1,
                color: '#fff',
                display: 'flex',
                gap: 32,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {vdoplaying ? (
                <BsPauseCircle
                  style={{ cursor: 'pointer' }}
                  size={32}
                  onClick={() => {
                    pause(vdoref);
                    setvdoplaying(false);
                  }}
                />
              ) : (
                <BsPlayCircle
                  style={{ cursor: 'pointer' }}
                  size={32}
                  onClick={() => {
                    play(vdoref);
                    setvdoplaying(true);
                  }}
                />
              )}

              <h3>Meet The Founders</h3>
            </div> */}
            <YouTube
              videoId='kQh1Wlv0i4w'
              opts={opts}
              className={'youtubeMeetToFounder'}
              onReady={(e) => e.target.pauseVideo()}
            />
          </div>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default MeetTheFounder;
