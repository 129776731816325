import React from "react";
import "./Faqs.css";
import Container from "../Container";
import FaqBanner from "./components/FaqBanner";
import FaqContent from "./components/FaqContent";


const Faqs = () => {
  return (
    <Container>
        <FaqBanner/>
        <FaqContent/>
    </Container>
  );
};

export default Faqs;
