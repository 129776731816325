import React from 'react';
import { HalfColoredContainer } from '../../global/styles/style-component';
import Container from '../Container';
import InfluencerForms from './components/InfluencerForms';
import { CategoriesRegistrationHeader } from './formStyle';

const InfluencerForm = () => {
  return (
    <Container>
      <HalfColoredContainer height='auto' color='#FFB703'>
        <CategoriesRegistrationHeader>
          {/* <h2>Influencers</h2>
          <p></p> */}
        </CategoriesRegistrationHeader>
        <InfluencerForms />
      </HalfColoredContainer>
    </Container>
  );
};

export default InfluencerForm;
