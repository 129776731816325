import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import { BsSearch } from 'react-icons/bs';
import { BannerHeading } from '../../../global/styles/style-component';

const InfoHubBanner = ({ filter, setFilter }) => {
  return (
    <>
      <PagerBanner
        imgUrl={require('../../../../src/assets/nft-details/nft-details-banner.png')}
        className='info-hub-banner'
      >
        <BannerHeading>
          Welcome to the
          <br /> demo Info Hub
        </BannerHeading>
        <div className='info-search-input'>
          <input
            type='text'
            placeholder='Search here'
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          />
          <BsSearch className='search-icon' />
        </div>
      </PagerBanner>
    </>
  );
};

export default InfoHubBanner;
