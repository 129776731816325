import React from 'react';
import { MainContainer } from '../../global/styles/style-component';
import Container from '../Container';

const PrivacyPolicy = () => {
  return (
    <Container>
      <MainContainer
        style={{
          textAlign: 'justify',
        }}
        justifyContent='center'
        alignItem='center'
        height={'60vh'}
      >
        <h2 className='m-5'>Privacy Policy</h2>
        {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Similique
        asperiores, unde officiis velit aliquid, error totam consequuntur modi
        vitae rerum animi pariatur repellendus quam soluta quia quasi,
        repudiandae sunt vel. Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Aliquid fugiat expedita eos eius sit praesentium cupiditate.
        Impedit laboriosam deleniti, eveniet iure accusamus qui nisi harum, at,
        vitae pariatur a eos.
        <br />
        <br />
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
        voluptatem officia omnis laborum. Nesciunt, iure? Quidem recusandae,
        eius inventore commodi aspernatur voluptas, alias ratione excepturi
        temporibus quod qui, harum similique! Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Doloremque, asperiores nostrum? Rerum,
        quam dolor labore quibusdam atque id, ducimus iure fuga ab expedita
        dolorum vitae iusto facilis, dolore voluptate harum? Lorem ipsum dolor,
        sit amet consectetur adipisicing elit. Cum temporibus excepturi ipsum,
        ab nemo inventore voluptate esse, fugit voluptas nostrum, vitae possimus
        aut dignissimos quam accusantium ullam quos doloremque tempora. Lorem
        ipsum dolor sit, amet consectetur adipisicing elit. Illo vero
        distinctio, fugiat aliquam magnam dolorum quia minus sint quaerat nobis
        in ipsa possimus iste quos quisquam corrupti eligendi omnis dolore! */}
      </MainContainer>
    </Container>
  );
};

export default PrivacyPolicy;
