import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from '../../global/components/Custom';
import FormLeftSide from '../../global/components/FormLeftSide';
import LoginWithFacebook from '../../global/components/LoginWithFacebook';
import LoginWithGoogle from '../../global/components/LoginWithGoogle';
import { userRegister } from '../../redux/user/actionCreator';
import { errorToast } from '../../utils/toast';
import {
  validateEmail,
  validateName,
  validatePassword,
  validatePhone,
  validateUserName,
} from '../../utils/validation';
import './Registration.css';

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [data, setData] = useState({
    email: {
      value: '',
      error: '',
    },
    firstName: {
      value: '',
      error: '',
    },
    lastName: {
      value: '',
      error: '',
    },
    phone: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    confirmPassword: {
      value: '',
      error: '',
    },
    username: {
      value: '',
      error: '',
    },
    termsAndConditions: {
      value: false,
      error: '',
    },

    recaptchaToken: null,
    provider: 'email',
  });

  const onRegistrationHandler = () => {
    if (
      (validateEmail(data.email.value) || validatePhone(data.phone.value)) &&
      validatePassword(data.password.value) &&
      validatePassword(data.confirmPassword.value) &&
      data.password.value === data.confirmPassword.value &&
      validateUserName(data.username.value) &&
      validateName(data.lastName.value) &&
      validateName(data.firstName.value) &&
      data.termsAndConditions.value == true
    ) {
      const value = {
        email: data.email.value,
        phone: data.phone.value,
        password: data.password.value,
        confirmPassword: data.confirmPassword.value,
        username: data.username.value,
        lastName: data.lastName.value,
        firstName: data.firstName.value,
        recaptchaToken: data.recaptchaToken,
        provider: data.provider,
      };
      if (data.provider === 'email') {
        dispatch(userRegister(value, navigate));
      } else {
        if (data.recaptchaToken != null) {
          dispatch(userRegister(value, navigate));
        } else {
          errorToast('Select the Recaptcha token');
        }
      }
    } else {
      setData((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          error:
            prev.provider === 'email'
              ? validateEmail(prev.email.value)
                ? ''
                : 'Invalid Email format'
              : '',
        },
        phone: {
          ...prev.phone,
          error:
            prev.provider === 'phone'
              ? validatePhone(prev.phone.value)
                ? ''
                : 'Invalid phone format'
              : '',
        },
        password: {
          ...prev.password,
          error: validatePassword(prev.password.value)
            ? ''
            : 'Password should be minimum of 8 characters long',
        },
        confirmPassword: {
          ...prev.confirmPassword,
          error:
            prev.password.value === prev.confirmPassword.value
              ? ''
              : 'Password should be same',
        },
        username: {
          ...prev.username,
          error: validateUserName(prev.username.value)
            ? ''
            : 'Invalid username',
        },
        firstName: {
          ...prev.firstName,
          error: validateUserName(prev.firstName.value)
            ? ''
            : 'Invalid first name',
        },
        lastName: {
          ...prev.lastName,
          error: validateUserName(prev.lastName.value)
            ? ''
            : 'Invalid last name',
        },
        termsAndConditions: {
          ...prev.username,
          error:
            prev.termsAndConditions.value == true
              ? ''
              : 'Please select terms and conditions',
        },
      }));
    }
  };

  // useEffect(() => {
  //   try {
  //     if (data.provider === 'phone') {
  //       window.recaptchaVerifier = new RecaptchaVerifier(
  //         btnRef.current,
  //         {
  //           callback: (response) => {
  //             console.log('response', response);
  //             setData((prev) => ({ ...prev, recaptchaToken: response }));
  //           },
  //         },
  //         auth
  //       );
  //       window.recaptchaVerifier
  //         .render()
  //         .then((widgetId) => {
  //           window.recaptchaWidgetId = widgetId;
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, [data.provider]);

  return (
    <div className='registerContainer'>
      <FormLeftSide
        children={
          <span>
            INFLUENCERS reach out{' '}
            <Link role='button' to='/influencer-form'>
              HERE
            </Link>
            . We'll get you set up! BRANDS & AGENCIES or NON-PROFITS & CAUSES
            Register after Signing Up.
          </span>
        }
        bgColor={'linear-gradient(144.95deg, #F53803 4.51%, #F5D020 99.13%)'}
      />
      <div className='registerRight'>
        <div>
          <h2>Create An Account</h2>
          <h5>
            Already have account? <Link to='/login'>SIGN IN</Link>
          </h5>
          <div className='registrationDetailContainer'>
            <Input
              key={'firstName'}
              label={'First Name'}
              onChange={(e) => {
                setData({
                  ...data,
                  firstName: {
                    value: e.target.value,
                    error: validateName(e.target.value)
                      ? ''
                      : 'Invalid first name',
                  },
                });
              }}
              inputValue={data.firstName}
              type='text'
              id='firstName'
            />
            <Input
              key={'lastName'}
              label={'Last Name'}
              onChange={(e) => {
                setData({
                  ...data,
                  lastName: {
                    value: e.target.value,
                    error: validateName(e.target.value)
                      ? ''
                      : 'Invalid last name',
                  },
                });
              }}
              inputValue={data.lastName}
              type='text'
              id='lastName'
            />
            <Input
              key={'username'}
              label={'Username'}
              onChange={(e) => {
                setData({
                  ...data,
                  username: {
                    value: e.target.value,
                    error: validateUserName(e.target.value)
                      ? ''
                      : 'Invalid username',
                  },
                });
              }}
              inputValue={data.username}
              type='text'
              id='username'
            />
            {/* <div>
               <input
                onChange={(e) => {
                  setData({
                    ...data,
                    provider: e.target.value,
                    phone: {
                      value: '',
                      error: '',
                    },
                  });
                  btnRef.current.innerHTML = '';
                }}
                type='radio'
                id='email'
                name='provider'
                value='email'
                checked={data.provider === 'email'}
              />
              <label htmlFor='email'>Email</label> 
            </div>
            <div>
              {/* <input
                onChange={(e) => {
                  setData({
                    ...data,
                    provider: e.target.value,
                    email: {
                      value: '',
                      error: '',
                    },
                  });
                }}
                type='radio'
                id='phone'
                name='provider'
                value='phone'
                checked={data.provider === 'phone'}
              />
              <label htmlFor='phone'>Phone</label>
            </div>*/}
            <Input
              key={'email'}
              label={'Email'}
              onChange={(e) => {
                setData({
                  ...data,
                  email: {
                    value: e.target.value,
                    error: validateEmail(e.target.value)
                      ? ''
                      : 'Invalid email format',
                  },
                });
              }}
              inputValue={data.email}
              disabled={data.provider !== 'email'}
              type='email'
              id='email'
            />
            {/* <Input
              key={'phoneNo'}
              label={'Phone number'}
              onChange={(e) => {
                setData({
                  ...data,
                  phone: {
                    value: e.target.value,
                    error: validatePhone(e.target.value)
                      ? ''
                      : 'Invalid Phone number format',
                  },
                });
              }}
              inputValue={data.phone}
              disabled={data.provider !== 'phone'}
              type='number'
              id='phoneNo'
            /> */}
            <Input
              key={'password'}
              label={'Password'}
              onChange={(e) => {
                setData({
                  ...data,
                  password: {
                    value: e.target.value,
                    error: validatePassword(e.target.value)
                      ? ''
                      : 'Password should be minimum of 8 characters',
                  },
                  confirmPassword: {
                    ...data.confirmPassword,
                    error:
                      data.confirmPassword.value === e.target.value
                        ? ''
                        : "Password don't match",
                  },
                });
              }}
              inputValue={data.password}
              type={showPassword.password ? 'text' : 'password'}
              id='password'
            >
              <IconButton
                aria-label='toggle password visibility'
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    password: !showPassword.password,
                  })
                }
                edge='end'
              >
                {showPassword.password ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Input>
            <Input
              key={'confirmPassword'}
              label={'Re-enter Password'}
              onChange={(e) => {
                setData({
                  ...data,
                  confirmPassword: {
                    value: e.target.value,
                    error:
                      data.password.value === e.target.value
                        ? ''
                        : 'Password not match',
                  },
                });
              }}
              inputValue={data.confirmPassword}
              type={showPassword.confirmPassword ? 'text' : 'password'}
              id='confirmPassword'
            >
              <IconButton
                aria-label='toggle password visibility'
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirmPassword: !showPassword.confirmPassword,
                  })
                }
                edge='end'
              >
                {showPassword.confirmPassword ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </Input>
            <div>
              {' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '0.5em',
                }}
              >
                <input
                  type='checkbox'
                  id='terms'
                  name='terms'
                  onChange={(e) => {
                    setData({
                      ...data,
                      termsAndConditions: {
                        value: e.target.checked,
                        error: e.target.checked
                          ? ''
                          : 'Please select terms and conditions',
                      },
                    });
                  }}
                />
                <label htmlFor='terms'>
                  I've read and agree with
                  <Link to='/terms'> Terms of Service</Link> and{' '}
                  <Link to='/privacy-policy'> Privacy Policy</Link>
                </label>
                <br />
              </div>
              <span style={{ color: 'red', fontSize: '12px' }}>
                {data.termsAndConditions.error}
              </span>
            </div>
            {/* <div ref={btnRef} id='sign-in-button'></div> */}
            <button onClick={onRegistrationHandler}>SIGN UP</button>
          </div>
          <div className='registerLine'>
            <hr />
          </div>
          <div className='registerBtnContainer'>
            <LoginWithGoogle method={'Sign Up'} />
            <LoginWithFacebook method={'Sign Up'} />
            <div
              style={{
                cursor: 'not-allowed',
                filter: 'grayscale(1)',
              }}
            >
              <img src={require('../../assets/wallet.png')} alt='' />
              <button
                style={{
                  cursor: 'not-allowed',
                }}
              >
                Sign In With Wallet
              </button>
            </div>
          </div>
          <div onClick={() => navigate('/')} className='back-to-login'>
            <BiLeftArrowAlt className='back-arrow' />
            <span>Back to Home</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
