import styled from 'styled-components';

export const NFTPercentageBtn = styled.button`
  background-color: ${({ active }) =>
    active ? '#219ebc !important' : 'white'};
  color: ${({ active }) => (active ? 'whitesmoke' : '#219ebc')};
  padding: 0.5em 2em;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 1px solid #219ebc;
`;
