import React from 'react';
import PhoneInput from 'react-phone-number-input';
import {
  freeRegex,
  validateEmail,
  validateFullName,
  validateName,
  validatePhone,
  validateUrl,
} from '../../../utils/validation';
import { Error, FieldContainer, FieldItem } from './vetting-style';

const Reference = ({
  data = {
    personalReference: {
      personalReferenceName: {
        value: '',
        error: '',
      },
      personalReferencePhoneNum: {
        value: '',
        error: '',
      },
      personalReferenceEmail: {
        value: '',
        error: '',
      },
      personalReferenceRelationShip: {
        value: '',
        error: '',
      },
      personalReferenceBioLink: {
        value: '',
        error: '',
      },
    },
    professionalReference: {
      professionalReferenceName: {
        value: '',
        error: '',
      },
      professionalReferencePhoneNum: {
        value: '',
        error: '',
      },
      professionalReferenceEmail: {
        value: '',
        error: '',
      },
      professionalReferenceRelationShip: {
        value: '',
        error: '',
      },
      professionalReferenceBioLink: {
        value: '',
        error: '',
      },
    },
  },
  setData,
}) => {
  return (
    <FieldContainer noOfColumn={2}>
      <div>
        <FieldItem
          error={data.personalReference.personalReferenceName?.error !== ''}
        >
          <span>
            Please provide the name of a personal reference (First and Last
            name)
          </span>
          <input
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                personalReference: {
                  ...prev.personalReference,
                  personalReferenceName: {
                    value: e.target.value,
                    error: !validateFullName(e.target.value)
                      ? 'First and last name required '
                      : '',
                  },
                },
              }));
            }}
            value={data.personalReference.personalReferenceName.value}
            type='text'
            name='name'
            id='name'
          />
          <Error>{data.personalReference.personalReferenceName.error}</Error>
        </FieldItem>
        <FieldItem
          error={data.personalReference.personalReferenceEmail?.error !== ''}
        >
          <span>
            Please provide an email address of the above personal reference
          </span>
          <input
            type='text'
            value={data.personalReference.personalReferenceEmail?.value}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                personalReference: {
                  ...prev.personalReference,
                  personalReferenceEmail: {
                    value: e.target.value,
                    error: validateEmail(e.target.value)
                      ? ''
                      : 'A personal email address is required',
                  },
                },
              }));
            }}
          />
          <Error>{data.personalReference.personalReferenceEmail?.error}</Error>
        </FieldItem>
        <FieldItem
          error={data.personalReference.personalReferencePhoneNum?.error !== ''}
        >
          <span>
            Please provide a phone number for the above personal reference
          </span>
          <PhoneInput
            style={{ width: '100%' }}
            international
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                personalReference: {
                  ...prev.personalReference,
                  personalReferencePhoneNum: {
                    value: e,
                    error: !validatePhone(e)
                      ? 'A phone number is required '
                      : '',
                  },
                },
              }));
            }}
            value={data.personalReference.personalReferencePhoneNum.value}
            name='personalReferencePhoneNum'
            id='personalReferencePhoneNum'
          />
          <Error>
            {data.personalReference.personalReferencePhoneNum.error}
          </Error>
        </FieldItem>

        <FieldItem
          error={data.personalReference.personalReferenceBioLink?.error !== ''}
        >
          <span>
            Please provide a bio link for the above personal reference
          </span>
          <input
            type='text'
            value={data.personalReference.personalReferenceBioLink?.value}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                personalReference: {
                  ...prev.personalReference,
                  personalReferenceBioLink: {
                    value: e.target.value,
                    error: validateUrl(e.target.value)
                      ? ''
                      : 'A bio link is required ',
                  },
                },
              }));
            }}
          />
          <Error>
            {data.personalReference.personalReferenceBioLink?.error}
          </Error>
        </FieldItem>

        <FieldItem
          error={
            data.personalReference.personalReferenceRelationShip?.error !== ''
          }
        >
          <span>
            Please share the nature of the relationship with the above personal
            reference (e.g. friend, family member)
          </span>
          <input
            type='text'
            value={data.personalReference.personalReferenceRelationShip?.value}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                personalReference: {
                  ...prev.personalReference,
                  personalReferenceRelationShip: {
                    value: e.target.value,
                    error: e.target.value === '' ? 'Response is required ' : '',
                  },
                },
              }));
            }}
          />
          <Error>
            {data.personalReference.personalReferenceRelationShip?.error}
          </Error>
        </FieldItem>
      </div>
      <div>
        <FieldItem
          error={
            data.professionalReference.professionalReferenceName?.error !== ''
          }
        >
          <span>
            Please provide the name of a professional reference (First and Last
            name)
          </span>
          <input
            type='text'
            value={data.professionalReference.professionalReferenceName?.value}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                professionalReference: {
                  ...prev.professionalReference,
                  professionalReferenceName: {
                    value: e.target.value,
                    error: !freeRegex(e.target.value)
                      ? 'First and last name required '
                      : '',
                  },
                },
              }));
            }}
          />
          <Error>
            {data.professionalReference.professionalReferenceName?.error}
          </Error>
        </FieldItem>
        <FieldItem
          error={
            data.professionalReference.professionalReferenceEmail?.error !== ''
          }
        >
          <span>
            Please provide a business email address of the above professional
            reference
          </span>
          <input
            type='text'
            value={data.professionalReference.professionalReferenceEmail?.value}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                professionalReference: {
                  ...prev.professionalReference,
                  professionalReferenceEmail: {
                    value: e.target.value,
                    error: validateEmail(e.target.value)
                      ? ''
                      : 'A business email address is required',
                  },
                },
              }));
            }}
          />
          <Error>
            {data.professionalReference.professionalReferenceEmail?.error}
          </Error>
        </FieldItem>
        <FieldItem
          error={
            data.professionalReference.professionalReferencePhoneNum?.error !==
            ''
          }
        >
          <span>
            Please provide a phone number for the above professional reference
          </span>
          <PhoneInput
            style={{ width: '100%' }}
            international
            name='campaignName'
            id='campaignName'
            value={
              data.professionalReference.professionalReferencePhoneNum?.value
            }
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                professionalReference: {
                  ...prev.professionalReference,
                  professionalReferencePhoneNum: {
                    value: e,
                    error: validatePhone(e)
                      ? ''
                      : 'A phone number is required ',
                  },
                },
              }));
            }}
          />
          <Error>
            {data.professionalReference.professionalReferencePhoneNum?.error}
          </Error>
        </FieldItem>
        <FieldItem
          error={
            data.professionalReference.professionalReferenceBioLink?.error !==
            ''
          }
        >
          <span>
            Please provide a bio link for the above professional reference
          </span>
          <input
            type='text'
            value={
              data.professionalReference.professionalReferenceBioLink?.value
            }
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                professionalReference: {
                  ...prev.professionalReference,
                  professionalReferenceBioLink: {
                    value: e.target.value,
                    error: validateUrl(e.target.value)
                      ? ''
                      : 'A bio link is required ',
                  },
                },
              }));
            }}
          />
          <Error>
            {data.professionalReference.professionalReferenceBioLink?.error}
          </Error>
        </FieldItem>

        <FieldItem
          error={
            data.professionalReference.professionalReferenceRelationShip
              ?.error !== ''
          }
        >
          <span>
            Please share the nature of the relationship with the above
            professional reference (e.g. peer, manager)
          </span>
          <input
            type='text'
            value={
              data.professionalReference.professionalReferenceRelationShip
                ?.value
            }
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                professionalReference: {
                  ...prev.professionalReference,
                  professionalReferenceRelationShip: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'Please provide relationship'
                        : '',
                  },
                },
              }));
            }}
          />
          <Error>
            {
              data.professionalReference.professionalReferenceRelationShip
                ?.error
            }
          </Error>
        </FieldItem>
      </div>
    </FieldContainer>
  );
};

export default Reference;
