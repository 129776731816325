import { Grid, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AiOutlineFile,
  AiOutlinePieChart,
  AiOutlineStar,
} from 'react-icons/ai';
import { BsCurrencyDollar } from 'react-icons/bs';
import { MdOutlineInsertPhoto } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import giftIcon from '../../../../assets/GiftIcon.png';
import helpIcon from '../../../../assets/HelpIcon.png';
import { Heading } from '../../../../global/styles/style-component';
import ApiCall from '../../../../utils/axiosCall';
import { errorToast } from '../../../../utils/toast';
import DashboardContainer from '../../DashboardContainer';
import './Dashboard.css';
import { DashboardCard, DashboardCollectionImage } from './dashboardStyle';

const Dashboard = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const [
    { contributions, giftedIn, giftedOut, portfolio, purchased },
    setData,
  ] = useState({
    contributions: [],
    giftedIn: [],
    giftedOut: [],
    portfolio: [],
    purchased: [],
  });
  const [userActivities, setUserActivities] = useState([]);
  useEffect(() => {
    ApiCall.get('user/list/my-collection')
      .then((res) => {
        const { contributions, giftedIn, giftedOut, portfolio, purchased } =
          res.data.userCollection;
        setData({ contributions, giftedIn, giftedOut, portfolio, purchased });
      })
      .catch((err) => {
        console.log(err);
        errorToast(err.response.data.message);
      });
  }, []);

  const [isVerified, setIsVerified] = useState(false);
  useEffect(() => {
    checkIfVerified();
    getUserActivities();
  }, []);

  const checkIfVerified = () => {
    ApiCall.get('user/account-verification')
      .then((res) => {
        setIsVerified(res.data.success);
      })
      .catch((err) => setIsVerified(err.response?.data?.success));
  };

  const getUserActivities = () => {
    ApiCall.get('/user/dashboard/user-activities?limit=20&offset=0').then(
      (res) => {
        const filterActive = res.data?.userActivities?.map((item) => ({
          ...item,
          asset: {
            ...item.asset,
            name:
              item?.asset?.name.length > 150
                ? `${item?.asset?.name.substring(0, 150)}...`
                : item?.asset?.name,
          },
        }));
        setUserActivities(filterActive);
      }
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'from.username', //access nested data with dot notation
        header: 'From',
      },
      {
        accessorKey: 'to.username',
        header: 'To',
      },
      {
        accessorKey: 'verb',
        header: 'Transaction Propose',
      },
      {
        accessorKey: 'amount', //normal accessorKey
        header: 'Amount',
      },
      {
        accessorKey: 'asset',
        header: 'Asset',
        Cell: ({ cell, row }) => {
          console.log(cell.getValue());
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <img
                alt='avatar'
                height={30}
                width={30}
                src={`${process.env.REACT_APP_DOMAIN}${
                  cell.getValue().imageUrl
                }`}
                loading='lazy'
                style={{ borderRadius: '50%' }}
              />
              <Typography>{cell.getValue()?.name}</Typography>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <DashboardContainer>
      <Grid container spacing={'1em'}>
        <Grid item sm='6' md='4' lg='2'>
          <DashboardCard bgColor={'#cedff1'} fill={'#1977d5'}>
            <AiOutlineFile />
            <div>
              <span>Purchases</span>
              <b>{purchased.length}</b>
            </div>
          </DashboardCard>
        </Grid>
        <Grid item sm='6' md='4' lg='2'>
          <DashboardCard fill={'#FCBF75'} bgColor='#F8F0D7'>
            <MdOutlineInsertPhoto />
            <div>
              <span>Contribution's</span>
              <b>${user?.data.totalUsdContributions || 0}</b>
            </div>
          </DashboardCard>
        </Grid>
        <Grid item sm='6' md='4' lg='2'>
          <DashboardCard fill={'#656ECD'} bgColor='#C1C5F0'>
            <AiOutlinePieChart />
            <div>
              <span>Gift Out</span>
              <b>{giftedOut.length}</b>
            </div>
          </DashboardCard>
        </Grid>
        <Grid item sm='6' md='4' lg='2'>
          <DashboardCard fill={'#ADDBFF'} bgColor='#F8F0D7'>
            <BsCurrencyDollar />
            <div>
              <span>Gift In</span>
              <b>{giftedIn.length}</b>
            </div>
          </DashboardCard>
        </Grid>
        <Grid item sm='6' md='4' lg='2'>
          <DashboardCard fill={'#4BDEE7'} bgColor='#C8F2F5'>
            <AiOutlineStar />
            <div>
              <span>Portfolio</span>
              <b>{portfolio.length}</b>
            </div>
          </DashboardCard>
        </Grid>
        <Grid item sm='6' md='4' lg='2'>
          <DashboardCard fill={'#4BDEE7'} bgColor='#C8F2F5'>
            <AiOutlineStar />
            <div>
              <span>
                Total Sales: <b>${user?.data?.withdrawableBal?.toFixed(2)}</b>
              </span>
              <span
                onClick={() => {
                  if (isVerified) {
                    ApiCall.get('payments/connect-account/payment-dashboard')
                      .then((res) => {
                        window.open(res.data.url, '_blank');
                      })
                      .catch((err) => errorToast(err.response?.data?.message));
                  } else {
                    ApiCall.post('user/verify-account')
                      .then((res) => {
                        // checkIfVerified();
                        window.open(res.data.url, '_self');
                      })
                      .catch((err) => errorToast(err.response?.data?.message));
                  }
                }}
                style={{
                  color: '#FB8501',
                  fontWeight: '599',
                  cursor: 'pointer',
                }}
              >
                {isVerified ? 'Payments' : 'Click to verify'}
              </span>
            </div>
          </DashboardCard>
        </Grid>
      </Grid>
      <div className='dashboardButtonContainer'>
        <button
          onClick={() => navigate('/dashboard/create-nft?type=contribution')}
        >
          Create an NFT
        </button>
        <button onClick={() => navigate('/dashboard/create-nft?type=gift')}>
          Gift an NFT
        </button>
      </div>
      <div className='myCollectionContainer'>
        <div>
          <strong>All Assets</strong>
          <div>
            {/* <button>
              <MdFormatListBulleted /> List View
            </button>
            <button>
              <FiFilter /> Filter
            </button> */}
          </div>
        </div>
        <Grid container spacing={'2em'}>
          {[...contributions, ...giftedIn, ...portfolio, ...purchased]
            .slice(0, 6)
            .map(({ imageUrl, giftRecipientName, _id }, index) => (
              <Grid item sm='6' md='4' lg='2'>
                <DashboardCollectionImage
                  onClick={() => {
                    if (index === 5) {
                      navigate('/dashboard/collections');
                    } else {
                      navigate(`/nft-details/${_id}`);
                    }
                  }}
                  isLast={index === 5}
                >
                  {giftRecipientName ? (
                    <img
                      style={{
                        width: '50px',
                        height: '50px',
                        position: 'absolute',
                        right: '0',
                        top: '10px',
                        zIndex: '50',
                      }}
                      src={giftIcon}
                      alt=''
                    />
                  ) : (
                    <img
                      style={{
                        width: '50px',
                        height: '50px',
                        position: 'absolute',
                        right: '0',
                        top: '10px',
                        zIndex: '50',
                      }}
                      src={helpIcon}
                      alt=''
                    />
                  )}
                  <img
                    key={index}
                    className='collectionCardImage'
                    src={`${process.env.REACT_APP_DOMAIN}${imageUrl}`}
                    alt=''
                  />
                </DashboardCollectionImage>
              </Grid>
            ))}
        </Grid>
      </div>
      <Heading marginTop={'1em'}>User Activities</Heading>
      <MaterialReactTable columns={columns} data={userActivities} />
      {/* <div className='d-flex mt-5'>
        <table id='customers'>
          <thead>
            <tr>
              <th>Status</th>
              <th>Customers</th>
              <th>From</th>
              <th>To</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
             <tr>
              <td>Alfreds Futterkiste</td>
              <td>Maria Anders</td>
              <td>Germany</td>
              <td>Germany</td>
              <td>Germany</td>
            </tr> 
          </tbody>
        </table>
        <div
          style={{
            width: '35%',
          }}
        ></div>
      </div> */}
    </DashboardContainer>
  );
};

export default Dashboard;
