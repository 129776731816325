import React from 'react';
import { IoSearch } from 'react-icons/io5';
import {
  MdOutlineShoppingBag,
  MdNotificationsNone,
  MdLogout,
} from 'react-icons/md';
import { RiMessage2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './Header.css';
import { successToast } from '../../../../utils/toast';
import { Popover2 } from '@blueprintjs/popover2';
import { Button, mergeRefs } from '@blueprintjs/core';
import { useState } from 'react';
import { CommonBtn } from '../../../../global/styles/style-component';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../../redux/user/userReducer';
import { ProfileTab, SidebarItem } from '../../dashboardStyled';
import { BiUser } from 'react-icons/bi';

const Header = () => {
  const user = useSelector((state) => state.users.user);
  const [openProfile, setOpenProfile] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className='headerContainer'>
      <div></div>
      <div className='headerContentContainer'>
        {/* <div className='searchInputContainer'>
          <IoSearch />
          <input
            type='text'
            name='search'
            id='search'
            placeholder='Search items, Collections'
          />
        </div> */}
        <MdOutlineShoppingBag />
        <MdNotificationsNone />
        {/* <RiMessage2Line /> */}
        <hr />

        <div
          onClick={() => {
            navigator.clipboard.writeText(user?.data?.walletAddress);
            successToast('Wallet address copied to clipboard');
          }}
          className='walletContainer'
        >
          <ContentCopyIcon />
          <div>
            <span>
              {user?.data?.walletAddress.slice(0, 4)}.......
              {user?.data?.walletAddress.slice(-5, -1)}
            </span>
          </div>
        </div>
        <div className='detailsContainer'>
          <img
            src={
              user?.data?.avatarUrl
                ? `${process.env.REACT_APP_DOMAIN}${user?.data?.avatarUrl}`
                : require('../../../../assets/dummyavatar.png')
            }
            style={{
              width: '50px',
              height: '50px',
              border: '3px solid #000',
              borderRadius: '100%',
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenProfile(!openProfile);
            }}
            alt=''
            srcset=''
          />
          {openProfile && (
            <div
              style={{
                top: '65px',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                background: '#fff',
                minWidth: '200px',
                boxShadow: '0px 14px 32px rgba(0, 0, 0, 0.25)',
                padding: '20px 12px',
                right: '0',
                borderRadius: '10px',
                zIndex: '500',
                gap: '0.5em',
              }}
            >
              <img
                src={
                  user?.data?.avatarUrl
                    ? `${process.env.REACT_APP_DOMAIN}${user?.data?.avatarUrl}`
                    : require('../../../../assets/dummyavatar.png')
                }
                style={{
                  width: '80px',
                  height: '80px',
                  border: '3px solid #000',
                  borderRadius: '100%',
                }}
                alt=''
                srcset=''
              />
              <h5>{user.data.fullName || user.data.username}</h5>
              <h6>{user.data.userRole !== 'General' && user.data.userRole}</h6>
              <ProfileTab
                onClick={() => {
                  navigate('/dashboard/profile');
                }}
              >
                <BiUser />
                <span> Profile</span>
              </ProfileTab>
              <ProfileTab
                onClick={() => {
                  dispatch(logout());
                  localStorage.removeItem('accessToken');
                  navigate('/');
                }}
                key={'logout'}
              >
                <MdLogout /> <span>logout</span>
              </ProfileTab>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
