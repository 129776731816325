import React from 'react';
import user from '../../../assets/admin-dashboard/user.png';
import { BsFacebook, BsDiscord, BsTelegram } from 'react-icons/bs';
import { AiFillTwitterCircle, AiOutlinePlus } from 'react-icons/ai';
import UserDetailsForm from './UserDetailsForm';
import { AiFillCamera } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import ApiCall from '../../../utils/axiosCall';
import { successToast } from '../../../utils/toast';
import { getUserDetail } from '../../../redux/user/actionCreator';

const PersonalDetails = () => {
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  return (
    <div className='user-personal-details-container'>
      <div className='user-profile-sidebar'>
        <div>
          <div className='profile-picture'>
            <img
              src={
                user?.data?.avatarUrl
                  ? `${process.env.REACT_APP_DOMAIN}${user?.data?.avatarUrl}`
                  : require('../../../assets/dummyavatar.png')
              }
              alt=''
            />
            <div className='change-profile'>
              <label htmlFor='profile'>
                <AiFillCamera />
              </label>
              <input
                id='profile'
                name='profile'
                type='file'
                accept='image/png, image/jpeg'
                hidden
                onChange={(e) => {
                  const formData = new FormData();
                  formData.append('avatar', e.target.files[0]);
                  ApiCall.put('user/user-profile/update-avatar', formData).then(
                    (res) => {
                      successToast(res.data.message);
                      dispatch(getUserDetail());
                    }
                  );
                }}
              />
            </div>
          </div>
          <h6>{user?.data?.username}</h6>
        </div>

        <div className='user-social-links'>
          <div>
            <h6>Social Links</h6>
            <button>
              <AiOutlinePlus /> Add
            </button>
          </div>
          <BsFacebook style={{ color: '#1976D2' }} />
          <input type='url' placeholder='Facebook' /> <br />
          <br />
          <AiFillTwitterCircle style={{ color: '#11E2FF' }} />
          <input type='url' placeholder='Twitter' /> <br />
          <br />
          <BsDiscord style={{ color: '#6563FF' }} />
          <input type='url' placeholder='Discord' /> <br />
          <br />
          <BsTelegram style={{ color: '#63C7FF' }} />
          <input type='url' placeholder='Telegram' /> <br />
          <br />
        </div>
      </div>

      <div className='user-personal-details'>
        <UserDetailsForm />
      </div>
    </div>
  );
};

export default PersonalDetails;
