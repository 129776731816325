import React from 'react';
import { InputContainer } from './customStyled';

const Input = ({
  id,
  label,
  type,
  inputValue,
  onChange,
  fontSize,
  disabled,
  children,
}) => {
  return (
    <InputContainer
      fontSize={fontSize}
      error={inputValue?.error !== '' && !disabled}
    >
      <label htmlFor={id}>{label}</label>
      <div>
        <input
          type={type}
          name={id}
          id={id}
          required
          value={inputValue?.value}
          onChange={onChange}
          disabled={disabled}
        />
        <div>{children}</div>
      </div>
      <span>{inputValue.error}</span>
    </InputContainer>
  );
};

export default Input;
