import React from 'react';
import { Grid } from '@mui/material';
import {
  CommonBtn,
  Heading,
  SubHeading,
} from '../../../global/styles/style-component';
import service from '../../../assets/build-impact-startup/full-services.png';
import earlyStage from '../../../assets/build-impact-startup/early-stage.png';
import lowQuality from '../../../assets/build-impact-startup/low-quality.png';
import build from '../../../assets/build-impact-startup/build-in-public.png';
import development from '../../../assets/build-impact-startup/product-development.png';
import value from '../../../assets/build-impact-startup/added-value.png';

const ImpactBenefits = () => {
  return (
    <div className='impact-benefits-container'>
      {/* <div style={{ textAlign: 'center' }}>
        <CommonBtn>CHECK OUT OUR DEVELOPMENT PROCESS</CommonBtn>
      </div> */}
      <div className='benefits-section'>
        <Heading>BENEFITS</Heading>
        <Grid container spacing={10}>
          {/* <Grid item xs={12} md={4} className="single-benefit">
            <img src={service} alt="" />
            <SubHeading>FULL SERVICE</SubHeading>
          </Grid> */}
          <Grid item md={3} className='single-benefit img-width1'>
            <img src={earlyStage} alt='' />
            <SubHeading>EARLY STAGE</SubHeading>
          </Grid>
          <Grid item md={3} className='single-benefit img-width2'>
            <img src={lowQuality} alt='' />
            <SubHeading>LOW EQUITY</SubHeading>
          </Grid>
          {/* <Grid item md={4} className='single-benefit img-width3'>
            <img src={build} alt='' />
            <SubHeading>BUILD IN PUBLIC</SubHeading>
          </Grid> */}
          <Grid item md={3} className='single-benefit img-width2'>
            <img src={development} alt='' />
            <SubHeading>PRODUCT DEVELOPMENT</SubHeading>
          </Grid>
          <Grid item md={3} className='single-benefit img-width2'>
            <img src={value} alt='' />
            <SubHeading>ADDED VALUE</SubHeading>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ImpactBenefits;
