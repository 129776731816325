import React from "react";
import "./DoneForYouServices.css";
import Container from "../Container";
import DoneForYouServicesBanner from "./components/DoneForYouServicesBanner";
import ServicesIntro from "./components/ServicesIntro";
import Services from "./components/Services";

const DoneForYouServices = () => {
  return (
    <Container>
        <DoneForYouServicesBanner/>
        <ServicesIntro/>
        <Services/>
    </Container>
  );
};

export default DoneForYouServices;
