import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonBtn } from '../../styles/style-component';
import { FormLeftSideContainer } from './formLeftSideStyled';

const FormLeftSide = ({ children, bgColor }) => {
  const navigate = useNavigate();
  return (
    <FormLeftSideContainer bgColor={bgColor}>
      {/* <CommonBtn onClick={() => navigate(-1)} alignSelf={'flex-start'} style={{marginLeft: '15px'}}>
        Go Back
      </CommonBtn> */}
      <div>
        <div>
          <img
            onClick={() => navigate('/')}
            src={require('../../../assets/loginInWhite.png')}
            alt=''
            srcSet=''
          />
          {/* {children} */}
        </div>
        <div>
          <h3>Let's Get Started</h3>
          <img
            src={require('../../../assets/rightIcon.png')}
            alt=''
            srcSet=''
          />
        </div>
      </div>
    </FormLeftSideContainer>
  );
};

export default FormLeftSide;
