import React from 'react';
import { MainContainer, Heading } from '../../../global/styles/style-component';
import './WhatDoWeDo.css';
import img1 from '../../../assets/images/homepage/cardImage (1).png';
import img2 from '../../../assets/images/homepage/cardImage (2).png';
import img3 from '../../../assets/images/homepage/cardImage (3).png';
import img4 from '../../../assets/images/homepage/cardImage (4).png';
import img5 from '../../../assets/images/homepage/cardImage (5).png';
import { Box } from '@mui/material';

const cardData = [
  {
    title: 'Brands and Agencies',
    desc: 'Align campaigns with vetted causes  to drive customer loyalty and build brand community.',
    image: img1,
  },
  {
    title: 'NPOs and Causes',
    desc: 'Collaborate with brands, creators and influencers to drive engagement and scale fundraising.',
    image: img2,
  },
  {
    title: 'Creators and Supporters',
    desc: 'Creators and Supporters Showcase your talent, collab with a cause, build or join a community.',
    image: img3,
  },
  {
    title: 'Influencers',
    desc: 'Do good while skyrocketing fan engagement and building your brand.',
    image: img4,
  },
  {
    title: 'Founders and Startups',
    desc: 'This is Founders and Startups description',
    image: img5,
  },
];
const WhatDoWeDo = () => {
  return (
    <>
      <MainContainer id='whatDoWeDoContainer' paddingVertical='2em'>
        <h3>
          demo is helping capitalism evolve to scale beneficial impact for
          people and the planet.
        </h3>
        <div>
          <div style={{ textAlign: 'center', marginBlock: 60 }}>
            <Heading marginBottom='0'>WHO IS demo FOR?</Heading>
            <p style={{ color: '#023047' }}>
              If you want to have a positive impact, you're in the right place!
            </p>
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              justifyContent: 'space-between',
              gap: { xs: '100px', md: '40px' },
              my: '80px',
            }}
            className='cardbox-container'
          >
            {cardData.map((el, i) => (
              <Box
                key={i}
                sx={{
                  position: 'relative',
                  width: { md: '300px', lg: '180px', xl: '220px' },
                  margin: 'auto',
                  '&:hover': {
                    '& .cardTop': {
                      transform: 'rotate(-70deg)',
                      transformOrigin: 'bottom right',
                    },
                    '& .cardBottom': {
                      transform: 'rotate(-10deg)',
                    },
                  },
                  '&:focus': {
                    '& .cardTop': {
                      transform: 'rotate(80deg)',
                      transformOrigin: 'bottom left',
                    },
                    '& .cardBottom': {
                      transform: 'rotate(-10deg)',
                    },
                  },
                }}
              >
                <div
                  className='cardBottom'
                  style={{
                    position: 'absolute',
                    transition: 'transform .5s ease-out',
                    inset: 0,
                    background: '#219EBC',
                    borderRadius: 40,
                    rotate: '10deg',
                    padding: 32,
                    fontSize: 20,
                  }}
                >
                  {el.desc}
                </div>
                <Box
                  className='cardTop'
                  sx={{
                    height: '320px',
                    position: 'relative',
                    transition: 'transform .5s ease-out',
                    padding: '32px',
                    boxShadow: ' 0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
                    backdropFilter: 'blur(20px)',
                    borderRadius: '40px',
                    background:
                      'linear-gradient(129.59deg, rgba(255, 255, 255, 0.4) -0.1%, rgba(255, 255, 255, 0.1) 95.18%)',
                  }}
                >
                  <div
                    style={{
                      width: 150,
                      height: 150,
                      margin: '0 auto',
                      padding: 24,
                      borderRadius: '50%',
                      display: 'grid',
                      placeItems: 'center',
                      backdropFilter: 'blur(20px)',
                      boxShadow: ' 0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
                      background:
                        'linear-gradient(129.59deg, rgba(255, 255, 255, 0.4) -0.1%, rgba(255, 255, 255, 0.1) 95.18%)',
                    }}
                  >
                    <img src={el.image} alt='' width={90} />
                  </div>
                  <p
                    style={{
                      textAlign: 'center',
                      marginBlock: 20,
                      fontWeight: 600,
                      fontSize: 20,
                    }}
                  >
                    {el.title}
                  </p>
                </Box>
              </Box>
            ))}
          </Box>
        </div>
      </MainContainer>
      <div id='wdqtn'>
        <MainContainer>
          <div className='whatDoWeDoBottomQuotation'>
            <img src={require('../../../assets/coma.png')} alt='' srcSet='' />
            <h3>
              "87 percent of consumers say they would purchase a product from a
              company that 'stood up or advocated for' an issue they care
              about."
              <span>-Deloitte</span>
            </h3>
          </div>
        </MainContainer>
      </div>
    </>
  );
};

export default WhatDoWeDo;
