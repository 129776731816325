import axios from 'axios';

export const baseURL = `${process.env.REACT_APP_DOMAIN}api`;

const ApiCall = axios.create({
  baseURL,
});

ApiCall.interceptors.request.use((request) => {
  document.querySelector('.overlay').style.display = 'block';
  if (localStorage.getItem('accessToken'))
    request.headers = {
      Authorization: localStorage.getItem('accessToken'),
    };
  return request;
});

ApiCall.interceptors.response.use(
  (response) => {
    document.querySelector('.overlay').style.display = 'none';
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.localStorage.clear();
      window.location.pathname = '/login';
    }
    document.querySelector('.overlay').style.display = 'none';
    // errorToast(error.response.data.message);
    return Promise.reject(error);
  }
);

export default ApiCall;
