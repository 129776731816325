import { Box, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonBtn } from '../../../global/styles/style-component';
import ApiCall from '../../../utils/axiosCall';
import { errorToast, successToast } from '../../../utils/toast';
import { CreatorCardContainer } from '../creatorStyled';

const initState = {
  itemName: {
    value: '',
    error: '',
  },
  description: {
    value: '',
    error: '',
  },
  priceUsd: {
    value: '',
    error: '',
  },
  percentageToImpact: {
    value: '',
    error: '',
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5em',
};

const CreatorCard = ({
  type,
  title = '',
  price,
  img,
  favCount,
  userList = [],
  btnTitle = 'Buy now',
  btnClick = () => {},
  description,
  percentageToImpact,
  nftType,
  id,
  isListed,
  fetchApi,
}) => {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(initState);
  const navigate = useNavigate();
  return (
    <>
      <Modal open={edit} onClose={() => setEdit(false)}>
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Edit Nft {title}
          </Typography>

          <div
            className='d-flex flex-column'
            style={{
              color: data.itemName.error !== '' && 'red',
              fontSize: '13px',
            }}
          >
            <label htmlFor='itemName'>Item Name</label>
            <input
              style={{
                outline: 'none',
                borderColor: data.itemName.error !== '' ? 'red' : '',
              }}
              value={data.itemName.value}
              type='text'
              name='itemName'
              id='itemName'
              placeholder='Raidparty Fighters'
              onChange={(e) => {
                setData({
                  ...data,
                  itemName: {
                    value: e.target.value,
                    error: e.target.value === '' ? 'Item name is required' : '',
                  },
                });
              }}
            />
            <span>{data.itemName?.error}</span>
          </div>
          <div
            className='d-flex flex-column'
            style={{
              color: data.description?.error !== '' && 'red',
              fontSize: '13px',
            }}
          >
            <label htmlFor='description'>
              Description ({data.description.value.length}/599)
            </label>
            <textarea
              name='description'
              id='description'
              cols='30'
              minLength={'60'}
              maxLength={'599'}
              rows='7'
              value={data.description.value}
              style={{
                outline: 'none',
                borderColor: data.description?.error !== '' ? 'red' : '',
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  description: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'Description is required'
                        : e.target.value.length > 15
                        ? ''
                        : 'Description should contain at least 15 characters',
                  },
                });
              }}
              placeholder='Provider a detail description of your team'
            ></textarea>
            <span>{data.description?.error}</span>
          </div>
          {nftType !== 'giftedOut' && (
            <div
              style={{
                color: data.priceUsd?.error !== '' && 'red',
                fontSize: '13px',
              }}
              className='salePriceContainer'
            >
              <label htmlFor='salePrice'>Instant sale price</label>
              <div>
                <input
                  value={data.priceUsd.value}
                  onChange={(e) => {
                    setData({
                      ...data,
                      priceUsd: {
                        value: e.target.value,
                        error:
                          e.target.value === '' ||
                          parseFloat(e.target.value) < 9.99
                            ? 'Sale Price must be at least 10 $ is required'
                            : '',
                      },
                    });
                  }}
                  style={{
                    outline: 'none',
                    borderColor: data.priceUsd?.error !== '' ? 'red' : '',
                  }}
                  min={100}
                  step={0.01}
                  type='number'
                  name='salePrice'
                  id='salePrice'
                  placeholder='Enter the price for which the team will be instantly sold'
                />
                <select>
                  <option>USD</option>
                </select>
              </div>
              <span>{data.priceUsd?.error}</span>
            </div>
          )}

          <div
            className='d-flex flex-column'
            style={{
              color: data.percentageToImpact.error !== '' && 'red',
              fontSize: '13px',
            }}
          >
            <label htmlFor='percentageToImpact'>Percentage to Impact</label>
            <input
              style={{
                outline: 'none',
                borderColor: data.percentageToImpact.error !== '' ? 'red' : '',
              }}
              value={data.percentageToImpact.value}
              type='text'
              name='percentageToImpact'
              id='percentageToImpact'
              placeholder='Percentage to impact'
              onChange={(e) => {
                setData({
                  ...data,
                  percentageToImpact: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'Percentage to impact is required'
                        : nftType === 'contributions'
                        ? parseInt(e.target.value) >= 50 &&
                          parseInt(e.target.value) <= 100
                          ? ''
                          : 'Percentage should be in between 50 to 100'
                        : nftType === 'portfolio'
                        ? parseInt(e.target.value) >= 10 &&
                          parseInt(e.target.value) <= 100
                          ? ''
                          : 'Percentage should be in between 10 to 100'
                        : '',
                  },
                });
              }}
            />
            <span>{data.percentageToImpact?.error}</span>
          </div>
          <CommonBtn
            onClick={() => {
              const tempData = Object.keys(data).reduce(
                (x, i) => ({
                  ...x,
                  [i]: data[i].value,
                }),
                []
              );
              ApiCall.put(`creator/update-asset/${id}`, tempData)
                .then((res) => {
                  successToast(res.data.message);
                  setData(initState);
                  setEdit(false);
                  fetchApi();
                })
                .catch((err) => {
                  errorToast(err.response.data.message);
                });
            }}
          >
            Update
          </CommonBtn>
        </Box>
      </Modal>
      <CreatorCardContainer onClick={() => btnClick}>
        <div className='creatorImg'>
          {(type === 'purchased' || type === 'contributions') && !isListed && (
            <div
              style={{
                left: '-20px',
                right: 'auto',
              }}
              className='favCount'
              onClick={() => {
                navigate(`/dashboard/collections/list/nft/${id}`);
              }}
            >
              <span>LIST</span>
            </div>
          )}
          {type !== 'favouriteAssets' && (
            <div
              className='favCount'
              onClick={() => {
                setEdit(true);
                setData({
                  itemName: {
                    value: title,
                    error: '',
                  },
                  description: {
                    value: description,
                    error: '',
                  },
                  percentageToImpact: {
                    value: percentageToImpact,
                    error: '',
                  },
                  priceUsd: {
                    value: price,
                    error: '',
                  },
                });
              }}
            >
              <span>Edit</span>
            </div>
          )}
          <div className='userList'>
            {userList.map((item, index) => (
              <img key={index} src={item} alt='' />
            ))}
          </div>
          <img src={img} alt='' />
        </div>
        <div className='d-flex mt-4 flex-row justify-content-between'>
          <h5 className='globalDescription' style={{ margin: '0' }}>
            {title}
          </h5>
        </div>
        <p className='globalDescription'>{description}</p>
        {price ? <h6>Price: ${price}</h6> : <h6>GIFT</h6>}

        <div className='d-flex flex-row justify-content-between'>
          <span
            onClick={btnClick}
            style={{
              color: '#219EBC',
              fontWeight: '700',
            }}
            role='button'
          >
            {btnTitle}
          </span>
          <div role='button' className='d-flex align-items-center gap-1'>
            {/* <GrHistory />
          <span>View History</span> */}
          </div>
        </div>
      </CreatorCardContainer>
    </>
  );
};

export default CreatorCard;
