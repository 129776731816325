import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import { errorToast, successToast } from '../../../utils/toast';
import useQuery from '../../../utils/useQuery';

const VerifyEmail = () => {
  let query = useQuery();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_DOMAIN}api/user/verify-email`,
          {},
          {
            headers: {
              Authorization: query.get('token'),
            },
          }
        )
        .then((res) => {
          successToast(res.data.message);
          navigate('/login');
        })
        .catch((err) => {
          errorToast(err?.response?.data.message);
          navigate('/');
        });
    }
  }, [loading, navigate, query]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      Email Verification
      <DotLoader color='#36d7b7' />
    </div>
  );
};

export default VerifyEmail;
