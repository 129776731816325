import React from 'react';
import Header from '../Header/Header';
import './ContentContainer.css';

const ContentContainer = ({ children }) => {
  return (
    <div className='contentContainer'>
      <Header />
      {children}
    </div>
  );
};

export default ContentContainer;
