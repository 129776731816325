import React from 'react';
import { SubHeading } from '../../../global/styles/style-component';

const Milestones = () => {
    return (
        <div className='milestones-container'>
            <SubHeading>Milestones</SubHeading>

            <div>
                <div className="milestone-number">
                <h6>Milestones Number 1</h6>
                <span>08/12/2022</span>
                </div>
                <h5>Milestone ID</h5>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here</p>

                <div className="kpi">
                    <h5>KPI 1</h5>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <div className="kpi">
                    <h5>KPI 1</h5>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>

            <div>
                <div className="milestone-number">
                <h6>Milestones Number 1</h6>
                <span>08/12/2022</span>
                </div>
                <h5>Milestone ID</h5>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here</p>

                <div className="kpi">
                    <h5>KPI 1</h5>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <div className="kpi">
                    <h5>KPI 1</h5>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>
        </div>
    );
};

export default Milestones;