import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { getCategory } from '../../../services/vetting';
import { Btn } from '../../styles/style-component';
import {
  Error,
  FieldContainer,
  FieldItem,
  KpiContainer,
  MileStoneContainer,
} from './vetting-style';

const Impact = ({
  data = {
    impact: {
      sdgs: {
        value: '',
        error: '',
      },
      category: {
        value: '',
        error: '',
      },
      projectNeed: {
        value: '',
        error: '',
      },
      impactOnPeople: {
        value: '',
        error: '',
      },
      impactOnPlanet: {
        value: '',
        error: '',
      },
      kpis: {
        value: '',
        error: '',
      },
      agreeToCollaborate: {
        value: '',
        error: '',
      },
      milestones: [
        {
          milestoneDate: {
            value: '',
            error: '',
          },
          heading: {
            value: '',
            error: '',
          },
          description: {
            value: '',
            error: '',
          },
          milestoneKpis: [
            {
              value: '',
              error: '',
            },
          ],
        },
      ],
    },
  },
  setData,
}) => {
  const [category, setCategory] = useState([]);
  const [sdgs, setSDGs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let SDGS = await getCategory('SDGS');
      const category = await getCategory('CAMPAIGN');
      setCategory(category.data.categoriesList);
      setSDGs(SDGS.data.categoriesList);
    };
    fetchData();
  }, []);
  return (
    <FieldContainer noOfColumn={2}>
      <div>
        <FieldItem error={data.impact.projectNeed.error !== ''}>
          <span>
            Explain the tangible need for this project (
            {data.impact.projectNeed.value.length}/599)
          </span>
          <textarea
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                impact: {
                  ...prev.impact,
                  projectNeed: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'More than 15 characters required'
                        : e.target.value.length > 15
                        ? ''
                        : 'More than 15 characters required',
                  },
                },
              }));
            }}
            value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
          />
          <Error>{data.impact.projectNeed.error}</Error>
        </FieldItem>

        <FieldItem error={data.impact.category.error !== ''}>
          <span>Category</span>
          <select
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                impact: {
                  ...prev.impact,
                  category: {
                    value: e.target.value,
                    error:
                      e.target.value === '' ? 'Category is compulsory ' : '',
                  },
                },
              }));
            }}
            value={data.impact.category.value}
          >
            <option value=''>Select category</option>
            {category.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <Error>{data.impact.category.error}</Error>
        </FieldItem>
        <FieldItem error={data.impact.sdgs.error !== ''}>
          <span>What SDGs does your project address?</span>

          <Multiselect
            options={sdgs.map((item, index) => ({
              key: item,
              name: item,
            }))}
            selectedValues={data.impact.sdgs.value}
            displayValue='name'
            onSelect={(e) => {
              console.log(e);
              setData((prev) => ({
                ...prev,
                impact: {
                  ...prev.impact,
                  sdgs: {
                    value: e,
                    error: e.length ? '' : 'Minimum of one SDG is required ',
                  },
                },
              }));
            }}
          />
          {/* <input type="text" placeholder="Write here" /> */}
          <Error>{data.impact.sdgs.error}</Error>
        </FieldItem>

        <FieldItem error={data.impact.impactOnPeople.error !== ''}>
          <span>
            How will it positively impact people? (
            {data.impact.impactOnPeople.value.length}/599)
          </span>
          <textarea
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                impact: {
                  ...prev.impact,
                  impactOnPeople: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'More than 15 characters required '
                        : e.target.value.length > 15
                        ? ''
                        : 'More than 15 characters required ',
                  },
                },
              }));
            }}
            value={data.impact.impactOnPeople.value}
            type='text'
            maxLength={'599'}
            rows='7'
            name='name'
            id='name'
          />
          <Error>{data.impact.impactOnPeople.error}</Error>
        </FieldItem>

        <FieldItem error={data.impact.impactOnPlanet.error !== ''}>
          <span>
            How will it positively impact the planet? (
            {data.impact.impactOnPlanet.value.length}/599)
          </span>
          <textarea
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                impact: {
                  ...prev.impact,
                  impactOnPlanet: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'More than 15 characters required '
                        : e.target.value.length > 15
                        ? ''
                        : 'More than 15 characters required ',
                  },
                },
              }));
            }}
            value={data.impact.impactOnPlanet.value}
            type='text'
            name='name'
            maxLength={'599'}
            rows='7'
            id='name'
          />

          <Error>{data.impact.impactOnPlanet.error}</Error>
        </FieldItem>
      </div>
      <div>
        <span>Please list your milestones and corresponding KPIs</span>
        <MileStoneContainer>
          <h6>Milestones </h6>
          {data?.impact?.milestones &&
            Array.isArray(data?.impact?.milestones) &&
            data?.impact?.milestones.map((i, index) => (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h5>Milestone {index + 1}</h5>
                  {index !== 0 && (
                    <IconButton
                      onClick={() => {
                        const tempData = data.impact.milestones.filter(
                          (_, idx) => idx !== index
                        );
                        setData({
                          ...data,
                          impact: {
                            ...data.impact,
                            milestones: tempData,
                          },
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </div>
                <FieldItem error={i.milestoneDate.error !== ''}>
                  <span>Set Date</span>
                  <input
                    style={{
                      color: '#000',
                    }}
                    type='date'
                    name='date'
                    min={data.overview.startDate.value.split('T')[0]}
                    max={data.overview.endDate.value.split('T')[0]}
                    value={i.milestoneDate.value.split('T')[0]}
                    onChange={(e) => {
                      const tempData = data.impact.milestones;
                      tempData[index].milestoneDate = {
                        value: new Date(e.target.value).toISOString(),
                        error:
                          e.target.value === ''
                            ? 'Date required when milestone will be met'
                            : '',
                      };
                      setData({
                        ...data,
                        impact: {
                          ...data.impact,
                          milestones: tempData,
                        },
                      });
                    }}
                  />
                  <Error>{i.milestoneDate.error}</Error>
                </FieldItem>

                <FieldItem error={i.heading.error !== ''}>
                  <span>Milestone Heading</span>

                  <input
                    value={i.heading.value}
                    onChange={(e) => {
                      const tempData = data.impact.milestones;
                      tempData[index].heading = {
                        value: e.target.value,
                        error: e.target.value === '' ? 'Heading required' : '',
                      };
                      setData({
                        ...data,
                        impact: {
                          ...data.impact,
                          milestones: tempData,
                        },
                      });
                    }}
                    type='text'
                  />
                  <Error>{i.heading.error}</Error>
                </FieldItem>

                <FieldItem error={i.description.error !== ''}>
                  <span>Milestone Description</span>
                  <textarea
                    value={i.description.value}
                    onChange={(e) => {
                      const tempData = data.impact.milestones;
                      tempData[index].description = {
                        value: e.target.value,
                        error:
                          e.target.value.length < 15
                            ? 'More than 15 characters required'
                            : '',
                      };
                      setData({
                        ...data,
                        impact: {
                          ...data.impact,
                          milestones: tempData,
                        },
                      });
                    }}
                    // value={data.impact.impactOnPlanet.value}
                    type='text'
                    name='name'
                    maxLength={'599'}
                    rows='5'
                    id='name'
                  />

                  <Error>{i.description.error}</Error>
                </FieldItem>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <KpiContainer>
                    {i.milestoneKpis.map((item, idx) => (
                      <>
                        <h6>KPI (Key Performance Indicator) {idx + 1}</h6>
                        <FieldItem error={item.error !== ''}>
                          <div
                            style={{
                              display: 'flex',
                            }}
                          >
                            <input
                              style={{
                                flex: '1',
                              }}
                              type='text'
                              onChange={(e) => {
                                const tempData = data.impact.milestones;
                                tempData[index].milestoneKpis[idx].value =
                                  e.target.value;
                                tempData[index].milestoneKpis[idx].error =
                                  e.target.value !== ''
                                    ? ''
                                    : "Kpi can't be empty";
                                setData({
                                  ...data,
                                  impact: {
                                    ...data.impact,
                                    milestones: tempData,
                                  },
                                });
                              }}
                              value={item.value}
                            />
                            {idx !== 0 && (
                              <IconButton
                                onClick={() => {
                                  const tempData = data.impact.milestones;
                                  tempData[index].milestoneKpis =
                                    i.milestoneKpis.filter(
                                      (_, index) => index !== idx
                                    );
                                  setData({
                                    ...data,
                                    impact: {
                                      ...data.impact,
                                      milestones: tempData,
                                    },
                                  });
                                }}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </div>
                          <Error>{item.error}</Error>
                        </FieldItem>
                      </>
                    ))}
                    <Btn
                      width='100px'
                      color='rgba(230, 115, 54, 0.35)'
                      textColor='#E67336'
                      style={{ fontWeight: 700 }}
                      onClick={() => {
                        const tempData = data.impact.milestones;
                        tempData[index].milestoneKpis.push({
                          value: '',
                          error: '',
                        });
                        setData({
                          ...data,
                          impact: {
                            ...data.impact,
                            milestones: tempData,
                          },
                        });
                      }}
                    >
                      ADD KPI
                    </Btn>
                  </KpiContainer>
                </div>
              </>
            ))}
          {data.impact.milestones.length < 3 && (
            <FieldItem>
              <Btn
                onClick={() => {
                  const tempData = data.impact.milestones;
                  tempData.push({
                    milestoneDate: {
                      value: '',
                      error: '',
                    },
                    heading: {
                      value: '',
                      error: '',
                    },
                    description: {
                      value: '',
                      error: '',
                    },
                    milestoneKpis: [
                      {
                        value: '',
                        error: '',
                      },
                    ],
                  });
                  setData({
                    ...data,
                    impact: {
                      ...data.impact,
                      milestones: tempData,
                    },
                  });
                }}
                width='150px'
                color='rgba(230, 115, 54, 0.35)'
                textColor='#E67336'
                style={{ fontWeight: 700, textTransform: 'uppercase' }}
              >
                ADD milestone
              </Btn>
            </FieldItem>
          )}
          <FieldItem
            style={{
              justifyContent: 'center',
            }}
            error={data.impact.agreeToCollaborate.error !== ''}
          >
            <div className='checkboxlayout radioContainer'>
              <input
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    impact: {
                      ...prev.impact,
                      agreeToCollaborate: {
                        value: e.target.checked,
                        error: e.target.checked ? '' : 'Please select',
                      },
                    },
                  }));
                }}
                checked={data.impact.agreeToCollaborate.value}
                type='checkbox'
                id='decision'
                name='decision'
                value='yes'
              />
              <label htmlFor='decision'>
                I agree to share opportunities to collaborate to scale impact
              </label>{' '}
              <br />
              {/* <input type='radio' id='yes' name='agree' value='Yes' />
              <label for='yes' className='yes'>
                {' '}
                Yes
              </label>
              <input type='radio' id='no' name='agree' value='No' />
              <label for='no' className='no'>
                {' '}
                No
              </label>  */}
            </div>
            <Error>{data.impact.agreeToCollaborate.error}</Error>
          </FieldItem>
        </MileStoneContainer>
      </div>
    </FieldContainer>
  );
};

export default Impact;
