import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommonBtn } from '../../../../global/styles/style-component';
import { brandRegistration } from '../../../../services/categoriesRegistration';
import ApiCall from '../../../../utils/axiosCall';
import { errorToast } from '../../../../utils/toast';
import {
  freeRegex,
  validateEmail,
  validateName,
  validatePhone,
  validateUrl,
} from '../../../../utils/validation';
import { CategoryFromContainer } from '../../formStyle';
import Form from './Form';

const BrandFrom = ({ isAccept, detail }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    logo: '',
    cover: '',
    name: '',
    mainPersonName: '',
    mainPhoneNum: '',
    mainEmail: '',
    alternatePersonName: '',
    alternatePhoneNum: '',
    alternateEmail: '',
    about: '',
    country: '',
    city: '',
    category: '',
    websiteUrl: '',
    socials: '',
    feelImpactoverse: '',
    isRegistered: 'false',
    registrationNum: '',
    countryOfRegistration: '',
  });

  const [dataError, setDataError] = useState({
    logo: '',
    cover: '',
    name: '',
    mainPersonName: '',
    mainPhoneNum: '',
    mainEmail: '',
    alternatePersonName: '',
    alternatePhoneNum: '',
    alternateEmail: '',
    about: '',
    country: '',
    city: '',
    category: '',
    websiteUrl: '',
    socials: '',
    feelImpactoverse: '',
    isRegistered: '',
    registrationNum: '',
    countryOfRegistration: '',
  });

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    ApiCall.get('app/categories?kind=BRAND').then((res) => {
      setCategories(res.data.categoriesList);
    });
  }, []);
  const [isVerified, setIsVerified] = useState(false);
  useEffect(() => {
    checkIfVerified();
  }, []);

  const checkIfVerified = () => {
    ApiCall.get('user/account-verification')
      .then((res) => {
        setIsVerified(res.data.success);
      })
      .catch((err) => setIsVerified(err.response?.data?.success));
  };

  useEffect(() => {
    console.log(dataError);
  }, [dataError]);

  const onValidate = () => {
    setDataError({
      name:
        data.name === '' ? 'Please provide the Brand/Organization name' : '',
      mainPersonName:
        data.mainPersonName === ''
          ? 'Please provide First and Last names'
          : freeRegex(data.mainPersonName)
          ? ''
          : 'No numbers allowed',
      mainPhoneNum:
        data.mainPhoneNum === ''
          ? 'Please provide a Phone number'
          : validatePhone(data.mainPhoneNum)
          ? ''
          : 'Should only contain numbers',
      mainEmail:
        data.mainEmail === ''
          ? 'Please provide an Email address'
          : validateEmail(data.mainEmail)
          ? ''
          : 'Invalid email format',
      alternatePersonName:
        data.alternatePersonName === ''
          ? 'Please provide First and Last names'
          : validateName(data.alternatePersonName)
          ? ''
          : 'No numbers allowed',
      alternatePhoneNum:
        data.alternatePhoneNum === ''
          ? 'Please provide alternate phone'
          : validatePhone(data.alternatePhoneNum)
          ? ''
          : 'Should only contain number',
      alternateEmail:
        data.alternateEmail === ''
          ? 'Please provide an Email address'
          : validateEmail(data.alternateEmail)
          ? ''
          : 'Invalid email format',
      about:
        data.about === ''
          ? 'Please provide information on the brand/organization'
          : data.about.length > 15
          ? ''
          : 'Please provide a minimum of 15 characters',
      country: data.country === '' ? 'Please provide country' : '',
      city: data.city === '' ? 'Please provide city' : '',
      category: data.category === '' ? 'Please provide category' : '',
      websiteUrl:
        data.websiteUrl === ''
          ? 'Please provide website URL'
          : validateUrl(data.websiteUrl)
          ? ''
          : 'Invalid url',
      feelImpactoverse:
        data.feelImpactoverse === ''
          ? 'Please provide the benefits you feel demo offers'
          : '',
      countryOfRegistration:
        data.countryOfRegistration === ''
          ? 'Please provide country of registration'
          : '',
      registrationNum:
        data.registrationNum === ''
          ? 'Please provide Brand/Organization registration number'
          : '',
    });
  };
  return (
    <CategoryFromContainer>
      {isVerified ? (
        <>
          {' '}
          <h4>Fill in the required information</h4>
          <Form
            data={data}
            dataError={dataError}
            setData={setData}
            setDataError={setDataError}
          />
          <CommonBtn
            onClick={() => {
              if (
                !validateName(data.name) ||
                !validateName(data.mainPersonName) ||
                !validatePhone(data.mainPhoneNum) ||
                !validateEmail(data.mainEmail) ||
                !validateName(data.alternatePersonName) ||
                !validatePhone(data.alternatePhoneNum) ||
                !validateEmail(data.alternateEmail) ||
                data.about.length < 15 ||
                data.country === '' ||
                data.city === '' ||
                data.category === '' ||
                !validateUrl(data.websiteUrl) ||
                // !validateUrl(data.socials) ||
                data.feelImpactoverse < 15 ||
                data.registrationNum === '' ||
                data.countryOfRegistration === ''
              ) {
                onValidate();
                return;
              }

              const formData = new FormData();
              Object.keys(data).forEach((item, index) => {
                if (Array.isArray(data[item])) {
                  data[item].forEach((a, idx) => {
                    if (a !== '') {
                      formData.append(`${item}[${idx}]`, a);
                    }
                  });
                } else {
                  formData.append(item, data[item]);
                }
              });

              dispatch(brandRegistration(formData, navigate));
            }}
            margin='1em 0'
          >
            SUBMIT REGISTRATION
          </CommonBtn>
        </>
      ) : (
        <CommonBtn
          onClick={() => {
            ApiCall.post('user/verify-account')
              .then((res) => {
                // checkIfVerified();
                window.open(res.data.url, '_self');
              })
              .catch((err) => errorToast(err.response?.data?.message));
          }}
        >
          verify your account
        </CommonBtn>
      )}
    </CategoryFromContainer>
  );
};

export default BrandFrom;
