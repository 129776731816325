import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import community from '../../../assets/images/engagement.png';
import { CommonBtn, Heading } from '../../../global/styles/style-component';

const JoinImpactoverseCommunity = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  return (
    <div className='join-community-container'>
      <div>
        <Heading>
          Get Involved. Make A Difference. Join The demo Community
        </Heading>
        <div className='bttn-container'>
          <CommonBtn
            onClick={() => {
              if (isLoggedIn) {
                navigate('/dashboard');
              } else {
                navigate('/login');
              }
            }}
          >
            GET STARTED
          </CommonBtn>
          <CommonBtn
            onClick={() => {
              navigate('/info-hub-main');
            }}
            style={{ background: '#219EBC' }}
          >
            LEARN MORE
          </CommonBtn>
        </div>
      </div>
      <div>
        <img src={community} alt='' />
      </div>
    </div>
  );
};

export default JoinImpactoverseCommunity;
