import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../../utils/toast';
import { DotLoader } from 'react-spinners';

const PaymentFailure = () => {
  const navigate = useNavigate();
  useEffect(() => {
    errorToast('unable to proceed please try later');
    navigate('/dashboard/create-nft');
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      Payment Verification
      <br />
      <DotLoader color='#36d7b7' />
    </div>
  );
};

export default PaymentFailure;
