import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CommonBtn, MainContainer } from '../../global/styles/style-component';
import { getAllNft, getCampaignDetail } from '../../services/campaign';
import { errorToast } from '../../utils/toast';
import Container from '../Container';
import CampaignDetailBanner from './components/CampaignDetailBanner';
import WhySupportUs from './components/WhySupportUs';
import './NewCampaignDetails.css';

const NewCampaignDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const [organization, setOrganization] = useState({
    _id: '',
    name: '',
    logoUrl: '',
    about: '',
    country: '',
    city: '',
    category: '',
    websiteUrl: '',
    socials: [''],
    type: 'CAUSE',
  });
  const [campaign, setCampaign] = useState({});

  useEffect(() => {
    getCampaignDetail(id)
      .then((res) => {
        setCampaign(res.data.campaignDetail);
        setOrganization(res.data.organization);
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  }, []);

  const [data, setData] = useState([]);
  useEffect(() => {
    getAllNft(id)
      .then((res) => {
        setData(res.data.impactAssets);
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  }, []);

  return (
    <Container>
      <CampaignDetailBanner
        imgUrl={`${process.env.REACT_APP_DOMAIN}${
          campaign?.overview?.bannerImageUrl[0] || ''
        }`}
        title={campaign?.overview?.campaignName}
      />
      <div className='campaignDetailCard'>
        <img
          style={{
            background: '#fff',
            padding: '1em',
          }}
          src={`${process.env.REACT_APP_DOMAIN}${
            campaign?.overview?.bannerImageUrl[0] || ''
          }`}
          alt=''
        />
        <ul>
          {campaign.overview?.isSuperCampaign && (
            <li>
              <span>Campaign type:</span> <b>Super Campaign</b>
            </li>
          )}
          <li>
            <span>Campaign Owner:</span> <b>{organization?.name}</b>
          </li>
          <li>
            <span>Impact Category:</span> <b>{campaign?.impact?.category}</b>
          </li>

          {organization?.registrationNum && (
            <li>
              <span>Organization/NPO Reg. Number:</span>{' '}
              <b>{organization?.registrationNum || '---------'}</b>
            </li>
          )}
          {campaign?.overview?.superCampaignName && (
            <li>
              <span>Super Campaign Name:</span>{' '}
              <b>{campaign?.overview?.superCampaignName}</b>
            </li>
          )}
        </ul>
      </div>
      <MainContainer
        paddingVertical='1em'
        style={{
          margin: '3em 0',
        }}
      >
        <Grid container className='campaignDetailInfoCard' spacing={'1em'}>
          <Grid item xs={11}>
            <div
              style={{
                boxShadow: '-3px 4px 10px 1px #00000040',
                background: '#FFFFFF',
                display: 'flex',
                gap: '1em',
                width: 'fit-content',
                padding: '10px 15px',
                borderRadius: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    color: '#2097B3',
                  }}
                >
                  Start Date
                </span>
                {new Date(campaign?.overview?.startDate).toDateString()}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    color: '#2097B3',
                  }}
                >
                  End Date
                </span>
                {new Date(campaign?.overview?.endDate).toDateString()}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={7}
            position='relative'
            display={'flex'}
            gap='1em'
            padding='2em'
            height='90%'
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                gap: '1em',
              }}
            >
              <img
                src={
                  data[0]
                    ? `${process.env.REACT_APP_DOMAIN}${data[0]?.imageUrl}`
                    : require('../../assets/placeholder.png')
                }
                alt=''
                style={{
                  height: '100%',
                  width: '33%',
                  objectFit: 'cover',
                  borderRadius: '35px',
                }}
              />
              {/* <div
              style={{
                flex: '1',
                width: '50%',
                height: '100%',
                display: 'flex',
                gap: '1em',
              }}
            > */}
              <img
                src={
                  data[1]
                    ? `${process.env.REACT_APP_DOMAIN}${data[1].imageUrl}`
                    : require('../../assets/placeholder.png')
                }
                alt=''
                style={{
                  height: '100%',
                  width: '33%',
                  objectFit: 'cover',
                  borderRadius: '35px',
                }}
              />
              <img
                src={
                  data[2]
                    ? `${process.env.REACT_APP_DOMAIN}${data[2].imageUrl}`
                    : require('../../assets/placeholder.png')
                }
                alt=''
                style={{
                  height: '100%',
                  width: '33%',
                  objectFit: 'cover',
                  borderRadius: '35px',
                }}
              />
              {/* </div> */}
              <div
                style={{
                  backdropFilter: 'blur(10px)',
                  padding: '20px 35px',
                  position: 'absolute',
                  textTransform: 'uppercase',
                  top: '50%',
                  left: '50%',
                  width: '40%',
                  alignSelf: 'center',
                  fontSize: '30px',
                  fontWeight: '800',
                  transform: 'translate(-50%, -50%)',
                  border: '3px solid #fff',
                  borderRadius: '15px',
                  textAlign: 'center',
                  boxShadow: '4px 8px 17px rgba(0, 0, 0, 0.25)',
                }}
              >
                {campaign?.overview?.campaignType} campaign
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={5}
            display='flex'
            flexDirection={'column'}
            alignItems='center'
            gap='1em'
          >
            <div
              style={{
                background: '#219EBC',
                borderRadius: '35px',
                width: '100%',
                padding: '3em 2em',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  background: '#fff',
                  borderRadius: '20px',
                  padding: '5px',
                }}
              >
                <span
                  style={{
                    flex: '1',
                    padding: '1em 1.5em',
                    fontSize: '20px',
                    fontWeight: '700',
                  }}
                >
                  Total Amount Raised
                </span>
                <span
                  style={{
                    flex: '3',
                    background: '#64a7b9',
                    color: '#023047',
                    borderRadius: '0 20px 20px 0',
                    fontSize: '3em',
                    fontWeight: '900',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  ${campaign?.totalRaised?.toFixed(2)}
                </span>
              </div>
              <div className='brandDetail'>
                <ul>
                  <li>
                    <span>Brand/Cause Category:</span>{' '}
                    <b>{organization.category}</b>
                  </li>
                  <li>
                    <span>Brand/Cause Location:</span>{' '}
                    <b>{organization.country}</b>
                  </li>
                  <li>
                    <span>Brand/Cause Rep:</span>{' '}
                    <b>{organization.name || '---------'}</b>
                  </li>
                </ul>
              </div>
            </div>
            <CommonBtn
              onClick={() => {
                if (isLoggedIn) navigate('/dashboard/create-nft');
                else navigate('/login');
              }}
              style={{
                color: '#023047',
                fontSize: '24px',
                fontWeight: '700',
              }}
            >
              CREATE AN NFT FOR THIS CAMPAIGN
            </CommonBtn>
          </Grid>
        </Grid>
      </MainContainer>
      {/* <CreateNft campaign={campaign} organization={organization} /> */}
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {campaign.overview?.isSuperCampaign && (
          <CommonBtn
            onClick={() => {
              navigate(`/browse-sub-campaign/${id}`);
            }}
            style={{
              fontSize: '1.2rem',
            }}
            margin={'2em 0'}
          >
            View Sub Campaign
          </CommonBtn>
        )}
      </div>
      <WhySupportUs
        description={campaign?.overview?.description}
        feasibility={campaign?.feasibility}
        impact={campaign?.impact}
      />
    </Container>
  );
};

export default NewCampaignDetails;
