import React from 'react';

const Impactoverce = () => {
  return (
    <div className='demo-section'>
      <div className='demo-content'>
        <h2>demo MAKES IT EASY </h2>
        <p style={{ color: '#219EBC' }}>
          Web3 offers unprecedented opportunities for influencers to scale and
          monetize their audience without relying on the social platforms that
          currently control your access to your audience.
        </p>
        <p>
          demo provides a turnkey ecosystem for influencers getting started with
          NFTs and other Web3 marketing innovations. <br /> <br />
          We make it easy for you to collaborate, design, implement, manage,
          track and measure cause marketing campaigns using NFTs, tokens, and
          more.
        </p>
        <p style={{ color: '#219EBC' }}>
          We are super passionate about the synergy between what works in Web2
          and the emerging potential of Web3 to benefit influencers, people and
          the planet. We’d love to learn more about your brand and goals.
          Contact us for a convo today
        </p>
      </div>
      <div className='impactoverce-border'></div>
    </div>
  );
};

export default Impactoverce;
