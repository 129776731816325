import React from 'react';
import { Grid } from '@mui/material';
import plan from '../../../assets/build-impact-startup/plain.png';
import design from '../../../assets/build-impact-startup/design.png';
import architect from '../../../assets/build-impact-startup/architech.png';
import build from '../../../assets/build-impact-startup/build.png';
import test from '../../../assets/build-impact-startup/test.png';
import launch from '../../../assets/build-impact-startup/launch.png';

const PlanBuildLaunch = () => {
  return (
    <div className='plan-build-container'>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4} className='single-grid'>
          <div>
            <img src={plan} alt='' />
          </div>
          <h4>1. Plan</h4>
          <p>
            We’ll sit down with you (figuratively, not literally) to understand
            the details of your project idea and discuss how we can turn your
            dreams into reality.
          </p>
        </Grid>
        <Grid item xs={12} md={4} className='single-grid'>
          <div>
            <img src={design} alt='' />
          </div>
          <h4>2. Design</h4>
          <p>
            We’ll then mock up your entire user experience from scratch in one
            of those fancy designer programs, so we can sculpt it into a
            masterpiece.
          </p>
        </Grid>
        <Grid item xs={12} md={4} className='single-grid'>
          <div>
            <img src={architect} alt='' />
          </div>
          <h4>3. Architect</h4>
          <p>
            While our designers are at work, our engineers will be working on
            your apps architecture. This is the invisible structure that ensures
            everything runs smoothly and is 100% secure.
          </p>
        </Grid>
        <Grid item xs={12} md={4} className='single-grid'>
          <div>
            <img src={build} alt='' />
          </div>
          <h4>4. Build</h4>
          <p>
            We'll then write some of the most beautiful code you’ve ever seen.
            We follow strict guidelines and everything is fully documented -
            giving you a solid foundation to build on
          </p>
        </Grid>
        <Grid item xs={12} md={4} className='single-grid'>
          <div>
            <img src={test} alt='' />
          </div>
          <h4>5. Test</h4>
          <p>
            Meanwhile, we’re creating automated tests that we run to prevent any
            small changes from breaking the app and causing "help desk hell" for
            your team
          </p>
        </Grid>
        <Grid item xs={12} md={4} className='single-grid'>
          <div>
            <img src={launch} alt='' />
          </div>
          <h4>6. Launch</h4>
          <p>
            Finally, we’re ready for deployment (aka launch). Since a good app
            is more than just a pile of source code on GitHub - much of the user
            experience comes from a proper deployment process.
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default PlanBuildLaunch;
