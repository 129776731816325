import styled from 'styled-components';

export const CardOverLay = styled.div`
  position: absolute;
  border: 1px solid ${({ color }) => color || '#fb8501'};
  min-width: 100%;
  min-height: 100%;
  top: -1em;
  left: -1em;
  border-radius: 10px;
`;

export const CardHeading = styled.h2`
  font-size: ${({ titleSize }) => titleSize || '1.8em'};
  font-weight: 700;
  margin-bottom: ${({ img }) => img && '0'};
  margin-top: ${({ img }) => !img && '1em'};
  padding: 0 1.7em;
  text-align: center;
  height: 90px;
  display: flex;
  align-items: center;
  @media (max-width: 786px) {
    font-size: ${({ titleSize }) => titleSize || '1.4em'};
  }
  @media (max-width: 425px) {
    font-size: ${({ titleSize }) => titleSize || '1.2em'};
    height: 50px;
  }
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  text-align: center;
  background-color: ${({ color }) => color};
  width: ${({ color }) => !color && '60%'};
  font-size: ${({ color }) => (color ? '1.2em' : '1.5em')};
  padding: ${({ color }) => (color ? '2em' : '1em 0em 2em 0em')};
  font-weight: 600;
  gap: 1em;
  & > a {
    color: white;
    background-color: #fb8501;
    border: none;
    padding: 0.5em 2em;
    border-radius: 10px;
    font-weight: 700;
    align-self: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: fit-content;
    margin-bottom: 1em;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
  }

  @media (max-width: 1160px) {
    width: ${({ color }) => !color && '75%'};
  }

  @media (max-width: 786px) {
    width: ${({ color }) => !color && '80%'};
    font-size: ${({ color }) => (color ? '1em' : '1.2em')};
  }

  @media (max-width: 425px) {
    font-size: ${({ color }) => (color ? '0.9em' : '1em')};
  }

  @media (max-width: 1160px) {
    width: ${({ color }) => !color && '75%'};
  }

  @media (max-width: 1160px) {
    width: ${({ color }) => !color && '75%'};
  }
`;
