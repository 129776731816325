import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ShareIcon from '@mui/icons-material/Share';
import { Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AiOutlineTag } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, MainContainer } from '../../global/styles/style-component';
import ApiCall from '../../utils/axiosCall';
import { errorToast } from '../../utils/toast';
import Container from '../Container';
import CreatorCard from './components/CreatorCard';
import {
  Banner,
  ContentType,
  ContentTypes,
  CreatorContent,
} from './creatorStyled';

const CreatorPortfolio = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('all');
  const list = [
    { title: 'All', key: 'all' },
    { title: 'Portfolio', key: 'portfolio' },
    { title: 'Gifts sent', key: 'giftedOut' },
    { title: 'Purchased', key: 'purchased' },
    { title: 'Contributions', key: 'contributions' },
  ];
  const [collection, setCollection] = useState({
    avatar: '',
    contributions: [],
    giftedOut: [],
    portfolio: [],
    purchased: [],
    username: '.....',
    walletAddress: '',
  });
  useEffect(() => {
    ApiCall.get(`app/user-collection/${id}`)
      .then((res) => {
        setCollection(res.data.userCollection);
      })
      .catch((err) => {
        console.log(err);
        errorToast(err.response.data.message);
      });
  }, []);
  return (
    <Container>
      <Banner>
        <div>
          <h3>Creators Portfolio </h3>
        </div>
        <div>
          <div className='profileImgContainer'>
            <img
              src={
                collection.avatarUrl
                  ? `${process.env.REACT_APP_DOMAIN}${collection.avatarUrl}`
                  : require('../../assets/dummyavatar.png')
              }
              alt=''
              style={{
                objectFit: 'cover',
              }}
            />
            <div />
          </div>
          <h2>{collection?.username}</h2>
          <span className='walletAddress'>{collection.walletAddress}</span>
          {/* <span className='creatorAbout'>
            Unique, Fully 3D And Built To Unite The Design Multiverse. Designed
            And Styled By Digimental.
          </span> */}
          {/* <span className='creatorJoinDate'>Joined December 2019</span> */}
          <div className='creatorMenu'>
            <IconButton>
              <FavoriteBorderIcon />
            </IconButton>
            <IconButton>
              <ShareIcon />
            </IconButton>
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </div>
        </div>
      </Banner>
      <MainContainer paddingVertical='5em'>
        <ContentTypes>
          {list.map((item) => (
            <ContentType
              onClick={() => {
                setActiveTab(item.key);
              }}
              key={item.key}
              active={activeTab === item.key}
            >
              <AiOutlineTag />
              <span>{item.title}</span>
            </ContentType>
          ))}
        </ContentTypes>
        {/* <ContentFilters>
          <div>
            <ContentFilter active={true}>
              <AiOutlineCloudUpload />
              <span>All</span>
            </ContentFilter>
            <ContentFilter>
              <AiOutlineCloudUpload />
              <span>Art</span>
            </ContentFilter>
            <ContentFilter>
              <AiOutlineCloudUpload />
              <span>Collectibles</span>
            </ContentFilter>
            <ContentFilter>
              <AiOutlineCloudUpload />
              <span>Domain</span>
            </ContentFilter>
          </div>
          <select>
            <option>Trending</option>
            <option>Trending</option>
            <option>Trending</option>
            <option>Trending</option>
          </select>
        </ContentFilters> */}
        <Grid
          container
          sx={{
            marginTop: '2em',
          }}
          spacing={'1em'}
        >
          {collection && activeTab === 'all' ? (
            [
              ...collection.contributions,
              ...collection.giftedOut,
              ...collection.portfolio,
              ...collection.purchased,
            ].map(
              ({
                owner,
                creator,
                _id,
                isSold,
                buyerId,
                isMinted,
                paymentStatus,
                saleType,
                priceUsd,
                itemName,
                description,
                imageUrl,
                nftCategory,
                impactId,
                impactInitiativeName,
                impactCategory,
                percentageToImpact,
                impactCampaignType,
              }) => (
                <Grid key={_id} item xs={12} sm={6} md={4} lg={3}>
                  <CreatorCard
                    img={`${process.env.REACT_APP_DOMAIN}${imageUrl}`}
                    favCount={10}
                    userList={[
                      `${process.env.REACT_APP_DOMAIN}${imageUrl}`,
                      `${process.env.REACT_APP_DOMAIN}${imageUrl}`,
                    ]}
                    btnTitle='View detail'
                    btnClick={() => {
                      navigate(`/nft-details/${_id}`);
                    }}
                    key={_id}
                    price={priceUsd}
                    title={itemName}
                  />
                </Grid>
              )
            )
          ) : collection[activeTab].length ? (
            collection[activeTab].map(
              ({
                owner,
                creator,
                _id,
                isSold,
                buyerId,
                isMinted,
                paymentStatus,
                saleType,
                priceUsd,
                itemName,
                description,
                imageUrl,
                nftCategory,
                impactId,
                impactInitiativeName,
                impactCategory,
                percentageToImpact,
                impactCampaignType,
              }) => (
                <Grid key={_id} item xs={12} sm={6} md={4} lg={3}>
                  <CreatorCard
                    img={`${process.env.REACT_APP_DOMAIN}${imageUrl}`}
                    favCount={10}
                    userList={[
                      `${process.env.REACT_APP_DOMAIN}${imageUrl}`,
                      `${process.env.REACT_APP_DOMAIN}${imageUrl}`,
                    ]}
                    btnTitle='View detail'
                    btnClick={() => {
                      navigate(`/nft-details/${_id}`);
                    }}
                    key={_id}
                    price={priceUsd}
                    title={itemName}
                  />
                </Grid>
              )
            )
          ) : (
            <Heading>No Record Found</Heading>
          )}
        </Grid>
      </MainContainer>
    </Container>
  );
};

export default CreatorPortfolio;
