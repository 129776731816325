import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import Typography from '@mui/material/Typography';

const Accordion = (props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
);

const FaqContent = () => {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className='faq-content-section'>
      <div className='faq-accordion'>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel1' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel1d-content'
            id='panel1d-header'
          >
            <Typography>What is blockchain technology?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Blockchain technology is a system that allows for secure,
              decentralized data storage and verification. It is often used in
              cryptocurrencies but has a wide range of potential applications.
              You can read more here.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel2' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel2d-content'
            id='panel2d-header'
          >
            <Typography>
              How is blockchain different from previous ledger systems?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>--------</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel3' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel3d-content'
            id='panel3d-header'
          >
            <Typography>
              What are the unique features of blockchain technology?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The features that make blockchain technology unique are its
              decentralized nature, its transparency, and its immutability.
              Decentralization means that there is no central authority
              controlling the network. Transparency means that all transactions
              are visible to everyone on the network. Immutability means that
              once a transaction is recorded on the blockchain, it cannot be
              changed or deleted.
              <h4>Decentralization:</h4>
              <p style={{ marginLeft: '20px' }}>
                The features that make blockchain technology unique are its
                decentralized nature, its transparency, and its immutability.
                Decentralization means that there is no central authority
                controlling the network. Transparency means that all
                transactions are visible to everyone on the network.
                Immutability means that once a transaction is recorded on the
                blockchain, it cannot be changed or deleted.
              </p>
              <h4>Transparency:</h4>
              <p style={{ marginLeft: '20px' }}>
                Blockchain technology is transparent because it is a distributed
                ledger system. This means that the information stored on the
                blockchain is visible to everyone on the network.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel4' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel4d-content'
            id='panel4d-header'
          >
            <Typography>
              How is cryptography related to blockchain technology?{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Cryptography is the practice of secure communication in the
              presence of third parties. It is used in blockchain technology to
              ensure that transactions are secure and cannot be tampered with.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel5' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel5d-content'
            id='panel5d-header'
          >
            <Typography>What is tokenization on the blockchain? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Tokenization is the process of creating digital tokens that
              represent assets on a blockchain. This can be used to represent
              things like ownership of property or shares in a company.
              Tokenization can make it easier to trade and manage these assets,
              as well as track them and prevent fraud.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel6' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel6d-content'
            id='panel6d-header'
          >
            <Typography>
              What are the different kinds of digital tokens?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              There are many different types of digital tokens. Some common
              types include utility tokens, reward tokens, asset-backed tokens,
              cryptocurrency, and security tokens.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel7' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel7d-content'
            id='panel7d-header'
          >
            <Typography>What is an NFT?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              NFTs are digital assets that are stored on a blockchain. They can
              represent anything from a piece of art to a tweet and can be
              bought and sold like any other asset.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel8' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel18-content'
            id='panel18-header'
          >
            <Typography>
              What is the difference between an NFT and other kinds of tokens?{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              NFTs are digital assets that are unique and not interchangeable.
              Other kinds of tokens, such as utility tokens, can be exchanged
              for goods or services.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel9'}
          onChange={handleChange('panel9')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel9' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel9d-content'
            id='panel9d-header'
          >
            <Typography>What Are Smart Contracts?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Smart contracts are digital agreements that are stored on a
              blockchain. They can be used to automate transactions or enforce
              rules.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel10'}
          onChange={handleChange('panel10')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel10' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel10d-content'
            id='panel10d-header'
          >
            <Typography>Why were smart contracts invented?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The idea of a smart contract was invented to make it possible for
              two parties to agree on and carry out a transaction without the
              need for a third party, like a bank or government.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel11'}
          onChange={handleChange('panel11')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel11' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel11d-content'
            id='panel11d-header'
          >
            <Typography>What is a digital (crypto) wallet? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A digital wallet is an online account that allows you to store and
              use your digital currency, like ETH or bitcoin, and digital assets
              like NFTs or non-currency token. You can use your digital wallet
              to buy things, send money to people, or even shop online with
              merchants that accept cryptocurrency.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel12'}
          onChange={handleChange('panel12')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel12' ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            aria-controls='panel12d-content'
            id='panel12d-header'
          >
            <Typography>
              Do we need crypto wallets for blockchain transactions?{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Unless you are using a credit card, you will need a digital wallet
              to transfer and store your cryptocurrency. <br /> <br />
              Even if you buy tokens or NFTs with a credit card, you will still
              need a wallet to store digital assets like NFTs and tokens.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FaqContent;
