import './Services.css';
import project1 from '../../assets/project1.PNG';
import project2 from '../../assets/project2.PNG';
import project3 from '../../assets/project3.PNG';
import project4 from '../../assets/project4.PNG';
import project5 from '../../assets/project5.PNG';
import project6 from '../../assets/project6.PNG';
import project7 from '../../assets/project7.PNG';
import { Link } from 'react-router-dom';
import Container from '../Container';

const Services = () => {
  return (
    <Container>
      <div className='services-container'>
        <h4>DONE FOR YOU SERVICES</h4>
        <p>
          Align with impact, and have your tech developments Powered By demo. We
          use our tech expertise to help you develop projects that also have an
          impact.
        </p>
        <p>
          Don’t know where to start? No problem! We can help guide you on
          options and do it all for you.{' '}
          <button className='reachout-btn resposive-btn'>REACH OUT</button>
        </p>
        <p>
          Have an idea of what you’re looking for? Click on! <br />
          <Link to='/done-for-you'>
            <button
              className='services-btn resposive-btn'
              style={{ marginLeft: '10px' }}
            >
              DFY SERVICES
            </button>
          </Link>
        </p>

        <div className='tech-stack-knowledge-container'>
          <h4>TECH STACK KNOWLEDGE</h4>
          <p>
            HTML, CSS, Boostrap, JS, Web 3, AWS, Moralis, NODE JS, Mocha/Chai,
            Node.JS, React.JS, MongoDB, Openzeppelin, Hardhat, Truffle, Alchemy,
            EVM, Solidity (and other blockchain development languages),
            Wordpress, HTML, PHP, Python, Metamask (and other wallets), Redux,
            Stripe , MySQL, Firebase, Firestore, Firebase Auth, Angular, Vue,
            Asp.Net, RDND AND MUCH MORE!
          </p>
        </div>
      </div>

      <div className='project-container'>
        <h2 className='project-heading'>PROJECTS</h2>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-4 col-padding'>
            <div className='single-project'>
              <img src={project1} alt='' />
              <div className='project-content'>
                <h4>BUILDING METAVERSE 2.0</h4>
                <p>
                  <a href='https://megaverse.game/io'>
                    https://megaverse.game/io
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-4 col-padding'>
            <div className='single-project'>
              <img src={project2} alt='' />
              <div className='project-content'>
                <h4>PROSTARTER</h4>
                <p>
                  <a href='https://prostarter.io/'>Prostarter.io</a>
                </p>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-4 col-padding'>
            <div className='single-project'>
              <img src={project3} alt='' />
              <div className='project-content'>
                <h4>KCCMEMEPAD</h4>
                <p>
                  <a href='https://www.kccmemepad.com/'>
                    https://www.kccmemepad.com/
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-4 col-padding'>
            <div className='single-project'>
              <img src={project4} alt='' />
              <div className='project-content'>
                <h4>MONSTERSCLAN</h4>
                <p>
                  <a href='https://monstersclan.com/'>Monstersclan.com</a>
                </p>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-4 col-padding'>
            <div className='single-project'>
              <img src={project5} alt='' />
              <div className='project-content'>
                <h4>MMOFURY</h4>
                <p>
                  <a href='https://mmofury.io/'>https://mmofury.io/</a>
                </p>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-4 col-padding'>
            <div className='single-project'>
              <img src={project6} alt='' />
              <div className='project-content'>
                <h4>ARABNFTGAMES</h4>
                <p>
                  <a href='https://arabnftgames.com/'>
                    https://arabnftgames.com/
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-4 col-padding'>
            <div className='single-project'>
              <img src={project7} alt='' />
              <div className='project-content'>
                <h4>VIRTUA</h4>
                <p>
                  <a href='https://virtua.com/'>Virtua.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Services;
