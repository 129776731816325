import React from 'react';
import Container from '../Container';
import BrandForImpact from './components/BrandForImpact';
import BrowseFilterTab from './components/BrowseFilterTab';
import FeaturedCampaign from './components/FeaturedCampaign';
import FeaturedInfluencer from './components/FeaturedInfluencer';
import InfoFaq from './components/InfoFaq';
import JoinImpactoverse from './components/JoinImpactoverse';
import MarketplaceLandingPageBanner from './components/MarketplaceLandingPageBanner';
import CampaignShowcase from './components/CampaignShowcase';
import './MarketplaceLandingPage.css';

const MarketplaceLandingPage = () => {
  return (
    <Container>
        <MarketplaceLandingPageBanner/>
        <BrowseFilterTab/>
        <FeaturedCampaign/>
        <BrandForImpact/>
        <CampaignShowcase/>
        <FeaturedInfluencer/>
        <InfoFaq/>
        <JoinImpactoverse/>
    </Container>
  );
};

export default MarketplaceLandingPage;
