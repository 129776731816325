import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CommonBtn,
  Heading,
  SubHeading,
} from '../../../global/styles/style-component';
import { featureBrands } from '../../../services/campaign';
const FeaturedBrand = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    featureBrands()
      .then((res) => {
        setData(res.data.brandsList[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Box
      sx={{
        margin: { xs: '10px 20px', md: '100px' },
      }}
    >
      <Box
        sx={{
          p: { xs: '20px', md: '40px' },
          borderRadius: '38px',
          background: '#fff',
        }}
      >
        <Heading>BRAND FOR IMPACT</Heading>
        <Box
          sx={{
            position: 'relative',
            display: { md: 'flex' },
            alignItems: 'center',
            gap: '40px',
            '&>div': { flex: 1 },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -8,
              left: -20,
              display: 'grid',
              gridTemplateColumns: '10px 10px 10px',
              gap: '20px',
            }}
          >
            {new Array(15).fill(null).map((el, i) => (
              <div
                key={i}
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  border: `2px solid #${Math.floor(
                    100000 + Math.random() * 900000
                  )}80`,
                }}
              />
            ))}
          </Box>
          {/* left */}
          <div
            style={{
              marginBlock: 40,
              position: 'relative',
              display: 'flex',
            }}
            className='brand-card-spacing'
          >
            <div
              style={{
                position: 'absolute',
                top: -20,
                bottom: -20,
                left: '10%',
                width: '80%',
                background: 'linear-gradient(129.59deg, #48bbbb, #66c48f70)',
                borderRadius: 20,
              }}
            />
            <img
              src={
                `${process.env.REACT_APP_DOMAIN}${data?.logoUrl}` ||
                require('../../../assets/new-nft-market-landing-page/Transparent-GIF.gif')
              }
              alt=''
              style={{
                width: data?.logoUrl ? '60%' : '100%',
                maxHeight: data?.logoUrl && '300px',
                objectFit: data?.logoUrl && 'contain',
                margin: 'auto',
                borderRadius: '20px',
                position: 'relative',
              }}
            />
          </div>
          {/* right */}
          <div
            style={{
              borderRadius: 40,
              padding: '3px',
              background: 'linear-gradient(129.59deg, #48bbbb, #66c48f70)',
              boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
            }}
          >
            <div
              style={{
                position: 'relative',
                borderRadius: 38,
                background: '#fff',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 40,
                  left: 32,
                  background: '#209AB7',
                  width: '160px',
                  height: '160px',
                  borderRadius: '50%',
                }}
              ></div>

              <div
                style={{
                  position: 'absolute',
                  background: '#F8AA52',
                  bottom: 40,
                  right: 80,
                  width: '160px',
                  height: '160px',
                  borderRadius: '50%',
                }}
              ></div>
              {/* content */}
              <div
                style={{
                  background: '#fff9',
                  backdropFilter: 'blur(32px)',
                  padding: '32px',
                  position: 'relative',
                }}
              >
                {data ? (
                  <>
                    {' '}
                    <Typography
                      sx={{
                        borderLeft: '5px solid #F8AA52',
                        pl: 2,
                        color: '#219EBC',
                        fontSize: '24px',
                      }}
                    >
                      {data?.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#023047',
                        textAlign: 'justify',
                        my: 3,
                        height: '200px',
                      }}
                    >
                      {data?.about}
                    </Typography>
                    {/* <CommonBtn>COLLABORATE</CommonBtn> */}
                    <CommonBtn
                      onClick={() => {
                        navigate(`/browse-brands-details/${data._id}`);
                      }}
                      style={{ background: '#219EBC' }}
                    >
                      LEARN MORE
                    </CommonBtn>
                  </>
                ) : (
                  <SubHeading>No Brand yet</SubHeading>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedBrand;
