import React from 'react';
import {
  CommonBtn,
  Heading,
  SubHeading,
} from '../../../global/styles/style-component';

const WhatWeDo = () => {
  return (
    <div className='what-we-do-container'>
      <div className='what-we-do-heading'>
        <Heading>What we do</Heading>
        <p>
          demo is helping capitalism evolve to scale beneficial impact for
          people and the planet{' '}
        </p>
      </div>
      <div className='development-design-container'>
        <div>
          <SubHeading>#Development</SubHeading>
          <p>dApps Web & mobile Smart contracts Bridges Formal verifications</p>
        </div>
        <div>
          <SubHeading>#Design</SubHeading>
          <p>
            User experience Interface design Product design Illustration &
            iconography Discovery process
          </p>
        </div>
      </div>
      {/* <div style={{ textAlign: 'center' }}>
        <CommonBtn style={{ fontsize: '1em' }}>LET'S TALK</CommonBtn>
      </div> */}
    </div>
  );
};

export default WhatWeDo;
