const nameRegex = /^[a-zA-Z ]+$/;
const companyNameRegex = /^[a-zA-Z0-9. ]*$/;
const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
const emailRegex =
  /^[a-zA-Z]+[a-zA-Z0-9._-]*@([a-zA-Z0-9]+){1}(\.[a-zA-Z0-9]+){1,2}/;
const fullNameRegex = /^([\w]{3,})+\s+([\w\s]{3,})+$/i;
const phoneRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

const usernameRegex = /^([A-Za-z]|[A-Za-z][0-9_-]*|[0-9]*[A-Za-z])+$/;
const nameFreeRegex = /^([^0-9]*)$/;

export const freeRegex = (e) => {
  return nameFreeRegex.test(e);
};

export const validateEmail = (e) => {
  return emailRegex.test(e);
};
export const validatePhone = (e) => {
  return phoneRegex.test(e);
};
export const validatePassword = (e) => {
  return e.length >= 8;
};
export const validateName = (e) => {
  return nameRegex.test(e);
};
export const validateUserName = (e) => {
  return usernameRegex.test(e);
};
export const validateCompanyName = (e) => {
  return companyNameRegex.test(e);
};
export const validateUrl = (e) => {
  return urlRegex.test(e);
};
export const validateFullName = (e) => {
  return fullNameRegex.test(e);
};

export const validateSelectedFile = (selectedFile, setSelectedFile) => {
  const MAX_FILE_SIZE = 15360; // 5MB

  const fileSizeKiloBytes = selectedFile.size / 1024;

  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    alert('File size is greater than maximum limit 15MB');
    return;
  }
  setSelectedFile(selectedFile);
};
