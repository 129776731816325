import React from 'react';
import community from '../../../assets/marketplace-landing-place/community.png';
import {
  CommonBtn,
  Heading,
  SubHeading,
} from '../../../global/styles/style-component';

const JoinImpactoverse = () => {
  return (
    <div className='join-community-container'>
      <div>
        <Heading>
          Get Involved. Make A Difference. Join The demo Community
        </Heading>
        <div className='bttn-container'>
          <CommonBtn>GET STARTED</CommonBtn>
          <CommonBtn style={{ background: '#219EBC' }}>LEARN MORE</CommonBtn>
        </div>
      </div>
      <div>
        <img src={community} alt='' />
      </div>
    </div>
  );
};

export default JoinImpactoverse;
