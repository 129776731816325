import { Grid } from "@mui/material";
import React from "react";
import { Heading } from "../../../global/styles/style-component";
import showcase1 from "../../../assets/marketplace-landing-place/showcase1.png";
import showcase2 from "../../../assets/marketplace-landing-place/showcase1.png";
import showcase3 from "../../../assets/marketplace-landing-place/showcase1.png";
import showcase4 from "../../../assets/marketplace-landing-place/showcase1.png";
import user1 from "../../../assets/marketplace-landing-place/user1.png";
import user2 from "../../../assets/marketplace-landing-place/user2.png";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HistoryIcon from '@mui/icons-material/History';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Slider from "react-slick";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];
const ITEM_HEIGHT = 48;
const CampaignShowcase = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="campaign-showcase-border">
    <div className="campaign-showcase-container">
      {/* <div className="slider-background-shaddow"> */}
      <Heading>CAMPAIGN SHOWCASE</Heading>
      <Slider {...settings}>
        <div className="single-campaign-showcase">
            <div className="showcase-gallary">
          <img src={showcase1} alt="" className="showcase-gallary-img"/>
          <div className="showcase-rating">
            <FavoriteBorderIcon/>
            <span>15</span>
          </div>
          <img src={user1} alt="" className="user-icon1" />
          <img src={user2} alt="" className="user-icon2" />
          </div>
          <div className="showcase-heading">
            <div>
              <h6>Flourishing Cat#180</h6>
            </div>

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          <span className="span-color">From 8.49 ETH 2/8</span>

          <div className="buy-view-history">
            <h6>Buy Now</h6>
            <div>
                <HistoryIcon/>
                <span>View History</span>
            </div>
          </div>
        </div>

        <div className="single-campaign-showcase">
            <div className="showcase-gallary">
          <img src={showcase1} alt="" className="showcase-gallary-img"/>
          <div className="showcase-rating">
            <FavoriteBorderIcon/>
            <span>15</span>
          </div>
          <img src={user1} alt="" className="user-icon1" />
          <img src={user2} alt="" className="user-icon2" />
          </div>
          <div className="showcase-heading">
            <div>
              <h6>Flourishing Cat#180</h6>
            </div>

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          <span className="span-color">From 8.49 ETH 2/8</span>

          <div className="buy-view-history">
            <h6>Buy Now</h6>
            <div>
                <HistoryIcon/>
                <span>View History</span>
            </div>
          </div>
        </div>

        <div className="single-campaign-showcase">
            <div className="showcase-gallary">
          <img src={showcase1} alt="" className="showcase-gallary-img"/>
          <div className="showcase-rating">
            <FavoriteBorderIcon/>
            <span>15</span>
          </div>
          <img src={user1} alt="" className="user-icon1" />
          <img src={user2} alt="" className="user-icon2" />
          </div>
          <div className="showcase-heading">
            <div>
              <h6>Flourishing Cat#180</h6>
            </div>

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          <span className="span-color">From 8.49 ETH 2/8</span>

          <div className="buy-view-history">
            <h6>Buy Now</h6>
            <div>
                <HistoryIcon/>
                <span>View History</span>
            </div>
          </div>
        </div>

        <div className="single-campaign-showcase">
            <div className="showcase-gallary">
          <img src={showcase1} alt="" className="showcase-gallary-img"/>
          <div className="showcase-rating">
            <FavoriteBorderIcon/>
            <span>15</span>
          </div>
          <img src={user1} alt="" className="user-icon1" />
          <img src={user2} alt="" className="user-icon2" />
          </div>
          <div className="showcase-heading">
            <div>
              <h6>Flourishing Cat#180</h6>
            </div>

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          <span className="span-color">From 8.49 ETH 2/8</span>

          <div className="buy-view-history">
            <h6>Buy Now</h6>
            <div>
                <HistoryIcon/>
                <span>View History</span>
            </div>
          </div>
        </div>

        <div className="single-campaign-showcase">
            <div className="showcase-gallary">
          <img src={showcase1} alt="" className="showcase-gallary-img"/>
          <div className="showcase-rating">
            <FavoriteBorderIcon/>
            <span>15</span>
          </div>
          <img src={user1} alt="" className="user-icon1" />
          <img src={user2} alt="" className="user-icon2" />
          </div>
          <div className="showcase-heading">
            <div>
              <h6>Flourishing Cat#180</h6>
            </div>

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          <span className="span-color">From 8.49 ETH 2/8</span>

          <div className="buy-view-history">
            <h6>Buy Now</h6>
            <div>
                <HistoryIcon/>
                <span>View History</span>
            </div>
          </div>
        </div>

        <div className="single-campaign-showcase">
            <div className="showcase-gallary">
          <img src={showcase1} alt="" className="showcase-gallary-img"/>
          <div className="showcase-rating">
            <FavoriteBorderIcon/>
            <span>15</span>
          </div>
          <img src={user1} alt="" className="user-icon1" />
          <img src={user2} alt="" className="user-icon2" />
          </div>
          <div className="showcase-heading">
            <div>
              <h6>Flourishing Cat#180</h6>
            </div>

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          <span className="span-color">From 8.49 ETH 2/8</span>

          <div className="buy-view-history">
            <h6>Buy Now</h6>
            <div>
                <HistoryIcon/>
                <span>View History</span>
            </div>
          </div>
        </div>
      </Slider>
      </div>
    </div>
  );
};

export default CampaignShowcase;
