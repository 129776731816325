import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import { BannerHeading } from '../../../global/styles/style-component';

const ShortHistoryBanner = () => {
  return (
    <PagerBanner
      imgUrl={require('../../../assets/nft-details/nft-details-banner.png')}
    >
      <BannerHeading>
        Short History of the Internet - <br /> Web 1.0, 2.0, 3.0
      </BannerHeading>
    </PagerBanner>
  );
};

export default ShortHistoryBanner;
