import { Tab, Tabs } from '@blueprintjs/core';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CommonBtn } from '../../../../global/styles/style-component';
import {
  getAllApproveCampaigns,
  getAllPendingCampaigns,
} from '../../../../services/vetting';
import ApiCall from '../../../../utils/axiosCall';
import { errorToast, successToast } from '../../../../utils/toast';
import DashboardContainer from '../../DashboardContainer';

const MyCampaign = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const pending = await getAllPendingCampaigns();
    const approve = await getAllApproveCampaigns();
    setData([...pending.data.campaignRequests, ...approve.data.campaigns]);
  };

  const [activeTabId, setActiveTabId] = useState();
  const list = [
    { title: 'All', key: 'all' },
    { title: 'Accept', key: 'portfolio' },
    { title: 'Reject', key: 'giftedOut' },
    { title: 'Pending', key: 'giftedIn' },
    { title: 'Draft', key: 'purchased' },
  ];
  return (
    <DashboardContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 className='mt-2'>My Campaigns</h3>
        <CommonBtn>
          <Link
            style={{
              width: '100%',
              height: '100%',
              color: '#fff',
              textDecoration: 'none',
            }}
            to={'/dashboard/campaign/contribution/requests'}
          >
            Contribution Request
          </Link>
        </CommonBtn>
      </div>
      <br />
      <Tabs
        onChange={(e) => {
          setActiveTabId(e);
        }}
        renderActiveTabPanelOnly
        selectedTabId={activeTabId}
        vertical={false}
        animate={true}
      >
        {list.map(({ key, title }) => (
          <Tab id={key} title={title} panel={<div></div>} />
        ))}
      </Tabs>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell width={50} component='th'>
                #
              </TableCell>
              <TableCell component='th'>Name</TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                }}
                component='th'
              >
                Banner Image
              </TableCell>
              <TableCell component='th'>Status</TableCell>
              <TableCell component='th'>Type</TableCell>
              <TableCell component='th'>Description</TableCell>
              <TableCell component='th'>Start Date</TableCell>
              <TableCell component='th'>End Date</TableCell>
              <TableCell component='th'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(0, rowsPerPage)
              .map(({ _id, overview, registrationStatus }, index) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope='row'>{index + 1}</TableCell>
                  <TableCell scope='row'>{overview.campaignName}</TableCell>
                  <TableCell scope='row'>
                    <div className='d-flex flex-column gap-2 align-items-center'>
                      <img
                        src={`${process.env.REACT_APP_DOMAIN}${overview.bannerImageUrl[0]}`}
                        alt=''
                        style={{
                          objectFit: 'contain',
                          height: '100px',
                          width: '150px',
                        }}
                      />
                      <input
                        id={`file${index}`}
                        accept='image/png, image/jpeg'
                        hidden
                        type='file'
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            const formData = new FormData();
                            formData.append('banner', e.target.files[0]);
                            ApiCall.put(
                              `campaign/update-banner/${_id}`,
                              formData
                            )
                              .then((res) => {
                                successToast(res.data.message);
                                fetch();
                              })
                              .catch((error) => {
                                errorToast(error?.response?.data?.message);
                              });
                          }
                        }}
                      />
                      <label htmlFor={`file${index}`}>
                        <div
                          style={{
                            fontSize: '12px',
                            color: 'white',
                            backgroundColor: '#fb8501',
                            border: 'none',
                            padding: ' 0.5em 2em',
                            borderRadius: '5px',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            width: 'fit-content',
                            textTransform: 'uppercase',
                          }}
                        >
                          Update
                        </div>
                      </label>
                    </div>
                  </TableCell>
                  <TableCell scope='row'>{registrationStatus}</TableCell>
                  <TableCell scope='row'>{overview.campaignType}</TableCell>
                  <TableCell scope='row'>{overview.description}</TableCell>
                  <TableCell scope='row'>
                    {new Date(overview.startDate).toLocaleString()}
                  </TableCell>
                  <TableCell scope='row'>
                    {new Date(overview.endDate).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <CommonBtn
                      style={{ fontSize: '12px' }}
                      onClick={() => {
                        navigate(
                          `/dashboard/my-campaign/${_id}?status=${registrationStatus}`
                        );
                      }}
                    >
                      View and edit
                    </CommonBtn>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={9}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </DashboardContainer>
  );
};

export default MyCampaign;
