import React, { useEffect } from 'react';
import { useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/loginInWhite.png';
import messageIcon from '../../assets/message-icon 1.png';
import '../../global/styles/LoginConfirmation.css';
import { recoverPasswordUsingEmail } from '../../services/verification';

const ResetPasswordOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const [email, setEmail] = useState('');
  useEffect(() => {
    setEmail(search.get('email'));
  }, [search]);
  return (
    <>
      <div className='login-confirmation-section'>
        <div className='login-confirmation-container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='confirmation-left'>
                <img src={logo} alt='' />
                <p></p>

                <div className='lets-get-started'>
                  <p>Let's Get Started</p>
                  <FaLongArrowAltRight className='forward-arrow' />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <img src={logo} className='responsive-logo' alt='' />
              <div className='reset-password-container'>
                <img src={messageIcon} alt='' />
                <h5>Check your Email</h5>
                <p>
                  We sent a 6 digits OTP Code to <br />{' '}
                  <span style={{ fontWeight: 600 }}>{email}</span>
                </p>
                <div style={{ textAlign: 'center' }}>
                  <button
                    onClick={() =>
                      navigate(
                        `/enter-verification-code?email=${email}&token=${search.get(
                          'token'
                        )}`
                      )
                    }
                  >
                    Reset Password
                  </button>
                </div>
                <span className='dont-recive-otp'>
                  DON’T RECEIVE THE OTP ?{' '}
                  <span
                    role={'button'}
                    onClick={() => {
                      dispatch(
                        recoverPasswordUsingEmail(
                          {
                            email,
                          },
                          navigate
                        )
                      );
                    }}
                    style={{ color: '#F8991D' }}
                  >
                    CLICK TO RESEND
                  </span>
                </span>

                <div
                  onClick={() => navigate('/login')}
                  className='back-to-login'
                >
                  <BiLeftArrowAlt className='back-arrow' />
                  <span>Back to log in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordOtp;
