import React from 'react';
import { Link } from 'react-router-dom';
import info from '../../../assets/new-nft-market-landing-page/info.png';
import question from '../../../assets/new-nft-market-landing-page/question.png';

const InfoQuestions = () => {
  return (
    <div className='info-questions-container'>
      <div>
        <div>
          <img src={info} alt='' />
          <h5>
            <Link to='/info-hub-main'>
              NOT SURE WHERE TO START? CHECK OUT OUR INFO HUB
            </Link>
          </h5>
        </div>
        <div>
          <img src={question} alt='' />
          <h5>
            <Link to='/done-for-you'>
              NEED HELP? CHECK OUT OUR DONE FOR YOU SERVICES
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default InfoQuestions;
