import { Grid } from "@mui/material";
import React from "react";
import {
  Btn,
  CommonBtn,
  Heading,
  SubHeading,
} from "../../../global/styles/style-component";
import influencerBg from '../../../assets/marketplace-landing-place/wave-bg.png'
import elipsBg from '../../../assets/marketplace-landing-place/vector-bg.png'
import elipsCircle from '../../../assets/marketplace-landing-place/elips-bg.png'

const FeaturedInfluencer = () => {
  return (
    <div className="featured-influencer">
      {/* <video autoPlay muted loop playsInline>
            <source src={pass} type='video/webm' />
          </video> */}
          <div className="vector-bg">
            <img src={elipsBg} alt="" />
          </div>
      <div>
        <div className="featured-video-container">
          <img
            src={require("../../../assets/new-nft-market-landing-page/3EF8FEC8-0DD4-4D2D-A1AE-4FF92AC73E7F.gif")}
            alt=""
            className="featured-video-gif"
          />
        </div>

        <div>
        <img src={influencerBg} alt="" className="influencer-bg" />
          <Heading style={{color:'#fff'}}>Want to be our next featured influencer?</Heading>
          <h6 style={{ paddingBottom: "1em" }}>
            Get closer to your fans, build community, support impact!{" "}
          </h6>
          <div className="">
            <CommonBtn margin="10px">GET STARTED</CommonBtn>
            <CommonBtn margin="10px" className="bttn-styles">LEARN MORE</CommonBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedInfluencer;
