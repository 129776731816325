import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  Btn,
  Heading,
  SubHeading,
} from '../../../global/styles/style-component';
import { BiFilterAlt } from 'react-icons/bi';
import { FaUserPlus } from 'react-icons/fa';
import { AiOutlineLike } from 'react-icons/ai';
import { BsFillShareFill } from 'react-icons/bs';
import { Grid } from '@mui/material';
import logo from '../../../assets/browse-influencer/influncer-logo.png';
import {
  CampaignContainer,
  CampaignContent,
  InfluencerInfo,
  InfluencerProffesion,
} from '../InfluencerStyle';
import { useNavigate } from 'react-router-dom';
import { getAllInfluencers } from '../../../services/campaign';
import { errorToast } from '../../../utils/toast';

const InfluencerCampaigns = () => {
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState([]);

  useEffect(() => {
    getAllInfluencers()
      .then((res) => {
        setCampaign(res.data.message);
      })
      .catch((err) => errorToast(err.response.data.message));
  }, []);
  return (
    <div className='influncer-campaign-section'>
      <Heading>Influencer CAMPAIGNS</Heading>
      {/* <div style={{ textAlign: "center" }} className="browse-bttn">
        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Search By Name{" "}
        </Btn>
        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Browse By Title{" "}
        </Btn>

        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Browse By Sector{" "}
        </Btn>
        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Browse by Impact Category{" "}
        </Btn>
        <BiFilterAlt className="filter-icon" />
      </div> */}

      <div className='influncer-cards'>
        <Grid container spacing={2}>
          {campaign.map((item, index) => (
            <Grid item xs={12} md={3}>
              <InfluencerInfo
                imgUrl={require('../../../assets/browse-influencer/bg.png')}
              >
                <div>
                  <img
                    style={{
                      borderRadius: '100%',
                      border: '5px solid #000',
                      height: '150px',
                      width: '150px',
                      objectFit: 'cover',
                      padding: '0',
                      marginTop: '1em',
                    }}
                    src={`${process.env.REACT_APP_DOMAIN}${item.profileImage}`}
                    alt=''
                  />
                  <SubHeading>{item.managerName}</SubHeading>
                </div>
              </InfluencerInfo>
              <InfluencerProffesion>
                <SubHeading>Sports Personality</SubHeading>
                <h6>{item.impactInterest}</h6>
              </InfluencerProffesion>
              <CampaignContainer>
                <p
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: ' -webkit-box',
                    '-webkitLineClamp': '2' /* number of lines to show */,
                    lineClamp: '2',
                    '-webkitBoxOrient': 'vertical',
                  }}
                >
                  {item.interestInNfts}
                </p>
                {item?.activeCampaigns && item?.activeCampaigns.length ? (
                  item?.activeCampaigns.map(({ overview, _id }, index) =>
                    item?.activeCampaigns.length > 1 ? (
                      <CampaignContent
                        onClick={() => {
                          navigate(`/campaign-details/${_id}`);
                        }}
                      >
                        {/* <div> */}
                        <SubHeading>{overview.campaignName}</SubHeading>
                        {/* <div>
                          <AiOutlineLike />
                          <BsFillShareFill />
                          <FaUserPlus />
                        </div> */}
                        {/* </div> */}
                        <h5>
                          Status: <span>{overview.campaignType}</span>{' '}
                        </h5>
                        <div>
                          <h5>
                            Ending In <br />{' '}
                            <span>
                              {new Date(overview.endDate).toLocaleDateString()}
                            </span>{' '}
                          </h5>
                          {/* <h5>
                          Amount Raised <br /> <span>${overview}</span>{' '}
                        </h5> */}
                        </div>

                        <div>
                          <h5>Description</h5>
                          <p>{overview.description}</p>
                        </div>
                      </CampaignContent>
                    ) : (
                      <CampaignContent onClick={() => {}}>
                        {/* <div> */}
                        <SubHeading>------------</SubHeading>
                        {/* <div>
                          <AiOutlineLike />
                          <BsFillShareFill />
                          <FaUserPlus />
                        </div> */}
                        {/* </div> */}
                        <h5>
                          Status: <span>------------</span>{' '}
                        </h5>
                        <div>
                          <h5>
                            Ending In <br /> <span>-----------</span>{' '}
                          </h5>
                          {/* <h5>
                          Amount Raised <br /> <span>${overview}</span>{' '}
                        </h5> */}
                        </div>

                        <div>
                          <h5>Description</h5>
                          <p>
                            ---------------------------------------------------------------------------------------------------------------------------------------
                          </p>
                        </div>
                      </CampaignContent>
                    )
                  )
                ) : (
                  <>
                    <CampaignContent onClick={() => {}}>
                      {/* <div> */}
                      <SubHeading>------------</SubHeading>
                      {/* <div>
                          <AiOutlineLike />
                          <BsFillShareFill />
                          <FaUserPlus />
                        </div> */}
                      {/* </div> */}
                      <h5>
                        Status: <span>------------</span>{' '}
                      </h5>
                      <div>
                        <h5>
                          Ending In <br /> <span>-----------</span>{' '}
                        </h5>
                        {/* <h5>
                          Amount Raised <br /> <span>${overview}</span>{' '}
                        </h5> */}
                      </div>

                      <div>
                        <h5>Description</h5>
                        <p>
                          ---------------------------------------------------------------------------------------------------------------------------------------
                        </p>
                      </div>
                    </CampaignContent>
                    <CampaignContent onClick={() => {}}>
                      {/* <div> */}
                      <SubHeading>------------</SubHeading>
                      {/* <div>
                          <AiOutlineLike />
                          <BsFillShareFill />
                          <FaUserPlus />
                        </div> */}
                      {/* </div> */}
                      <h5>
                        Status: <span>------------</span>{' '}
                      </h5>
                      <div>
                        <h5>
                          Ending In <br /> <span>-----------</span>{' '}
                        </h5>
                        {/* <h5>
                          Amount Raised <br /> <span>${overview}</span>{' '}
                        </h5> */}
                      </div>

                      <div>
                        <h5>Description</h5>
                        <p>
                          ---------------------------------------------------------------------------------------------------------------------------------------
                        </p>
                      </div>
                    </CampaignContent>
                  </>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Btn
                    onClick={() => {
                      navigate(`/influencers-details/${item.userId}`);
                    }}
                  >
                    VIEW MORE
                  </Btn>
                </div>
              </CampaignContainer>
            </Grid>
          ))}
        </Grid>
        ,
        {/* <div style={{ textAlign: 'center' }}>
          <Btn marginTop='80px'>VIEW MORE</Btn>
        </div> */}
      </div>
    </div>
  );
};

export default InfluencerCampaigns;
