import React from 'react';
import { MainContainer } from '../../../global/styles/style-component';

const BrandDomination = () => {
  return (
    <MainContainer className='brand-domination-section'>
      <h2>
        Here is the formula for futureproofing <br />
        brand domination of your sapce
      </h2>
    </MainContainer>
  );
};

export default BrandDomination;
