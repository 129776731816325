import styled from 'styled-components';

export const CampaignCard = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  overflow: hidden;
  transition: all 1s;
  cursor: pointer;
  & > img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }
  & > div {
    padding: 1em;
    display: flex;
    gap: 1em;
    & > img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 3px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      & > #campaignCardDescription {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }
  &:hover {
    transform: scale(1.05);
  }
`;
