import React from 'react';
import Container from '../Container';
import BlockChainTechnology from './components/BlockChainTechnology';
import ImpactBenefits from './components/ImpactBenefits';
import ImpactStartupBanner from './components/ImpactStartupBanner';
import PlanBuildLaunch from './components/PlanBuildLaunch';
import Requirments from './components/Requirments';
import WhatWeDo from './components/WhatWeDo';
import './NewImpactStartup.css';

const newImpactStartup = () => {
  return (
    <>
      <Container>
        <ImpactStartupBanner />
        {/* <BlockChainTechnology /> */}
        <WhatWeDo />
        <PlanBuildLaunch />
        <ImpactBenefits />
        <Requirments />
      </Container>
    </>
  );
};

export default newImpactStartup;
