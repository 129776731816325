import React from 'react';
import { Link } from 'react-router-dom';
import { Btn, MainContainer } from '../../styles/style-component';
import './Footer.css';
import logo from '../../../assets/logo.png';
import footer from '../../../assets/footer.png';

const Footer = () => {
  return (
    <div className='footerContainer'>
      <MainContainer paddingVertical='15px' bgColor='transparent'>
        <div className='footer'>
          <div className='footerLinks'>
            <strong className='footerHeader'>Get our updates</strong>
            <div className='footerForm'>
              <label htmlFor='firstName'>First name</label>
              <input type='text' name='firstName' id='firstName' />
              <label htmlFor='lastName'>Last name</label>
              <input type='text' name='lastName' id='lastName' />
              <label htmlFor='email'>Email</label>
              <input type='email' name='email' id='email' />
              <Btn
                id='footerBtn'
                onClick={() => {
                  alert('Successfully subscribe');
                }}
                marginLeft='0'
              >
                Subscribe
              </Btn>
            </div>
          </div>
          <div className='footerLinks'>
            <strong className='footerHeader'>Explore</strong>
            <div>
              <Link to='/brand'>Brand and Agencies</Link>
              <Link to='/nPos'>NPOs and Causes</Link>
              <Link to='/creators'>Creators</Link>
              <Link to='/influencer'>Influencers</Link>
            </div>
          </div>
          <div className='footerLinks'>
            {/* <strong className='footerHeader'>Info hub</strong> */}

            <div className='info-hub-links'>
              <Link to='/info-hub-main'>Info hub</Link>
              <Link to='/marketplace'>Marketplace</Link>
              <Link to='/impact-startup'>Impact startups</Link>
              <Link to='/faq'>FAQs</Link>
              <Link to='/contact-us'>Contact</Link>
              <Link to='/privacy-policy'>Privacy policy</Link>
              <Link to='/terms'>Terms and conditions</Link>
              {/* <Link to='/short-history-of-internet'>
                Short History Of Internet
              </Link> */}
              <a
                href='https://medium.com/web3-for-the-rest-of-us'
                target={'_blank'}
                rel='noreferrer'
              >
                Blog
              </a>
            </div>
          </div>
        </div>
      </MainContainer>
      <div className='footer-logo'>
        <img src={logo} alt='' />
      </div>
      <div className='footer-quote'>
        <img src={footer} alt='' />
      </div>
      <div className='footer-copyright'>
        <p>All Rights Reserved &#169; demo 2022</p>
      </div>
    </div>
  );
};

export default Footer;
