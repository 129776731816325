import React from 'react';
import { Error, FieldContainer, FieldItem } from './vetting-style';

const Feasibility = ({
  data = {
    feasibility: {
      operationalCapacity: {
        value: '',
        error: '',
      },
      circumstances: {
        value: '',
        error: '',
      },
    },
  },
  setData,
}) => {
  return (
    <FieldContainer noOfColumn={2}>
      <FieldItem error={data.feasibility.operationalCapacity.error !== ''}>
        <span>
          What operational capacity do you have to be able to deliver on this
          campaign? ({data.feasibility.operationalCapacity.value.length}/599)
        </span>
        <textarea
          onChange={(e) => {
            setData((prev) => ({
              ...prev,
              feasibility: {
                ...prev.feasibility,
                operationalCapacity: {
                  value: e.target.value,
                  error:
                    e.target.value === ''
                      ? 'More than 15 characters required'
                      : e.target.value.length > 15
                      ? ''
                      : 'More than 15 characters required',
                },
              },
            }));
          }}
          value={data.feasibility.operationalCapacity.value}
          maxLength={'599'}
          name='campaign'
          id=''
          rows='7'
        />
        <Error>{data.feasibility.operationalCapacity.error}</Error>
      </FieldItem>
      <FieldItem error={data.feasibility.circumstances.error !== ''}>
        <span>
          Under what circumstances would you not be able to complete the
          project? ({data.feasibility.circumstances.value.length}/599)
        </span>
        <textarea
          onChange={(e) => {
            setData((prev) => ({
              ...prev,
              feasibility: {
                ...prev.feasibility,
                circumstances: {
                  value: e.target.value,
                  error:
                    e.target.value === ''
                      ? 'More than 15 characters required'
                      : e.target.value.length > 15
                      ? ''
                      : 'More than 15 characters required',
                },
              },
            }));
          }}
          value={data.feasibility.circumstances.value}
          maxLength={'599'}
          name='campaign'
          id=''
          rows='7'
        />
        <Error>{data.feasibility.circumstances.error}</Error>
      </FieldItem>
    </FieldContainer>
  );
};

export default Feasibility;
