import React from 'react';
import { BannerHeading } from '../../global/styles/style-component';
import Container from '../Container';
import { PagerBanner } from '../Pager/PagerStyle';
import BrowseSuperCategory from './components/BrowseSuperCategory';

const BrowseSuperCampaign = () => {
  return (
    <>
      <Container>
        <PagerBanner
          imgUrl={require('../../assets/nft-details/nft-details-banner.png')}
        >
          <BannerHeading>BROWSE SUPER-CAMPAIGNS</BannerHeading>
        </PagerBanner>
        <BrowseSuperCategory />
      </Container>
    </>
  );
};

export default BrowseSuperCampaign;
