import styled from 'styled-components';

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    background-image: url(${require('../../assets/maskGroup.jpg')});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    & > h3 {
      font-size: 5em;
      color: white;
    }
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f8fa;
    gap: 0.8em;
    padding-bottom: 3em;
    & > .profileImgContainer {
      margin-top: -125px;
      position: relative;
      width: 250px;
      height: 250px;
      padding: 5px;
      background-color: white;
      box-shadow: 0px 0px 10px #ccc;
      border-radius: 7px;
      & > img {
        width: 100%;
        height: 100%;
        border-radius: 7px;
      }
      & > div {
        width: 25px;
        height: 25px;
        background-color: #5aba81;
        position: absolute;
        bottom: -10px;
        right: -10px;
        border-radius: 50%;
      }
    }
    & > h2 {
      font-weight: bolder;
    }
    & > .walletAddress {
      margin: 0;
      padding: 5px 1em;
      background-color: white;
      border-radius: 50px;
      border: 1px solid #e0e0e0;
      color: #023047;
      font-size: 14px;
      font-weight: 400;
    }
    & > .creatorAbout {
      width: 25%;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
    }
    & > .creatorJoinDate {
      color: #aeaeae;
      font-size: 14px;
    }
    & > .creatorMenu {
      gap: 1em;
      display: flex;
      margin-top: 5px;

      & button {
        background-color: white;
        box-shadow: 0px 0px 10px #e1e1e1;
        border-radius: 5px;
      }
    }
  }
`;

export const ContentTypes = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
`;

export const ContentType = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 1em;
  gap: 10px;
  cursor: pointer;
  & > span {
    color: ${({ active }) => (active ? '#023047' : '#AEAEAE')};
    font-weight: 700;
    font-size: 16px;
  }
  & > svg {
    fill: ${({ active }) => (active ? '#023047' : '#AEAEAE')};
  }
  &::after {
    position: absolute;
    content: '';
    z-index: 1;
    bottom: -1em;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${({ active }) => (active ? '#023047' : 'transparent')};
  }
`;

export const ContentFilters = styled.div`
  margin-top: 1em;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1em;
  & > div:first-child {
    display: flex;
    gap: 1em;
  }
  & > select {
    padding: 0.5em 1.5em;
  }
`;

export const ContentFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3em;
  border: 1px solid #e7e8ec;
  padding: 0.5em 1.5em;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#219EBC' : 'transparent')};
  & > span {
    color: ${({ active }) => (active ? '#fff' : '#000')};
  }
  & > svg {
    fill: ${({ active }) => (active ? '#fff' : '#A6A6A6')};
  }
`;

export const CreatorContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1em;
  margin-top: 1em;
`;

export const CreatorCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  border: 2px solid #fb8501;
  box-shadow: 0px 0px 10px #fb8501;
  gap: 5px;
  background: white;
  border-radius: 5px;
  transition: all 0.1s;
  cursor: pointer;
  height: 100%;
  &:hover {
    background: #fb8501;
    color: #fff;
    transform: rotate3d(1, 1, 1, 5deg);
  }
  & > .creatorImg {
    position: relative;
    align-self: center;
    width: 100%;
    min-height: 250px;
    & > img {
      width: 100%;
      height: 250px;
      object-fit: contain;
      border-radius: 5px;
    }
    & > .favCount {
      position: absolute;
      display: flex;
      padding: 0.8em 1em;
      z-index: 10;
      background-color: #fb8501;
      font-weight: 600;
      right: -20px;
      border-radius: 10px;
      top: -20px;
      align-items: center;
      gap: 5px;
    }
    & > .userList {
      display: flex;
      gap: 0.3em;
      align-items: center;
      position: absolute;
      bottom: -17px;
      left: 30px;
      & > img {
        width: 35px;
        height: 35px;
        padding: 3px;
        background: white;
        box-shadow: 0px 0px 10px #e1e1e1;
        border-radius: 100%;
        margin-left: -18px;
      }
    }
  }
`;
