import React from 'react';
import { SubHeading } from '../../../global/styles/style-component';

const InfluencerAbout = ({ title = 'About Me', detail = '' }) => {
  return (
    <div className='influencer-about-container'>
      <SubHeading>{title}</SubHeading>
      <p>{detail}</p>
    </div>
  );
};

export default InfluencerAbout;
