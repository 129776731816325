import publicApiCall, { baseURL } from '../../utils/publicAxiosCall';
import AxiosCall from '../../utils/axiosCall';
import { errorToast, successToast } from '../../utils/toast';
import { togglePopup } from '../popup/popUpReducer';
import { setAccessToken, setUser } from './userReducer';

export const userLogin =
  (user, navigate, setOpen, setSearchParams) => (dispatch) => {
    publicApiCall
      .post('/user/login', user)
      .then((res) => {
        if (res.data.success && res.status === 200) {
          dispatch(setUser(res.data));
          dispatch(setAccessToken(res.data.accessToken));
          localStorage.setItem('accessToken', res.data.accessToken);
          dispatch(togglePopup());
          navigate('/dashboard');
          successToast(res.data.message);
        } else {
          if (res.status === 202) {
            setSearchParams({
              success: false,
              tempToken: res.data?.tempToken,
            });
          }
        }
      })
      .catch((err) => {
        console.log(
          err.response.data.message
            ?.toLowerCase()
            .includes('email not verified')
        );
        if (
          err.response.data.message
            ?.toLowerCase()
            .includes('email not verified')
        ) {
          setOpen(true);
        } else {
          errorToast(err.response.data.message);
        }
      });
  };

export const getUserDetail = () => (dispatch) => {
  AxiosCall.get('/user/dashboard/info')
    .then((res) => {
      if (res.data.success) {
        dispatch(setUser(res.data));
      }
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};

export const userLoginGoogle = () => (dispatch) => {
  window.open(`${baseURL}/user/auth/google`, '_self');
};
export const userLoginFacebook = () => (dispatch) => {
  window.open(`${baseURL}/user/auth/facebook`, '_self');
};

export const userRegister = (user, navigate) => (dispatch) => {
  publicApiCall
    .post('/user/register', user)
    .then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        navigate('/login');
        // dispatch(togglePopup());
      } else {
        errorToast(res.data.message);
      }
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};
export const userRegisterPhone = (user, navigate) => (dispatch) => {
  publicApiCall
    .post('/user/register/phone', user)
    .then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        navigate(`/phone/verification-code/${res.data.verificationToken}`);
      } else {
        errorToast(res.data.message);
      }
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};
export const userVerifyPhone =
  (verificationToken, otp, navigate) => (dispatch) => {
    publicApiCall
      .post(
        '/user/verify-phone',
        {
          otp,
        },
        {
          headers: {
            Authorization: verificationToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate('/login');
        } else {
          errorToast(res.data.message);
        }
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };
