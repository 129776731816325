import styled, { css } from 'styled-components';

export const DashboardCard = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  box-shadow: 0px 14px 32px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  flex: 1;
  gap: 1em;
  background-color: white;
  border-radius: 10px;
  & > svg {
    background-color: ${({ bgColor }) => bgColor};
    fill: ${({ fill }) => fill};
    width: 35px;
    height: 35px;
    padding: 0.5em;
    border-radius: 7px;
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const DashboardCollectionImage = styled.div`
  position: relative;
  height: 300px;
  cursor: pointer;
  & > .collectionCardImage {
    transition: all 1s;
    height: 100% !important;
  }
  & > .collectionCardImage:hover {
    transform: scale(1.1);
  }
  ${({ isLast }) =>
    isLast &&
    css`
      &::before {
        content: 'View more';
        background: #0000009e;
        position: absolute;
        opacity: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        text-shadow: 0px 0px 15px black;
      }
    `}
`;
