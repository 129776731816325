import React, { useEffect, useState } from 'react';
import {
  CommonBtn,
  Heading,
  SubHeading,
} from '../../../global/styles/style-component';
import ellipsCircle from '../../../assets/marketplace-landing-place/elips-bg.png';
import { featureBrands } from '../../../services/campaign';

const BrandForImpact = () => {
  return (
    <div className='brand-for-impact-container'>
      <Heading>BRAND FOR IMPACT</Heading>
      <div>
        <div>
          <img src={ellipsCircle} alt='' className='ellips-circle-bg' />
          <img
            src={require('../../../assets/new-nft-market-landing-page/Transparent-GIF.gif')}
            alt=''
            className='brand-video-image'
          />
        </div>

        <div>
          <Heading>TECH WITH SOUL</Heading>
          <h6 style={{ paddingBottom: '1em' }}>
            Tech with Soul bridges the representation gap in the tech space.
            It's the premier destination for leaders, futurists, startups,
            entrepreneurs, influencers and game changers of ALL color to address
            tomorrow's issues, today!
          </h6>
          <div className='bttn-container featured-influencer-btn'>
            <CommonBtn>COLLABORATE</CommonBtn>
            <CommonBtn style={{ background: '#219EBC' }}>LEARN MORE</CommonBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandForImpact;
