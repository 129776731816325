import React from 'react';
import DashboardContainer from '../dashboard/DashboardContainer';
import DashboardBanner from './components/DashboardBanner';
import PersonalDetails from './components/PersonalDetails';
import './DashboardPage.css';

const DashboardProfile = () => {
  return (
    <DashboardContainer>
      {/* <DashboardHeader /> */}
      <DashboardBanner />
      <PersonalDetails />
    </DashboardContainer>
  );
};

export default DashboardProfile;
