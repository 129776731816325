import { Grid } from "@mui/material";
import React from "react";
import playIcon from "../../../assets/done-for-you-services/play-icon.png";
import { CommonBtn } from "../../../global/styles/style-component";
const BookExploratoryCall = () => {
  return (
    <div className="book-exploratory-call-container">
      <div className="booking-cards">
        <Grid container>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                CONSULTING & <br /> PROJECT <br /> MANAGEMENT
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                CONCEPT & <br /> UTILITY SCOPE <br /> & DESIGN
              </h5>
            </div>
          </Grid>

          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                INNOVATION <br /> MGT, STRATEGY <br /> & PANNLING
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                ARCHITECTURE <br /> & <br /> DEVELOPMENT
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                MARKETING, <br /> GROWTH & <br /> ROLLOUT
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                COACHING, <br /> MENTORING & <br /> ACCELERATORS
              </h5>
            </div>
          </Grid>

          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                GAMES, APPS & <br /> WEARABLES
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                SUSTAINABILITY <br /> & IMPACT <br /> ADVISORY
              </h5>
            </div>
          </Grid>

          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                VERIFICATIONS <br /> & DIGITAL <br /> IDENTITY <br /> SOLUTIONS
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                CONSULTING & <br /> PROJECT <br /> MANAGEMENT
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                CRYPTO LEGAL <br /> & TAX <br /> ADVISORY
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                DEFI <br /> CONSULTING & <br /> DEVELOPMENT
              </h5>
            </div>
          </Grid>

          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                METAVERSE <br /> DESIGN & <br /> DEVELOPMENT
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                CONSULTING & <br /> PROJECT <br /> MANAGEMENT
              </h5>
            </div>
          </Grid>

          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                LAUNCHPADS, <br /> DAOS & <br /> MARKETPLACES
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                AI, MACHINE <br /> LEARNING & <br /> SCITECH
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                SUPPLY CHAIN <br /> VCM, VPM, & <br /> RENEWABLE <br />{" "}
                SOLUTIONS
              </h5>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="booking-card-container">
            <div>
              <img src={playIcon} alt="" />
              <h5>
                CONSULTING & <br /> PROJECT <br /> MANAGEMENT
              </h5>
            </div>
          </Grid>
        </Grid>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CommonBtn>BOOK AN EXPLORATORY CALL</CommonBtn>
        </div>
      </div>
    </div>
  );
};

export default BookExploratoryCall;
