import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/loginInWhite.png';
import tikIcon from '../../assets/tick 2.png';
import { Input } from '../../global/components/Custom';
import '../../global/styles/LoginConfirmation.css';
import { recoverPassword } from '../../services/verification';
import { validatePassword } from '../../utils/validation';
import './PasswordReset.css';

const PasswordReset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParma] = useSearchParams();
  const [password, setPassword] = useState({
    newPassword: {
      value: '',
      error: '',
    },
    confirmPassword: {
      value: '',
      error: '',
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className='login-confirmation-section'>
      <div className='login-confirmation-container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='confirmation-left'>
              <img src={logo} alt='' />
              <p></p>

              <div className='lets-get-started'>
                <p>Let's Get Started</p>
                <FaLongArrowAltRight className='forward-arrow' />
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <img src={logo} className='responsive-logo' alt='' />
            <div className='reset-password-container new-password-container'>
              <img src={tikIcon} alt='' />
              <h5>Password Reset</h5>
              <p style={{ marginBottom: '15px' }}>
                Your new password must be different to previously used passwords{' '}
              </p>

              <form action=''>
                <div className='password-reset-form'>
                  <Input
                    id='password'
                    label={'Password'}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='*******'
                    inputValue={password.newPassword}
                    onChange={(e) =>
                      setPassword({
                        ...password,
                        newPassword: {
                          value: e.target.value,
                          error: validatePassword(e.target.value)
                            ? ''
                            : 'Password should be minimum of 8 characters',
                        },
                        confirmPassword: {
                          ...password.confirmPassword,
                          error:
                            password.confirmPassword.value === e.target.value
                              ? ''
                              : "Password don't match",
                        },
                      })
                    }
                  >
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Input>
                  <Input
                    id='confirmPassword'
                    label={'Confirm Password'}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='*******'
                    inputValue={password.confirmPassword}
                    onChange={(e) =>
                      setPassword({
                        ...password,
                        confirmPassword: {
                          value: e.target.value,
                          error:
                            password.newPassword.value === e.target.value
                              ? ''
                              : "Password don't match",
                        },
                      })
                    }
                  >
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Input>
                </div>
              </form>

              <button
                onClick={() => {
                  if (
                    password.newPassword.value.length > 8 &&
                    password.newPassword.value ===
                      password.confirmPassword.value
                  ) {
                    dispatch(
                      recoverPassword(
                        {
                          newPassword: password.newPassword.value,
                          confirmPassword: password.confirmPassword.value,
                        },
                        searchParma.get('token'),
                        navigate
                      )
                    );
                  } else {
                    setPassword({
                      ...password,
                      newPassword: {
                        ...password.newPassword,
                        error:
                          password.newPassword.length > 8
                            ? ''
                            : 'Password should be 8 digit long',
                      },
                      confirmPassword: {
                        ...password.confirmPassword,
                        error:
                          password.newPassword.value ===
                          password.confirmPassword.value
                            ? ''
                            : "Password don't match",
                      },
                    });
                  }
                }}
                style={{ marginTop: '40px' }}
              >
                Reset Password
              </button>

              <div onClick={() => navigate('/login')} className='back-to-login'>
                <BiLeftArrowAlt className='back-arrow' />
                <span>Back to log in</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
