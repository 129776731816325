import { Grid } from '@mui/material';
import React from 'react';
import { Btn, SubHeading } from '../../../global/styles/style-component';
import { CampaignContainer, CampaignContent, InfluencerInfo } from '../../browseInfluencers/InfluencerStyle';
import { FaUserPlus } from "react-icons/fa";
import { AiOutlineLike } from "react-icons/ai";
import { BsFillShareFill } from "react-icons/bs";

const InfluencerCampaign = () => {
    return (
        <div className="influncer-cards">
            <SubHeading marginBottom="50px" style={{color:"#219EBC"}}>CAMPAIGNS</SubHeading>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <InfluencerInfo
              imgUrl={require("../../../assets/browse-influencer/bg.png")}
            >
            </InfluencerInfo>
            <CampaignContainer>
              <CampaignContent>
                <div>
                  <SubHeading>Campaign 2</SubHeading>
                  <div>
                    <AiOutlineLike />
                    <BsFillShareFill />
                    <FaUserPlus />
                  </div>
                </div>
                <h5>
                  Impact Category: <span>xyz</span>{" "}
                </h5>
                <div>
                  <h5>
                    Ending In <br /> <span>18 h:12 m:23 s</span>{" "}
                  </h5>
                  <h5>
                    Amount Raised <br /> <span>$59.99</span>{" "}
                  </h5>
                </div>

                <div>
                  <h5>Description</h5>
                  <p>
                    Get a skilled product team to gain initial traction and
                    close the next fundraising round
                  </p>
                </div>
              </CampaignContent>
              <div style={{ textAlign: "center" }}>
                <Btn>VIEW MORE</Btn>
              </div>
            </CampaignContainer>
          </Grid>

          <Grid item xs={12} md={3}>
            <InfluencerInfo
              imgUrl={require("../../../assets/browse-influencer/bg.png")}
            >
            </InfluencerInfo>
            <CampaignContainer>
              <CampaignContent>
                <div>
                  <SubHeading>Campaign 2</SubHeading>
                  <div>
                    <AiOutlineLike />
                    <BsFillShareFill />
                    <FaUserPlus />
                  </div>
                </div>
                <h5>
                  Impact Category: <span>xyz</span>{" "}
                </h5>
                <div>
                  <h5>
                    Ending In <br /> <span>18 h:12 m:23 s</span>{" "}
                  </h5>
                  <h5>
                    Amount Raised <br /> <span>$59.99</span>{" "}
                  </h5>
                </div>

                <div>
                  <h5>Description</h5>
                  <p>
                    Get a skilled product team to gain initial traction and
                    close the next fundraising round
                  </p>
                </div>
              </CampaignContent>
              <div style={{ textAlign: "center" }}>
                <Btn>VIEW MORE</Btn>
              </div>
            </CampaignContainer>
          </Grid>

          <Grid item xs={12} md={3}>
            <InfluencerInfo
              imgUrl={require("../../../assets/browse-influencer/bg.png")}
            >
            </InfluencerInfo>
            <CampaignContainer>
              <CampaignContent>
                <div>
                  <SubHeading>Campaign 2</SubHeading>
                  <div>
                    <AiOutlineLike />
                    <BsFillShareFill />
                    <FaUserPlus />
                  </div>
                </div>
                <h5>
                  Impact Category: <span>xyz</span>{" "}
                </h5>
                <div>
                  <h5>
                    Ending In <br /> <span>18 h:12 m:23 s</span>{" "}
                  </h5>
                  <h5>
                    Amount Raised <br /> <span>$59.99</span>{" "}
                  </h5>
                </div>

                <div>
                  <h5>Description</h5>
                  <p>
                    Get a skilled product team to gain initial traction and
                    close the next fundraising round
                  </p>
                </div>
              </CampaignContent>
              <div style={{ textAlign: "center" }}>
                <Btn>VIEW MORE</Btn>
              </div>
            </CampaignContainer>
          </Grid>

          <Grid item xs={12} md={3}>
            <InfluencerInfo
              imgUrl={require("../../../assets/browse-influencer/bg.png")}
            >
            </InfluencerInfo>
            <CampaignContainer>
              <CampaignContent>
                <div>
                  <SubHeading>Campaign 2</SubHeading>
                  <div>
                    <AiOutlineLike />
                    <BsFillShareFill />
                    <FaUserPlus />
                  </div>
                </div>
                <h5>
                  Impact Category: <span>xyz</span>{" "}
                </h5>
                <div>
                  <h5>
                    Ending In <br /> <span>18 h:12 m:23 s</span>{" "}
                  </h5>
                  <h5>
                    Amount Raised <br /> <span>$59.99</span>{" "}
                  </h5>
                </div>

                <div>
                  <h5>Description</h5>
                  <p>
                    Get a skilled product team to gain initial traction and
                    close the next fundraising round
                  </p>
                </div>
              </CampaignContent>
              <div style={{ textAlign: "center" }}>
                <Btn>VIEW MORE</Btn>
              </div>
            </CampaignContainer>
          </Grid>
        </Grid>

        <div style={{ textAlign: "center" }}>
          <Btn marginTop="80px" marginBottom="100px">VIEW MORE</Btn>
        </div>
      </div>
    );
};

export default InfluencerCampaign;