import React from "react";
import { CommonBtn, Heading, SubHeading } from "../../../global/styles/style-component";
import { BiFilter } from "react-icons/bi";
import { HiOutlineViewGrid } from "react-icons/hi";
import { Grid } from "@mui/material";
import gallery from '../../../assets/reporting-gallery/gallery.png'

const PortfolioGallery = () => {
  return (
    <div className="portfolio-gallery-container">
      <div>
        <Heading>Portfolio Gallery</Heading>
        <div className="portfolio-browse-filter">
          <div>
            <HiOutlineViewGrid />
            <h6>Browse By Campaign</h6>
          </div>
          <div>
            <BiFilter />
            <h6>Filter</h6>
          </div>
        </div>
      </div>

      <div className="portfolio-gallery">
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <div className="single-portfolio-gallery">
                <img src={gallery} alt="" />
                <div>
                <SubHeading>Snoozies</SubHeading>
                <span>02:08:2022</span>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className="single-portfolio-gallery">
                <img src={gallery} alt="" />
                <div>
                <SubHeading>Snoozies</SubHeading>
                <span>02:08:2022</span>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className="single-portfolio-gallery">
                <img src={gallery} alt="" />
                <div>
                <SubHeading>Snoozies</SubHeading>
                <span>02:08:2022</span>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className="single-portfolio-gallery">
                <img src={gallery} alt="" />
                <div>
                <SubHeading>Snoozies</SubHeading>
                <span>02:08:2022</span>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className="single-portfolio-gallery">
                <img src={gallery} alt="" />
                <div>
                <SubHeading>Snoozies</SubHeading>
                <span>02:08:2022</span>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className="single-portfolio-gallery">
                <img src={gallery} alt="" />
                <div>
                <SubHeading>Snoozies</SubHeading>
                <span>02:08:2022</span>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                </div>
            </Grid>
            
        </Grid>
      </div>

      <div style={{textAlign:'center', marginTop:'80px'}}>
        <CommonBtn>VIEW MORE</CommonBtn>
      </div>
      </div>
    
  );
};

export default PortfolioGallery;
