import React from "react";
import Container from "../Container";
import "../../pages/browseInfluencers/BrowseInfluencer.css";
import BrowseBrandsBanner from "./components/BrowseBrandsBanner";
import BrowseBrandCampaign from "./components/BrowseBrandCampaign";

const BrowseBrands = () => {
  return (
    <Container>
      <BrowseBrandsBanner />
      <BrowseBrandCampaign />
    </Container>
  );
};

export default BrowseBrands;
