import React from 'react';
import logo from '../../../assets/logo.png';
import user from '../../../assets/influencer-details/user-logo.png';
import logo2 from '../../../assets/influencer-details/logo.png';
import mask1 from '../../../assets/influencer-details/Mask group.png';
import mask2 from '../../../assets/influencer-details/Mask group(1).png';
import mask3 from '../../../assets/influencer-details/Mask group(2).png';
import { FaUserPlus, FaRegEye } from 'react-icons/fa';
import { AiFillLike } from 'react-icons/ai';
import { Heading } from '../../../global/styles/style-component';
import { Grid } from '@mui/material';
const InfluncerDetailsIntro = ({ influencer }) => {
  return (
    <div className='influencer-detail-intro'>
      <div style={{ textAlign: 'center' }}>
        <img src={logo} alt='' className='company-logo' />
      </div>
      <div className='details-intro-container'>
        <Grid container spacing={6}>
          <Grid item xs={12} md={4} className='details-intro1'>
            <img
              style={{
                width: '250px',
                height: '250px',
                borderRadius: '100%',
                objectFit: 'cover',
              }}
              src={`${process.env.REACT_APP_DOMAIN}${influencer?.profileImage}`}
              alt=''
            />
            <div>
              <span>
                <AiFillLike /> 1000+
              </span>
              <span>
                <FaRegEye /> 1000+
              </span>
              <span>
                <FaUserPlus /> 1000+
              </span>
            </div>
          </Grid>

          <Grid item xs={12} md={4} className='details-intro2'>
            <Heading> {influencer?.influencerName}</Heading>
            <h5>Web 3 Ambassador</h5>
            <span>Media</span>

            <div className='intro-images'>
              <div>
                <img src={logo2} alt='' className='logo-img' />
                <div>
                  <img src={mask1} alt='' />
                  <img src={mask2} alt='' />
                </div>
              </div>
              <div>
                <img src={mask3} alt='' />
              </div>
            </div>
            <h6>
              Open to Influencer Marketing Requests{' '}
              <span>{influencer?.openToIMRs ? 'YES' : 'No'}</span>
            </h6>
          </Grid>

          <Grid item xs={12} md={4} className='details-intro3'>
            <h5>Impact Interests</h5>
            <p>{influencer?.impactInterest}</p>
            <h5 style={{ marginTop: '40px' }}>Brand Affiliations</h5>
            <p>influencer?.brandAffiliation</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default InfluncerDetailsIntro;
