import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Error,
  FieldContainer,
  FieldItem,
} from '../../../global/components/vetting/vetting-style';
import { CommonBtn } from '../../../global/styles/style-component';
import { logout } from '../../../redux/user/userReducer';
import ApiCall from '../../../utils/axiosCall';
import { errorToast, successToast } from '../../../utils/toast';
import { validatePassword } from '../../../utils/validation';

const UserDetailsForm = () => {
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState({
    previousPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [error, setError] = useState({
    previousPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  return (
    <div className='submit-report-form user-details-form'>
      <h6 style={{ marginBottom: '15px', display: 'inline-block' }}>
        Personal Details
      </h6>
      <FieldContainer noOfColumn={2} style={{ padding: '0' }}>
        <FieldItem>
          <span>Name</span>

          <input
            type='text'
            placeholder='Write here'
            value={user?.data?.fullName}
          />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>
        <FieldItem>
          <span>User Name</span>

          <input
            type='text'
            placeholder='Write here'
            value={user?.data?.username}
          />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>

        <FieldItem>
          <span>Phone Number</span>

          <input type='text' placeholder='Write here' value={'------'} />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>

        <FieldItem>
          <span>Email Address</span>

          <input
            type='email'
            placeholder='Write here'
            value={user?.data?.email}
          />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>
      </FieldContainer>

      <FieldContainer
        noOfColumn={1}
        style={{ padding: '0', marginTop: '20px' }}
      >
        <FieldItem>
          <span>Joining Date</span>
          <input type='date' name='date' min='2017-04-01' max='2017-04-30' />
        </FieldItem>

        <FieldItem>
          <span>Wallet Address</span>
          <input
            type='text'
            placeholder='Write here'
            value={user?.data?.walletAddress}
          />
        </FieldItem>
      </FieldContainer>

      <FieldContainer
        noOfColumn={3}
        style={{ padding: '0', marginTop: '20px' }}
      >
        <FieldItem>
          <span>City</span>

          <input type='text' placeholder='Lancaster' />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>
        <FieldItem>
          <span>Country</span>

          <input type='text' placeholder='Britian' />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>

        <FieldItem>
          <span>Zip Code</span>

          <input type='text' placeholder='123456789' />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>
      </FieldContainer>

      <FieldContainer
        noOfColumn={1}
        style={{ padding: '0', marginTop: '20px' }}
      >
        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <span>Bio</span>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder=''
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>
      </FieldContainer>

      <h6 style={{ marginTop: '15px', display: 'inline-block' }}>
        Password Details
      </h6>
      <FieldContainer
        noOfColumn={2}
        style={{ padding: '0', marginTop: '20px' }}
      >
        <FieldItem>
          <span>Old Password</span>

          <input
            type='password'
            placeholder='Write here'
            value={password.previousPassword}
            onChange={(e) => {
              setPassword({
                ...password,
                previousPassword: e.target.value,
              });
              setError({
                ...error,
                previousPassword: validatePassword(e.target.value)
                  ? ''
                  : 'Invalid password',
              });
            }}
          />
          <Error>{error.previousPassword}</Error>
        </FieldItem>
        <FieldItem>
          <span>New Password</span>

          <input
            type='password'
            placeholder='Write here'
            value={password.newPassword}
            onChange={(e) => {
              setPassword({
                ...password,
                newPassword: e.target.value,
              });
              setError({
                ...error,
                newPassword: validatePassword(e.target.value)
                  ? ''
                  : 'Password should be minimum of 8 characters long',
              });
            }}
          />
          <Error>{error.newPassword}</Error>
        </FieldItem>

        <FieldItem>
          <span>Confirm Password</span>

          <input
            type='password'
            placeholder='Write here'
            value={password.confirmNewPassword}
            onChange={(e) => {
              setPassword({
                ...password,
                confirmNewPassword: e.target.value,
              });
              setError({
                ...error,
                confirmNewPassword:
                  password.newPassword === e.target.value
                    ? ''
                    : 'Password should be same',
              });
            }}
          />
          <Error>{error.confirmNewPassword}</Error>
        </FieldItem>
      </FieldContainer>

      <div style={{ textAlign: 'right', marginTop: '20px' }}>
        <CommonBtn
          onClick={() => {
            if (
              Object.values(password).every((i) => validatePassword(i)) &&
              password.newPassword === password.confirmNewPassword
            ) {
              ApiCall.post('/user/user-profile/change-password', password)
                .then((res) => {
                  successToast(res.data.message);

                  dispatch(logout());
                  localStorage.removeItem('accessToken');
                  navigate('/');
                })
                .catch((err) => {
                  errorToast(err?.response?.data?.message);
                });
            } else {
              setError({
                ...error,
                previousPassword: validatePassword(password.previousPassword)
                  ? ''
                  : 'Invalid password',
                newPassword: validatePassword(password.newPassword)
                  ? ''
                  : 'Password should be minimum of 8 characters long',

                confirmNewPassword:
                  password.newPassword === password.confirmNewPassword
                    ? ''
                    : 'Password should be same',
              });
            }
          }}
          style={{ marginRight: '20px' }}
        >
          UPDATE
        </CommonBtn>
        <CommonBtn
          onClick={() => {
            setPassword({
              previousPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            });
            setError({
              previousPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            });
          }}
          style={{ background: '#AEAEAE' }}
        >
          CANCEL
        </CommonBtn>
      </div>
    </div>
  );
};

export default UserDetailsForm;
