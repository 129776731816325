import { Grid } from "@mui/material";
import React from "react";
import { Heading, SubHeading } from "../../../global/styles/style-component";
import benefit1 from "../../../assets/done-for-you-partTwo/benefits1.png";
import benefit2 from "../../../assets/done-for-you-partTwo/benefits2.png";
import benefit3 from "../../../assets/done-for-you-partTwo/benefits3.png";
const DoneForYouBenefits = () => {
  return (
    <div className="done-for-you-benefits-container">
      <Heading>BENEFITS</Heading>
      <div className="benefits-card-container">
        <div className="benefits-card">
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 66,
              background: "#209AB7",
              width: "150px",
              height: "150px",
              borderRadius: "50%",
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              background: "#F8AA52",
              bottom: 30,
              left: 62,
              width: "300px",
              height: "100px",
              borderRadius: "50%",
            }}
          ></div>
          <div
            style={{
              background: "#fff9",
              backdropFilter: "blur(60px)",
              padding: "76px 40px",
              position: "relative",
              borderRadius: "10px",
            }}
          >
            <img src={benefit1} alt="" className="innovation-img" />
            <SubHeading>FAST INNOVATION</SubHeading>
            <p>
              Problems are solved quickly, while also developing an edge over
              competitors with unique ideas, fast results and high quality.
            </p>
          </div>
        </div>
        <div className="benefits-card save-costs-card">
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 66,
              background: "#209AB7",
              width: "150px",
              height: "150px",
              borderRadius: "50%",
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              background: "#F8AA52",
              bottom: 30,
              left: 62,
              width: "300px",
              height: "100px",
              borderRadius: "50%",
            }}
          ></div>
          <div
            style={{
              background: "#fff9",
              backdropFilter: "blur(60px)",
              padding: "52px 40px",
              position: "relative",
              borderRadius: "10px",
            }}
          >
            <img src={benefit2} alt="" className="cost-img" />
            <SubHeading>SAVE COSTS</SubHeading>
            <p>
            Allows for immediate action on your ideas and products, without the hassle of extra costs incurred by hiring top talent or training new hires.
            </p>
          </div>
        </div>
        <div className="benefits-card reduce-risk-card">
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 66,
              background: "#209AB7",
              width: "150px",
              height: "150px",
              borderRadius: "50%",
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              background: "#F8AA52",
              bottom: 30,
              left: 62,
              width: "300px",
              height: "100px",
              borderRadius: "50%",
            }}
          ></div>
          <div
            style={{
              background: "#fff9",
              backdropFilter: "blur(60px)",
              padding: "63px 40px",
              position: "relative",
              borderRadius: "10px",
            }}
          >
            <img src={benefit3} alt="" className="chart-img" />
            <SubHeading>REDUCE RISKS</SubHeading>
            <p>
            Reduce the risk of failure with the implementation of lean and agile methodologies
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoneForYouBenefits;
