import styled from 'styled-components';

export const PagerBanner = styled.div`
  background: ${(props) => `url(${props.imgUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: '';
    background-color: #0000002b;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & > h5,
  & > h1 {
    position: relative;
    z-index: 5;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    padding: 0;
    /* background-size: cover; */
  }
`;
export const PagerDescription = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space between;
  align-items: center;
  margin: 0;
  @media screen and (max-width: 992px) {
    display: block;
  }
`;
export const PagerDescriptionHeading = styled.div`
  width: 100%;
  & > h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    color: #219ebc;
  }
  & > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 131%;
    color: #023047;
    opacity: 0.75;
  }
  & > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 131%;
    color: #023047;
    opacity: 0.75;
  }
  & > ul {
    margin-top: 10px;

    & > li {
      font-weight: 400;
      font-size: 16px;
      line-height: 131%;
      color: #023047;
      opacity: 0.75;
      list-style: none;
      margin-bottom: 20px;
      position: relative;
      margin-left: 20px;
      &:before {
        content: '';
        position: absolute;
        background: #f8991d;
        top: 5px;
        width: 10px;
        height: 10px;
        border-radius: 20px;
        left: -18px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    & > h2 {
      font-size: 30px;
      line-height: 0;
      margin-bottom: 30px;
    }
    & > p {
      font-size: 14px;
    }
    & > span {
      font-size: 14px;
    }
    & > ul {
      & > li {
        font-size: 14px;
      }
    }
  }
`;
export const PagerDescriptionImage = styled.div`
  & > img {
    width: 100%;
  }
  @media screen and (max-width: 992px) {
    margin-top: -20px;
  }
`;
export const PagerQueries = styled.div`
  width: 80%;
  margin: auto;
  padding-bottom: 50px;
`;
export const SingleQueries = styled.div`
  & > h4 {
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    color: #219ebc;
    position: relative;
    margin-left: 20px;

    &:before {
      content: '';
      position: absolute;
      background: #f8991d;
      top: 15px;
      width: 4px;
      height: 25px;
      left: -18px;
    }
  }
  & > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 131%;
    color: #023047;
    opacity: 0.75;
  }
  @media screen and (max-width: 992px) {
    & > h4 {
      font-size: 20px;
      line-height: 34px;

      &:before {
        height: 15px;
        top: 10px;
      }
    }
    & > p {
      font-size: 14px;
    }
  }
`;
