import React from "react";
import { PagerBanner } from "../../../../src/pages/Pager/PagerStyle";

const Web3ToolsBanner = () => {
  return (
    <PagerBanner
      imgUrl={require("../../../assets/nft-details/nft-details-banner.png")}
    >
    <h1>Web3 Tools - Smart Contracts</h1>
    </PagerBanner>
  );
};

export default Web3ToolsBanner;
