import React from "react";
import {
  Heading,
  StatementHeading,
} from "../../../global/styles/style-component";
import { Box } from "@mui/material";

const ServicesIntro = () => {
  return (
    <div className="services-intro-container">
      <StatementHeading>
        Are you an early stage startup working on an innovative Web 3 tech
        solution that can have a significant positive impact in the world?
      </StatementHeading>

      <div className="here-to-help">
        <Heading>HERE TO HELP</Heading>
        <p>
          Wherever you are on your impact journey, we are here to help. Whether
          you're at the starting point with just a vision and no idea how to
          achieve it, or if your roadmap is fully plotted out, we can assist
          every step of the way.
        </p>
      </div>

      <div className="impact-solutions">
        <Box
          sx={{
            width: "90%",
            margin: "auto",
            my: "80px",
            p: "3px",
            borderRadius: "40px",
            background: "linear-gradient(129.59deg, #48bbbb, #66c48f70)",
            boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.2)",
            // mx: { xs: "20px", md: "40px" },
          }}
        >
          <Box
            sx={{
              p: { xs: "30px", md: "40px" },
              borderRadius: "38px",
              background: "#fff",
            }}
          >
            <Box
              sx={{
                display: { md: "flex" },
                alignItems: "center",
                gap: "16px",
                "&>div": { flex: 1 },
              }}
            >
              <div>
                <img
                  src={require("../../../assets/new-nft-market-landing-page/3EF8FEC8-0DD4-4D2D-A1AE-4FF92AC73E7F.gif")}
                  alt=""
                />
              </div>
              <div className="impact-solution-content">
                <Heading>360 SOLUTIONS</Heading>
                <p>
                  With our network of preferred, impact-aligned partners, we
                  offer a full suite of tech for good consulting, development
                  and support solutions at fair rates AND with a percentage of
                  all earnings supporting verified impact initiatives. It's a
                  double-whammy of goodness!
                </p>
              </div>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ServicesIntro;
