import React from 'react';
import Banner from '../../global/components/banner';
import { BannerHeading } from '../../global/styles/style-component';
import Container from '../Container';
import { PagerBanner } from '../Pager/PagerStyle';
import './NftHowToHub.css';
import NftHubCards from './NftHubCards';
import NftHubQA from './NftHubQA';
import NftMovement from './NftMovement';
import WelcomeToHub from './WelcomeToHub';
const imgUrl = require('../../assets/nft-details/nft-details-banner.png');

const NftHowToHub = () => {
  return (
    <Container>
      <div className='nft-hub-container'>
        <PagerBanner imgUrl={imgUrl}>
          <BannerHeading>Intro To NFTs</BannerHeading>
        </PagerBanner>
        <WelcomeToHub />
        <NftHubCards />
        <NftHubQA />
        <NftMovement />
      </div>
    </Container>
  );
};

export default NftHowToHub;
