import { Box } from "@mui/material";
import { MainContainer, Heading } from "../../../global/styles/style-component";

import coreFeatures from "../../../assets/images/homepage/coreFeature.png";
import roadmap from "../../../assets/images/homepage/roadmap.png";

const CoreFeatures = () => {
  return (
    <MainContainer>
      <Box
        sx={{
          p: "3px",
          borderRadius: "40px",
          background: "linear-gradient(129.59deg, #48bbbb, #66c48f70)",
          boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box
          sx={{
            p: { xs: "20px", md: "40px" },
            borderRadius: "38px",
            background: "#fff",
          }}
        >
          <Box
            sx={{
              display: { md: "flex" },
              gap: "16px",
              "&>div": { flex: 1 },
            }}
          >
            {/* left */}
            <div
              style={{
                padding: "20px",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  marginBottom: 40,
                  color: "#023047",
                  fontSize: "2em",
                }}
              >
                CORE FEATURES
              </h1>
              <img src={coreFeatures} alt="" style={{ width: "100%" }} />
            </div>
            {/* right */}
            <div
              style={{
                padding: "20px",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  marginBottom: 40,
                  color: "#023047",
                  fontSize: "2em",
                }}
              >
                ROADMAP
              </h1>
              <img src={roadmap} alt="" style={{ width: "100%" }} />
            </div>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default CoreFeatures;
