import React, { useState } from 'react';
import { AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { BiLink } from 'react-icons/bi';
import { GoMail } from 'react-icons/go';
import { VscLocation } from 'react-icons/vsc';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommonBtn } from '../../../global/styles/style-component';
import { influencerSubmitInfo } from '../../../services/categoriesRegistration';
import {
  validateEmail,
  validateName,
  validateUrl,
} from '../../../utils/validation';
import {
  CategoryFromContainer,
  GridContainer,
  GridItem,
  InputContainer,
} from '../formStyle';

const InfluencerForms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    influencerName: {
      value: '',
      error: '',
    },
    location: {
      value: '',
      error: '',
    },
    websiteUrl: {
      value: '',
      error: '',
    },
    personName: {
      value: '',
      error: '',
    },
    personEmail: {
      value: '',
      error: '',
    },
    personPhoneNum: {
      value: '',
      error: '',
    },
  });

  const onSubmitHandler = () => {
    if (
      validateName(data.influencerName.value) &&
      validateName(data.personName.value) &&
      data.location.value !== '' &&
      validateUrl(data.websiteUrl.value) &&
      validateEmail(data.personEmail.value) &&
      formatPhoneNumber(data.personPhoneNum.value)
    ) {
      let tempData = {};
      Object.keys(data).forEach((item) => {
        tempData[item] = data[item].value;
      });
      dispatch(influencerSubmitInfo(tempData, navigate));
    } else {
      setData({
        ...data,
        influencerName: {
          ...data.influencerName,
          error: validateName(data.influencerName.value)
            ? ''
            : 'Name should contain alphabet only',
        },
        websiteUrl: {
          ...data.websiteUrl,
          error: validateUrl(data.websiteUrl.value)
            ? ''
            : 'Invalid website link',
        },
        location: {
          ...data.location,
          error: data.location.value !== '' ? '' : 'Invalid location',
        },
        personName: {
          ...data.personName,
          error: validateName(data.personName.value)
            ? ''
            : 'Name should contain alphabet only',
        },
        personEmail: {
          ...data.personEmail,
          error: validateEmail(data.personEmail.value)
            ? ''
            : 'Invalid email address',
        },
        personPhoneNum: {
          ...data.personPhoneNum,
          error: formatPhoneNumber(data.personPhoneNum.value)
            ? ''
            : 'Invalid phone number',
        },
      });
    }
  };
  return (
    <CategoryFromContainer>
      <h1 style={{ margin: '0' }}>Influencer</h1>
      <h5>Fill in the required information</h5>
      <GridContainer width={'70%'} columnCount={'2'}>
        <GridItem style={{ gap: '0' }} className='single-input nine'>
          <label htmlFor=''>Influencer name</label>
          <InputContainer error={data.influencerName.error !== ''}>
            <input
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  influencerName: {
                    value: e.target.value,
                    error: !validateName(e.target.value)
                      ? 'Please provide valid influencer name'
                      : '',
                  },
                }));
              }}
              value={data.influencerName.value}
              type='text'
            />
            <AiOutlineUser className='input-icon' />
          </InputContainer>
          <span>{data.influencerName.error}</span>
        </GridItem>
        <GridItem style={{ gap: '0' }} className='single-input nine'>
          <label htmlFor=''>Person Name</label>
          <InputContainer error={data.personName.error !== ''}>
            <input
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  personName: {
                    value: e.target.value,
                    error: !validateName(e.target.value)
                      ? 'Please provide valid person name'
                      : '',
                  },
                }));
              }}
              value={data.personName.value}
              type='text'
            />
            <AiOutlineUser className='input-icon' />
          </InputContainer>
          <span>{data.personName.error}</span>
        </GridItem>
        <GridItem style={{ gap: '0' }} className='single-input nine'>
          <label htmlFor=''>Person email</label>
          <InputContainer error={data.personEmail.error !== ''}>
            <input
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  personEmail: {
                    value: e.target.value,
                    error: !validateEmail(e.target.value)
                      ? 'Please provide valid email'
                      : '',
                  },
                }));
              }}
              value={data.personEmail.value}
              type='text'
            />
            <GoMail className='input-icon' />
          </InputContainer>
          <span>{data.personEmail.error}</span>
        </GridItem>
        <GridItem style={{ gap: '0' }} className='single-input three'>
          <label htmlFor=''>Phone number</label>
          <InputContainer error={data.personPhoneNum.error !== ''}>
            <PhoneInput
              style={{ width: '100%' }}
              international
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  personPhoneNum: {
                    value: e,
                    error: !formatPhoneNumber(e)
                      ? 'Please provide valid Phone Num'
                      : '',
                  },
                }));
              }}
              value={data.personPhoneNum.value}
            />
            <AiOutlinePhone className='input-icon' />
          </InputContainer>
          <span>{data.personPhoneNum.error}</span>
        </GridItem>
        <GridItem style={{ gap: '0' }} className='single-input nine'>
          <label htmlFor=''>Location</label>
          <InputContainer error={data.location.error !== ''}>
            <input
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  location: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'Please provide valid location'
                        : '',
                  },
                }));
              }}
              value={data.location.value}
              type='text'
            />
            <VscLocation className='input-icon' />
          </InputContainer>
          <span>{data.location.error}</span>
        </GridItem>
        <GridItem style={{ gap: '0' }} className='single-input nine'>
          <label htmlFor=''>Website URL</label>
          <InputContainer error={data.websiteUrl.error !== ''}>
            <input
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  websiteUrl: {
                    value: e.target.value,
                    error: !validateUrl(e.target.value)
                      ? 'Please provide valid website url'
                      : '',
                  },
                }));
              }}
              value={data.websiteUrl.value}
              type='text'
            />
            <BiLink className='input-icon' />
          </InputContainer>
          <span>{data.websiteUrl.error}</span>
        </GridItem>
      </GridContainer>
      <CommonBtn
        onClick={onSubmitHandler}
        margin={'1em 0 2em 0'}
        className='submit-btn'
      >
        SUBMIT
      </CommonBtn>
    </CategoryFromContainer>
  );
};

export default InfluencerForms;
