import React from 'react';
import { CommonBtn, SubHeading } from '../../../global/styles/style-component';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoIosArrowDropupCircle } from 'react-icons/io';

const Services = () => {
  return (
    <div className='services-section'>
      <div className='service-container'>
        <div>
          <SubHeading>BLOCKCHAIN ARCHITECTURE AND DEVELOPMENT</SubHeading>
          <ul>
            <li>
              <AiOutlineCheck /> Token Creation, Liquidity Pool, Tokenomics
            </li>
            <li>
              <AiOutlineCheck /> Pre Sale/Seed Round, Private Sale, Public Sale,
              Airdrop Token/NFTs
            </li>
            <li>
              <AiOutlineCheck /> Staking Smart Contract
            </li>
            <li>
              <AiOutlineCheck /> Fixed Marketplace
            </li>
            <li>
              <AiOutlineCheck /> Closed NFT Marketplace
            </li>
            <li>
              <AiOutlineCheck /> Open NFT Marketplace
            </li>
            <li>
              <AiOutlineCheck /> NFT Mystery Box
            </li>
            <li>
              <AiOutlineCheck /> Launchpads (IDO, INO, IMO)
            </li>
            <li>
              <AiOutlineCheck /> Play to Earn Smart Contracts
            </li>
            <li>
              <AiOutlineCheck /> Gasless Marketplaces
            </li>
            <li>
              <AiOutlineCheck /> Gasless Metaverse Architecture
            </li>
            <li>
              <AiOutlineCheck /> Metaverse Virtual Estate Sale Architecture
              design
            </li>
            <li>
              <AiOutlineCheck /> Metaverse Land Sale Smart Contracts
            </li>
            <li>
              <AiOutlineCheck /> Land Renting / Mortgage Smart Contracts
            </li>
            <li>
              <AiOutlineCheck /> Metaverse Collectables/Wearables Marketplace
            </li>
            <li>
              <AiOutlineCheck /> NFT play-to-earn games: turnkey and customized
            </li>
            <li>
              <AiOutlineCheck /> Web 3.0 DApp (transactions based) with smart
              contracts
            </li>
            <li>
              <AiOutlineCheck /> Crypto coin development
            </li>
            <li>
              <AiOutlineCheck /> Project scoping and management
            </li>
            <li>
              <AiOutlineCheck /> Web 2 to Web3 transition
            </li>
            <li>
              <AiOutlineCheck /> Tokenomics
            </li>
            <li>
              <AiOutlineCheck /> NFT utility
            </li>
            <li>
              <AiOutlineCheck /> Smart contract review and audit
            </li>
            <li>
              <AiOutlineCheck /> Metaverse strategy and project implementation
            </li>
            <li>
              <div>
                <AiOutlineCheck /> DAO
              </div>
              <div>
                <IoIosArrowDropupCircle className='up-arrow' />
              </div>
            </li>
          </ul>
        </div>

        <div>
          <SubHeading>WEB BASED SERVICES & MERN STACK DEVELOPMENT</SubHeading>
          <ul>
            <li>
              <AiOutlineCheck /> Websites Design & Development
            </li>
            <li>
              <AiOutlineCheck /> E-commerce stores
            </li>
            <li>
              <AiOutlineCheck /> Real time chat
            </li>
            <li>
              <AiOutlineCheck /> Firebase hosting
            </li>
            <li>
              <AiOutlineCheck /> Firebase Cloud Function Development and
              Deployment
            </li>
            <li>
              <AiOutlineCheck /> Firestore Database Management and Creation
            </li>
            <li>
              <AiOutlineCheck /> Firebase authentications for social, phone and
              mail
            </li>
            <li>
              <AiOutlineCheck /> Rest APIs
            </li>
            <li>
              <AiOutlineCheck /> Learning management systems
            </li>
            <li>
              <AiOutlineCheck /> PoS systems
            </li>
            <li>
              <AiOutlineCheck /> Backend development for any site
            </li>
            <li>
              <AiOutlineCheck /> Custom Projects
            </li>
            <li>
              <div>
                <AiOutlineCheck /> AND MUCH MORE!
              </div>
              <div>
                <IoIosArrowDropupCircle className='up-arrow' />
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="service-btn">
        <CommonBtn>REACH OUT</CommonBtn>
      </div> */}
    </div>
  );
};

export default Services;
