import React from 'react';

const NftHubCards = () => {
    return (
        <div className='nft-hub-card-section'>
            <div className="row">
                <div className="col-lg-6 col-xxl-4">
                    <div className="single-hub-card">
                        <p>The NFT art world made a big splash featuring simple (sometimes really simple) graphics and computer-generated (CG) art. Just like with famous abstract paintings from the 1940s to today, many examples of art for NFTs look like they were not very hard to create.  </p>
                        <div className="card-border"></div>
                    </div>
                </div>
                <div className="col-lg-6 col-xxl-4">
                <div className="single-hub-card">
                        <p>The simplicity of some of the artwork with high dollar NFT price tags is the 2020s version of an 80-year-old controversy around abstract art. </p>
                        <div className="card-border"></div>
                    </div>
                </div>
                <div className="col-lg-6 col-xxl-4">
                <div className="single-hub-card">
                        <p>Very few would argue that it takes years to acquire the technical skills to paint like Leonardo or sculpt like Michelangelo, and even then most people could not come close to creating art as they did. In contrast, almost anyone could reproduce some abstract paintings in an afternoon. </p>
                        <div className="card-border"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NftHubCards;