import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: ${({ fontSize }) => fontSize};
  & > label {
    color: ${({ error }) => (error ? 'red' : '#023047')};
    font-weight: 500;
  }
  & > div {
    border: 1px solid ${({ error }) => (error ? 'red' : '#ccc')};
    border-radius: 3px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 3px;
    & > input,
    & > textarea {
      outline: none;
      padding: 0.5em 1em;
      flex: 1;
      border: none;
      height: 100%;
      background-color: white;
    }
    & > div > button {
      margin-right: 0;
    }
    & > svg {
      fill: ${({ error }) => (error ? 'red' : '#023047')};
      margin-right: 5px;
    }
  }
  & > span {
    color: red;
    font-weight: bold;
    font-size: 12px;
  }
`;
