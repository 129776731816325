import React from 'react';
import { Link } from 'react-router-dom';
import workImg from '../../../assets/workImg.png';
import workElips from '../../../assets/Ellipse 19.png';
import workElips2 from '../../../assets/Ellipse 20.png';
import elips from '../../../assets/elips.png';
import elips2 from '../../../assets/elips2.png';

const HowWeWork = () => {
  return (
    <div className='HowWeWork-section'>
      <div className='row work-align'>
        <div className='col-md-5'>
          <div className='work-img'>
            <img className='work-main-img' src={workImg} alt='' />
            <img className='workElips' src={workElips} alt='' />
            <img className='workElips2' src={workElips2} alt='' />
          </div>
        </div>
        <div className='col-md-7'>
          <div className='how-we-work-content'>
            <img className='contentElips' src={elips} alt='' />
            <img className='contentElips2' src={elips2} alt='' />

            <h4>HOW WE WORK</h4>

            <div className='single-work-content'>
              <h4>DIY</h4>
              <p>
                Set up your campaigns and engage with your fans on demo. To get
                started go
                <Link
                  to='/'
                  style={{ color: '#FFB703', textDecoration: 'none' }}
                >
                  here
                </Link>
              </p>
            </div>
            <div className='single-work-content'>
              <h4>DONE FOR YOU</h4>
              <p>
                Want it done for you? Get in touch!
                <Link
                  to='/'
                  style={{ color: '#FFB703', textDecoration: 'none' }}
                >
                  here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
