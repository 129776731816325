import React from "react";
import { SubHeading } from "../../../global/styles/style-component";

const Reports = () => {
  return (
    <div className="report-container">
      <SubHeading>Reports</SubHeading>
      <div>
        <div>
          <h5>Report 1</h5>
          <span>08/12/2022</span>
        </div>
        <div>
          <h5>Report 1</h5>
          <span>08/12/2022</span>
        </div>
        <div>
          <h5>Report 1</h5>
          <span>08/12/2022</span>
        </div>
        <div>
          <h5>Report 1</h5>
          <span>08/12/2022</span>
        </div>
      </div>
    </div>
  );
};

export default Reports;
