import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Btn,
  Heading,
  SubHeading,
} from '../../../global/styles/style-component';
import {
  CampaignContainer,
  CampaignContent,
  InfluencerInfo,
  InfluencerProffesion,
} from '../../../pages/browseInfluencers/InfluencerStyle';
import { getAllCauses } from '../../../services/campaign';
import { errorToast } from '../../../utils/toast';

const NposAndCausesCampaign = () => {
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState([]);

  useEffect(() => {
    getAllCauses()
      .then((res) => {
        setCampaign(res.data.message);
      })
      .catch((err) => errorToast(err.response.data.message));
  }, []);
  return (
    <div className='influncer-campaign-section'>
      <Heading>NPOs AND CAUSES CAMPAIGNS</Heading>
      {/* <div style={{ textAlign: "center" }} className="browse-bttn">
        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Search By Name{" "}
        </Btn>
        <Btn color={"#023047"} marginRight="15px" marginLeft="15px" width="300px">
          {" "}
          <FiSearch className="browse-search-icon" /> Browse By Cause/NPO Category{" "}
        </Btn>

        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Browse By  Impact Categoryr{" "}
        </Btn>
        <BiFilterAlt className="filter-icon" />
      </div> */}

      <div className='influncer-cards'>
        <Grid container spacing={2}>
          {campaign.map((item, index) => (
            <Grid key={index} item xs={12} md={3}>
              <InfluencerInfo
                imgUrl={require('../../../assets/browse-influencer/bg.png')}
              >
                <div>
                  <img
                    src={`${process.env.REACT_APP_DOMAIN}${item.logoUrl}`}
                    alt=''
                    className='brand-logo'
                  />
                  <SubHeading>{item?.name}</SubHeading>
                </div>
              </InfluencerInfo>
              <InfluencerProffesion>
                {/* <h6>
                  Address:{' '}
                  <span style={{ color: '#fff', marginLeft: '10px' }}>
                    NPO/CAUSE
                  </span>
                </h6> */}
                <h6>
                  Category:{' '}
                  <span style={{ color: '#fff', marginLeft: '10px' }}>
                    {item?.category}
                  </span>
                </h6>
              </InfluencerProffesion>
              <CampaignContainer>
                <p className='globalDescription'>{item?.about}</p>
                {item?.activeCampaigns.length ? (
                  item?.activeCampaigns.map(({ overview, _id }, index) =>
                    item?.activeCampaigns.length > 1 ? (
                      <CampaignContent
                        onClick={() => {
                          navigate(`/campaign-details/${_id}`);
                        }}
                      >
                        <div>
                          <SubHeading>{overview.campaignName}</SubHeading>
                          {/* <div>
                          <AiOutlineLike />
                          <BsFillShareFill />
                          <FaUserPlus />
                        </div> */}
                        </div>
                        <h5>
                          Status: <span>{overview.campaignType}</span>{' '}
                        </h5>
                        <div>
                          <h5>
                            Ending In <br />{' '}
                            <span>
                              {new Date(overview.endDate).toLocaleDateString()}
                            </span>{' '}
                          </h5>
                          {/* <h5>
                          Amount Raised <br /> <span>${overview}</span>{' '}
                        </h5> */}
                        </div>

                        <div>
                          <h5>Description</h5>
                          <p>{overview.description}</p>
                        </div>
                      </CampaignContent>
                    ) : (
                      <CampaignContent onClick={() => {}}>
                        {/* <div> */}
                        <SubHeading>------------</SubHeading>
                        {/* <div>
                          <AiOutlineLike />
                          <BsFillShareFill />
                          <FaUserPlus />
                        </div> */}
                        {/* </div> */}
                        <h5>
                          Status: <span>------------</span>{' '}
                        </h5>
                        <div>
                          <h5>
                            Ending In <br /> <span>-----------</span>{' '}
                          </h5>
                          {/* <h5>
                          Amount Raised <br /> <span>${overview}</span>{' '}
                        </h5> */}
                        </div>

                        <div>
                          <h5>Description</h5>
                          <p>
                            ---------------------------------------------------------------------------------------------------------------------------------------
                          </p>
                        </div>
                      </CampaignContent>
                    )
                  )
                ) : (
                  <>
                    <CampaignContent onClick={() => {}}>
                      {/* <div> */}
                      <SubHeading>------------</SubHeading>
                      {/* <div>
                          <AiOutlineLike />
                          <BsFillShareFill />
                          <FaUserPlus />
                        </div> */}
                      {/* </div> */}
                      <h5>
                        Status: <span>------------</span>{' '}
                      </h5>
                      <div>
                        <h5>
                          Ending In <br /> <span>-----------</span>{' '}
                        </h5>
                        {/* <h5>
                          Amount Raised <br /> <span>${overview}</span>{' '}
                        </h5> */}
                      </div>

                      <div>
                        <h5>Description</h5>
                        <p>
                          ---------------------------------------------------------------------------------------------------------------------------------------
                        </p>
                      </div>
                    </CampaignContent>
                    <CampaignContent onClick={() => {}}>
                      {/* <div> */}
                      <SubHeading>------------</SubHeading>
                      {/* <div>
                          <AiOutlineLike />
                          <BsFillShareFill />
                          <FaUserPlus />
                        </div> */}
                      {/* </div> */}
                      <h5>
                        Status: <span>------------</span>{' '}
                      </h5>
                      <div>
                        <h5>
                          Ending In <br /> <span>-----------</span>{' '}
                        </h5>
                        {/* <h5>
                          Amount Raised <br /> <span>${overview}</span>{' '}
                        </h5> */}
                      </div>

                      <div>
                        <h5>Description</h5>
                        <p>
                          ---------------------------------------------------------------------------------------------------------------------------------------
                        </p>
                      </div>
                    </CampaignContent>
                  </>
                )}

                <div style={{ textAlign: 'center' }}>
                  <Btn
                    onClick={() => {
                      navigate(`/npos-causes-details/${item.userId}`);
                    }}
                  >
                    View
                  </Btn>
                </div>
              </CampaignContainer>
            </Grid>
          ))}
        </Grid>

        {/* <div style={{ textAlign: 'center' }}>
          <Btn marginTop='80px'>VIEW MORE</Btn>
        </div> */}
      </div>
    </div>
  );
};

export default NposAndCausesCampaign;
