import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommonBtn } from '../../../../global/styles/style-component';
import { caseRegistration } from '../../../../services/categoriesRegistration';
import ApiCall from '../../../../utils/axiosCall';
import {
  validateEmail,
  validateName,
  validatePhone,
  validateUrl,
} from '../../../../utils/validation';
import { CategoryFromContainer } from '../../formStyle';

import { formatPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { errorToast } from '../../../../utils/toast';
import Form from './Form';

const CauseForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    logo: '',
    cover: '',
    name: '',
    mainPersonName: '',
    mainPhoneNum: '',
    mainEmail: '',
    alternatePersonName: '',
    alternatePhoneNum: '',
    alternateEmail: '',
    about: '',
    country: '',
    city: '',
    category: '',
    websiteUrl: '',
    socials: '',
    feelImpactoverse: '',
    isRegistered: 'true',
    countryOfRegistration: '',
    groupAgenda: '',
    groupActivities: '',
    previousActivity: '',
    sinceWhen: '',
    registrationNum: '',
    history: '',
  });

  const [dataError, setDataError] = useState({
    logo: '',
    cover: '',
    name: '',
    mainPersonName: '',
    mainPhoneNum: '',
    mainEmail: '',
    alternatePersonName: '',
    alternatePhoneNum: '',
    alternateEmail: '',
    about: '',
    country: '',
    city: '',
    category: '',
    websiteUrl: '',
    socials: '',
    feelImpactoverse: '',
    isRegistered: '',
    countryOfRegistration: '',
    groupAgenda: '',
    groupActivities: '',
    previousActivity: '',
    sinceWhen: '',
    registrationNum: '',
    history: '',
  });

  const onValidate = () => {
    setDataError({
      name: data.name === '' ? 'Please provide the Non profit/Cause name' : '',
      mainPersonName: !validateName(data.mainPersonName)
        ? 'Please provide First and Last names'
        : '',
      mainPhoneNum: !formatPhoneNumber(data.mainPhoneNum)
        ? 'Please provide a Phone number'
        : '',
      mainEmail: !validateEmail(data.mainEmail)
        ? 'Please provide an Email address'
        : '',
      alternatePersonName: !validateName(data.alternatePersonName)
        ? 'Please provide First and Last names'
        : '',
      alternatePhoneNum: !validatePhone(data.alternatePhoneNum)
        ? 'Please provide a Phone number'
        : '',
      alternateEmail: !validateEmail(data.alternateEmail)
        ? 'Please provide an Email address'
        : data.alternateEmail.match(data.mainEmail)
        ? 'Alternate email should not be same as main email'
        : '',
      about:
        data.about === ''
          ? 'Please provide information on the brand/organization'
          : data.about.length > 15
          ? ''
          : 'Please provide a minimum of 15 characters',
      country: data.country === '' ? 'Please select country' : '',
      city: data.city === '' ? 'Please select city' : '',
      category: data.category === '' ? 'Please select category' : '',
      websiteUrl: !validateUrl(data.websiteUrl)
        ? 'Please provide valid  website URL'
        : '',
      // socials: !validateUrl(data.socials) ? 'Please provide valid socials' : '',
      feelImpactoverse:
        data.feelImpactoverse.length < 15
          ? 'Please provide the benefits you feel demo offers'
          : '',
      countryOfRegistration:
        data.countryOfRegistration === ''
          ? 'Please provide Non profit/Cause registration number'
          : '',
      groupAgenda:
        data.groupAgenda === '' ? 'Please provide valid group agenda' : '',
      groupActivities:
        data.groupActivities === ''
          ? 'Please provide valid     group activities'
          : '',
      previousActivity:
        data.previousActivity === ''
          ? 'Please provide valid     previous activity'
          : '',
      sinceWhen: data.sinceWhen === '' ? 'Please provide valid since when' : '',
      registrationNum:
        data.registrationNum === ''
          ? 'Please provide valid  charity umber'
          : '',
      history: data.history === '' ? 'Please provide valid history' : '',
    });
  };

  const [isVerified, setIsVerified] = useState(false);
  useEffect(() => {
    checkIfVerified();
  }, []);

  const checkIfVerified = () => {
    ApiCall.get('user/account-verification')
      .then((res) => {
        setIsVerified(res.data.success);
      })
      .catch((err) => setIsVerified(err.response?.data?.success));
  };
  return (
    <CategoryFromContainer>
      {isVerified ? (
        <>
          <h4>Fill in the required information</h4>
          <Form
            data={data}
            dataError={dataError}
            setDataError={setDataError}
            setData={setData}
          />
          <CommonBtn
            onClick={() => {
              if (
                !validateName(data.name) ||
                !validateName(data.mainPersonName) ||
                !formatPhoneNumber(data.mainPhoneNum) ||
                !validateEmail(data.mainEmail) ||
                !validateName(data.alternatePersonName) ||
                !validatePhone(data.alternatePhoneNum) ||
                !validateEmail(data.alternateEmail) ||
                data.about === '' ||
                data.about.length < 15 ||
                data.country === '' ||
                data.city === '' ||
                data.category === '' ||
                !validateUrl(data.websiteUrl) ||
                // !validateUrl(data.socials) ||
                data.feelImpactoverse < 15 ||
                (data.isRegistered === 'true' &&
                  (data.countryOfRegistration === '' ||
                    data.registrationNum === '')) ||
                (data.isRegistered !== 'true' &&
                  (data.groupAgenda === '' ||
                    data.groupActivities === '' ||
                    data.previousActivity === ''))
              ) {
                console.log(
                  !validateName(data.name),
                  !validateName(data.mainPersonName),
                  !formatPhoneNumber(data.mainPhoneNum),
                  !validateEmail(data.mainEmail),
                  !validateName(data.alternatePersonName),
                  !validatePhone(data.alternatePhoneNum),
                  !validateEmail(data.alternateEmail),
                  data.about === '',
                  data.country === '',
                  data.city === '',
                  data.category === '',
                  !validateUrl(data.websiteUrl),
                  // !validateUrl(data.socials),
                  data.feelImpactoverse < 15,
                  data.isRegistered === 'true' &&
                    (data.countryOfRegistration === '' ||
                      data.registrationNum === ''),
                  data.isRegistered !== 'true' &&
                    (data.groupAgenda === '' ||
                      data.groupActivities === '' ||
                      data.previousActivity === '')
                );
                onValidate();
                return;
              }
              const formData = new FormData();
              Object.keys(data).forEach((item, index) => {
                if (Array.isArray(data[item])) {
                  data[item].forEach((a, idx) => {
                    if (a !== '') {
                      formData.append(`${item}[${idx}]`, a);
                    }
                  });
                } else {
                  formData.append(item, data[item]);
                }
              });
              dispatch(caseRegistration(formData, navigate));
            }}
            className='submit-btn'
          >
            SUBMIT REGISTRATION
          </CommonBtn>{' '}
        </>
      ) : (
        <CommonBtn
          onClick={() => {
            ApiCall.post('user/verify-account')
              .then((res) => {
                // checkIfVerified();
                window.open(res.data.url, '_self');
              })
              .catch((err) => errorToast(err.response?.data?.message));
          }}
        >
          verify your account
        </CommonBtn>
      )}
    </CategoryFromContainer>
  );
};

export default CauseForm;
