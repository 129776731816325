import React from 'react';

const Reporting = () => {
  return (
    <h4
      style={{
        color: '#FB8501',
      }}
      className='text-center mt-5 mb-4'
    >
      REPORTS SUBMITTED BY THIS CAMPAIGN WILL BE AVAILABLE TO VIEW HERE
    </h4>
  );
};

export default Reporting;
