import React from 'react';
import {
  Error,
  FieldContainer,
  FieldItem,
  UploadBtn,
} from '../../../global/components/vetting/vetting-style';
import { Btn } from '../../../global/styles/style-component';

const SubmitReportForm = () => {
  return (
    <div className='submit-report-form'>
      <span style={{ marginBottom: '15px', display: 'inline-block' }}>
        Please report on
      </span>
      <FieldContainer noOfColumn={3} style={{ padding: '0' }}>
        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <h5>KPI 1</h5>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>

        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <h5>KPI 2</h5>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>

        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <h5>KPI 3</h5>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>
      </FieldContainer>

      <FieldContainer
        noOfColumn={2}
        style={{ padding: '0', marginTop: '20px' }}
      >
        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <span>Share opportunities to collaborate to scale impact</span>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>

        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <span>Share any obstacles faced and how you overcame these</span>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>

        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <span>Share any lessons learned</span>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>

        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <span>Provide any other information you feel is relevant</span>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>
      </FieldContainer>

      <FieldContainer
        noOfColumn={2}
        style={{ padding: '0', marginTop: '20px' }}
      >
        <span style={{ display: 'inline-block' }}>
          Provide contact information of third parties who can corroborate the
          work done
        </span>{' '}
        <br />
        <FieldItem>
          <span>Name</span>

          <input type='text' placeholder='Write here' />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>
        <FieldItem>
          <span>Phone</span>

          <input type='text' placeholder='Write here' />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>
        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <span>How are they involved in the project?</span>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>
        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <span>Add links to other reports and documents if applicable</span>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>
      </FieldContainer>

      <FieldContainer
        noOfColumn={2}
        style={{ padding: '0', marginTop: '20px' }}
      >
        <span style={{ display: 'inline-block' }}>
          Upload videos and images that evidences the work you have done
        </span>{' '}
        <br />
        <FieldItem>
          <span>Add Date</span>
          <input type='date' name='date' min='2017-04-01' max='2017-04-30' />
        </FieldItem>
        <FieldItem>
          <span>Heading</span>

          <input type='text' placeholder='Write here' />
          {/* <Error>{data.impact.sdgs.error}</Error> */}
        </FieldItem>
        <FieldItem>
          {/* error={data.impact.projectNeed.error !== "" */}
          <span>Add description</span>
          <textarea
            // onChange={(e) => {
            //   setData((prev) => ({
            //     ...prev,
            //     impact: {
            //       ...prev.impact,
            //       projectNeed: {
            //         value: e.target.value,
            //         error:
            //           e.target.value === ""
            //             ? "Invalid project need format"
            //             : e.target.value.length > 15
            //             ? ""
            //             : "Please provide a minimum of 15 characters",
            //       },
            //     },
            //   }));
            // }}
            // value={data.impact.projectNeed.value}
            maxLength={'599'}
            rows='7'
            type='text'
            name='name'
            id='name'
            placeholder='Write here'
          />
          {/* <Error>{data.impact.projectNeed.error}</Error> */}
        </FieldItem>
        <FieldItem>
          <span>Add Image/Video</span>
          <label htmlFor='report'>
            <UploadBtn
              // isSelected={data.identityImages.value[0] != null}
              src={
                //   data.identityImages.value[0] !== null
                //     ? URL.createObjectURL(data.identityImages?.value[0])
                //     :
                require('../../../assets/add.png')
              }
              alt=''
            />
          </label>
          <input
            id='report'
            name='report'
            type='file'
            accept='image/png, image/jpeg'
            hidden
            //   onChange={(e) => {
            //     setData((prev) => ({
            //       ...prev,
            //       identityImages: {
            //         value: {
            //           ...prev.identityImages.value,
            //           0: e.target.files[0],
            //         },
            //       },
            //     }));
            //   }}
          />
          <Btn width='150px' marginTop='10px'>
            ADD MORE
          </Btn>
        </FieldItem>
      </FieldContainer>

      <div style={{ textAlign: 'center' }}>
        <Btn
          width='100px'
          marginTop='80px'
          marginBottom='30px'
          style={{ background: '#219EBC' }}
        >
          SUBMIT
        </Btn>
      </div>
    </div>
  );
};

export default SubmitReportForm;
