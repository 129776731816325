import styled from 'styled-components';

export const CategoriesRegistrationContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  padding-top: 80px;
  z-index: 100;
  position: relative;
`;

export const CategoriesRegistrationHeader = styled.div`
  text-align: center;
  padding-bottom: 30px;
  margin-top: 75px;
  & > h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 80px;
  }
  & > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 144.02%;
    color: #023047;
    opacity: 0.75;
    width: 517px;
    margin: auto;
  }
`;

export const CategoryFromContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 297px 119px rgba(27, 41, 62, 0.01),
    0px 167px 100px rgba(27, 41, 62, 0.05), 0px 74px 74px rgba(27, 41, 62, 0.09),
    0px 19px 41px rgba(27, 41, 62, 0.1), 0px 0px 0px rgba(27, 41, 62, 0.1);
  border-radius: 20px;
  padding-top: 80px;
  padding-bottom: 80px;
  gap: 1.5em;
  padding-left: 75px;
  padding-right: 75px;
  font-family: 'Poppins';
  position: relative;
  z-index: 50;
  width: 70%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
  & > h4 {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #023047;
    margin: 0;
  }

  & > p {
    text-align: center;
    padding-bottom: 30px;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #023047;
    margin: 0;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ columnCount }) => columnCount || '3'},
    1fr
  );
  width: ${({ width }) => width};
  gap: 1em;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
`;

export const GridItem = styled.div`
  gap: 1em;
  & > select {
    padding: 0 15px;
  }
  & > label {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #2d3748;
  }
  & > span {
    color: red;
    margin-top: 5px;
    font-size: 12px;
  }
`;

export const SelectContainer = styled.select`
  width: 100%;
  height: 33px;
  background: #ffffff;
  border: 0.7px solid ${({ error }) => (error ? 'red' : '#aeaeae')};
  border-radius: 3.5px;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  padding-left: 15px !important;
  padding-right: 15px;
  position: relative;
`;
export const TextAreaContainer = styled.textarea`
  width: 100%;
  background: #ffffff;
  border: 0.7px solid ${({ error }) => (error ? 'red' : '#aeaeae')};
  border-radius: 3.5px;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  height: 100px;
  cursor: pointer;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border: 0.7px solid ${({ error }) => (error ? 'red' : '#aeaeae')};
  color: ${({ error }) => (error ? 'red' : '#aeaeae')};
  border-radius: 3.5px;

  & input {
    outline: none;
    border: none;
    width: 100%;
    height: 33px;
    background: #ffffff;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #023047;
    position: relative;
  }
`;
