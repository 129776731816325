import React, { useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import keyIcon from '../../assets/arrow-icon 1.png';
import logo from '../../assets/loginInWhite.png';
import { Input } from '../../global/components/Custom';
import FormLeftSide from '../../global/components/FormLeftSide';
import '../../global/styles/LoginConfirmation.css';
import { recoverPasswordUsingEmail } from '../../services/verification';
import { validateEmail } from '../../utils/validation';
import './PasswordReset.css';

const ResetEmailPhoneVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    value: '',
    error: '',
  });
  const onResetPassword = () => {
    if (validateEmail(user.value)) {
      dispatch(
        recoverPasswordUsingEmail(
          {
            email: user.value,
          },
          navigate
        )
      );
    } else {
      setUser({
        ...user,
        error: validateEmail(user) ? '' : 'Invalid email address',
      });
    }
  };
  return (
    <div className='loginContainer'>
      <FormLeftSide
        bgColor={'linear-gradient(135.83deg, #191654 -84.22%, #219EBC 98.97%)'}
        children={<span></span>}
      />
      <div className='loginRight'>
        <img src={logo} className='responsive-logo' alt='' />
        <div
          style={{
            alignItems: 'center',
          }}
          className='reset-password-container new-password-container'
        >
          <img src={keyIcon} alt='' />
          <h5>Forgot Password?</h5>
          <p style={{ marginBottom: '15px' }}>
            No worries, we will send you reset instructions.{' '}
          </p>

          <form
            style={{
              width: '100%',
            }}
            action=''
          >
            <div className='password-reset-form' style={{ marginTop: '35px' }}>
              <Input
                type='email'
                id='email'
                label={'Email / Username'}
                placeholder='Email'
                inputValue={user}
                onChange={(e) => {
                  setUser({
                    value: e.target.value,
                    error: validateEmail(e.target.value)
                      ? ''
                      : 'Invalid Format',
                  });
                }}
              />
            </div>
          </form>

          <button onClick={onResetPassword} style={{ marginTop: '40px' }}>
            Reset Password
          </button>

          <div onClick={() => navigate(-1)} className='back-to-login'>
            <BiLeftArrowAlt className='back-arrow' />
            <span>Back to log in</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetEmailPhoneVerification;
