import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Input } from '../../global/components/Custom';
import FormLeftSide from '../../global/components/FormLeftSide';
import LoginWithFacebook from '../../global/components/LoginWithFacebook';
import LoginWithGoogle from '../../global/components/LoginWithGoogle';
import { setPopup } from '../../redux/popup/popUpReducer';
import { userLogin } from '../../redux/user/actionCreator';
import { setAccessToken, setUser } from '../../redux/user/userReducer';
import {
  resendVerification,
  setUsernameApi,
} from '../../services/verification';
import { validateEmail, validateUserName } from '../../utils/validation';
import './Login.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [openUsername, setOpenUsername] = useState(false);
  const [data, setData] = useState({
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const [username, setUsername] = useState({
    value: '',
    error: '',
  });

  useEffect(() => {
    if (searchParams.get('success') === 'true') {
      const accessToken = searchParams.get('accessToken');
      localStorage.setItem('accessToken', accessToken);
      dispatch(setPopup(true));
      dispatch(setAccessToken(accessToken));
      dispatch(
        setUser({
          data: {
            username: searchParams.get('fullName'),
            email: searchParams.get('email'),
            walletAddress: searchParams.get('walletAddress'),
            userRole: searchParams.get('userRole'),
          },
        })
      );
      navigate('/dashboard');
    } else if (searchParams.get('success') === 'false') {
      setOpenUsername(true);
      localStorage.removeItem('accessToken');
    }
  }, [dispatch, navigate, searchParams]);

  const onLogin = (e) => {
    e.preventDefault();
    if (validateEmail(data.email.value) || validateUserName(data.email.value)) {
      dispatch(
        userLogin(
          {
            emailOrUsername: data.email.value,
            password: data.password.value,
          },
          navigate,
          setOpen,
          setSearchParams
        )
      );
    } else {
      setData((prev) => ({
        email: {
          ...prev.email,
          error:
            validateEmail(data.email.value) ||
            validateUserName(data.email.value)
              ? ''
              : 'Invalid format',
        },
        password: {
          ...prev.password,
          error: prev.password.value !== '' ? '' : 'Password is required',
        },
      }));
    }
  };
  return (
    <div className='loginContainer'>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Email verification'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Please verify your email =&gt; {data.email.value} to login
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>discard</Button>
          <Button
            onClick={() => {
              setOpen(false);
              dispatch(resendVerification(data.email.value));
            }}
            autoFocus
          >
            Resend
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUsername}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {searchParams.get('message')}
        </DialogTitle>
        <DialogContent
          sx={{
            width: '20vw',
          }}
        >
          <Input
            type='text'
            id='username'
            label={'Username'}
            placeholder='Username'
            inputValue={username}
            onChange={(e) =>
              setUsername({
                value: e.target.value,
                error: validateUserName(e.target.value)
                  ? ''
                  : 'Invalid username',
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (searchParams.get('tempToken')) {
                setOpenUsername(false);
                setUsernameApi(
                  username.value,
                  searchParams.get('tempToken'),
                  navigate
                );
              } else {
                setOpenUsername(false);
                setUsernameApi(username.value, '', navigate);
              }
            }}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <FormLeftSide
        bgColor={'linear-gradient(135.83deg, #191654 -84.22%, #219EBC 98.97%)'}
        children={<span></span>}
      />
      <div className='loginRight'>
        <div>
          <h2>Welcome Back </h2>
          <h5>
            Don't have account? <Link to='/registration'>SIGN UP</Link>
          </h5>
          <form>
            <Input
              type='email'
              id='email'
              label={'Email / Username'}
              placeholder='Email'
              inputValue={data.email}
              onChange={(e) =>
                setData({
                  ...data,
                  email: {
                    value: e.target.value,
                    error:
                      validateEmail(e.target.value) ||
                      validateUserName(e.target.value)
                        ? ''
                        : 'Invalid Format',
                  },
                })
              }
            />
            <Input
              id='password'
              label={'Password'}
              type={showPassword ? 'text' : 'password'}
              placeholder='*******'
              inputValue={data.password}
              onChange={(e) =>
                setData({
                  ...data,
                  password: {
                    value: e.target.value,
                    error: e.target.value !== '' ? '' : 'Password is required',
                  },
                })
              }
            >
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(!showPassword)}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Input>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/reset-credential-verification')}
            >
              Forgot Password?
            </span>
            <button onClick={onLogin}>SIGN IN</button>
          </form>
          <div className='loginLine'>
            <hr />
          </div>
          <div className='loginBtnContainer'>
            <LoginWithGoogle method={'Sign '} />
            <LoginWithFacebook method={'Sign '} />
            <div
              style={{
                cursor: 'not-allowed',
                filter: 'grayscale(1)',
              }}
            >
              <img src={require('../../assets/wallet.png')} alt='' />
              <button
                style={{
                  cursor: 'not-allowed',
                }}
              >
                Sign In With Wallet
              </button>
            </div>
          </div>
          <div onClick={() => navigate('/')} className='back-to-login'>
            <BiLeftArrowAlt className='back-arrow' />
            <span>Back to Home</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
