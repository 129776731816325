import React from 'react';
import Container from '../Container';
import PortfolioGallery from './components/PortfolioGallery';
import ReportingGalleryBanner from './components/ReportingGalleryBanner';

const ReportingGallery = () => {
    return (
        <Container>
            <ReportingGalleryBanner/>
            <PortfolioGallery/>
        </Container>
    );
};

export default ReportingGallery;