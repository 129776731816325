import React from "react";
import { CommonBtn, Heading } from "../../../global/styles/style-component";
import campaignBrand from "../../../assets/marketplace-landing-place/campaign-brand.png";

const FeaturedCampaign = () => {
  return (
    <div className="featured-campaign">
      <Heading>FEATURED CAMPAIGN</Heading>
      <div>
        <div>
          <img src={campaignBrand} alt="" />
        </div>

        <div className="typo-text-align">
          <Heading>GSA PetDrops</Heading>
          <h6 style={{ paddingBottom: "1em" }}>
            A very special collaboration with the Girls of Austin! The 8th grade
            STEM class at the GSA is creating artwork and art NFTS of their
            pets! While helping animals in need, the girls will be learning
            important concepts about NFTs, blockchain, and digital currency.
            Their artwork and NFTs will be for sale on the PetDrops NFT
            marketplace. A portion of the proceeds will go to help the Austin
            Animal Center. Superhero Pets and People Saving Animals!
          </h6>
          <div>
            <CommonBtn>LEARN MORE</CommonBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCampaign;
