import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { FaLongArrowAltRight } from 'react-icons/fa';
import keyIcon from '../../assets/arrow-icon 1.png';
import logo from '../../assets/loginInWhite.png';
import '../../global/styles/LoginConfirmation.css';
import './PasswordReset.css';

const SetNewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <div className='login-confirmation-section'>
        <div className='login-confirmation-container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='confirmation-left'>
                <img src={logo} alt='' />
                <p></p>

                <div className='lets-get-started'>
                  <p>Let's Get Started</p>
                  <FaLongArrowAltRight className='forward-arrow' />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <img src={logo} className='responsive-logo' alt='' />
              <div className='reset-password-container new-password-container'>
                <img src={keyIcon} alt='' />
                <h5>Set New Password</h5>
                <p style={{ marginBottom: '15px' }}>
                  Your new password must be different to previously used
                  passwords{' '}
                </p>

                <form action=''>
                  <div className='password-reset-form'>
                    <div>
                      <label htmlFor=''>Password</label> <br />
                      <div className='password-input-field'>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          placeholder='*******'
                        />
                        <IconButton
                          style={{
                            width: 'auto',
                            height: 'auto',
                            margin: '0',
                          }}
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                    </div>
                    <div>
                      <label htmlFor=''>Confirm Password</label> <br />
                      <div className='password-input-field password-input-field-margin'>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          placeholder='*******'
                        />
                        <IconButton
                          aria-label='toggle password visibility'
                          style={{
                            width: 'auto',
                            height: 'auto',
                            margin: '0',
                          }}
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                    </div>

                    <div className='forgot-password'>
                      <a href=''>Forgot Password?</a>
                    </div>
                  </div>
                </form>

                <div style={{ textAlign: 'center' }}>
                  <button style={{ marginTop: '40px' }}>Reset Password</button>
                </div>

                <div className='back-to-login'>
                  <BiLeftArrowAlt className='back-arrow' />
                  <span>Back to log in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetNewPassword;
