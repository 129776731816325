import { Grid } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, MainContainer } from '../../global/styles/style-component';
import { getAllCampaign } from '../../services/campaign';
import { errorToast } from '../../utils/toast';
import Container from '../Container';
import { CampaignCard } from './browseStyle';

const BrowseCampaign = () => {
  const [campaign, setCampaign] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllCampaign()
      .then((res) => {
        console.log('askdjghaskdhaskjhdskajh', res.data);
        setCampaign(res.data.ActiveCampaigns);
      })
      .catch((err) => errorToast(err.response.data.message));
  }, []);
  return (
    <Container>
      <MainContainer paddingVertical={'100px'}>
        <Heading>Browse Campaigns</Heading>
        <Grid
          container
          sx={{
            marginTop: '2em',
          }}
          spacing={'1em'}
        >
          {campaign.map(({ overview, _id }) => (
            <Grid key={_id} item xs={12} sm={6} md={4} lg={3}>
              <CampaignCard
                onClick={() => {
                  navigate(`/campaign-details/${_id}`);
                }}
              >
                <img
                  src={`${process.env.REACT_APP_DOMAIN}${overview?.bannerImageUrl}`}
                  alt=''
                />
                <div>
                  <img
                    src={`${process.env.REACT_APP_DOMAIN}${overview?.bannerImageUrl}`}
                    alt=''
                  />
                  <div>
                    <strong>{overview.campaignName}</strong>
                    <p className='globalDescription'>{overview.description}</p>
                  </div>
                </div>
              </CampaignCard>
            </Grid>
          ))}
        </Grid>
      </MainContainer>
    </Container>
  );
};

export default BrowseCampaign;
