import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dashboard from '../../../../routes/dashboard.routes';
import { SidebarContainer, SidebarItem } from '../../dashboardStyled';
import { MdSettings, MdLogout } from 'react-icons/md';
import { logout } from '../../../../redux/user/userReducer';
import { useDispatch, useSelector } from 'react-redux';

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [route, setRoute] = useState(null);
  const user = useSelector((state) => state.users.user);
  useEffect(() => {
    setRoute(window.location.pathname);
  }, []);
  return (
    <SidebarContainer>
      <img
        role='button'
        onClick={() => navigate('/')}
        src={require('../../../../assets/logo.png')}
        alt=''
      />
      <div className='navItemContainer'>
        {user?.data?.userRole &&
          dashboard
            .find((e) =>
              e.role
                .toLocaleLowerCase()
                .includes(user?.data?.userRole.toLocaleLowerCase())
            )
            ?.routes.map((item, index) => (
              <SidebarItem active={item.route === route} key={index}>
                <Link to={item.route}>
                  {item.icon}
                  <span> {item.title}</span>
                </Link>
              </SidebarItem>
            ))}
      </div>
      {/* <div className='navItemBottom'>
        <SidebarItem
          active={'/settings' === route}
          onClick={() => {
            navigate('/settings');
          }}
          key={'settings'}
        >
          <MdSettings /> <span>settings</span>
        </SidebarItem>
        <SidebarItem
          onClick={() => {
            dispatch(logout());
            localStorage.removeItem('accessToken');
            navigate('/');
          }}
          key={'logout'}
        >
          <MdLogout /> <span>logout</span>
        </SidebarItem>
      </div> */}
    </SidebarContainer>
  );
};

export default Sidebar;
