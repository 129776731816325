import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ShowCaseCard from '../../../global/components/ShowCaseCard/ShowCaseCard';
import { CommonBtn } from '../../../global/styles/style-component';
import { getAllNft } from '../../../services/campaign';
import { errorToast } from '../../../utils/toast';
const NftContribute = () => {
  const { id } = useParams();
  const [count, setCount] = useState(18);
  const [data, setData] = useState([]);
  useEffect(() => {
    getAllNft(id)
      .then((res) => {
        setData(res.data.impactAssets);
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  }, []);
  return (
    <div className='container_fluid mt-5 '>
      <Grid container spacing={'1em'}>
        {data.length > 0 ? (
          data
            .slice(0, count)
            .map(
              ({
                _id,
                imageUrl,
                itemName,
                description,
                priceUsd,
                saleType,
                creator,
              }) => (
                <Grid item xs='12' sm='6' md='4' lg='2'>
                  <ShowCaseCard
                    key={_id}
                    id={_id}
                    url={`${process.env.REACT_APP_DOMAIN}${imageUrl}`.replace(
                      /\ /g,
                      '%20'
                    )}
                    title={itemName}
                    price={priceUsd}
                    creator={creator?.username}
                    creatorId={creator?.creatorId}
                    width='100%'
                    height='350px'
                  />
                </Grid>
              )
            )
        ) : (
          <h4
            style={{
              color: '#FB8501',
            }}
            className='text-center mt-5 mb-4'
          >
            No NFT contribute to this campaign yet
          </h4>
        )}
      </Grid>
      <div style={{ width: '100%' }} className='d-flex mt-3 flex-column'>
        {data?.length > 18 && (
          <CommonBtn
            onClick={() => {
              setCount(count + 18);
            }}
            align
          >
            View More
          </CommonBtn>
        )}
      </div>
    </div>
  );
};

export default NftContribute;
