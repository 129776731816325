import React from 'react';
import Container from '../Container';
import CoreFeatures from './components/CoreFeatures';
import Hero from './components/Hero';
import HowDoWeDo from './components/HowDoWeDo';
import ImpactNFT from './components/ImpactNFT';
import MeetTheFounder from './components/MeetTheFounder';
import NftCampaigns from './components/NftCampaigns';
import OurPartners from './components/OurPartners';
import SetupCampaign from './components/SetupCampaign';
import ShowcaseSlider from './components/ShowcaseSlider';
import WhatDoWeDo from './components/WhatDoWeDo';

const Landing = () => {
  return (
    <Container>
      <Hero />
      <WhatDoWeDo />
      <ImpactNFT />
      <HowDoWeDo />
      <NftCampaigns />
      <SetupCampaign />
      <ShowcaseSlider />
      <MeetTheFounder />
      <CoreFeatures />
      <OurPartners />
    </Container>
  );
};

export default Landing;
