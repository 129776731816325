import React from 'react';
import NftCollection from './NftCollection';
import NftHubBanner from './NftHubBanner';
import NftHubCards from './NftHubCards';
import NftHubQA from './NftHubQA';
import NftHubSignUp from './NftHubSignUp';
import NftMovement from './NftMovement';
import SneakPriviewgallary from './SneakPriviewgallary';
import WelcomeToHub from './WelcomeToHub';
import './NftHowToHub.css';
import Container from '../Container';
import {
  BannerHeading,
  MainContainer,
} from '../../global/styles/style-component';
import { PagerBanner } from '../Pager/PagerStyle';
const imgUrl = require('../../assets/nft-details/nft-details-banner.png');

const NFTSteps = () => {
  return (
    <Container>
      <PagerBanner imgUrl={imgUrl}>
        <BannerHeading>NFT In 3 Simple Steps</BannerHeading>
      </PagerBanner>
      <MainContainer paddingVertical='150px' className='nft-hub-container'>
        <NftCollection />
        {/* <NftHubSignUp /> */}
        {/* <SneakPriviewgallary /> */}
      </MainContainer>
    </Container>
  );
};

export default NFTSteps;
