import React from 'react';
import { HalfColoredContainer } from '../../global/styles/style-component';
import Container from '../Container';
import './BrandAgencyForm.css';
import BrandFrom from './components/brandForm';
import { CategoriesRegistrationHeader } from './formStyle';
import './NonProfitCauses.css';

const BrandAgencyForm = () => {
  return (
    <Container>
      <HalfColoredContainer height='auto' color='#023047'>
        <CategoriesRegistrationHeader>
          <h2>Brand & Agencies</h2>
          <p>-------------</p>
        </CategoriesRegistrationHeader>
        <BrandFrom />
      </HalfColoredContainer>
    </Container>
  );
};

export default BrandAgencyForm;
