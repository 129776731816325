import React from 'react';
import InfluencerForms from '../../../form/components/InfluencerForms';
import DashboardContainer from '../../DashboardContainer';

const Influencer = () => {
  return (
    <DashboardContainer>
      <InfluencerForms />
    </DashboardContainer>
  );
};

export default Influencer;
