import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AiOutlinePhone } from 'react-icons/ai';
import { BiLink } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa';
import PhoneInput from 'react-phone-number-input';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UploadBtn } from '../../../global/components/vetting/vetting-style';
import { CommonBtn } from '../../../global/styles/style-component';
import {
  brandRegistration,
  influencerRegistration,
} from '../../../services/categoriesRegistration';
import ApiCall from '../../../utils/axiosCall';
import country from '../../../utils/countryList.json';
import {
  validateEmail,
  validateName,
  validatePhone,
  validateSelectedFile,
  validateUrl,
} from '../../../utils/validation';
import HelpIcon from '@mui/icons-material/Help';
import {
  CategoryFromContainer,
  GridContainer,
  GridItem,
  InputContainer,
  SelectContainer,
  TextAreaContainer,
} from '../formStyle';

const InfluencerRegistrationForms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    profileImage: '',
    logo: '',
    images: '',
  });
  const [searchParma] = useSearchParams();
  const [data, setData] = useState({
    logo: {
      value: null,
      error: '',
    },
    profileImage: {
      value: null,
      error: '',
    },
    additionalImages: {
      value: null,
      error: '',
    },
    // verificationVideo: {
    //   value: null,
    //   error: '',
    // },
    managerName: {
      value: '',
      error: '',
    },
    managerEmail: {
      value: '',
      error: '',
    },
    managerPhoneNum: {
      value: '',
      error: '',
    },
    influencerName: {
      value: '',
      error: '',
    },
    title: {
      value: '',
      error: '',
    },
    sector: {
      value: '',
      error: '',
    },
    influencerBio: {
      value: '',
      error: '',
    },
    impactInterest: {
      value: '',
      error: '',
    },
    brandAffiliation: {
      value: '',
      error: '',
    },
    interestInNfts: {
      value: '',
      error: '',
    },
    openToIMRs: {
      value: '',
      error: '',
    },
    idVerificationMethod: {
      value: '',
      error: '',
    },
  });

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <CategoryFromContainer className='reg-form-container'>
      <h4>Fill in the required information</h4>
      <GridContainer width={'100%'} columnCount='2' className='reg-form-grid'>
        <div className='influence-form'>
          <GridItem className='single-input'>
            <label htmlFor='name'>
              Campaign Manager (CM) Name <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={data.managerName.error !== ''}>
              <input
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    managerName: {
                      value: e.target.value,
                      error:
                        e.target.value === ''
                          ? 'Please provide name'
                          : validateName(e.target.value)
                          ? ''
                          : 'Should only contain alphabet',
                    },
                  }));
                }}
                id='name'
                type='text'
              />
            </InputContainer>
            <span>{data.managerName.error}</span>
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor='contactPerson'>
              CM Contact Business Email <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={data.managerEmail.error !== ''}>
              <input
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    managerEmail: {
                      value: e.target.value,
                      error: validateEmail(e.target.value)
                        ? ''
                        : 'Invalid email formate',
                    },
                  }));
                }}
                id='businessEmail'
                type='email'
              />
            </InputContainer>
            <span>{data.managerEmail.error}</span>
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor='phoneNumber'>
              CM Contact Phone <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={data.managerPhoneNum.error !== ''}>
              <PhoneInput
                style={{ width: '100%' }}
                international
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    managerPhoneNum: {
                      value: e,
                      error: !formatPhoneNumber(e)
                        ? 'Please provide valid Phone Num'
                        : '',
                    },
                  }));
                }}
                value={data.managerPhoneNum.value}
              />
              <AiOutlinePhone className='input-icon' />
            </InputContainer>
            <span>{data.managerPhoneNum.error}</span>
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor='email'>
              Influencer Name: <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={data.influencerName.error !== ''}>
              <input
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    influencerName: {
                      value: e.target.value,
                      error:
                        e.target.value === ''
                          ? 'Please provide influencer name'
                          : validateName(e.target.value)
                          ? ''
                          : 'Invalid influencer name',
                    },
                  }));
                }}
                id='name'
                type='text'
              />
            </InputContainer>
            <span>{data.influencerName.error}</span>
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor=''>
              Title <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={data.title.error !== ''}>
              <input
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    title: {
                      value: e.target.value,
                      error:
                        e.target.value === '' ? 'Please provide title' : '',
                    },
                  }));
                }}
                type='text'
                placeholder='E.g. Sportsperson, Artist, Musician, etc'
              />
            </InputContainer>
            <span>{data.title.error}</span>
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor=''>
              Sector <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={data.sector.error !== ''}>
              <input
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    sector: {
                      value: e.target.value,
                      error:
                        e.target.value === '' ? 'Please provide sector' : '',
                    },
                  }));
                }}
                type='text'
              />
            </InputContainer>
            <span>{data.sector.error}</span>
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor=''>
              Influencer Bio <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <TextAreaContainer
              error={data.influencerBio.error !== ''}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  influencerBio: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'Please provide influencer bio'
                        : e.target.value.length > 15
                        ? ''
                        : 'Please provide a minimum of 15 characters',
                  },
                }));
              }}
              name=''
              id=''
              maxLength={'599'}
              cols='30'
              rows='20'
            />
            <span>{data.influencerBio.error}</span>
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor=''>
              Impact Interests <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={data.impactInterest.error !== ''}>
              <input
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    impactInterest: {
                      value: e.target.value,
                      error:
                        e.target.value === ''
                          ? 'Please provide impact interest'
                          : e.target.value.length > 15
                          ? ''
                          : 'Please provide a minimum of 15 characters',
                    },
                  }));
                }}
                type='text'
              />
            </InputContainer>
            <span>{data.impactInterest.error}</span>
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor=''>
              Brand Affiliations <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={data.brandAffiliation.error !== ''}>
              <input
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    brandAffiliation: {
                      value: e.target.value,
                      error:
                        e.target.value === ''
                          ? 'Please provide brand affiliation'
                          : '',
                    },
                  }));
                }}
                type='text'
              />
            </InputContainer>
            <span>{data.brandAffiliation.error}</span>
          </GridItem>
        </div>
        <div className='influence-form form-grid'>
          <GridItem className='single-input'>
            <label htmlFor='profileImage'>
              Add Profile Image<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <label htmlFor='profileImage'>
              <UploadBtn
                style={{
                  width: '70px',
                  height: '70px',
                  padding: '10px',
                }}
                isSelected={data.profileImage.value != null}
                src={
                  data.profileImage.value != null
                    ? state.profileImage
                    : require('../../../assets/add.png')
                }
                alt=''
              />
            </label>
            <input
              accept='image/png, image/jpeg'
              onChange={(e) => {
                validateSelectedFile(e.target.files[0], (file) => {
                  setState({
                    ...state,
                    profileImage: URL.createObjectURL(file),
                  });
                  setData((prev) => ({
                    ...prev,
                    profileImage: {
                      value: file,
                      error: '',
                    },
                  }));
                });
              }}
              id='profileImage'
              name='profileImage'
              type='file'
              hidden
            />
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor='logoImage'>
              Add Logo<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <label htmlFor='logoImage'>
              <UploadBtn
                style={{
                  width: '70px',
                  height: '70px',
                  padding: '10px',
                }}
                isSelected={data.logo.value != null}
                src={
                  data.logo.value != null
                    ? state.logo
                    : require('../../../assets/add.png')
                }
                alt=''
              />
            </label>
            <input
              accept='image/png, image/jpeg'
              onChange={(e) => {
                validateSelectedFile(e.target.files[0], (file) => {
                  setState({
                    ...state,
                    logo: URL.createObjectURL(file),
                  });
                  setData((prev) => ({
                    ...prev,
                    logo: {
                      value: file,
                      error: '',
                    },
                  }));
                });
              }}
              id='logoImage'
              name='logoImage'
              type='file'
              hidden
            />
          </GridItem>
          <GridItem className='single-input'>
            <label htmlFor='additionalImages'>
              Add Images<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <label htmlFor='additionalImages'>
              <UploadBtn
                style={{
                  width: '70px',
                  height: '70px',
                  padding: '10px',
                }}
                isSelected={data.additionalImages.value != null}
                src={
                  data.additionalImages.value != null
                    ? state.images
                    : require('../../../assets/add.png')
                }
                alt=''
              />
            </label>
            <input
              accept='image/png, image/jpeg'
              onChange={(e) => {
                validateSelectedFile(e.target.files[0], (file) => {
                  setState({
                    ...state,
                    images: URL.createObjectURL(file),
                  });
                  setData((prev) => ({
                    ...prev,
                    additionalImages: {
                      value: e.target.files[0],
                      error: '',
                    },
                  }));
                });
              }}
              id='additionalImages'
              name='additionalImages'
              type='file'
              hidden
            />
          </GridItem>

          <GridItem className='single-input'>
            <label htmlFor=''>
              Interest in Use of NFTs <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <TextAreaContainer
              error={data.interestInNfts.error !== ''}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  interestInNfts: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'Please provide interest in use of nft'
                        : e.target.value.length > 15
                        ? ''
                        : 'Please provide a minimum of 15 characters',
                  },
                }));
              }}
              name=''
              id=''
              maxLength={'599'}
              cols='30'
              rows='20'
            />
            <span>{data.interestInNfts.error}</span>
          </GridItem>

          <GridItem className='single-input checkbox-input'>
            <label htmlFor='' style={{ marginBottom: '10px' }}>
              Open to Influencer Marketing Requests (Y/N){' '}
              <b style={{ color: 'red' }}>*</b>
              <Tooltip
                title={
                  'We will be offering paid opportunities to influencers interested in supporting impact. We’d love for you to be an part of that! '
                }
                arrow
              >
                <HelpIcon />
              </Tooltip>
            </label>{' '}
            <br />
            <input
              type='radio'
              id='openIMRyes'
              onChange={(e) =>
                setData({
                  ...data,
                  openToIMRs: {
                    value: e.target.checked.toString(),
                  },
                })
              }
              name='openIMR'
              value='Yes'
            />
            <label htmlFor='openIMRyes' className='yes'>
              {' '}
              Yes
            </label>
            <input
              type='radio'
              id='openIMRno'
              onChange={(e) =>
                setData({
                  ...data,
                  openToIMRs: {
                    value: (!e.target.checked).toString(),
                  },
                })
              }
              name='openIMR'
              value='No'
            />
            <label htmlFor='openIMRno' className='no'>
              {' '}
              No
            </label>
          </GridItem>

          <GridItem className='single-input checkbox-input'>
            <label htmlFor='' style={{ marginBottom: '10px' }}>
              Influencer ID Verification <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <input
              type='radio'
              id='VIDEO_CALL'
              name='idVerificationMethod'
              value='VIDEO_CALL'
              onChange={(e) => {
                setData({
                  ...data,
                  idVerificationMethod: {
                    value: e.target.value,
                    error: '',
                  },
                });
              }}
            />
            <label htmlFor='VIDEO_CALL'> Verification By Video Call</label>{' '}
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex' }}>
                <input
                  type='radio'
                  id='VIDEO_RECORDING'
                  name='idVerificationMethod'
                  value='VIDEO_RECORDING'
                  onChange={(e) => {
                    setData({
                      ...data,
                      idVerificationMethod: {
                        value: e.target.value,
                        error: '',
                      },
                    });
                  }}
                />
                <label htmlFor='VIDEO_RECORDING'>
                  {' '}
                  Verification by Selfie Video (confirming interest in demo and
                  Campaign Managers name)
                </label>
              </div>
            </div>
            <span>{data.idVerificationMethod.error}</span>
          </GridItem>
        </div>
      </GridContainer>
      <CommonBtn
        disabled={!Object.values(data).every((i) => i.value)}
        isDisabled={!Object.values(data).every((i) => i.value)}
        onClick={() => {
          const formData = new FormData();
          formData.append(
            'influencerInfoId',
            searchParma.get('influencerInfoId')
          );
          for (let keyParent in data) {
            formData.append(keyParent, data[keyParent].value);
          }
          dispatch(influencerRegistration(formData, navigate));
        }}
        margin='1em 0'
      >
        SUBMIT REGISTRATION
      </CommonBtn>
    </CategoryFromContainer>
  );
};

export default InfluencerRegistrationForms;
