import React from 'react';
import { Grid } from '@mui/material';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import innerImg from '../../../assets/inner-img.png';
import { Heading } from '../../../global/styles/style-component';
import { DetailsBanner } from '../BrowseNposDetailsStyle';
import InfluencerAbout from '../../influencerDetails/components/InfluencerAbout';

const NposDetailsBanner = ({ cause }) => {
  return (
    <>
      <PagerBanner
        imgUrl={require('../../../assets/nft-details/nft-details-banner.png')}
        style={{ padding: '150px 0' }}
      >
        <div
          style={{
            position: 'relative',
            zIndex: '10',
            width: '30%',
          }}
          className='influencer-detail-intro'
        >
          <div className='details-intro-container'>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <img
                  style={{
                    width: '150px',
                    height: '150px',
                    margin: 0,
                  }}
                  src={`${process.env.REACT_APP_DOMAIN}${cause?.logoUrl}`}
                  alt=''
                  className='company-logo'
                />
              </Grid>
              <Grid
                display='flex'
                flexDirection={'column'}
                gap={'1em'}
                item
                xs={12}
                md={7}
                className='details-intro2'
              >
                <Heading
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  {cause?.name}
                </Heading>
                <span>Category: {cause?.category}</span>
                <span>Country: {cause?.country}</span>
                <span>City: {cause?.city}</span>
                {cause?.isRegistered && (
                  <>
                    <span>Registration Number: {cause?.registrationNum}</span>
                    <span>
                      Country of registration: {cause?.countryOfRegistration}
                    </span>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </PagerBanner>
      <InfluencerAbout title={'About'} detail={cause?.about} />
    </>
  );
};

export default NposDetailsBanner;
