import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { MainContainer } from '../../../global/styles/style-component';
import './SetupCampaign.css';

const SetupCampaign = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  return (
    <div
      style={{
        background: '#023047',
      }}
    >
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          padding: '40px 0px',
          color: '#fff',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: '-100px',
            right: '-100px',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            background: 'rgba(255, 255, 255, 0.15)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: -16,
            right: '-140px',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            background: 'rgba(255, 255, 255, 0.15)',
          }}
        />
        <MainContainer>
          <Box
            sx={{
              margin: '0 auto',
              display: { md: 'flex' },
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              width: '100%',
            }}
          >
            <button
              onClick={() =>
                isLoggedIn
                  ? navigate('/dashboard/campaign')
                  : navigate('/login')
              }
              style={{
                background:
                  'linear-gradient(129.59deg, rgba(255, 255, 255, 0.4) -0.1%, rgba(255, 255, 255, 0.1) 95.18%)',
                boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
                borderRadius: 20,
                border: '0',
                color: '#fff',
                fontSize: 30,
                fontWeight: 600,
                cursor: 'pointer',
                padding: '20px 40px',
              }}
              className='launch-button'
            >
              LAUNCH YOUR OWN CAMPAIGN
            </button>
            {isLoggedIn ? (
              <div></div>
            ) : (
              <div className='register-button'>
                <button
                  onClick={() => navigate('/registration')}
                  style={{
                    background: '#F8991D',
                    borderRadius: 8,
                    border: '0',
                    color: '#fff',
                    fontSize: 30,
                    fontWeight: 500,
                    cursor: 'pointer',
                    padding: '8px 20px',
                    marginBlock: '20px',
                  }}
                >
                  REGISTER NOW
                </button>
              </div>
            )}
          </Box>
        </MainContainer>
      </div>
    </div>
  );
};

export default SetupCampaign;
