import ApiCall from '../utils/axiosCall';

export const getAllComments = (campaignId) => {
  return ApiCall.get(`campaign/${campaignId}/comments?limit=30&offset=0`);
};

export const getAllNft = (campaignId) => {
  return ApiCall.get(`app/impact-associated-assets/${campaignId}`);
};

export const postComment = (id, comment) => {
  return ApiCall.post(`campaign/${id}/comment`, {
    comment,
  });
};
export const addToFav = (id) => {
  return ApiCall.put(`/user/marketplace/add-to-favourites/${id}`);
};

export const getAllCampaign = () => {
  return ApiCall.get(`app/active-campaigns`);
};
export const getFeatureCampaign = () => {
  return ApiCall.get(`app/active-campaigns?isFeatured=true`);
};

export const getAllSuperCampaign = () => {
  return ApiCall.get(`app/super-campaigns`);
};
export const getAllSubCampaign = (id) => {
  return ApiCall.get(`/app/sub-campaigns/${id}`);
};

export const getCampaignDetail = (campaignId) => {
  return ApiCall.get(`app/campaign-detail/${campaignId}`);
};
export const getNftAssetHistory = (id) => {
  return ApiCall.get(`/app/asset-history/${id}?limit=3&offset=0`);
};

export const getMyCampaignDetail = (campaignId) => {
  return ApiCall.get(`campaign/campaign-request-by-id/${campaignId}`);
};

export const getNftDetail = (nftId) => {
  return ApiCall.get(`app/asset-details/${nftId}`);
};

export const buyNft = (nftId) => {
  return ApiCall.post(`user/marketplace/buy-nft/${nftId}`);
};

export const featureBrands = () => {
  return ApiCall.get(`app/active-brands?isFeatured=true`);
};
export const getAllBrands = () => {
  return ApiCall.get(`app/active-brands?isFeatured=false`);
};
export const featureCauses = () => {
  return ApiCall.get(`app/active-causes?isFeatured=true`);
};
export const getAllCauses = () => {
  return ApiCall.get(`app/active-causes?isFeatured=false`);
};
export const getAllInfluencers = () => {
  return ApiCall.get(`app/influencers?isFeatured=false`);
};
export const getAllUserCampaign = (id) => {
  return ApiCall.get(`app/active-campaigns/${id}`);
};
