import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../pages/browseInfluencers/BrowseInfluencer.css';
import Campaigns from '../../pages/browseNposAndCauses/components/Campaigns';
import { getAllBrands } from '../../services/campaign';
import { errorToast } from '../../utils/toast';
import Container from '../Container';
import BrandsDetailsBanner from './components/BrandsDetailsBanner';

const BrowseBrandsDetails = () => {
  const { id } = useParams();
  const [brand, setBrand] = useState();
  useEffect(() => {
    getAllBrands()
      .then((res) => {
        setBrand(res.data.brandsList.find((i) => i.userId === id));
      })
      .catch((err) => errorToast(err.response.data.message));
  }, []);
  return (
    <Container>
      <BrandsDetailsBanner brand={brand} />
      {/* <OurImpactFocus /> */}
      <Campaigns />
    </Container>
  );
};

export default BrowseBrandsDetails;
