import React from 'react';
import BrandFrom from '../../../form/components/brandForm/BrandFrom';
import DashboardContainer from '../../DashboardContainer';

const Brand = () => {
  return (
    <DashboardContainer>
      <BrandFrom />
    </DashboardContainer>
  );
};

export default Brand;
