import React from 'react';
import { BannerHeading } from '../../../global/styles/style-component';
import { PagerBanner } from '../../Pager/PagerStyle';

const Banner = () => {
  return (
    <PagerBanner
      imgUrl={require('../../../assets/nft-details/nft-details-banner.png')}
    >
      <BannerHeading style={{ margin: '0' }}>
        Opportunity for <br /> Brands and Agencies
      </BannerHeading>
    </PagerBanner>
  );
};

export default Banner;
