import React from "react";
import { StatementHeading } from "../../../global/styles/style-component";
import { Typography } from "@mui/material";

const AcceleratorStartupIntro = () => {
  return (
    <div className="startup-intro">
      <StatementHeading>
        Are you an early stage startup working on an innovative Web 3 tech
        solution that can have a significant positive impact in the world?
      </StatementHeading>

      <div
      style={{
        width: "70%",
        maxWidth: "1200px",
        margin: "60px auto",
        position: "relative",
        borderRadius: 10,
        background: "#fff",
        overflow: "hidden",
        boxShadow: "10px 7px 32px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 200,
          background: "#209AB7",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          background: "#F8AA52",
          bottom: 10,
          left: 500,
          width: "200px",
          height: "200px",
          borderRadius: "50%",
        }}
      ></div>
      {/* content */}
      <div
        style={{
          background: "#fff9",
          backdropFilter: "blur(32px)",
          padding: "40px",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "#023047",
            textAlign: "justify",
            my: 3,
          }}
        >

Our accelerator program is designed to support tech-for-good solutions by helping impact tech founders and startups build user-friendly blockchain and Web 3 solutions. We take you from concept to launch, no tech expertise needed!
        </Typography>
      </div>
    </div>
    </div>
  );
};

export default AcceleratorStartupIntro;
