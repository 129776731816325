import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import {
  BannerHeading,
  CommonBtn,
  SubHeading,
} from '../../../global/styles/style-component';

const ImpactStartupBanner = () => {
  return (
    <PagerBanner
      imgUrl={require('../../../../src/assets/nft-details/nft-details-banner.png')}
      className='startup-banner'
    >
      <BannerHeading>BUILD YOUR IMPACT STARTUP</BannerHeading>
      <div className='startup-content'>
        <SubHeading color='white' marginTop='1em'>
          We help early-stage impact startups to build, launch and grow products
          in 3 - 4 months.
        </SubHeading>
        {/* <div style={{ textAlign: 'center' }}>
          <CommonBtn style={{ fontSize: '1.5em', fontWeight: '700' }}>
            Let's talk
          </CommonBtn>
        </div> */}
      </div>
    </PagerBanner>
  );
};

export default ImpactStartupBanner;
