import React from 'react';
import Container from '../Container';
import AcceleratorStartupBanner from './components/AcceleratorStartupBanner';
import AcceleratorStartupIntro from './components/AcceleratorStartupIntro';
import './AcceleratorStartup.css'
import AcceleratorStartupProcess from './components/AcceleratorStartupProcess';
import Apply from './components/Apply';
const AcceleratorStartup = () => {
  return (
    <>
      <Container>
        <AcceleratorStartupBanner/>
        <AcceleratorStartupIntro/>
        <AcceleratorStartupProcess/>
        <Apply/>
      </Container>
    </>
  );
};

export default AcceleratorStartup;
