import React, { useEffect, useState } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Feasibility from '../../../../global/components/vetting/Feasibility';
import Impact from '../../../../global/components/vetting/Impact';
import OverView from '../../../../global/components/vetting/OverView';
import Reference from '../../../../global/components/vetting/Reference';
import Transparency from '../../../../global/components/vetting/Transparency';
import {
  Tab,
  TabNumber,
} from '../../../../global/components/vetting/vetting-style';
import {
  CommonBtn,
  HalfColoredContainer,
  Heading,
} from '../../../../global/styles/style-component';
import {
  getCampaignDetail,
  getMyCampaignDetail,
} from '../../../../services/campaign';
import {
  updateApproveCampaign,
  updateCampaign,
} from '../../../../services/vetting';
import { errorToast } from '../../../../utils/toast';
import {
  validateEmail,
  validateFullName,
  validateName,
  validateUrl,
} from '../../../../utils/validation';
import DashboardContainer from '../../DashboardContainer';

const EditVetting = () => {
  const dispatch = useDispatch();

  const [searchParma] = useSearchParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [data, setData] = useState({
    overview: {
      campaignName: {
        value: '',
        error: '',
      },
      isSuperCampaign: {
        value: 'false',
        error: '',
      },
      isSubCampaign: {
        value: 'false',
        error: '',
      },
      superCampaignId: {
        value: '',
        error: '',
      },
      superCampaignName: {
        value: '',
        error: '',
      },
      description: {
        value: '',
        error: '',
      },
      isBeneficiary: {
        value: '',
        error: '',
      },
      beneficiaries: {
        value: [
          {
            firstName: '',
            lastName: '',
            walletAddress: '',
            earningAllocate: '',
          },
        ],
        error: '',
      },
      startDate: {
        value: new Date().toISOString(),
        error: '',
      },
      endDate: {
        value: new Date().toISOString(),
        error: '',
      },
      campaignType: {
        value: '',
        error: '',
      },
    },
    impact: {
      sdgs: {
        value: '',
        error: '',
      },
      category: {
        value: '',
        error: '',
      },
      projectNeed: {
        value: '',
        error: '',
      },
      impactOnPeople: {
        value: '',
        error: '',
      },
      impactOnPlanet: {
        value: '',
        error: '',
      },
      kpis: {
        value: 'This is an testing kpis',
        error: '',
      },
      agreeToCollaborate: {
        value: '',
        error: '',
      },
      milestones: [
        {
          milestoneDate: {
            value: '',
            error: '',
          },
          heading: {
            value: '',
            error: '',
          },
          description: {
            value: '',
            error: '',
          },
          milestoneKpis: [
            {
              value: '',
              error: '',
            },
          ],
        },
      ],
    },
    transparency: {
      agreeForReporting: {
        value: '',
        error: '',
      },
      teamMembers: {
        value: [],
        error: '',
      },
      agreeToShareLessons: {
        value: '',
        error: '',
      },
    },
    feasibility: {
      operationalCapacity: {
        value: '',
        error: '',
      },
      circumstances: {
        value: '',
        error: '',
      },
    },
    personalReference: {
      personalReferenceName: {
        value: '',
        error: '',
      },
      personalReferencePhoneNum: {
        value: '',
        error: '',
      },
      personalReferenceEmail: {
        value: '',
        error: '',
      },
      personalReferenceRelationShip: {
        value: '',
        error: '',
      },
      personalReferenceBioLink: {
        value: '',
        error: '',
      },
    },
    professionalReference: {
      professionalReferenceName: {
        value: '',
        error: '',
      },
      professionalReferencePhoneNum: {
        value: '',
        error: '',
      },
      professionalReferenceEmail: {
        value: '',
        error: '',
      },
      professionalReferenceRelationShip: {
        value: '',
        error: '',
      },
      professionalReferenceBioLink: {
        value: '',
        error: '',
      },
    },
  });

  const list = [
    {
      title: 'Overview',
      component: <OverView data={data} setData={setData} isEdit={true} />,
    },
    {
      title: 'Impact',
      component: <Impact data={data} setData={setData} isEdit={true} />,
    },
    {
      title: 'Transparency',
      component: <Transparency data={data} setData={setData} isEdit={true} />,
    },
    {
      title: 'Feasibility',
      component: <Feasibility data={data} setData={setData} isEdit={true} />,
    },
    {
      title: 'Reference',
      component: <Reference data={data} setData={setData} isEdit={true} />,
    },
  ];

  useEffect(() => {
    console.log('VETTING', data);
  }, [data]);

  const onClickHandler = () => {
    if (activeIndex <= list.length - 1) {
      switch (activeIndex) {
        case 0:
          if (
            data.overview.description.value.length <= 15 ||
            data.overview.campaignName.value === '' ||
            data.overview.campaignType.value === '' ||
            data.overview.startDate.value === '' ||
            new Date() > new Date(data.overview.startDate.value) ||
            data.overview.endDate.value === '' ||
            new Date(data.overview.endDate.value) <
              new Date(data.overview.startDate.value) ||
            data.overview.isSuperCampaign.value === '' ||
            (data.overview.isSuperCampaign.value === 'no' &&
              (data.overview.isSubCampaign.value === '' ||
                (data.overview.isSubCampaign.value === 'yes' &&
                  data.overview.superCampaignName.value === '')))
          ) {
            setData({
              ...data,
              overview: {
                ...data.overview,
                description: {
                  ...data.overview.description,
                  error:
                    data.overview.description.value.length < 15
                      ? 'More than 15 characters required'
                      : '',
                },

                campaignName: {
                  ...data.overview.campaignName,
                  error:
                    data.overview.campaignName.value === ''
                      ? 'Invalid Campaign Name format'
                      : '',
                },
                startDate: {
                  ...data.overview.startDate,
                  error:
                    data.overview.startDate.value === ''
                      ? 'Select a valid date in the future'
                      : new Date() > new Date(data.overview.startDate.value)
                      ? 'Select a valid date in the future'
                      : '',
                },
                endDate: {
                  ...data.overview.endDate,
                  error:
                    data.overview.endDate.value === ''
                      ? 'Select a valid date after the start date '
                      : new Date(data.overview.startDate.value) >=
                        new Date(data.overview.endDate.value)
                      ? 'Select a valid date after the start date '
                      : '',
                },
                isSuperCampaign: {
                  ...data.overview.isSuperCampaign,
                  error:
                    data.overview.isSuperCampaign.value === ''
                      ? 'Response is compulsory'
                      : '',
                },
                isSubCampaign: {
                  ...data.overview.isSubCampaign,
                  error:
                    data.overview.isSubCampaign.value === ''
                      ? 'Response is compulsory'
                      : '',
                },
                superCampaignName: {
                  ...data.overview.superCampaignName,
                  error:
                    data.overview.superCampaignName.value === ''
                      ? 'Response required '
                      : '',
                },
                campaignType: {
                  ...data.overview.campaignType,
                  error:
                    data.overview.campaignType.value === ''
                      ? 'One option is required'
                      : '',
                },
              },
              banner: {
                ...data.banner,
                error: !data.banner.value.every((i) => i)
                  ? '3 images are required'
                  : '',
              },
            });
            return;
          }
          // dispatch(setVettingForm(data));
          setActiveIndex((prev) => prev + 1);
          break;
        case 1:
          if (
            data.impact.sdgs.value === '' ||
            data.impact.category.value === '' ||
            data.impact.kpis.value === '' ||
            !data.impact.agreeToCollaborate.value ||
            data.impact.projectNeed.value.length < 15 ||
            data.impact.impactOnPeople.value.length < 15 ||
            data.impact.impactOnPlanet.value.length < 15 ||
            !data.impact.milestones.every((i) => {
              return Object.values(i).every((a) => {
                console.log(
                  Array.isArray(a) ? a.every((y) => y?.value) : a.value
                );
                return Array.isArray(a) ? a.every((y) => y?.value) : a.value;
              });
            })
          ) {
            setData({
              ...data,
              impact: {
                ...data.impact,
                sdgs: {
                  ...data.impact.sdgs,
                  error:
                    data.impact.sdgs.value === ''
                      ? 'Minimum of one SDG is required '
                      : '',
                },
                category: {
                  ...data.impact.category,
                  error:
                    data.impact.category.value === ''
                      ? 'Category is compulsory '
                      : '',
                },
                projectNeed: {
                  ...data.impact.projectNeed,
                  error:
                    data.impact.projectNeed.value === ''
                      ? 'More than 15 characters required'
                      : '',
                },
                impactOnPeople: {
                  ...data.impact.impactOnPeople,
                  error:
                    data.impact.impactOnPeople.value === ''
                      ? 'More than 15 characters required '
                      : '',
                },
                impactOnPlanet: {
                  ...data.impact.impactOnPlanet,
                  error:
                    data.impact.impactOnPlanet.value.length < 15
                      ? 'More than 15 characters required'
                      : '',
                },
                kpis: {
                  ...data.impact.kpis,
                  error:
                    data.impact.kpis.value === '' ? 'Invalid KPIS format' : '',
                },
                agreeToCollaborate: {
                  ...data.impact.agreeToCollaborate,
                  error: !data.impact.agreeToCollaborate.value
                    ? 'Invalid agree to collaborate format'
                    : '',
                },
              },
            });
            if (
              !data.impact.milestones.every((i) =>
                Array.isArray(i) ? i.every((y) => y?.value) : i.value
              )
            ) {
              const tempData = data.impact.milestones.map((i) => ({
                milestoneDate: {
                  ...i.milestoneDate,
                  error:
                    i.milestoneDate.value.trim() === ''
                      ? 'Date required when milestone will be met '
                      : '',
                },
                heading: {
                  ...i.heading,
                  error:
                    i.heading.value.trim() === '' ? 'Heading required ' : '',
                },
                description: {
                  ...i.description,
                  error:
                    i.description.value.length < 15
                      ? 'More than 15 characters required'
                      : '',
                },
                milestoneKpis: i.milestoneKpis.map((i) => ({
                  ...i,
                  error: i.value.trim() === '' ? "Kpi can't be empty" : '',
                })),
              }));
              setData((prev) => ({
                ...prev,
                impact: {
                  ...prev.impact,
                  milestones: tempData,
                },
              }));
            }
            return;
          }
          // dispatch(setVettingForm(data));
          setActiveIndex((prev) => prev + 1);
          break;
        case 2:
          if (
            !data.transparency.agreeForReporting.value ||
            data.transparency.teamMembers.value.length === 0 ||
            !data.transparency.agreeToShareLessons.value
          ) {
            setData({
              ...data,

              transparency: {
                agreeForReporting: {
                  ...data.transparency.agreeForReporting,
                  error: !data.transparency.agreeForReporting.value
                    ? 'Response is required'
                    : '',
                },
                teamMembers: {
                  ...data.transparency.teamMembers,
                  error:
                    data.transparency.teamMembers.value.length === 0
                      ? 'there should be at least one team member'
                      : '',
                },
                agreeToShareLessons: {
                  ...data.transparency.agreeToShareLessons,
                  error: !data.transparency.agreeToShareLessons.value
                    ? 'Response is required'
                    : '',
                },
              },
            });
            return;
          }
          // dispatch(setVettingForm(data));
          setActiveIndex((prev) => prev + 1);
          break;
        case 3:
          if (
            data.feasibility.operationalCapacity.value === '' ||
            data.feasibility.circumstances.value === ''
          ) {
            setData({
              ...data,
              feasibility: {
                operationalCapacity: {
                  ...data.feasibility.operationalCapacity,
                  error:
                    data.feasibility.operationalCapacity.value === ''
                      ? 'More than 15 characters required'
                      : '',
                },
                circumstances: {
                  ...data.feasibility.circumstances,
                  error:
                    data.feasibility.circumstances.value === ''
                      ? 'More than 15 characters required'
                      : '',
                },
              },
            });
            return;
          }
          // dispatch(setVettingForm(data));
          setActiveIndex((prev) => prev + 1);
          break;
        case 4:
          if (
            !validateFullName(
              data.professionalReference.professionalReferenceName.value
            ) ||
            !validateFullName(
              data.personalReference.personalReferenceName.value
            ) ||
            !formatPhoneNumber(
              data.personalReference.personalReferencePhoneNum.value
            ) ||
            !formatPhoneNumber(
              data.professionalReference.professionalReferencePhoneNum.value
            ) ||
            !validateEmail(
              data.personalReference.personalReferenceEmail.value
            ) ||
            !validateEmail(
              data.professionalReference.professionalReferenceEmail.value
            ) ||
            !validateUrl(
              data.personalReference.personalReferenceBioLink.value
            ) ||
            !validateUrl(
              data.professionalReference.professionalReferenceBioLink.value
            ) ||
            data.personalReference.personalReferenceRelationShip.value === '' ||
            data.professionalReference.professionalReferenceRelationShip
              .value === ''
          ) {
            setData({
              ...data,
              personalReference: {
                personalReferenceName: {
                  ...data.personalReference.personalReferenceName,
                  error: !validateFullName(
                    data.personalReference.personalReferenceName.value
                  )
                    ? 'First and last name required '
                    : '',
                },
                personalReferencePhoneNum: {
                  ...data.personalReference.personalReferencePhoneNum,
                  error: !formatPhoneNumber(
                    data.personalReference.personalReferencePhoneNum.value
                  )
                    ? 'A phone number is required '
                    : '',
                },
                personalReferenceEmail: {
                  ...data.personalReference.personalReferenceEmail,
                  error: !validateEmail(
                    data.personalReference.personalReferenceEmail.value
                  )
                    ? 'A personal email address is required'
                    : '',
                },
                personalReferenceBioLink: {
                  ...data.personalReference.personalReferenceBioLink,
                  error: !validateUrl(
                    data.personalReference.personalReferenceBioLink.value
                  )
                    ? 'A bio link is required '
                    : '',
                },
                personalReferenceRelationShip: {
                  ...data.personalReference.personalReferenceRelationShip,
                  error:
                    data.personalReference.personalReferenceRelationShip
                      .value === ''
                      ? 'Response is required '
                      : '',
                },
              },
              professionalReference: {
                professionalReferenceName: {
                  ...data.professionalReference.professionalReferenceName,
                  error: !validateFullName(
                    data.professionalReference.professionalReferenceName.value
                  )
                    ? 'First and last name required '
                    : '',
                },

                professionalReferencePhoneNum: {
                  ...data.professionalReference.professionalReferencePhoneNum,
                  error: !formatPhoneNumber(
                    data.professionalReference.professionalReferencePhoneNum
                      .value
                  )
                    ? 'A phone number is required '
                    : '',
                },

                professionalReferenceEmail: {
                  ...data.professionalReference.professionalReferenceEmail,
                  error: !validateEmail(
                    data.professionalReference.professionalReferenceEmail.value
                  )
                    ? 'A business email address is required'
                    : '',
                },

                professionalReferenceBioLink: {
                  ...data.professionalReference.professionalReferenceBioLink,
                  error: !validateUrl(
                    data.professionalReference.professionalReferenceBioLink
                      .value
                  )
                    ? 'A bio link is required '
                    : '',
                },
                professionalReferenceRelationShip: {
                  ...data.professionalReference
                    .professionalReferenceRelationShip,
                  error:
                    data.professionalReference.professionalReferenceRelationShip
                      .value === ''
                      ? 'Please provide relationship'
                      : '',
                },
              },
            });
            return;
          }
          const tempData = Object.keys(data).reduce(
            (prevParent, currentParent) => ({
              ...prevParent,
              [currentParent]: Object.keys(data[currentParent]).reduce(
                (prev, curr) => ({
                  ...prev,
                  [curr]:
                    curr === 'sdgs'
                      ? data[currentParent][curr]?.value.map((i) => i.name)
                      : curr === 'milestones'
                      ? data[currentParent][curr]?.map((i) => {
                          const temp = {};
                          Object.keys(i).forEach((j) => {
                            temp[j] = Array.isArray(i[j])
                              ? i[j].map((i) => i.value)
                              : i[j].value;
                          });
                          return temp;
                        })
                      : data[currentParent][curr]?.value,
                }),
                []
              ),
            }),
            []
          );
          console.log(tempData);
          if (searchParma.get('status').toLowerCase() === 'pending') {
            dispatch(updateCampaign(tempData, id, navigate));
          } else {
            dispatch(updateApproveCampaign(tempData, id, navigate));
          }
          // setActiveIndex((prev) => prev + 1);
          break;
        default:
          break;
      }
    }
  };

  const onPreClickHandler = () => {
    if (activeIndex > 0) {
      setActiveIndex((prev) => prev - 1);
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (searchParma.get('status').toLowerCase() === 'pending') {
      getMyCampaignDetail(id)
        .then((res) => {
          const tempData = {};
          const tempCampaignData = res.data.campaignRequest;
          Object.keys(data).forEach((item) => {
            if (tempCampaignData[item]) {
              tempData[item] = Object.keys(tempCampaignData[item]).reduce(
                (prev, curr) => ({
                  ...prev,
                  [curr]:
                    curr === 'milestones'
                      ? tempCampaignData[item][curr].map((i, idx) => {
                          const tempData = {};
                          Object.keys(i).forEach((a, b) => {
                            if (Array.isArray(i[a])) {
                              tempData[a] = i[a].map((c, d) => ({
                                value: c,
                                error: '',
                              }));
                            } else {
                              tempData[a] = {
                                value: i[a],
                                error: '',
                              };
                            }
                          });
                          console.log('//////////////////', tempData);
                          return tempData;
                        })
                      : {
                          value:
                            curr === 'sdgs'
                              ? tempCampaignData[item][curr].map((item) => ({
                                  key: item,
                                  name: item,
                                }))
                              : tempCampaignData[item][curr],
                          error: '',
                        },
                }),
                []
              );
            }
          });
          tempData.overview = {
            ...data.overview,
            ...tempData.overview,
          };
          console.log(tempData, 'Working', tempCampaignData);
          setData({ ...data, ...tempData });
        })
        .catch((err) => {
          console.log(err);
          errorToast(err?.response?.data?.massage);
        });
    } else {
      getCampaignDetail(id)
        .then((res) => {
          const tempData = {};
          const tempCampaignData = res.data.campaignDetail;
          Object.keys(data).forEach((item) => {
            if (tempCampaignData[item]) {
              tempData[item] = Object.keys(tempCampaignData[item]).reduce(
                (prev, curr) => ({
                  ...prev,
                  [curr]:
                    curr === 'milestones'
                      ? tempCampaignData[item][curr].map((i, idx) => {
                          const tempData = {};
                          Object.keys(i).forEach((a, b) => {
                            if (Array.isArray(i[a])) {
                              tempData[a] = i[a].map((c, d) => ({
                                value: c,
                                error: '',
                              }));
                            } else {
                              tempData[a] = {
                                value: i[a],
                                error: '',
                              };
                            }
                          });
                          console.log('//////////////////', tempData);
                          return tempData;
                        })
                      : {
                          value:
                            curr === 'sdgs'
                              ? tempCampaignData[item][curr].map((item) => ({
                                  key: item,
                                  name: item,
                                }))
                              : tempCampaignData[item][curr],
                          error: '',
                        },
                }),
                []
              );
            }
          });
          tempData.overview = {
            ...data.overview,
            ...tempData.overview,
          };
          console.log(tempData, 'Working', tempCampaignData);
          setData({ ...data, ...tempData });
        })
        .catch((err) => {
          console.log(err);
          errorToast(err?.response?.data?.massage);
        });
    }
  }, [id]);

  return (
    <DashboardContainer>
      <HalfColoredContainer
        height={'auto'}
        color={'transparent'}
        className='vettingContainer'
      >
        <Heading marginBottom='0'>CAMPAIGN INFORMATION</Heading>
        <strong
          style={{
            textAlign: 'center',
            marginBottom: '0.8em',
          }}
        >
          Get your campaign approved
        </strong>
        <div className='vettingContent'>
          <div className='vettingContentHeader'>
            {list.map((item, index) => (
              <div className='vettingHeaderItem' key={index}>
                <Tab
                  onClick={() => setActiveIndex(index)}
                  active={activeIndex === index}
                >
                  <TabNumber>{index + 1}</TabNumber>
                  <span>{item.title}</span>
                </Tab>
              </div>
            ))}
          </div>
          <div className='vettingFormContainer'>
            {list[activeIndex].component}
          </div>
          <div className='vettingBtnContainer'>
            {activeIndex > 0 ? (
              <CommonBtn id='vettingPre' onClick={onPreClickHandler}>
                Previous
              </CommonBtn>
            ) : (
              <div></div>
            )}
            <CommonBtn id='vettingNext' onClick={onClickHandler}>
              {activeIndex === list.length - 1 ? 'Submit' : 'Next'}
            </CommonBtn>
          </div>
        </div>
      </HalfColoredContainer>
    </DashboardContainer>
  );
};

export default EditVetting;
