import React from 'react';
import {
  BannerHeading,
  MainContainer,
} from '../../../global/styles/style-component';

const Banner = () => {
  return (
    <MainContainer className='influencer-banner-section'>
      <BannerHeading>Opportunity for Influencer</BannerHeading>
    </MainContainer>
  );
};

export default Banner;
