import React from 'react';
import banner from '../../../assets/new-nft-market-landing-page/nft-market-banner.mp4';
const NftMarketBanner = () => {
  return (
    <div className='nft-banner heroContainer'>
      <video autoPlay muted loop playsInline id='myVideo'>
        <source src={banner} type='video/webm' />
      </video>
    </div>
  );
};

export default NftMarketBanner;
