import './HowDoWeDo.css';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../../global/styles/style-component';

import { Box } from '@mui/material';
import nft from '../../../assets/videos/vdo1.mp4';
import it from '../../../assets/videos/vdo2.mp4';
import { BsPlayCircle } from 'react-icons/bs';
import { BsPauseCircle } from 'react-icons/bs';
import { useRef, useState } from 'react';

const HowDoWeDo = () => {
  const [vdo1playing, setvdo1playing] = useState(true);
  const [vdo2playing, setvdo2playing] = useState(true);

  const vdo1ref = useRef();
  const vdo2ref = useRef();

  function pause(ref) {
    ref.current.pause();
  }
  function play(ref) {
    ref.current.play();
  }
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        p: { xs: '20px', md: '40px' },
      }}
    >
      <Box
        sx={{
          display: { md: 'flex' },
          gap: '16px',
          alignItems: 'stretch',
        }}
      >
        {/* left */}
        <div
          style={{
            padding: '20px',
            flex: 2,
            gap: '16px',
          }}
        >
          <Heading marginBottom='0'>HOW IT WORKS</Heading>
          <Box
            sx={{
              marginTop: { xs: '40px', md: '70px' },
              display: 'flex',
              gap: '16px',
            }}
          >
            <div style={{ position: 'relative', flex: 1 }}>
              <div
                style={{
                  position: 'absolute',
                  inset: 0,
                  zIndex: 1,
                  color: '#fff',
                  display: 'flex',
                  gap: 32,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {vdo1playing ? (
                  <BsPauseCircle
                    style={{ cursor: 'pointer' }}
                    size={60}
                    onClick={() => {
                      pause(vdo1ref);
                      setvdo1playing(false);
                    }}
                  />
                ) : (
                  <BsPlayCircle
                    style={{ cursor: 'pointer' }}
                    size={60}
                    onClick={() => {
                      play(vdo1ref);
                      setvdo1playing(true);
                    }}
                  />
                )}
                <h3>
                  Impact NFT <br />
                  Marketplace
                </h3>
              </div>
              <video
                ref={vdo1ref}
                autoPlay
                muted
                loop
                type='video/mp4'
                src={nft}
                alt=''
                style={{
                  width: '100%',
                  aspectRatio: '1/1',
                  objectFit: 'cover',
                  display: 'block',
                  filter: 'brightness(0.5)',
                }}
              />
            </div>
            <div style={{ position: 'relative', flex: 1 }}>
              <div
                style={{
                  position: 'absolute',
                  inset: 0,
                  zIndex: 1,
                  color: '#fff',
                  display: 'flex',
                  gap: 32,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {vdo2playing ? (
                  <BsPauseCircle
                    style={{ cursor: 'pointer' }}
                    size={60}
                    onClick={() => {
                      pause(vdo2ref);
                      setvdo2playing(false);
                    }}
                  />
                ) : (
                  <BsPlayCircle
                    style={{ cursor: 'pointer' }}
                    size={60}
                    onClick={() => {
                      play(vdo2ref);
                      setvdo2playing(true);
                    }}
                  />
                )}

                <h3>
                  Done For Your <br />
                  Services
                </h3>
              </div>
              <video
                ref={vdo2ref}
                type='video/mp4'
                autoPlay
                muted
                loop
                src={it}
                alt=''
                style={{
                  width: '100%',
                  aspectRatio: '1/1',
                  objectFit: 'cover',
                  display: 'block',
                  filter: 'brightness(0.5)',
                }}
              />
            </div>
          </Box>
        </div>
        {/* right */}
        <div
          style={{
            padding: '20px',
            flex: 1,
          }}
        >
          <Heading marginBottom='0' textAlign='left'>
            CHECK IT OUT
          </Heading>
          <Box sx={{ marginTop: { xs: '40px', md: '70px' } }}>
            {[
              'Impact NFT Marketplace',
              'Done For You Services',
              'Info Hub',
            ].map((el, i) => (
              <div
                onClick={() => {
                  switch (i) {
                    case 0:
                      navigate('/marketplace');
                      break;
                    case 1:
                      navigate('/done-for-you-services');
                      break;
                    case 2:
                      navigate('/nft-how-to-hub');
                      break;
                    default:
                      break;
                  }
                }}
                key={i}
                style={{
                  position: 'relative',
                  marginBlock: 16,
                  padding: '32px 20px 20px 20px',
                  border: '2px solid',
                  borderRadius: 20,
                  marginBottom: '40px',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    fontWeight: 700,
                    color: '#fff',
                    background: '#023047',
                    textAlign: 'center',
                    padding: '4px 8px',
                    width: 100,
                    position: 'absolute',
                    top: -10,
                    left: -2,
                    borderRadius: '20px 0 20px 0',
                  }}
                >
                  0{i + 1}
                </div>
                <h4 style={{ fontWeight: 700 }}>{el}</h4>
              </div>
            ))}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default HowDoWeDo;
