import React from 'react';
import {
  GridContainer,
  GridItem,
  InputContainer,
  SelectContainer,
  TextAreaContainer,
} from '../../formStyle';
import { AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { BiHash, BiLink } from 'react-icons/bi';
import { GoMail } from 'react-icons/go';
import {
  validateEmail,
  validateFullName,
  validateSelectedFile,
  validateUrl,
} from '../../../../utils/validation';
import { formatPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import country from '../../../../utils/countryList.json';
import { useState } from 'react';
import { useEffect } from 'react';
import ApiCall from '../../../../utils/axiosCall';
import { UploadBtn } from '../../../../global/components/vetting/vetting-style';

const Form = ({ data, dataError, setData, setDataError, isActive = false }) => {
  const [categories, setCategories] = useState([]);
  const [state, setState] = useState({
    cover: '',
    logo: '',
  });
  useEffect(() => {
    ApiCall.get('app/categories?kind=CAUSE').then((res) => {
      setCategories(res.data.categoriesList);
    });
  }, []);
  return (
    <>
      {' '}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            gap: '10px',
            height: '300px',
          }}
        >
          <label
            style={{
              width: '100%',
              height: '100%',
            }}
            htmlFor='cover'
          >
            <UploadBtn
              style={{
                width: '100%',
                height: '100%',
                padding: '0px',
                objectFit: 'cover',
              }}
              isSelected={false}
              isSet={typeof data.coverUrl === 'string' || data.cover}
              src={
                typeof data.coverUrl === 'string'
                  ? `${process.env.REACT_APP_DOMAIN}${data.coverUrl}`
                  : data.cover
                  ? state.cover
                  : require('../../../../assets/add.png')
              }
              alt=''
            />
          </label>
          <input
            accept='image/png, image/jpeg'
            type='file'
            id='cover'
            name='cover'
            hidden
            onChange={(e) => {
              validateSelectedFile(e.target.files[0], (file) => {
                setState({
                  ...state,
                  cover: URL.createObjectURL(file),
                });
                setData({
                  ...data,
                  cover: file,
                });
              });
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            gap: '10px',
            width: '100%',
            marginTop: '-60px',
          }}
        >
          <label htmlFor='logo'>
            <UploadBtn
              style={{
                padding: '0px',
                objectFit: 'contain',
                width: '120px',
                height: '120px',
              }}
              isSelected={false}
              src={
                typeof data.logoUrl === 'string'
                  ? `${process.env.REACT_APP_DOMAIN}${data.logoUrl}`
                  : data.logo
                  ? state.logo
                  : require('../../../../assets/add.png')
              }
              alt=''
            />
          </label>
          <input
            accept='image/png, image/jpeg'
            type='file'
            id='logo'
            name='logo'
            hidden
            onChange={(e) => {
              validateSelectedFile(e.target.files[0], (file) => {
                setState({
                  ...state,
                  logo: URL.createObjectURL(file),
                });
                setData({
                  ...data,
                  logo: file,
                });
              });
            }}
          />
        </div>
      </div>
      <GridContainer>
        <div>
          <GridItem className='single-input nine'>
            <label htmlFor=''>
              Non profit/Cause Name<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.name !== ''}>
              <input
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    name:
                      e.target.value === ''
                        ? 'Please provide the Non profit/Cause name'
                        : '',
                  });
                }}
                value={data.name}
                type='text'
              />
              <AiOutlineUser className='input-icon' />
            </InputContainer>
            <span>{dataError.name}</span>
          </GridItem>
          <GridItem className='single-input two'>
            <label htmlFor=''>
              Primary Contact Person<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.mainPersonName !== ''}>
              <input
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    mainPersonName: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    mainPersonName: !validateFullName(e.target.value)
                      ? 'Please provide First and Last names'
                      : '',
                  });
                }}
                value={data.mainPersonName}
                type='text'
              />
              <AiOutlineUser className='input-icon' />
            </InputContainer>
            <span>{dataError.mainPersonName}</span>
          </GridItem>
          <GridItem className='single-input three'>
            <label htmlFor=''>
              Primary Phone number<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.mainPhoneNum !== ''}>
              <PhoneInput
                disabled={isActive}
                style={{ width: '100%' }}
                international
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    mainPhoneNum: e,
                  }));
                  setDataError({
                    ...dataError,
                    mainPhoneNum: !formatPhoneNumber(e)
                      ? 'Please provide a Phone number'
                      : '',
                  });
                }}
                value={data.mainPhoneNum}
              />
              <AiOutlinePhone className='input-icon' />
            </InputContainer>
            <span>{dataError.mainPhoneNum}</span>
          </GridItem>
          <GridItem className='single-input four'>
            <label htmlFor=''>
              Primary Email address (must be a professional, not personal email)
              <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.mainEmail !== ''}>
              <input
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    mainEmail: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    mainEmail: !validateEmail(e.target.value)
                      ? 'Please provide an Email address'
                      : '',
                  });
                }}
                value={data.mainEmail}
                type='email'
              />
              <GoMail className='input-icon' />
            </InputContainer>
            <span>{dataError.mainEmail}</span>
          </GridItem>
          <GridItem className='single-input five'>
            <label htmlFor=''>
              Secondary Contact Person<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.alternatePersonName !== ''}>
              <input
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    alternatePersonName: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    alternatePersonName: !validateFullName(e.target.value)
                      ? 'Please provide First and Last names'
                      : '',
                  });
                }}
                value={data.alternatePersonName}
                type='text'
              />
              <AiOutlineUser className='input-icon' />
            </InputContainer>
            <span>{dataError.alternatePersonName}</span>
          </GridItem>
          <GridItem className='single-input six'>
            <label htmlFor=''>
              Secondary Phone number<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.alternatePhoneNum !== ''}>
              <PhoneInput
                disabled={isActive}
                style={{ width: '100%' }}
                international
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    alternatePhoneNum: e,
                  }));
                  setDataError({
                    ...dataError,
                    alternatePhoneNum: !formatPhoneNumber(e)
                      ? 'Please provide a Phone number'
                      : '',
                  });
                }}
                value={data.alternatePhoneNum}
              />
              <AiOutlinePhone className='input-icon' />
            </InputContainer>
            <span>{dataError.alternatePhoneNum}</span>
          </GridItem>
          <GridItem className='single-input seven'>
            <label htmlFor=''>
              Secondary Email address (must be a professional, not personal
              email)<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.alternateEmail !== ''}>
              <input
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    alternateEmail: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    alternateEmail: !validateEmail(e.target.value)
                      ? 'Please provide an Email address'
                      : e.target.value.match(data.mainEmail)
                      ? 'Alternate email should not be same as main email'
                      : '',
                  });
                }}
                value={data.alternateEmail}
                type='email'
              />
              <GoMail className='input-icon' />
            </InputContainer>
            <span>{dataError.alternateEmail}</span>
          </GridItem>
          <GridItem className='single-input eight'>
            <label htmlFor=''>
              About the Non profit/Cause ({data.about.length}/250)
              <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <TextAreaContainer
              disabled={isActive}
              error={dataError.about !== ''}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  about: e.target.value,
                }));
                setDataError({
                  ...dataError,
                  about:
                    e.target.value === ''
                      ? 'Please provide information on the brand/organization'
                      : e.target.value.length > 15
                      ? ''
                      : 'Please provide a minimum of 15 characters',
                });
              }}
              value={data.about}
              name=''
              id=''
              minLength={'15'}
              maxLength={250}
              cols='30'
              rows='20'
            ></TextAreaContainer>
            <span>{dataError.about}</span>
          </GridItem>
        </div>
        <div>
          <GridItem className='single-input nine'>
            <label htmlFor='cars'>
              Non profit/Cause country<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <SelectContainer
              disabled={isActive}
              error={dataError.country !== ''}
              name='cars'
              id='cars'
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  country: e.target.value,
                }));
                setDataError({
                  ...dataError,
                  country: e.target.value === '' ? 'Please select country' : '',
                });
              }}
              value={data.country}
            >
              <option value=''>Choose country</option>
              {Object.keys(country).map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </SelectContainer>
            <span>{dataError.country}</span>
          </GridItem>
          <GridItem className='single-input ten'>
            <label htmlFor='cars'>
              Non profit/Cause city<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <SelectContainer
              disabled={isActive}
              error={dataError.city !== ''}
              name='cars'
              id='cars'
              form='carform'
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  city: e.target.value,
                }));
                setDataError({
                  ...dataError,
                  city: e.target.value === '' ? 'Please select city' : '',
                });
              }}
              value={data.city}
            >
              <option value=''>Choose City</option>
              {country[data.country] &&
                country[data.country].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </SelectContainer>
            <span>{dataError.city}</span>
          </GridItem>
          <GridItem className='single-input eleven'>
            <label htmlFor='cars'>
              Non-profit cause category<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <SelectContainer
              disabled={isActive}
              error={dataError.category !== ''}
              name='cars'
              id='cars'
              form='carform'
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  category: e.target.value,
                }));
                setDataError({
                  ...dataError,
                  category:
                    e.target.value === '' ? 'Please select category' : '',
                });
              }}
              value={data.category}
            >
              <option value='volvo'>Choose category</option>
              {categories.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </SelectContainer>
            <span>{dataError.category}</span>
          </GridItem>
          <GridItem className='single-input twelve'>
            <label htmlFor='cars'>
              Non profit/Cause website<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.websiteUrl !== ''}>
              <input
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    websiteUrl: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    websiteUrl: !validateUrl(e.target.value)
                      ? 'Please provide valid  website URL'
                      : '',
                  });
                }}
                value={data.websiteUrl}
                type='url'
              />
              <BiLink className='input-icon' />
            </InputContainer>
            <span>{dataError.websiteUrl}</span>
          </GridItem>
          <GridItem className='single-input thirteen'>
            <label htmlFor='cars'>Non profit/Cause social media links</label>{' '}
            <br />
            <InputContainer
            //  error={dataError.socials !== ''}
            >
              <input
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    socials: e.target.value.split(','),
                  }));
                  // setDataError({
                  //   ...dataError,
                  //   socials: !validateUrl(e.target.value)
                  //     ? 'Please provide valid socials'
                  //     : '',
                  // });
                }}
                value={data.socials}
                type='url'
              />
              <BiLink className='input-icon' />
            </InputContainer>
            <span>{dataError.socials}</span>
          </GridItem>
          <GridItem className='single-input fouteen'>
            <label htmlFor='cars'>
              How do you feel that demo will benefit you (
              {data.feelImpactoverse.length}/250)
              <b style={{ color: 'red' }}>*</b>
            </label>
            <br />
            <TextAreaContainer
              disabled={isActive}
              error={dataError.feelImpactoverse !== ''}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  feelImpactoverse: e.target.value,
                }));
                setDataError({
                  ...dataError,
                  feelImpactoverse:
                    e.target.value.length < 15
                      ? 'Please provide feedback and should be 15 character long'
                      : '',
                });
              }}
              value={data.feelImpactoverse}
              name=''
              id=''
              minLength={'60'}
              maxLength={250}
              cols='30'
              rows='20'
            ></TextAreaContainer>
            <span>{dataError.feelImpactoverse}</span>
          </GridItem>
          <GridItem className='single-input fifteen'>
            <label htmlFor='cars'>
              Are you a registered non-profit<b style={{ color: 'red' }}>*</b>
            </label>
            <br />
            <SelectContainer
              disabled={isActive}
              error={false}
              onChange={(e) => {
                console.log(e.target.value);
                setData((prev) => ({
                  ...prev,
                  isRegistered: e.target.value,
                }));
              }}
              value={data.isRegistered}
              name='cars'
              id='cars'
              form='carform'
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </SelectContainer>
            <span>{dataError.isRegistered}</span>
          </GridItem>
        </div>
        {data.isRegistered === 'true' ? (
          <div>
            <GridItem className='single-input eighten'>
              <label htmlFor=''>
                {' '}
                Charity registration number<b style={{ color: 'red' }}>*</b>
              </label>{' '}
              <br />
              <InputContainer error={dataError.registrationNum !== ''}>
                <input
                  disabled={isActive}
                  type='number'
                  onChange={(e) => {
                    if (e.currentTarget.value.startsWith(0)) {
                      return;
                    }
                    setData((prev) => ({
                      ...prev,
                      registrationNum: e.target.value,
                    }));
                    setDataError({
                      ...dataError,
                      registrationNum:
                        e.target.value === ''
                          ? 'Please provide valid charity umber'
                          : '',
                    });
                  }}
                  value={data.registrationNum}
                />
                <BiHash className='input-icon' />
              </InputContainer>
              <span>{dataError.registrationNum}</span>
            </GridItem>
            <GridItem className='single-input nineteen'>
              <label htmlFor=''>
                Country of registration<b style={{ color: 'red' }}>*</b>
              </label>{' '}
              <br />
              <SelectContainer
                disabled={isActive}
                error={dataError.countryOfRegistration !== ''}
                name='cars'
                id='cars'
                form='carform'
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    countryOfRegistration: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    countryOfRegistration:
                      e.target.value === ''
                        ? 'Please provide country of registration'
                        : '',
                  });
                }}
                value={data.countryOfRegistration}
              >
                <option value=''>Select country</option>
                {Object.keys(country).map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </SelectContainer>
              <span>{dataError.countryOfRegistration}</span>
            </GridItem>
          </div>
        ) : (
          <div>
            <GridItem className='single-input tweentyone'>
              <label htmlFor=''>
                How did the group come together?
                <b style={{ color: 'red' }}>*</b>
              </label>
              <br />
              <InputContainer error={dataError.groupAgenda !== ''}>
                <input
                  disabled={isActive}
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      groupAgenda: e.target.value,
                    }));
                    setDataError({
                      ...dataError,
                      groupAgenda:
                        e.target.value === ''
                          ? 'Please provide valid group agenda'
                          : '',
                    });
                  }}
                  value={data.groupAgenda}
                  type='text'
                />
              </InputContainer>
              <span>{dataError.groupAgenda}</span>
            </GridItem>
            <GridItem className='single-input tweentytwo'>
              <label htmlFor=''>
                What are the some group activities?
                <b style={{ color: 'red' }}>*</b>
              </label>
              <br />
              <InputContainer error={dataError.groupActivities !== ''}>
                <input
                  disabled={isActive}
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      groupActivities: e.target.value,
                    }));
                    setDataError({
                      ...dataError,
                      groupActivities:
                        e.target.value === ''
                          ? 'Please provide valid     group activities'
                          : '',
                    });
                  }}
                  value={data.groupActivities}
                  type='text'
                />
              </InputContainer>
              <span>{dataError.groupActivities}</span>
            </GridItem>
            <GridItem className='single-input tweentythree'>
              <label htmlFor=''>
                Is there any evidence of previous activity?
                <b style={{ color: 'red' }}>*</b>
              </label>
              <br />
              <InputContainer error={dataError.previousActivity !== ''}>
                <input
                  disabled={isActive}
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      previousActivity: e.target.value,
                    }));
                    setDataError({
                      ...dataError,
                      previousActivity:
                        e.target.value === ''
                          ? 'Please provide valid     previous activity'
                          : '',
                    });
                  }}
                  value={data.previousActivity}
                  type='text'
                />
              </InputContainer>
              <span>{dataError.previousActivity}</span>
            </GridItem>
            <GridItem className='single-input tweentyfour'>
              <label htmlFor=''>
                How long is the group work together?
                <b style={{ color: 'red' }}>*</b>
              </label>
              <br />
              <InputContainer error={dataError.sinceWhen !== ''}>
                <input
                  disabled={isActive}
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      sinceWhen: e.target.value,
                    }));
                    setDataError({
                      ...dataError,
                      sinceWhen:
                        e.target.value === ''
                          ? 'Please provide valid since when'
                          : '',
                    });
                  }}
                  value={data.sinceWhen}
                  type='number'
                />
              </InputContainer>
              <span>{dataError.sinceWhen}</span>
            </GridItem>
          </div>
        )}
      </GridContainer>
    </>
  );
};

export default Form;
