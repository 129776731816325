import React from 'react';
import Container from '../Container';
import Banner from './components/Banner';
import BrandDomination from './components/BrandDomination';
import '../Influencer/Influencer.css';
import Opportunities from './components/Opportunities';
import Card from '../../global/components/Card/Card';
import Benefits from './components/Benefits';
import LearningMore from '../Influencer/components/LearningMore';
import { Link } from 'react-router-dom';

const Brands = () => {
  return (
    <Container>
      <div className='infulencer-page-container'>
        <Banner />
        <BrandDomination />
        <Opportunities />
        <div
          style={{
            margin: '4em auto',
            width: '65%',
          }}
        >
          <Card
            title={'HOW WE WORK WITH BRANDS & AGENCIES '}
            detail={`demo makes it easy to create campaigns to achieve business outcomes and benefit causes`}
          />
        </div>
        <Benefits />
        <div class='container position-relative'>
          <img
            src={require('../../assets/top.png')}
            alt=''
            width='150px'
            style={{ position: 'absolute', top: '15px', right: '12px' }}
          />
          <img
            src={require('../../assets/bottom.png')}
            alt=''
            width='150px'
            style={{ position: 'absolute', bottom: '0px', right: '12px' }}
          />
          <div class='row'>
            <div class='col-md-5 '>
              <div class='position-relative'>
                <img
                  src={require('../../assets/Ellipse 19.png')}
                  alt=''
                  style={{
                    zIndex: '-100',
                    position: 'absolute',
                    left: '-36px',
                    bottom: '-19px',
                  }}
                />
                <img
                  src={require('../../assets/boy.png')}
                  alt=''
                  class='img-fluid'
                />
              </div>
            </div>
            <div class='col-md-6 pt-5 mx-3'>
              <h1 style={{ color: '#219EBC' }} class='my-4'>
                ACCESS OUR ECOSYSTEM
              </h1>
              <div class='mx-4 px-3 my-4'>
                <h3>DIY</h3>
                <p style={{ color: '#219EBC' }}>
                  Once your brand or agency approved, you can set up your own
                  campaigns on demo. To get started go{' '}
                  <Link to='/registration'>here</Link>
                </p>
              </div>
              <div class='mx-4 px-3 my-4'>
                <h3>DONE FOR YOU</h3>
                <p style={{ color: '#219EBC' }}>
                  We offer a done-for-you service to get you set up or to run
                  your campaign, too. Please contact us here to discuss your
                  project.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        {/* <LearningMore /> */}
      </div>
    </Container>
  );
};

export default Brands;
