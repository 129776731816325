import React from 'react';
import background from '../../../assets/web2.mp4';
import { MainContainer } from '../../../global/styles/style-component';
import './Hero.css';
const Hero = () => {
  return (
    <div className="heroContainer">
      <video autoPlay muted loop playsInline id="myVideo">
        <source src={background} type="video/webm" />
      </video>
      {/* <img
        src={require('../../../assets/ezgif.com-gif-maker.gif')}
        alt=''
        id='myVideo'
      /> */}
      <MainContainer width="100%" justifyContent="flex-end">
        <h1>
          Collabs To <br />
          Scale Impact
        </h1>
      </MainContainer>
    </div>
  );
};

export default Hero;
