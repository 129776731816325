import React from 'react';
import { GiCheckMark } from 'react-icons/gi';
import { HalfColoredContainer } from '../../../global/styles/style-component';
const Benefits = () => {
  return (
    <HalfColoredContainer
      bgColor={'#fff'}
      color={'#219EBD'}
      height={'800px'}
      className='benefits-influence-section'
    >
      <div className='benefits-influence-content'>
        <h2>BENEFITS</h2>
        <ul>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} />
            Pre-vetted NPOs and impact initiatives for cause collaborations. We
            look for a spectrum of impact organizations, from well-known to
            small and focused.
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Artists and Creators
            for artistic collaborations. We showcase a diverse variety of
            creators, many from underserved areas or groups.
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Brand pages to
            showcase campaigns and build community.
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Reporting on
            campaign performance and impact.
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Automation of
            donations to NPO partners (or what should we say about this?
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Minting tools for
            NFTs and tokenization.
          </li>
        </ul>
      </div>
    </HalfColoredContainer>
  );
};

export default Benefits;
