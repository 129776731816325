import React from "react";
import { Heading } from "../../../global/styles/style-component";
import { BsSquareFill } from "react-icons/bs";
const Web3Contents = () => {
  return (
    <div className="short-history-container">
      <p>
        As you know by now, a blockchain is a ledger that stores information
        about assets and transactions. Data is stored sequentially in “blocks,”
        thus the name blockchain. <br /> <br /> Similar to a paper ledger, the
        first blockchains didn’t “do” much with the information other than store
        it.
        <br /> <br />
        Smart contracts are an innovative tool that transforms blockchain from a
        passive record of transactions into a lean, mean contract automation
        machine. Let’s take a closer look. As you know by now, a blockchain is a
        ledger that stores information about assets and transactions. Data is
        stored sequentially in “blocks,” thus the name blockchain. Similar to a
        paper ledger, the first blockchains didn’t “do” much with the
        information other than store it. Smart contracts are an innovative tool
        that transforms blockchain from a passive record of transactions into a
        lean, mean contract automation machine. Let’s take a closer look.
        <br /> <br />
      </p>

      <div className="industrial-revolution">
        <Heading>What Is A Smart Contract?</Heading>
        <p>
          Hundreds of years ago, contracts evolved to document and confirm
          agreements between parties. If parties don’t know each other well
          enough, they will not agree to move forward without the mutual
          guarantee of a contract. Contracts are like a trust proxy, one that
          has been essential to the growth of commerce and economies. <br />
          <br /> A smart contract is a digital contract that is stored on a
          blockchain. They are “smart” because rather than relying on each party
          to execute their side of the agreement, the smart contract uses
          computer code to automate the execution. Smart contracts use
          pre-defined rules to facilitate the exchange of goods or services.
          <br />
          <br /> The conditions of a smart contract are set by the parties
          involved in the contract. When certain conditions are met, the
          contracts are executed automatically.
          <br />
          <br />
          For example, rather than delay invoicing and waiting for payment, a
          smart contract could automatically send payments to a supplier when
          goods are delivered. Smart contracts could also send a royalty to an
          artist every time an artwork is sold without needing a dealer or other
          intermediary to facilitate the transaction.
          <br />
          <br /> The benefit of smart contracts is they increase transaction
          transparency while reducing fees and nonperformance risk.
        </p>{" "}
        <br />
        {/* <h4>First Industrial Revolution (1IR) — 1765</h4>
        <p>The steam engine, advances in weaving</p> <br />
        <h4>Second Industrial Revolution (2IR) — 1870</h4>
        <p>
          Electricity, science, and mass production (think Edison and Ford).
        </p>{" "}
        <br />
        <h4>Third Industrial Revolution (3IR) — 1969 </h4>
        <p>
          Computing, digital tech, and nuclear energy. Web 1.0 emerges in the
          1990s; see more detail below. Society has begun to move into a time
          when people cannot see or touch major pieces of the infrastructure
          that runs people's lives.
        </p>{" "}
        <br />
        <h4>Fourth Industrial Revolution (4IR) — 2000s </h4>
        <p>
          Like its predecessors, 4IR is rooted in technological advances. The
          distinguishing advances are smart technology integration via
          cloud-based computing, blockchain, and artificial intelligence.
        </p>{" "}
        <br /> */}
      </div>

      <div className="the-evolution">
        <Heading>What Do Smart Contracts Do?</Heading>
        <p>
          We are at the very beginning stages of smart contracts functionality.
          Not only do they store data, they can also perform computations,
          create or "mint" NFTs, send communications, generate graphics and
          more. <br />
          <br /> Here are other real-world examples:
        </p>{" "}
        <p>
          <BsSquareFill /> Automate payments to vendors
        </p>
        <p>
          <BsSquareFill /> Creating and distributing unique digital assets
        </p>
        <p>
          <BsSquareFill /> An automatic, open currency exchange
        </p>
        <p>
          <BsSquareFill />
          Automate royalty payments to owners of original intellectual property
        </p>
        <p>
          <BsSquareFill />
          Simplify insurance claims and automate payments rather than rely on
          time-consuming human review
        </p>
        <p>
          <BsSquareFill />
          Execute real estate transactions and record ownership on the
          blockchain
        </p>
      </div>

      <div className="industrial-revolution">
        <Heading>What Are the Benefits of Smart Contracts?</Heading>
        <h4>Automated execution and transactions</h4>
        <p>
          Smart contracts can automate transactions, removing the need to wait
          for a human to execute transactions. Automation saves time and reduces
          errors.
        </p>{" "}
        <br />
        <h4>Reduced costs</h4>
        <p>
          Smart contracts can reduce the costs of transactions by eliminating
          the need for intermediaries.
        </p>{" "}
        <br />
        <h4>Increased security </h4>
        <p>
          Smart contracts are stored on the blockchain, which makes them secure
          and tamper-proof.
        </p>{" "}
        <br />
        <h4>Increased transparency </h4>
        <p>
          Smart contracts can increase transparency as all parties can view the
          terms of the agreement and the history of transactions based on the
          terms of the contract
        </p>{" "}
        <br />
        <h4>Public record </h4>
        <p>
          Smart contracts are helpful for audits and tracking. For example,
          Ethereum smart contracts are on a public blockchain, so anyone can
          instantly track asset transfers and other related information. For
          example, you can check to see that someone sent money to your address.
        </p>{" "}
        <br />
        <h4>Privacy protection </h4>
        <p>
          Just because transactions are trackable doesn’t mean that your
          identity is public. Your transactions are tied publicly to a unique
          cryptographic address, not your identity. Smart contracts can protect
          your privacy.
        </p>{" "}
        <br />
        <h4>Visible terms</h4>
        <p>
          Public transparency of the terms in the contract means that anyone can
          scrutinize it. Much like open-source software development, shared
          expertise in developing contracts could lead to better contracts and
          more transparency.
        </p>{" "}
        <br />
      </div>

      <div
        className="the-evolution industrial-revolution"
        style={{ marginBottom: "60px" }}
      >
        <Heading>Do Smart Contracts Have Risks?</Heading>
        <p>
          Like traditional contracts, smart contracts cover real-world
          situations between individuals and businesses. As such, they carry
          some risks and uncertainty.{" "}
        </p>
        <br />
        <h4>Complexity</h4>
        <p>
          Smart contracts can be complex and challenging to understand, leading
          to errors and unforeseen consequences. Lawyers will continue to be
          very busy in the Web3 Wild West for some time.
        </p>{" "}
        <br />
        <h4>Inflexibility</h4>
        <p>
          Unlike traditional contracts, in theory, smart contracts have no room
          for interpretation. All terms are predetermined and automatically
          enforced. Once a smart contract is created, it cannot be changed.{" "}
          <br />
          <br /> This can be problematic if the contract conditions change or if
          there is an unforeseen consequence or error in the contract.
          <br />
          <br />
        </p>{" "}
        <h4>Human error </h4>
        <p>
          Smart contracts are stored on the blockchain, which makes them secure
          from hacking and tampering. However, there is still a risk that errors
          in the code could lead to problems with the execution of the contract.
        </p>{" "}
        <br />
      </div>
    </div>
  );
};

export default Web3Contents;
