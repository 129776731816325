import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../global/components/Footer/Footer';
import Header from '../global/components/Header/Header';

const Container = ({ children }) => {
  const accessToken = useSelector((state) => state.users.accessToken);
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('accessToken', accessToken);
  }, [window.location.pathname]);
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Container;
