import { Classes, Dialog } from '@blueprintjs/core';
import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import publicApiCall from '../../../utils/publicAxiosCall';
import { errorToast, successToast } from '../../../utils/toast';
import { validateEmail } from '../../../utils/validation';
import { Btn, CommonBtn, MainContainer } from '../../styles/style-component';
import { Input } from '../Custom';
import { InputContainer } from '../Custom/customStyled';
import './Header.css';
import { Menu } from './headerStyled';

const Header = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const [isScroll, setIsScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [menu, setMenu] = useState(false);
  const [menuExplore, setMenuExplore] = useState(false);
  const handleClick = () => {
    setMenuExplore(false);
    setMenu(!menu);
  };

  const handleClose = () => {
    setMenu(false);
    setMenuExplore(false);
  };

  const onScroll = () => {
    const path = window.location.pathname !== '/';
    if (window.scrollY > 100) {
      setIsScroll(true || path);
    } else {
      setIsScroll(false || path);
    }
  };
  const downHandler = () => {};

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    window.addEventListener('scroll', onScroll);
    window.addEventListener('keydown', downHandler);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({
    email: {
      value: '',
      error: '',
    },
    fullName: {
      value: '',
      error: '',
    },
    feedback: {
      value: '',
      error: '',
    },
  });

  return (
    <div style={{ position: 'fixed', width: '100%', zIndex: '1000' }}>
      <MainContainer bgColor='#FB8501'>
        <h6 style={{ textAlign: 'center' }}>
          Welcome to the BETA version of demo. Please click{' '}
          <u onClick={() => setOpenModal(true)} style={{ cursor: 'pointer' }}>
            here
          </u>{' '}
          to submit feedback.
        </h6>
      </MainContainer>
      <MainContainer
        id='navHeaderContainer'
        bgColor={
          isScroll || window.location.pathname !== '/'
            ? '#219ebcd1'
            : 'transparent'
        }
        flexDirection='row'
        paddingVertical={
          isScroll || window.location.pathname !== '/' ? '0.7rem' : '1.5rem'
        }
        justifyContent='space-between'
        alignItem='center'
      >
        <div id='menuIcon' onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={faBars} color='#fb8501' />
        </div>
        <Link id='logoHead' to='/'>
          <img
            src={Logo}
            alt='Logo'
            style={{
              width:
                isScroll || window.location.pathname !== '/' ? '50px' : '50px',
            }}
          />
        </Link>
        {width > 620 || isOpen ? (
          <div className='navItemsContainer'>
            <Link
              to='/browse-campaign'
              style={{ textDecoration: 'none' }}
              className='navBtn'
              onClick={handleClose}
            >
              Browse Campaigns
            </Link>
            <Link
              to='/marketplace'
              style={{ textDecoration: 'none' }}
              className='navBtn'
            >
              Marketplace
            </Link>

            <Link
              to='/done-for-you'
              style={{ textDecoration: 'none' }}
              className='navBtn'
            >
              DONE FOR YOU
            </Link>
            <Link to='/info-hub-main' className='navBtn'>
              Info hub
            </Link>
            {/* <div
              style={{
                position: 'relative',
              }}
            >
              <div className='navBtn' onClick={handleClick}>
                Info hub <FontAwesomeIcon icon={faAngleDown} color='#fff' />
              </div>
              <Menu active={menu}>
                 <Link to='/brand' className='navBtn' onClick={handleClose}>
                  Brands
                </Link>
                <Link to='/' className='navBtn' onClick={handleClose}>
                  Causes
                </Link>
                <Link to='/creators' className='navBtn' onClick={handleClose}>
                  Creators
                </Link> 
                <Link to={'/nft-how-to-hub'} className='navBtn'>
                  Intro to NFTS
                </Link>
                <Link to={'/nftSteps'} className='navBtn'>
                  NFTs in 3 simple steps
                </Link>
                <Link
                  to='/blockchain-one-pager'
                  style={{ textDecoration: 'none' }}
                  className='navBtn'
                  onClick={handleClose}
                >
                  Blockchain One Pager
                </Link>
                <Link
                  to='/nft-one-pager'
                  className='navBtn'
                  onClick={handleClose}
                  style={{ textDecoration: 'none' }}
                >
                  NFT One Pager
                </Link>
                <Link
                  to='/crypto-wallet'
                  className='navBtn'
                  onClick={handleClose}
                  style={{ textDecoration: 'none' }}
                >
                  Crypto wallet
                </Link>
              </Menu>
            </div> */}
            <div
              style={{
                position: 'relative',
              }}
            >
              <div
                className='navBtn'
                onClick={() => {
                  setMenuExplore(!menuExplore);
                  setMenu(false);
                }}
              >
                Explore <FontAwesomeIcon icon={faAngleDown} color='#fff' />
              </div>
              <Menu active={menuExplore}>
                <Link to='/brand' className='navBtn' onClick={handleClose}>
                  Brands & Agencies
                </Link>
                <Link to='/nPos' className='navBtn' onClick={handleClose}>
                  NPOs & Causes
                </Link>
                <Link to='/creators' className='navBtn' onClick={handleClose}>
                  Creators
                </Link>
                <Link
                  to='/nft-marketplace'
                  className='navBtn'
                  onClick={handleClose}
                >
                  Nft MarketPlace
                </Link>
                <Link
                  to='/browse-super-campaign'
                  className='navBtn'
                  onClick={handleClose}
                >
                  Browse Super Campaign
                </Link>
                <Link
                  to='/accelerator-startup'
                  className='navBtn'
                  onClick={handleClose}
                >
                  Accelerator Start up
                </Link>
                <Link
                  to='/impact-startup'
                  style={{ textDecoration: 'none' }}
                  className='navBtn'
                >
                  Impact startup
                </Link>
                <a
                  href='https://medium.com/web3-for-the-rest-of-us'
                  target={'_blank'}
                  className='navBtn'
                  onClick={handleClose}
                  rel='noreferrer'
                >
                  Blog
                </a>
              </Menu>
            </div>
            <Btn
              id='loginBtn'
              width='fit-content'
              fontSize='14px'
              onClick={() => {
                if (isLoggedIn) {
                  navigate('/dashboard');
                } else {
                  navigate('/login');
                }
              }}
            >
              {isLoggedIn ? 'Dashboard' : 'SIGN UP/LOG IN'}
            </Btn>
          </div>
        ) : null}
      </MainContainer>
      <Dialog
        title='Feedback'
        icon='feed'
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <Input
            type='text'
            label={'Full name'}
            placeholder='Full name'
            inputValue={data.fullName}
            onChange={(e) =>
              setData({
                ...data,
                fullName: {
                  value: e.target.value,
                  error:
                    e.target.value.length === 0 ? 'Full name is required' : '',
                },
              })
            }
          />
          <Input
            type='email'
            label={'Email'}
            placeholder='Email'
            inputValue={data.email}
            onChange={(e) =>
              setData({
                ...data,
                email: {
                  value: e.target.value,
                  error: validateEmail(e.target.value)
                    ? ''
                    : 'Invalid email format',
                },
              })
            }
          />
          <InputContainer>
            <label htmlFor={'feedback'}>Feedback</label>
            <div>
              <textarea
                id='feedback'
                rows='7'
                inputValue={data.feedback}
                onChange={(e) =>
                  setData({
                    ...data,
                    feedback: {
                      value: e.target.value,
                      error:
                        e.target.value.length === 0
                          ? 'Feedback is required'
                          : '',
                    },
                  })
                }
              />
            </div>
            <span>{data.feedback.error}</span>
          </InputContainer>
        </div>
        <div
          style={{ justifyContent: 'end' }}
          className={`${Classes.DIALOG_FOOTER} d-flex`}
        >
          <CommonBtn
            onClick={() => {
              if (
                validateEmail(data.email.value) &&
                data.feedback.value.length > 0 &&
                data.fullName.value.length > 0
              ) {
                publicApiCall
                  .post('app/feedback', {
                    email: data.email.value,
                    fullName: data.fullName.value,
                    feedback: data.feedback.value,
                  })
                  .then((res) => {
                    setOpenModal(false);
                    successToast(res.data.message);
                  })
                  .catch((err) => {
                    errorToast(err.response.data.message);
                  });
              } else {
                setData({
                  ...data,
                  feedback: {
                    ...data.feedback,
                    error:
                      data.feedback.value.length === 0
                        ? 'Feedback is required'
                        : '',
                  },
                  email: {
                    ...data.email,
                    error: validateEmail(data.email.value)
                      ? ''
                      : 'Invalid email format',
                  },
                  fullName: {
                    ...data.fullName,
                    error:
                      data.fullName.value.length === 0
                        ? 'Full name is required'
                        : '',
                  },
                });
              }
            }}
            margin='1em 0'
          >
            Submit
          </CommonBtn>
        </div>
      </Dialog>
    </div>
  );
};

export default Header;
