import React from 'react';
import info from '../../../assets/marketplace-landing-place/info.png';
import faq from '../../../assets/marketplace-landing-place/faq.png';
import cirlce1 from '../../../assets/marketplace-landing-place/ellips-circle1.png';
import cirlce2 from '../../../assets/marketplace-landing-place/ellips-circle2.png';
import { useNavigate } from 'react-router-dom';
const InfoFaq = () => {
  const navigate = useNavigate();
  return (
    <div className='info-faq-container'>
      <img src={cirlce1} alt='' className='ellips-circle1' />
      <img src={cirlce2} alt='' className='ellips-circle2' />
      <div>
        <div
          onClick={() => {
            navigate('/info-hub-main');
          }}
        >
          <img src={info} alt='' />
          <h5>NOT SURE WHERE TO START? CHECK OUT OUR INFO HUB</h5>
        </div>
        <div
          onClick={() => {
            navigate('/done-for-you');
          }}
        >
          <img src={faq} alt='' />
          <h5>NEED HELP? CHECK OUT OUR DONE FOR YOU SERVICES</h5>
        </div>
      </div>
    </div>
  );
};

export default InfoFaq;
