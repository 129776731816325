import React from 'react';
import { BoxShadow, Heading } from '../../styles/style-component';
import { CardDetails, CardHeading, CardOverLay } from './card-style';
import './Card.css';

const Card = ({
  img,
  overLayColor,
  title,
  detail,
  fill,
  titleSize,
  children,
}) => {
  return (
    <BoxShadow>
      <div className='cardContainer'>
        <CardOverLay color={overLayColor} />
        {img && <img src={img} alt='' />}
        {img ? (
          <CardHeading titleSize={titleSize} img={img}>
            {title}
          </CardHeading>
        ) : (
          <Heading marginTop={'1em'} marginBottom={'0'}>
            {title}
          </Heading>
        )}
        <CardDetails color={fill}>
          <span>{detail}</span>
          {children}
        </CardDetails>
      </div>
    </BoxShadow>
  );
};

export default Card;
