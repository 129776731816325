import React from 'react';
import './ShortHistoryOfInternet.css';
import Container from '../Container';
import ShortHistoryBanner from './components/ShortHistoryBanner';
import ShortHistoryContent from './components/ShortHistoryContent';

const ShortHistoryOfInternet = () => {
  return (
    <Container>
      <ShortHistoryBanner />
      <ShortHistoryContent />
    </Container>
  );
};

export default ShortHistoryOfInternet;
