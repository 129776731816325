import { Grid } from "@mui/material";
import React from "react";
import { CommonBtn, Heading } from "../../../global/styles/style-component";
import advisor1 from "../../../assets/done-for-you-services/advisor1.png";
import advisor2 from "../../../assets/done-for-you-services/advisor2.png";
import advisor3 from "../../../assets/done-for-you-services/advisor3.png";
import advisor4 from "../../../assets/done-for-you-services/advisor4.png";
import advisor5 from "../../../assets/done-for-you-services/advisor5.png";
import advisor6 from "../../../assets/done-for-you-services/advisor6.png";
import advisor7 from "../../../assets/done-for-you-services/advisor7.png";
import advisor8 from "../../../assets/done-for-you-services/advisor8.png";
import { Box } from "@mui/material";
import advisorImg from "../.././../assets/done-for-you-services/advisor-img-bg.png";
const Advisors = () => {
  return (
    <div className="advisor-section">
      <div className="advisors-container">
        <Box
          sx={{
            width: "80%",
            margin: "auto",
            my: "80px",
            p: "3px",
            borderRadius: "40px",
            background: "linear-gradient(129.59deg, #48bbbb, #66c48f70)",
            boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.2)",
            // mx: { xs: "20px", md: "40px" },
          }}
        >
          <Box
            sx={{
              borderRadius: "38px",
              background: "#fff",
            }}
          >
            <div
              style={{
                position: "relative",
                borderRadius: 40,
                background: "#fff",
                overflow: "hidden",
                boxShadow: "10px 7px 32px rgba(0, 0, 0, 0.25)",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 100,
                  left: 200,
                  background: "#209AB7",
                  width: "400px",
                  height: "400px",
                  borderRadius: "50%",
                }}
              ></div>

              <div
                style={{
                  position: "absolute",
                  background: "#F8AA52",
                  bottom: 120,
                  left: 400,
                  width: "300px",
                  height: "300px",
                  borderRadius: "50%",
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  background: "#F8AA52",
                  bottom: 100,
                  left: 608,
                  width: "580px",
                  height: "500px",
                  borderRadius: "50%",
                }}
              ></div>
              {/* content */}
              <div
                style={{
                  background: "#fff9",
                  backdropFilter: "blur(32px)",
                  padding: "40px",
                  position: "relative",
                }}
              >
                <Heading>OUR ADVISORS</Heading>

                <Grid container spacing={3}>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor1} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>NOLEEN MARIAPPEN  </h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor2} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>KALA PHILO</h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor3} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>DR. REGINALD PARKER</h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor4} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>KARINA MURRAY </h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor5} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>MIKE JOHNS  </h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor6} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>RITESH JAIN  </h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor7} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    
                    <div className="advisor-name">
                      <h5>Dr. Ingrid Vasiliu Feltes </h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor8} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>Priya Guliani </h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  {/* <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor8} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>DUMMY NAME </h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid>
                  <Grid item lg={12 / 5} className="advisor-card">
                    <div className="advisor-img">
                      <img src={advisor8} alt="" />
                      <img src={advisorImg} className="advisor-img-bg" alt="" />
                    </div>
                    <div className="advisor-name">
                      <h5>DUMMY NAME</h5>
                      <span>Founder & CEO</span>
                    </div>
                  </Grid> */}
                </Grid>

                <div style={{ textAlign: "center" }}>
                  <CommonBtn>VIEW MORE</CommonBtn>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Advisors;
