import React from 'react';
import { Heading, SubHeading } from '../../../global/styles/style-component';
import phase1icon1 from '../../../assets/accelerator-startup/phase1-icon1.png'
import phase1icon2 from '../../../assets/accelerator-startup/phase1-icon2.png'
import phase1icon3 from '../../../assets/accelerator-startup/phase1-icon3.png'
import phase1icon4 from '../../../assets/accelerator-startup/phase1-icon4.png'
import phase1icon5 from '../../../assets/accelerator-startup/phase1-icon5.png'
import phase1icon6 from '../../../assets/accelerator-startup/phase1-icon6.png'
import phase2icon1 from '../../../assets/accelerator-startup/phase2-icon1.png'
import phase2icon2 from '../../../assets/accelerator-startup/phase2-icon2.png'
import phase2icon3 from '../../../assets/accelerator-startup/phase2-icon3.png'
import phase2icon4 from '../../../assets/accelerator-startup/phase2-icon4.png'
import phase3icon from '../../../assets/accelerator-startup/pahse3-icon.png'
const AcceleratorStartupProcess = () => {
    return (
        <div className='startup-process-section'>
            <div className="process-section-bg">
            <div className="startup-process-container">
                <Heading>THE PROCESS</Heading>
                <div className="phase-container">
                    <div className="phase-heading">
                    <h6>PHASE 1</h6>
                    <span>FOUNDATION</span>
                    </div>

                    <div className='phases-content'>
                        <div className="single-phase-card">
                            <img src={phase1icon1} alt="" />
                            <h5>Application & <br />Pre-screening</h5>
                        </div>

                        <div className="single-phase-card">
                            <img src={phase1icon2} alt="" />
                            <h5>Concept <br /> Analysis & <br /> Positioning</h5>
                        </div>

                        <div className="single-phase-card">
                            <img src={phase1icon3} alt="" />
                            <h5>Growth <br /> Hacking & <br /> Exit Planning</h5>
                        </div>

                        <div className="single-phase-card">
                            <img src={phase1icon4} alt="" />
                            <h5>Interviews & <br /> Acceptance <br /> Agreements</h5>
                        </div>

                        <div className="single-phase-card">
                            <img src={phase1icon5} alt="" />
                            <h5>Business Model <br /> & Unit <br /> Economics</h5>
                        </div>

                        <div className="single-phase-card">
                            <img src={phase1icon6} alt="" />
                            <h5>Marketing <br /> Strategy</h5>
                        </div>
                    </div>

                    
                    <div className="phase-heading" style={{marginTop:'50px'}}>
                    <h6>PHASE 2</h6>
                    <span>TECH DEVELOPMENT</span>
                    </div>

                    <div className='phases-content phase-two-content'>
                        <div className="single-phase-card phase-two-card">
                            <img src={phase2icon1} alt="" />
                            <h5>PLAN</h5>
                        </div>

                        <div className="single-phase-card phase-two-card">
                            <img src={phase2icon2} alt="" />
                            <h5>DESIGN</h5>
                        </div>

                        <div className="single-phase-card phase-two-card">
                            <img src={phase2icon3} alt="" />
                            <h5>ARCHITECT</h5>
                        </div>

                        <div className="single-phase-card phase-two-card">
                            <img src={phase2icon4} alt="" />
                            <h5>BUILD &  TEST</h5>
                        </div>
                    </div>

                    <div className="phase-heading" style={{marginTop:'50px'}}>
                    <h6>PHASE 3</h6>
                    <span>LAUNCH</span>
                    </div>

                    <div className="phase-three-content">
                        <div>
                        <img src={phase3icon} alt="" />
                        <div className="pahse-three-heading">
                            <SubHeading>LAUNCH</SubHeading>
                            <h6>3 MONTHS MENTORING SUPPORT</h6>
                        </div>
                        </div>
                    </div>

                </div>
                </div>
        </div>
        </div>
    );
};

export default AcceleratorStartupProcess;