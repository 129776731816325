import React from 'react';
import { Btn, SubHeading } from '../../../global/styles/style-component';
import { FiSearch } from 'react-icons/fi';
import { Grid } from '@mui/material';
import browseCard from '../../../assets/new-browse-campaign/browse-by-category.png';
import { BiFilterAlt } from 'react-icons/bi';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getAllSuperCampaign } from '../../../services/campaign';
import { errorToast } from '../../../utils/toast';
import Select from 'react-select';
import { getCategory, getSuperCategory } from '../../../services/vetting';

const BrowseSuperCategory = () => {
  const [campaign, setCampaign] = useState([]);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    superCampaign: [],
    impactCategory: [],
    industry: [],
  });
  const [filterValue, setFilterValue] = useState({
    superCampaign: '',
    impactCategory: '',
    industry: '',
  });
  useEffect(() => {
    getAllSuperCampaign()
      .then((res) => {
        setCampaign(res.data.superCampaigns);
      })
      .catch((err) => errorToast(err?.response?.data?.message));
  }, []);

  useEffect(() => {
    getFilter();
  }, []);

  const getFilter = async () => {
    const nftCategory = await getCategory('NFT');

    const superCategory = await getSuperCategory();

    const campaignCategory = await getCategory('CAMPAIGN');

    setFilter({
      ...filter,
      impactCategory: campaignCategory.data.categoriesList.map((item) => ({
        value: item,
        label: item,
      })),
      industry: nftCategory.data.categoriesList.map((item) => ({
        value: item,
        label: item,
      })),
      superCampaign: superCategory.data.superCampaignNames.map((item) => ({
        value: item.superCampaignName,
        label: item.superCampaignName,
      })),
    });
  };

  return (
    <div className='browse-category-section'>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          gap: '1em',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Select
          options={filter.superCampaign}
          value={filterValue.superCampaign}
          placeholder='Browse By Super Campaign'
          className='reactSelect'
          onChange={(e) => {
            setFilterValue({
              ...filterValue,
              superCampaign: e,
            });
          }}
        />
        <Select
          options={filter.impactCategory}
          value={filterValue.impactCategory}
          placeholder='Browse by Impact Category'
          className='reactSelect'
          onChange={(e) => {
            setFilterValue({
              ...filterValue,
              impactCategory: e,
            });
          }}
        />
        <Select
          options={filter.industry}
          value={filterValue.industry}
          placeholder='Browse by Industry'
          className='reactSelect'
          onChange={(e) => {
            setFilterValue({
              ...filterValue,
              industry: e,
            });
          }}
        />
        <BiFilterAlt
          onClick={() => {
            setFilterValue({
              superCampaign: '',
              campaign: '',
              impactCategory: '',
              industry: '',
            });
          }}
          className='filter-icon'
        />
      </div>
      <div className='browse-cards'>
        <Grid container spacing={3}>
          {campaign.map(({ overview, _id }) => (
            <Grid item xs={12} md={6} lg={3}>
              <div
                style={{
                  height: '100%',
                }}
                onClick={() => {
                  navigate(`/campaign-details/${_id}`);
                }}
                className='browse-card-container'
              >
                <img
                  style={{
                    width: '100%',
                    height: '250px',
                    objectFit: 'contain',
                  }}
                  src={`${process.env.REACT_APP_DOMAIN}${overview?.bannerImageUrl[0]}`}
                  alt=''
                />
                <SubHeading>{overview.campaignName}</SubHeading>
                <div className='browse-card-content'>
                  <span>Description</span>
                  <p className='globalDescription'>{overview.description}</p>
                  {/* <span>Ending In</span>
                  <p>08 h : 11 m : 22 s</p> */}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default BrowseSuperCategory;
