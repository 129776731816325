import styled from 'styled-components';

export const InfluencerInfo = styled.div`
  background: ${(props) => `url(${props.imgUrl})`};
  border-radius: 31px 31px 0px 0px;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(2, 48, 71, 0.5);
    border-radius: 31px 31px 0px 0px;
  }
  & > div {
    text-align: center;
    position: relative;
    z-index: 50;
  }
  & > div > img {
    width: 40%;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  & > div > h4 {
    color: #fff;
    padding-bottom: 20px;
    margin: 0;
  }
`;

export const InfluencerProffesion = styled.div`
  background: #f8991d;
  padding: 8px 15px;
  & > h4 {
    text-transform: capitalize;
    color: #fff;
    margin: 0;
    text-align: left;
  }
  & > h6 {
    color: #023047;
    margin: 0;
    font-weight: 600;
    margin-top: 2px;
  }
`;

export const CampaignContainer = styled.div`
  background: #ffffff;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 14px 14px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  & > p {
    max-height: 120px;
    letter-spacing: 0.2px;
    color: #023047;
    opacity: 0.75;
    font-weight: 600;
    font-size: 18px;
  }
  & > h6 {
    color: #023047;
    margin: 0;
    font-weight: 600;
    margin-top: 2px;
  }
  & > div > button {
    margin-top: 25px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const CampaignContent = styled.div`
  background: #ffffff;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  margin-top: 20px;
  height: 100%;
  padding: 15px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & > div > h4 {
    text-transform: capitalize;
    color: #219ebc;
    margin: 0;
  }
  & > div > div > svg {
    background: #d9d9d9;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 24px;
    padding: 6px;
    border-radius: 50%;
  }
  & > div {
    margin-top: 20px;
  }
  & > h5,
  & > div > h5 {
    color: #023047;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 3px;
  }
  & > h5 > span,
  & > div > h5 > span {
    color: #219ebc;
  }
  & > div:nth-child(4) {
    display: block;
  }
  & > div:nth-child(4) p {
    letter-spacing: 0.2px;
    color: #023047;
    opacity: 0.75;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
