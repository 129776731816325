import { Grid } from '@mui/material';
import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import { Heading } from '../../../global/styles/style-component';
import InfluencerAbout from '../../influencerDetails/components/InfluencerAbout';

const BrandsDetailsBanner = ({ brand }) => {
  return (
    <>
      <PagerBanner
        imgUrl={`${process.env.REACT_APP_DOMAIN}${brand?.coverUrl}`}
        style={{ padding: '150px 0' }}
      >
        <div
          style={{
            position: 'relative',
            zIndex: '10',
            width: '30%',
          }}
          className='influencer-detail-intro'
        >
          <div className='details-intro-container'>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <img
                  style={{
                    width: '150px',
                    height: '150px',
                    margin: 0,
                  }}
                  src={`${process.env.REACT_APP_DOMAIN}${brand?.logoUrl}`}
                  alt=''
                  className='company-logo'
                />
              </Grid>
              <Grid
                display='flex'
                flexDirection={'column'}
                gap={'1em'}
                item
                xs={12}
                md={7}
                className='details-intro2'
              >
                <Heading
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  {brand?.name}
                </Heading>
                <span>Category: {brand?.category}</span>
                <span>Country: {brand?.country}</span>
                <span>City: {brand?.city}</span>
                <span>Registration Number: {brand?.registrationNum}</span>
                <span>
                  Country of registration: {brand?.countryOfRegistration}
                </span>
              </Grid>
            </Grid>
          </div>
        </div>
      </PagerBanner>

      <InfluencerAbout title={'About'} detail={brand?.about} />
    </>
  );
};

export default BrandsDetailsBanner;
