import React, { useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import keyIcon from '../../assets/arrow-icon 1.png';
import logo from '../../assets/loginInWhite.png';
import { Input } from '../../global/components/Custom';
import '../../global/styles/LoginConfirmation.css';
import { recoverPasswordUsingEmail } from '../../services/verification';
import ApiCall from '../../utils/axiosCall';
import { errorToast, successToast } from '../../utils/toast';
import { validateEmail, validateName } from '../../utils/validation';
import FormLeftSide from '../../global/components/FormLeftSide';
import { CommonBtn } from '../../global/styles/style-component';

const initState = {
  name: {
    value: '',
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  subject: {
    value: '',
    error: '',
  },
  message: {
    value: '',
    error: '',
  },
  optIn: {
    value: '',
    error: '',
  },
};

const ContactUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [contact, setContact] = useState(initState);

  return (
    <div className='loginContainer'>
      <FormLeftSide
        bgColor={'linear-gradient(135.83deg, #191654 -84.22%, #219EBC 98.97%)'}
        children={<span></span>}
      />

      <div className='loginRight'>
        <div>
          <h2>
            Contact <span style={{ color: '#fb8501' }}>Us</span>
          </h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              ApiCall.post(`app/contact-us`, {
                name: contact.name.value,
                email: contact.email.value,
                subject: contact.subject.value,
                message: contact.message.value,
                optIn: contact.optIn.value,
              })
                .then((res) => {
                  successToast(res.data.message);
                  setContact(initState);
                })
                .catch((err) => {
                  errorToast(err?.response?.data?.message);
                });
            }}
          >
            <Input
              type='name'
              id='name'
              label={'Name'}
              placeholder='name'
              inputValue={contact.name}
              onChange={(e) =>
                setContact({
                  ...contact,
                  name: {
                    value: e.target.value,
                    error: validateName(e.target.value)
                      ? ''
                      : 'Name is required',
                  },
                })
              }
            />
            <Input
              type='email'
              id='email'
              label={'Email'}
              placeholder='Email'
              inputValue={contact.email}
              onChange={(e) =>
                setContact({
                  ...contact,
                  email: {
                    value: e.target.value,
                    error: validateEmail(e.target.value)
                      ? ''
                      : 'Invalid email format',
                  },
                })
              }
            />
            <Input
              type='subject'
              id='subject'
              label={'Subject'}
              placeholder='subject'
              inputValue={contact.subject}
              onChange={(e) =>
                setContact({
                  ...contact,
                  subject: {
                    value: e.target.value,
                    error:
                      e.target.value !== '' ? '' : 'This field cant be empty',
                  },
                })
              }
            />
            <Input
              type='message'
              id='message'
              label={'Message'}
              placeholder='message'
              inputValue={contact.message}
              onChange={(e) =>
                setContact({
                  ...contact,
                  message: {
                    value: e.target.value,
                    error:
                      e.target.value !== '' ? '' : 'This field cant be empty',
                  },
                })
              }
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <input
                type='checkbox'
                id='optIn'
                defaultChecked
                label={'OptIn'}
                placeholder='optIn'
                value={contact.optIn}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    optIn: {
                      value: e.target.value,
                      error:
                        e.target.value !== '' ? '' : 'This field cant be empty',
                    },
                  })
                }
              />
              <label
                style={{
                  fontWeight: '500',
                }}
                htmlFor='optIn'
              >
                Click to opt out of our newsletter and updates
              </label>
            </div>

            <CommonBtn
              style={{
                width: '100%',
              }}
              disabled={!Object.values(contact).every((i) => i.value)}
              isDisabled={
                !Object.values(contact).every((i) => i.value && !i.error)
              }
            >
              Submit
            </CommonBtn>
          </form>
          <div onClick={() => navigate('/')} className='back-to-login'>
            <BiLeftArrowAlt className='back-arrow' />
            <span>Back to Home</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
