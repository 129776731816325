import React from "react";
import Container from "../Container";
import BrowseCampaignBanner from "./components/BrowseCampaignBanner";
import BrowseCategoryIndustry from "./components/BrowseCategoryIndustry";
import "./NewBrowseCampaign.css";

const NewBrowseCampaign = () => {
  return (
    <>
      <Container>
        <BrowseCampaignBanner />
        <BrowseCategoryIndustry />
      </Container>
    </>
  );
};

export default NewBrowseCampaign;
