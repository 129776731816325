import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import { BannerHeading } from '../../../global/styles/style-component';

const DoneForYouServicesBanner = () => {
  return (
    <PagerBanner
      imgUrl={require('../../../assets/nft-details/nft-details-banner.png')}
      className='landing-page-banner'
    >
      <BannerHeading>DONE FOR YOU SERVICES</BannerHeading>
    </PagerBanner>
  );
};

export default DoneForYouServicesBanner;
