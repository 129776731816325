import React from "react";
import "../../pages/shortHistoryOfInternet/ShortHistoryOfInternet.css";
import Container from "../Container";
import Web3ToolsBanner from "./components/Web3ToolsBanner";
import Web3Contents from "./components/Web3Contents";

const web3Tools = () => {
  return (
    <Container>
        <Web3ToolsBanner/>
        <Web3Contents/>
    </Container>
  );
};

export default web3Tools;
