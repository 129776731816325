import React from 'react';
import { Heading, SubHeading } from '../../../global/styles/style-component';
import chekIcon from '../../../assets/done-for-you-partTwo/check-symbol.png'

const DevelopersPlan = () => {
    return (
        <div className='developer-plan-section'>
      <div
        style={{
          position: "absolute",
          bottom: 60,
          left: 0,
          background: "#209AB7",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          background: "#F8AA52",
          top: 145,
          left: 70,
          width: "200px",
          height: "200px",
          borderRadius: "50%",
        }}
      ></div>
      {/* content */}
      <div
        style={{
          background: "#fff9",
          backdropFilter: "blur(40px)",
          padding: "40px",
          position: "relative",
        }}
      >
            <div className="developer-plan-container">
                <div className="developer-pricing-card developer-border">
                <div>
                    <SubHeading>DEVELOPERS</SubHeading>
                    <Heading>$4,900*</Heading>
                    <span>per month</span>
                </div>

                <div>
                    <div>
                    2 DEVELOPERS
                    </div>
                    <div>
                    160 HOURS PER MONTH
                    </div>
                </div>

                <div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Designated developers</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Realtime slack collaboration</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Complete code ownership</p>
                    </div>
                </div>
            </div>

            <div className="developer-pricing-card developer-pro-border">
                <div>
                    <SubHeading>DEVELOPERS PRO</SubHeading>
                    <Heading>$9,900*</Heading>
                    <span>per month</span>
                </div>

                <div>
                    <div>
                    2 DEVELOPERS
                    </div>
                    <div>
                    UNLIMITED HOURS
                    </div>
                </div>

                <div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Designated developers</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Realtime slack collaboration</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Complete code ownership</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Timezone availability</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Strategy session</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Onboarding CTO</p>
                    </div>
                </div>
            </div>

            <div className="developer-pricing-card developer-premium-border">
                <div>
                    <SubHeading>DEVELOPERS PREMIUM</SubHeading>
                    <Heading>$19,500*</Heading>
                    <span>per month</span>
                </div>

                <div>
                    <div>
                    3 DEVELOPERS
                    </div>
                    <div>
                    UNLIMITED HOURS
                    </div>
                    <div>
                    1 BUSINESS ANALYST
                    </div>
                    <div>
                    1 TESTER
                    </div>
                    <div>
                    1 SCRUM MASTER
                    </div>
                </div>

                <div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Designated developers</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Realtime slack collaboration</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Complete code ownership</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Timezone availability</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Strategy session</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Onboarding CTO</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Innovation roadmap</p>
                    </div>
                    <div>
                    <img src={chekIcon} alt="" />
                    <p>Marketing roadmap</p>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    );
};

export default DevelopersPlan;