import React from 'react';
import { GiCheckMark } from 'react-icons/gi';
import { HalfColoredContainer } from '../../../global/styles/style-component';
const Benefits = () => {
  return (
    <HalfColoredContainer
      bgColor={'#fff'}
      color={'#219EBD'}
      className='benefits-influence-section'
    >
      <div className='benefits-influence-content'>
        <h2>BENEFITS FOR INFLUENCERS</h2>
        <ul>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Brand pages to
            showcase your campaigns and ramp up engagement.
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Minting tools for
            NFTs and tokenization to build out innovative campaigns.
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Metrics for
            reporting campaign performance and impact.
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Pre-vetted NPOs and
            impact initiatives…..
          </li>
          <li>
            <GiCheckMark style={{ marginRight: '15px' }} /> Artists and creators
            for artistic……..
          </li>
        </ul>
      </div>
    </HalfColoredContainer>
  );
};

export default Benefits;
