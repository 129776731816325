import React from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import { Button } from '@mui/material';

const PhoneEdit = ({ path, setOpenEditor }) => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
      <Button onClick={() => setOpenEditor(false)} variant='contained'>
        Close
      </Button>
      <ImageEditor
        includeUI={{
          loadImage: {
            path: path,
            name: 'SampleImage',
          },
          menu: ['shape', 'filter'],
          initMenu: 'filter',
          uiSize: {
            width: '80%',
            height: '90%',
          },
          menuBarPosition: 'bottom',
        }}
        cssMaxHeight={500}
        cssMaxWidth={700}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
        usageStatistics={true}
      />
    </div>
  );
};

export default PhoneEdit;
