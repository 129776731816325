import { Collections } from '@mui/icons-material';
import { AiFillPicture } from 'react-icons/ai';
import { MdSpaceDashboard, MdStore } from 'react-icons/md';
import { TbBrandGoogleAnalytics } from 'react-icons/tb';
import Brand from '../pages/dashboard/pages/brand';
import Portfolio from '../pages/dashboard/pages/portfolio';
import Causes from '../pages/dashboard/pages/causes';
import Dashboard from '../pages/dashboard/pages/dashboard/Dashboard';
import Influencer from '../pages/dashboard/pages/influencer';
import MyCampaign from '../pages/dashboard/pages/myCampaign';
import MyRequest from '../pages/dashboard/pages/myRequest';
import NFTMinting from '../pages/dashboard/pages/nftMinting/NFTMinting';
import VettingDashboard from '../pages/dashboard/pages/vettingDashboard/VettingDashboard';
const dashboard = [
  {
    role: 'GENERAL',
    routes: [
      {
        title: 'dashboard',
        icon: <MdSpaceDashboard />,
        route: '/dashboard',
        Component: <Dashboard />,
      },
      {
        title: 'Create NFT',
        icon: <AiFillPicture />,
        route: '/dashboard/create-nft',
        Component: <NFTMinting />,
      },
      {
        title: 'Collections',
        icon: <Collections />,
        route: '/dashboard/collections',
        Component: <Portfolio />,
      },
      {
        title: 'brands',
        icon: <TbBrandGoogleAnalytics />,
        route: '/dashboard/brands',
        Component: <Brand />,
      },
      {
        title: 'causes',
        icon: <MdStore />,
        route: '/dashboard/cause',
        Component: <Causes />,
      },
      {
        title: 'influencer',
        icon: <MdStore />,
        route: '/dashboard/influencer',
        Component: <Influencer />,
      },
      {
        title: 'Approvals Status',
        icon: <MdStore />,
        route: '/dashboard/my-request',
        Component: <MyRequest />,
      },
    ],
  },
  {
    role: 'BRAND CAUSE INFLUENCER',
    routes: [
      {
        title: 'dashboard',
        icon: <MdSpaceDashboard />,
        route: '/dashboard',
        Component: <Dashboard />,
        role: ['userRole'],
      },
      {
        title: 'Create NFT',
        icon: <AiFillPicture />,
        route: '/dashboard/create-nft',
        Component: <NFTMinting />,
      },
      {
        title: 'Collections',
        icon: <Collections />,
        route: '/dashboard/collections',
        Component: <Portfolio />,
      },
      {
        title: 'influencer',
        icon: <MdStore />,
        route: '/dashboard/influencer',
        Component: <Influencer />,
      },
      {
        title: 'Launch a campaign',
        icon: <MdStore />,
        route: '/dashboard/campaign',
        Component: <VettingDashboard />,
      },
      {
        title: 'my campaigns',
        icon: <MdStore />,
        route: '/dashboard/my-campaign',
        Component: <MyCampaign />,
      },
      {
        title: 'Approvals Status',
        icon: <MdStore />,
        route: '/dashboard/my-request',
        Component: <MyRequest />,
      },
    ],
  },
];

export default dashboard;
