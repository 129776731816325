import React from "react";
import { Heading } from "../../../global/styles/style-component";

const Requirments = () => {
  return (
    <div className="requirments-section">
      <div className="requirments-content">
        <Heading>REQUIREMENTS</Heading>
        <ul>
          <li>Validated idea or ready-made no-code MVP</li>
          <li>
            6-month draft roadmap and long-term vision.{" "}
            <span>
              <a href="" style={{ color: "rgb(251, 209, 0)" }}>
                Read more
              </a>
            </span>{" "}
          </li>
          <li>
            Willingness to establish a C-corp in Delaware, US.{" "}
            <span>
              <a href="" style={{ color: "rgb(251, 209, 0)" }}>
                Why?
              </a>
            </span>{" "}
          </li>
          <li>
            Commitment to meet our requirements during the program. Read more
          </li>
          <li>
            Commitment to meet our requirements during the program.{" "}
            <span>
              <a href="" style={{ color: "rgb(251, 209, 0)" }}>
                Read more
              </a>
            </span>{" "}
          </li>
          <li>
            <span style={{ color: "rgb(251, 209, 0)" }}>"Build in public"</span>{" "}
            attitude
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Requirments;
