import styled from 'styled-components';

export const TabNumber = styled.h1`
  background-color: #e67336;
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 100%;
  @media (max-width: 1100px) {
    font-size: 1.7em;
  }
  @media (max-width: 885px) {
    font-size: 1.2em;
  }
  @media (max-width: 670px) {
    width: 40px;
    height: 40px;
  }
`;

export const Tab = styled.div`
  width: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  transition: all 0.5s;
  opacity: ${({ active }) => (active ? '1' : '0.5')};

  & > span {
    text-align: center;
    font-weight: bold;
    flex: 1;
    @media screen and (max-width: 1100px) {
      font-size: 1em;
    }
    @media screen and (max-width: 786px) {
      font-size: 0.8em;
    }
    @media screen and (max-width: 526px) {
      font-size: 0.7em;
    }
    @media screen and (max-width: 438px) {
      display: none;
    }
  }
`;

export const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ noOfColumn }) => noOfColumn}, 1fr);
  padding: 2em 4em;
  gap: 1em;
  font-size: 0.9em;
  overflow-y: auto;
  @media screen and (max-width: 570px) {
    grid-template-columns: 1fr;
    & > div:last-child {
      grid-column: 1;
    }
  }
  @media screen and (max-width: 438px) {
    padding: 2em;
  }
`;

export const FieldInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  & > div {
    display: flex;
    flex-direction: row;
    padding: 0;
    gap: 1em;
    font-size: 0.7em;
    & > div {
      flex: 1;
    }
    & > button {
      width: 40px;
      height: 40px;
      margin-top: 17px;
    }
  }
  @media screen and (max-width: 570px) {
    & > div {
      flex-direction: column;
    }
  }
`;
// ${({ icon }) => icon}
export const FieldItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-row: ${({ row }) => row};
  grid-column: ${({ col }) => col};

  & > .radioContainer {
    display: flex;
    gap: 1em;
    & > div {
      display: flex;
      gap: 0.5em;
    }
  }
  & > .checkboxlayout {
    display: block;
    margin-top: 12px;
  }
  & > .checkboxlayout > input {
    margin-top: 12px;
    margin-right: 10px;
  }
  & > .checkboxlayout > label {
    margin-right: 20px;
  }
  & input,
  & textarea,
  & select {
    border: 1px solid ${({ error }) => (error ? 'red' : '#aeaeae')};
    padding: 7px 14px;
    border-radius: 4px;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
  }
  & > .PhoneInput {
    border: 1px solid ${({ error }) => (error ? 'red' : '#aeaeae')};
    padding: 7px 14px;
    border-radius: 4px;
    & input {
      outline: none;
      border: none;
      padding: 0;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
  }
`;

export const UploadBtn = styled.div`
  width: 70px;
  height: 70px;
  background-color: #e673366e;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  border: 1px solid #e67336;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ isSet }) => (isSet ? 'cover' : '100px 100px')};
  padding: ${({ isSelected }) => (isSelected ? '0px' : '20px')};
  @media screen and (max-width: 1100px) {
    width: 60px;
    height: 60px;
  }
`;

export const UploadBttn = styled.button`
  width: 100px;
  height: 30px;
  background-color: #e673366e;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  border: none;
  color: #e67336;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  /* padding: ${({ isSelected }) => (isSelected ? '0px' : '20px')}; */
  /* @media screen and (max-width: 1100px) {
    width: 60px;
    height: 60px;
  } */
`;

export const MileStoneContainer = styled.div`
  border: 1px solid #aeaeae;
  padding: 10px 25px;
  border-radius: 4px;
  margin-top: 10px;

  & input[type='date'] {
    color: #aeaeae;
    margin-bottom: 12px;
  }
  & h6 {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 7px;
    margin: 0;
  }
  & h5 {
    color: #219ebc;
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 3px;
  }
  /* @media screen and (max-width: 1100px) {
    width: 60px;
    height: 60px;
  } */
`;

export const KpiContainer = styled.div`
  border: 1px solid #aeaeae;
  padding: 10px 25px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  & h6 {
    color: #aeaeae;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    font-size: 14px;
  }
  & p {
    color: #aeaeae;
  }
  /* @media screen and (max-width: 1100px) {
    width: 60px;
    height: 60px;
  } */
`;

export const Error = styled.span`
  color: red;
`;
