import React, { useState } from 'react';
import Container from '../Container';
import InfoHubBanner from './components/InfoHubBanner';
import InfoHubFilter from './components/InfoHubFilter';
import './InfoHubMain.css';
const InfoHubMain = () => {
  const [filter, setFilter] = useState('');
  return (
    <>
      <Container>
        <InfoHubBanner filter={filter} setFilter={setFilter} />
        <InfoHubFilter filter={filter} setFilter={setFilter} />
      </Container>
    </>
  );
};

export default InfoHubMain;
