import React from 'react';
import Container from '../Container';
import Banner from './components/Banner';
import Benefits from './components/Benefits';
import BrandDomination from './components/BrandDomination';
import HowWeWork from './components/HowWeWork';
import Impactoverce from './components/Impactoverce';
import LearningMore from './components/LearningMore';
import Opportunities from './components/Opportunities';
import './Influencer.css';

const Influencer = () => {
  return (
    <Container>
      <div className='infulencer-page-container'>
        <Banner />
        <BrandDomination />
        <Opportunities />
        <Impactoverce />
        <HowWeWork />
        <Benefits />
        {/* <LearningMore /> */}
      </div>
    </Container>
  );
};

export default Influencer;
