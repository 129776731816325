import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  popup: false,
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    togglePopup: (state, action) => {
      state.popup = !state.popup;
    },
    setPopup: (state, action) => {
      state.popup = action.payload;
    },
  },
});

export const { togglePopup, setPopup } = popupSlice.actions;

export default popupSlice.reducer;
