import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  HalfColoredContainer,
  Heading,
} from "../../../global/styles/style-component";
import SubmitReportForm from "./SubmitReportForm";

const SubmitReport = () => {
  const [individual, setIndividual] = useState(false);
  useEffect(() => {
    setIndividual(window.location.pathname === "/submit-report");
  }, []);

  return (
    <HalfColoredContainer
      height={"auto"}
      color={individual ? "#219ebc" : "transparent"}
      className="report-Container vettingContainer"
    >
      {individual && <img src={require("../../../assets/logo.png")} alt="" />}
      <Heading marginBottom="0">SUBMIT REPORT</Heading>
      <div className="submit-report-container">
        <div className="submit-report-contents">
          <div className="milestones-container submit-reports">
            <p>The following is what you included for your first milestone: </p>
            <div>
              <div className="milestone-number">
                <h6>Milestones Number 1</h6>
                <span>08/12/2022</span>
              </div>
              <h5>Milestone ID</h5>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here
              </p>

              <div className="kpi">
                <h5>KPI 1</h5>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
              <div className="kpi">
                <h5>KPI 1</h5>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
              <div className="kpi">
                <h5>KPI 1</h5>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
        </div>

        <SubmitReportForm />
      </div>
    </HalfColoredContainer>
  );
};

export default SubmitReport;
