import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import infohub1 from '../../../assets/info-hub-main/crypto-currency.png';
import infohub2 from '../../../assets/info-hub-main/nft.png';
import infohub3 from '../../../assets/info-hub-main/blockchain.png';
import infohub4 from '../../../assets/info-hub-main/nft2.png';
import infohub5 from '../../../assets/info-hub-main/nft3.png';
import infohub6 from '../../../assets/info-hub-main/short-history.png';

import { useNavigate } from 'react-router-dom';
import { SubHeading } from '../../../global/styles/style-component';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tab = [
  {
    type: '',
    link: '/crypto-wallet',
    title: 'Intro to Crypto Wallets',
    img: infohub1,
  },
  {
    type: '',
    link: '/nft-one-pager',
    title: 'WHAT IS AN NFT?',
    img: infohub2,
  },
  {
    type: '',
    link: '/blockchain-one-pager',
    title: 'Blockchain Basics',
    img: infohub3,
  },
  {
    type: '',
    link: '/intro-to-nft',
    title: 'Intro To NFTs',
    img: infohub4,
  },
  {
    type: '',
    link: '/nftSteps',
    title: 'NFT In 3 Simple Steps',
    img: infohub5,
  },
  {
    type: '',
    link: '/short-history-of-internet',
    title: 'Short History Of The Internet',
    img: infohub6,
  },
];

const InfoHubFilter = ({ filter, setFilter }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setValue(0);
  }, [filter]);

  return (
    <>
      <Box sx={{ width: '100%', position: 'relative', zIndex: '10' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          className='tabs-label'
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='All' {...a11yProps(0)} />
            <Tab label='Web3' {...a11yProps(1)} />
            <Tab label='Blockchain' {...a11yProps(2)} />
            <Tab label='NFTs' {...a11yProps(3)} />
            <Tab label='DAOs' {...a11yProps(4)} />
            <Tab label='Metaverse' {...a11yProps(5)} />
            <Tab label='Crypto' {...a11yProps(6)} />
            <Tab label='More' {...a11yProps(7)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className='filter-tabpanel'>
          <Grid container spacing={3}>
            {tab
              .filter((item) =>
                filter
                  ? item.title.toLowerCase().match(filter.toLowerCase())
                  : true
              )
              .map((item, index) => (
                <Grid xs={12} md={4} key={index}>
                  <div
                    className='single-infohub'
                    onClick={() => navigate(item.link)}
                  >
                    <img src={item.img} alt='' />
                    <SubHeading>{item.title}</SubHeading>
                  </div>
                </Grid>
              ))}
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1} className='filter-tabpanel'>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/crypto-wallet')}
              >
                <img src={infohub1} alt='' />
                <SubHeading>Intro to Crypto Wallets</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nft-one-pager')}
              >
                <img src={infohub2} alt='' />
                <SubHeading>What is an NFT?</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/blockchain-one-pager')}
              >
                <img src={infohub3} alt='' />
                <SubHeading>Blockchain Basics</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/intro-to-nft')}
              >
                <img src={infohub4} alt='' />
                <SubHeading>Intro to NFTs</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nftSteps')}
              >
                <img src={infohub5} alt='' />
                <SubHeading>NFT in 3 Simple Steps</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/short-history-of-internet')}
              >
                <img src={infohub6} alt='' />
                <SubHeading>Short History of the Internet</SubHeading>
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2} className='filter-tabpanel'>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/blockchain-one-pager')}
              >
                <img src={infohub3} alt='' />
                <SubHeading>Blockchain Basics</SubHeading>
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={3} className='filter-tabpanel'>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nft-one-pager')}
              >
                <img src={infohub2} alt='' />
                <SubHeading>What is an NFT?</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/intro-to-nft')}
              >
                <img src={infohub4} alt='' />
                <SubHeading>Intro to NFTs</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nftSteps')}
              >
                <img src={infohub5} alt='' />
                <SubHeading>NFT in 3 Simple Steps</SubHeading>
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={4} className='filter-tabpanel'>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/crypto-wallet')}
              >
                <img src={infohub1} alt='' />
                <SubHeading>Intro to Crypto Wallets</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nft-one-pager')}
              >
                <img src={infohub2} alt='' />
                <SubHeading>What is an NFT?</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/blockchain-one-pager')}
              >
                <img src={infohub3} alt='' />
                <SubHeading>Blockchain Basics</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/intro-to-nft')}
              >
                <img src={infohub4} alt='' />
                <SubHeading>Intro to NFTs</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nftSteps')}
              >
                <img src={infohub5} alt='' />
                <SubHeading>NFT in 3 Simple Steps</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/short-history-of-internet')}
              >
                <img src={infohub6} alt='' />
                <SubHeading>Short History of the Internet</SubHeading>
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={5} className='filter-tabpanel'>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/crypto-wallet')}
              >
                <img src={infohub1} alt='' />
                <SubHeading>Intro to Crypto Wallets</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nft-one-pager')}
              >
                <img src={infohub2} alt='' />
                <SubHeading>What is an NFT?</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/blockchain-one-pager')}
              >
                <img src={infohub3} alt='' />
                <SubHeading>Blockchain Basics</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/intro-to-nft')}
              >
                <img src={infohub4} alt='' />
                <SubHeading>Intro to NFTs</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nftSteps')}
              >
                <img src={infohub5} alt='' />
                <SubHeading>NFT in 3 Simple Steps</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/short-history-of-internet')}
              >
                <img src={infohub6} alt='' />
                <SubHeading>Short History of the Internet</SubHeading>
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={6} className='filter-tabpanel'>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/crypto-wallet')}
              >
                <img src={infohub1} alt='' />
                <SubHeading>Intro to Crypto Wallets</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nft-one-pager')}
              >
                <img src={infohub2} alt='' />
                <SubHeading>What is an NFT?</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/blockchain-one-pager')}
              >
                <img src={infohub3} alt='' />
                <SubHeading>Blockchain Basics</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/intro-to-nft')}
              >
                <img src={infohub4} alt='' />
                <SubHeading>Intro to NFTs</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/nftSteps')}
              >
                <img src={infohub5} alt='' />
                <SubHeading>NFT in 3 Simple Steps</SubHeading>
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/short-history-of-internet')}
              >
                <img src={infohub6} alt='' />
                <SubHeading>Short History of the Internet</SubHeading>
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={7} className='filter-tabpanel'>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/crypto-wallet')}
              >
                <img src={infohub1} alt='' />
                <SubHeading>Intro to Crypto Wallets</SubHeading>
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={8} className='filter-tabpanel'>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <div
                className='single-infohub'
                onClick={() => navigate('/crypto-wallet')}
              >
                <img src={infohub1} alt='' />
                <SubHeading>Intro to Crypto Wallets</SubHeading>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  );
};

export default InfoHubFilter;
