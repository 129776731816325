import React, { useState } from 'react';
import NftCampaigns from '../../landing/components/NftCampaigns';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Heading, MainContainer } from '../../../global/styles/style-component';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FeaturedCampaign = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/browse-campaign');
        break;
      case 1:
        navigate('/browse-brands');
        break;
      case 2:
        navigate('/browse-npos-and-causes');
        break;
      case 3:
        navigate('/browse-influencers');
        break;
      default:
    }
  };

  return (
    <MainContainer className='why-support-us-section nft-tabs-container'>
      <Box sx={{ width: '100%' }}>
        <Box className='nft-market-tabs nft-marketplace-tabs'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            sx={{
              p: 2,
              gap: '1em',
            }}
            centered
          >
            <Tab label='BROWSE CAMPAIGNS' {...a11yProps(0)} />
            <Tab label='BROWSE BRAND AND AGENCIES ' {...a11yProps(1)} />
            <Tab label='BROWSE NPOs AND CAUSES' {...a11yProps(2)} />
            <Tab label='BROWSE INFLUENCERS' {...a11yProps(3)} />
          </Tabs>
        </Box>
        {/* <TabPanel
          value={value}
          index={0}
          className='why-support-us-content nft-market-tabpanel'
        >
          <NftCampaigns />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          className='why-support-us-content nft-market-tabpanel'
        >
          <NftCampaigns />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          className='why-support-us-content nft-market-tabpanel'
        >
          <NftCampaigns />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          className='why-support-us-content nft-market-tabpanel'
        >
          <NftCampaigns />
        </TabPanel> */}

        <NftCampaigns />
      </Box>
    </MainContainer>
  );
};

export default FeaturedCampaign;
