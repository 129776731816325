import React from "react";
import "./ReportingPage.css";
import Container from "../Container";
import ReportingDetails from "./components/ReportingDetails";

const ReportingPage = () => {
  return (
    <Container>
      <ReportingDetails />
    </Container>
  );
};

export default ReportingPage;
