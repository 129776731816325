import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  isLoggedIn: false,
  accessToken: null,
  vettingForm: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => ({
      ...state,
      user: action.payload,
      isLoggedIn: true,
    }),
    logout: (state) => {
      state.user = {};
      state.isLoggedIn = false;
    },
    setAccessToken: (state, action) => ({
      ...state,
      accessToken: action.payload,
      isLoggedIn: true,
    }),
    setVettingForm: (state, action) => {
      return {
        ...state,
        vettingForm: action.payload,
      };
    },
    removeVettingFrom: (state, action) => ({
      ...state,
      vettingForm: {},
    }),
  },
});

export const {
  setUser,
  logout,
  setAccessToken,
  setVettingForm,
  removeVettingFrom,
} = userSlice.actions;

export default userSlice.reducer;
