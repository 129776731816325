import React from "react";
import "./NftHowToHub.css";

const NftMovement = () => {
  return (
    <div className="nft-movement-content">
      <h2>NFTS: THE BEGINNING OF A NEW MOVEMENT</h2>
      <p>
        We are witnessing the start of a new era. Eventually, all artwork sold
        and collected will have an NFT identifier, including tangible and
        virtual art. <br /> <br /> Artwork for NFTs does not have to be in the blocky or
        cartoonish “NFT” style of many of the first-gen digital editions. Any
        kind of art can have an NFT, including real 2D and 3D pieces, photos,
        video, and (of course) native-digital art created with graphic design
        tools.
      </p>
    </div>
  );
};

export default NftMovement;
