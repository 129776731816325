import React from 'react';

const MilestoneCard = ({
  description = '',
  heading = '',
  milestoneDate = '',
  milestoneKpis = [],
  index = 1,
}) => {
  return (
    <div className='milestoneCardContainer'>
      <div className='milestoneNumber'>
        <h5>Milestone number {index}</h5>
        <span>{new Date(milestoneDate).toDateString()}</span>
      </div>
      <h2 className='milestoneHeading'>{heading}</h2>
      <p>{description}</p>
      {milestoneKpis.map((item, index) => (
        <div className='kpiContainer'>
          <h2>KPI {index + 1}</h2>
          <p>{item}</p>
        </div>
      ))}
    </div>
  );
};

export default MilestoneCard;
