import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import { paymentVerification } from '../../services/verification';

const PaymentSuccess = () => {
  const [searchParms] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    paymentVerification(searchParms.get('session_id'), navigate);
  }, [navigate, searchParms]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      Payment Verification
      <br />
      <DotLoader color='#36d7b7' />
    </div>
  );
};

export default PaymentSuccess;
