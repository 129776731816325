import { Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Brands from './pages/brand/Brands';
import { BrowseCampaign } from './pages/browse';
import BrowseBrands from './pages/browseBrands/BrowseBrands';
import BrowseBrandsDetails from './pages/browseBrands/BrowseBrandsDetails';
import BrowseInfluencer from './pages/browseInfluencers/BrowseInfluencer';
import BrowseNposAndCauses from './pages/browseNposAndCauses/BrowseNposAndCauses';
import BrowseNposDetails from './pages/browseNposAndCauses/BrowseNposDetails';
import BrowseSubCampaign from './pages/browseSubCampaign/BrowseSubCampaign';
import BrowseSuperCampaign from './pages/browseSuperCampaign/BrowseSuperCampaign';
import ContactUs from './pages/contactUs';
import CreatorPage from './pages/creator/CreatorPage';
import CreatorPortfolio from './pages/creatorPortfolio/CreatorPortfolio';
import NftContributionRequest from './pages/dashboard/pages/nftContrbution';
import ListMintedNft from './pages/dashboard/pages/nftMinting/ListMintedNft';
import EditVetting from './pages/dashboard/pages/vettingDashboard/EditVetting';
import DashboardPage from './pages/dashboardPage/DashboardPage.jsx';
import DoneForYouServices from './pages/doneForYouServices/DoneForYouServices';
import Faqs from './pages/Faqs/Faqs';
import BrandAgencyForm from './pages/form/BrandAgencyForm';
import InfluencerForm from './pages/form/InfluencerForm';
import InfluencerRegistrationForm from './pages/form/InfluencerRegistrationForm';
import NonProfitCauses from './pages/form/NonProfitCauses';
import Influencer from './pages/Influencer/Influencer';
import InfluencerDetails from './pages/influencerDetails/InfluencerDetails';
import InfoHubMain from './pages/infoHubMain/InfoHubMain';
import Landing from './pages/landing/Landing';
import EnterVerificationCode from './pages/login/EnterVerificationCode copy';
import Login from './pages/login/Login';
import PasswordReset from './pages/login/PasswordReset';
import RecoverPassword from './pages/login/recoverPassword';
import ResetEmailPhoneVerification from './pages/login/ResetEmailPhoneVerification';
import ResetPasswordEmail from './pages/login/ResetPasswordEmail';
import ResetPasswordOtp from './pages/login/ResetPasswordOtp';
import SetNewPassword from './pages/login/SetNewPassword';
import NewBrowseCampaign from './pages/newbrowseCampaign/NewBrowseCampaign';
import NewCampaignDetails from './pages/newCampaignDetails/NewCampaignDetails';
import NewImpactStartup from './pages/newImpactStartup/NewImpactStartup';
import MarketplaceLandingPage from './pages/newMarketplaceLandingPage/MarketplaceLandingPage';
import NewNftDetails from './pages/newNftDetails/NewNftDetails';
import NewNftMarketlandingPage from './pages/newNftMarketlandingPage/NewNftMarketlandingPage';
import NftHowToHub from './pages/nftHowToHub/NftHowToHub';
import NFTSteps from './pages/nftHowToHub/NFTSteps';
import NftMarketPlace from './pages/nftMarketPlace';
import Npos from './pages/nPos/Npos';
import BlockChainPager from './pages/Pager/BlockChainPager';
import CryptoWallet from './pages/Pager/CryptoWallet';
import NftPager from './pages/Pager/NftPager';
import PaymentFailure from './pages/payment/PaymentFailure';
import PaymentSuccess from './pages/payment/PaymentSuccess';
import Popup from './pages/popup/Popup';
import PrivacyPolicy from './pages/privacyPolicy';
import EnterVerificationPhoneOtp from './pages/registration/EnterVerificationPhoneOtp';
import Registration from './pages/registration/Registration';
import VerifyEmail from './pages/registration/verifyEmail';
import SubmitReport from './pages/reportingPage/components/SubmitReport';
import ReportingGallery from './pages/reportingPage/ReportingGallery';
import ReportingPage from './pages/reportingPage/ReportingPage';
import Services from './pages/services/Services';
import ShortHistoryOfInternet from './pages/shortHistoryOfInternet/ShortHistoryOfInternet';
import TermsAndServices from './pages/termsAndServices';
import ToastMessages from './pages/toast/ToastMessages';
import Vetting from './pages/vetting/Vetting';
import Web3Tools from './pages/web3Tools/web3Tools';
import dashboard from './routes/dashboard.routes';
import AcceleratorStartup from './pages/AcceleratorStartup/AcceleratorStartup';
import NewDoneForYouServices from './pages/newDoneForYouServices/NewDoneForYouServices';
import DoneForYouPartTwo from './pages/doneForYouPartTwo/DoneForYouPartTwo';

function App() {
  const popup = useSelector((state) => state.popup.popup);
  const user = useSelector((state) => state.users.user);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/verify-email' element={<VerifyEmail />} />
        <Route path='/payment-success' element={<PaymentSuccess />} />
        <Route path='/Payment-failed' element={<PaymentFailure />} />
        <Route path='/recover-password' element={<RecoverPassword />} />
        <Route path='/reset-password-otp' element={<ResetPasswordOtp />} />
        <Route path='/reset-password-email' element={<ResetPasswordEmail />} />
        <Route path='/password-reset' element={<PasswordReset />} />
        <Route path='/set-new-password' element={<SetNewPassword />} />
        <Route
          path='/enter-verification-code'
          element={<EnterVerificationCode />}
        />
        <Route
          path='/phone/verification-code/:verificationToken'
          element={<EnterVerificationPhoneOtp />}
        />
        <Route
          path='/reset-credential-verification'
          element={<ResetEmailPhoneVerification />}
        />
        <Route exact path='/services' element={<Services />} />
        <Route path='/noProfitCauses' element={<NonProfitCauses />} />
        <Route path='/brand-agency-form' element={<BrandAgencyForm />} />
        <Route path='/influencer-form' element={<InfluencerForm />} />
        <Route path='/done-for-you' element={<DoneForYouServices />} />
        <Route path='/toast-messages' element={<ToastMessages />} />
        <Route path='/login' element={<Login />} />
        <Route path='/vetting' element={<Vetting />} />
        <Route path='/brand' element={<Brands />} />
        <Route path='/marketplace' element={<NewNftMarketlandingPage />} />
        <Route path='/browse-campaign' element={<NewBrowseCampaign />} />
        <Route path='/browse-brand-&-agencies' element={<BrowseCampaign />} />
        <Route path='/browse-npo-&-causes' element={<BrowseCampaign />} />
        <Route path='/browse-influencer' element={<BrowseCampaign />} />
        <Route path='/influencer' element={<Influencer />} />
        <Route path='/creator-portfolio/:id' element={<CreatorPortfolio />} />
        <Route path='/impact-startup' element={<NewImpactStartup />} />
        <Route path='/nft-how-to-hub' element={<InfoHubMain />} />
        <Route path='/nPos' element={<Npos />} />
        <Route path='/creators' element={<CreatorPage />} />
        <Route path='/nftSteps' element={<NFTSteps />} />
        <Route path='/intro-to-nft' element={<NftHowToHub />} />
        <Route path='/nft-one-pager' element={<NftPager />} />
        <Route path='/blockchain-one-pager' element={<BlockChainPager />} />
        <Route path='/crypto-wallet' element={<CryptoWallet />} />
        <Route path='/campaign-details/:id' element={<NewCampaignDetails />} />
        <Route path='/nft-details/:id' element={<NewNftDetails />} />
        <Route path='/dashboard/my-campaign/:id' element={<EditVetting />} />
        <Route path='/dashboard/profile' element={<DashboardPage />} />
        <Route
          path='/dashboard/collections/list/nft/:id'
          element={<ListMintedNft />}
        />
        <Route
          path='/dashboard/campaign/contribution/requests'
          element={<NftContributionRequest />}
        />
        {user?.data?.userRole &&
          dashboard
            .find((e) =>
              e.role
                ?.toLocaleLowerCase()
                .includes(user?.data?.userRole?.toLocaleLowerCase())
            )
            ?.routes.map(({ route, Component }, index) => (
              <Route key={index} path={route} element={Component} />
            ))}
        <Route path='/registration' element={<Registration />} />
        <Route path='/terms' element={<TermsAndServices />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/' element={<Landing />} />
        <Route path='/new-impact-startup' element={<NewImpactStartup />} />
        <Route path='/info-hub-main' element={<InfoHubMain />} />
        <Route path='/new-browse-campaign' element={<NewBrowseCampaign />} />
        <Route path='/new-campaign-details' element={<NewCampaignDetails />} />
        {/* <Route
          path='/browse-super-campaign'
          element={<SuperCampaignDetails />}
        /> */}
        <Route
          path='/browse-super-campaign'
          element={<BrowseSuperCampaign />}
        />
        <Route
          path='/browse-sub-campaign/:id'
          element={<BrowseSubCampaign />}
        />
        <Route path='/new-nft-details' element={<NewNftDetails />} />
        <Route
          path='/new-nft-market-lading-page'
          element={<NewNftMarketlandingPage />}
        />
        <Route path='/done-for-you-services' element={<DoneForYouServices />} />
        <Route path='/faq' element={<Faqs />} />
        <Route
          path='/short-history-of-internet'
          element={<ShortHistoryOfInternet />}
        />
        <Route path='/web3-tools' element={<Web3Tools />} />
        <Route path='/browse-influencers' element={<BrowseInfluencer />} />
        <Route
          path='/influencers-registration-form'
          element={<InfluencerRegistrationForm />}
        />
        <Route path='/browse-brands' element={<BrowseBrands />} />
        <Route path='/ReportingPage' element={<ReportingPage />} />
        <Route path='/ReportingGallery' element={<ReportingGallery />} />
        <Route
          path='/influencers-details/:id'
          element={<InfluencerDetails />}
        />
        <Route
          path='/browse-npos-and-causes'
          element={<BrowseNposAndCauses />}
        />
        <Route
          path='/npos-causes-details/:id'
          element={<BrowseNposDetails />}
        />
        <Route
          path='/browse-brands-details/:id'
          element={<BrowseBrandsDetails />}
        />
        <Route path='/reporting-details' element={<ReportingPage />} />
        <Route path='/reporting-gallery' element={<ReportingGallery />} />
        <Route path='/submit-report' element={<SubmitReport />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route
          path='/new-market-landing-page'
          element={<MarketplaceLandingPage />}
        />
        <Route path='nft-marketplace' element={<NftMarketPlace />} />
        {/* <Route path='*' element={<Navigate replace to='/' />} /> */}
        <Route path='/accelerator-startup' element={<AcceleratorStartup />} />
        <Route
          path='/new-done-for-you-services'
          element={<NewDoneForYouServices />}
        />
        <Route path='/done-for-you-part-two' element={<DoneForYouPartTwo />} />
        <Route path='*' element={<Navigate replace to='/login' />} />
      </Routes>
      <Modal open={popup} children={<Popup />} />
    </BrowserRouter>
  );
}

export default App;
