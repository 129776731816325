import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  CommonBtn,
  MainContainer,
  Heading,
} from '../../../global/styles/style-component';

import { getFeatureCampaign } from '../../../services/landing';
import { errorToast } from '../../../utils/toast';

import './NftCompaigns.css';
import innerImg from '../../../assets/inner-img.png';

const NftCampaigns = () => {
  const navigate = useNavigate();
  const [featureCampaign, setFeatureCampaign] = useState(null);
  // const [featureCampaignNFT, setFeatureCampaignNFT] = useState([]);
  useEffect(() => {
    getFeatureCampaign()
      .then((res) => {
        setFeatureCampaign(res.data.ActiveCampaigns[0]);
        // getAllNft(res.data.featuredCampaigns[0]?._id)
        //   .then((res) => {
        //     setFeatureCampaignNFT(res.data.impactAssets);
        //   })
        //   .catch((err) => {
        //     errorToast(err.response.data.message);
        //   });
      })
      .catch((err) => {
        errorToast(err.response?.data?.message);
      });
  }, []);
  return (
    <>
      <MainContainer className='container-padding'>
        <Box
          sx={{
            my: '80px',
            p: '3px',
            borderRadius: '40px',
            background: 'linear-gradient(129.59deg, #48bbbb, #66c48f70)',
            boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
            // mx: { xs: "20px", md: "40px" },
          }}
        >
          <Box
            sx={{
              p: { xs: '20px', md: '40px' },
              borderRadius: '38px',
              background: '#fff',
            }}
          >
            <Heading style={{marginBottom:'40px'}}>FEATURED CAMPAIGN</Heading>

            <Box
              sx={{
                display: { md: 'flex' },
                alignItems: 'center',
                gap: '16px',
                '&>div': { flex: 1 },
              }}
            >
              {/* left */}
              <div
                style={{
                  position: 'relative',
                  padding: '40px',
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    background: '#F8AA52',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                  }}
                ></div>

                <div
                  style={{
                    position: 'absolute',
                    bottom: -40,
                    left: -40,
                    background: '#209AB7',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                  }}
                ></div>
                <div
                  style={{
                    boxShadow: '0px 3px 34px rgba(0, 0, 0, 0.50)',
                    background: '#fff',
                    padding: '32px',
                    position: 'relative',
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_DOMAIN}${featureCampaign?.overview?.bannerImageUrl[0]}`}
                    alt=''
                    style={{
                      width: '100%',
                      height: '350px',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              </div>
              {/* right */}
              <div
                style={{
                  position: 'relative',
                  padding: '20px',
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 40,
                    left: 32,
                    background: '#209AB7',
                    width: '160px',
                    height: '160px',
                    borderRadius: '50%',
                  }}
                ></div>

                <div
                  style={{
                    position: 'absolute',
                    background: '#F8AA52',
                    bottom: 40,
                    right: 80,
                    width: '160px',
                    height: '160px',
                    borderRadius: '50%',
                  }}
                ></div>
                {/* content */}
                <div
                  style={{
                    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.20)',
                    borderRadius: '8px',
                    background: '#fff9',
                    backdropFilter: 'blur(32px)',
                    padding: '32px',
                    position: 'relative',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      borderLeft: '5px solid #F8AA52',
                      pl: 2,
                      color: '#219EBC',
                      fontSize: '24px',
                    }}
                  >
                    {featureCampaign?.overview?.campaignName}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#023047',
                      textAlign: 'justify',
                      my: 3,
                      minHeight: '150px',
                    }}
                  >
                    {featureCampaign?.overview?.description}
                  </Typography>
                  <CommonBtn
                    onClick={() => {
                      navigate(`/campaign-details/${featureCampaign._id}`);
                    }}
                  >
                    LEARN MORE
                  </CommonBtn>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default NftCampaigns;
