import React from 'react';
import { Heading } from '../../../global/styles/style-component';

const Apply = () => {
    return (
        <div className='apply-here-container'>
            <p>Interested in joining our 2023 cohort?</p>
            <Heading>APPLY HERE</Heading>
        </div>
    );
};

export default Apply;