import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ContentContainer from './components/content/ContentContainer';
import Sidebar from './components/sidebar/Sidebar';
import { Container } from './dashboardStyled';
import { getUserDetail } from '../../redux/user/actionCreator';

const DashboardContainer = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch]);
  return (
    <Container>
      <Sidebar />
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

export default DashboardContainer;
