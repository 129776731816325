import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';

const CampaignDetailBanner = ({ imgUrl, title }) => {
  return (
    <>
      <PagerBanner
        imgUrl={
          imgUrl ||
          require('../../../assets/nft-details/nft-details-banner.png')
        }
        className='capaign-details-banner'
      >
        <h5
          style={{
            filter: 'drop-shadow(2px 4px 6px black)',
          }}
        >
          {title}
        </h5>
      </PagerBanner>
    </>
  );
};

export default CampaignDetailBanner;
