import styled from 'styled-components';

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 1em 0;
  padding: 1em;
  gap: 1em;
`;

export const CommentDetailList = styled.div`
  min-height: 30vh;
  display: flex;
  flex-direction: column;
`;
export const CommentDetails = styled.div`
  border-bottom: 1px solid #dee2e6;
  padding: 1em 0;
`;

export const CommentInputContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1em;
`;

export const CommentInput = styled.input`
  flex: 1;
`;

export const CommentButton = styled.button`
  background-color: #fff;
  color: #7780a1;
  font-weight: 600;
`;
