import React from 'react';
import collection1 from '../../assets/images/Group 688.png';
import collection2 from '../../assets/images/Group 693.png';
import collection3 from '../../assets/images/Mask group.png';
import collection4 from '../../assets/images/collection.png';
import elips1 from '../../assets/images/Ellipse 29.png';
import elips2 from '../../assets/images/Ellipse 31.png';
import elips4 from '../../assets/images/Ellipse 4.png';
import elips5 from '../../assets/images/Ellipse 5.png';
import photo from '../../assets/images/Group 690.png';
import setting from '../../assets/images/Group 690(2).png';
import image from '../../assets/images/Group 690(1).png';

const NftCollection = () => {
  return (
    <div className='nft-collection-section'>
      <img className='elips1' src={elips1} alt='' />
      <img className='elips2' src={elips2} alt='' />

      <img className='elips3' src={elips1} alt='' />
      <img className='elips4' src={elips2} alt='' />

      <img className='elips5' src={elips4} alt='' />
      <img className='elips6' src={elips5} alt='' />

      <img className='elips7' src={elips4} alt='' />
      <img className='elips8' src={elips5} alt='' />

      <h2>
        3 EASY STEPS <br /> TO MAKE YOUR OWN <br />{' '}
        <span style={{ color: '#FFB703' }}>NFTs</span> COLLECTION
      </h2>
      <p>
        Here are some steps to think about to prepare your photo or other art,
        before you mint your NFT.
      </p>

      <div className='collection-content-wrap'>
        <div className='single-collection-content'>
          <div className='row collection-alignment'>
            <div className='col-md-6'>
              <div className='collection-content'>
                <div className='inner-collection-content'>
                  <img src={photo} alt='' />
                  <div>
                    <p>1. Choose or Take a Photo</p>
                    <span>
                      For PetDrops, most of you will be using photos of your
                      pet. We recommend you choose or take a photo with good
                      lighting and without clutter behind them. Here is a fun
                      video with tips on how to take good photos of your pets
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='collection-img'>
                <img src={collection1} alt='' />
              </div>
            </div>
          </div>
        </div>

        <div className='single-collection-content'>
          <div className='row collection-alignment'>
            <div className='col-md-6'>
              <div className='collection-img'>
                <img src={collection2} alt='' />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='collection-content'>
                <div className='inner-collection-content'>
                  <div>
                    <p>2. Basic Photo Editing</p>
                    <span>
                      You may choose to use the photo as-is or any basic photo
                      editor on your phone can improve lighting, change
                      saturation, brightness, crop, add filters and make other
                      changes.
                    </span>
                  </div>
                  <img src={image} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='single-collection-content'>
          <div className='row collection-alignment'>
            <div className='col-md-6'>
              <div className='collection-content'>
                <div className='inner-collection-content'>
                  <img src={setting} alt='' />
                  <div>
                    <p>3. Adding Special Effects</p>
                    <span>
                      As we said, you don’t have to turn your photo into a
                      cartoon image, unless you want to. If you do want to get
                      creative and add special effects, the choices are almost
                      endless. There are many apps that make it really simple to
                      add additional filters or special effects like
                      brushstrokes.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='collection-img'>
                <img src={collection3} alt='' />
              </div>
            </div>
          </div>
        </div>

        <div className='single-collection-content'>
          <div className='row collection-alignment'>
            <div className='col-md-6'>
              <div className='collection-img'>
                <img src={collection4} alt='' />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='collection-content'>
                <div className='inner-collection-content'>
                  <div>
                    <p>4. What about real artwork?</p>
                    <span>
                      If you are creating an NFT of a real painting or drawing,
                      take some pictures of the painting in good light. Later,
                      you will clarify in your listing if you are selling the
                      painting itself, only digital copies of it, or both.
                    </span>
                  </div>
                  <img src={image} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftCollection;
