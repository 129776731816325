import { loadStripe } from '@stripe/stripe-js';
import { removeVettingFrom } from '../redux/user/userReducer';
import ApiCall from '../utils/axiosCall';
import { errorToast, successToast } from '../utils/toast';

const getCategory = async (category) => {
  return await ApiCall.get(`app/categories?kind=${category}`).catch((err) => {
    errorToast(err?.response.data?.message);
  });
};

const getSuperCategory = async (category) => {
  return await ApiCall.get(`app/super-campaign-names`).catch((err) => {
    errorToast(err?.response.data?.message);
  });
};

const getImpactCategory = async (category) => {
  return await ApiCall.get(`app/active-projects?category=${category}`).catch(
    (err) => {
      errorToast(err?.response.data?.message);
      return {
        data: { impactInitiatives: [] },
      };
    }
  );
};

const createCampaign = (campaign, navigate) => (dispatch) => {
  ApiCall.post(`campaign/register`, campaign, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => {
      successToast(res.data?.message);
      dispatch(removeVettingFrom());
      navigate('/dashboard/my-campaign');
    })
    .catch((err) => {
      errorToast(err.response.data?.message);
    });
};

const updateCampaign = (campaign, id, navigate) => (dispatch) => {
  ApiCall.put(`campaign/update-campaign-request/${id}`, campaign)
    .then((res) => {
      successToast(res.data?.message);
      navigate('/dashboard/my-campaign');
    })
    .catch((err) => {
      errorToast(err.response.data?.message);
    });
};

const updateApproveCampaign = (campaign, id, navigate) => (dispatch) => {
  ApiCall.put(`campaign/campaign-update-request/${id}`, campaign)
    .then((res) => {
      successToast(res.data?.message);
      navigate('/dashboard/my-campaign');
    })
    .catch((err) => {
      errorToast(err.response.data?.message);
    });
};

const createNFT = (campaign, purpose, setData, navigate) => (dispatch) => {
  let link =
    purpose === '0'
      ? 'creator/create-to-contribute'
      : purpose === '1'
      ? 'creator/create-to-gift'
      : 'creator/create-for-portfolio';
  ApiCall.post(`${link}`, campaign, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => {
      successToast(res.data?.message);

      if (purpose === '1') {
        const stripePromise = loadStripe(
          'pk_test_51Ll8XBKBvYFYDRD4KK8fJYK6T7n0ItwT9boFJlvAeKJK8CxlCokg8E2qBPwWaT4WzoPRZuSsFQmMpl7UIIqMj73m00jFLPj7Wv'
        );
        stripePromise.then((stripe) => {
          stripe.redirectToCheckout({
            sessionId: res.data.sessionId,
          });
        });
      }

      setData({
        purpose: '0',
        nft: {
          value: null,
          error: '',
        },
        itemName: {
          value: '',
          error: '',
        },
        description: {
          value: '',
          error: '',
        },
        priceUsd: {
          value: '',
          error: '',
        },
        nftCategory: {
          value: '',
          error: '',
        },
        impactCategory: {
          value: '',
          error: '',
        },
        impactCampaign: {
          value: '',
          error: '',
        },
        percentageToImpact: {
          value: '',
          error: '',
        },
        giftRecipientAddress: {
          value: '',
          error: '',
        },
      });
      navigate('/dashboard/collections');
    })
    .catch((err) => {
      errorToast(err?.response?.data?.message);
    });
};

const getAllPendingCampaigns = async () => {
  return await ApiCall.get(`campaign/campaign-requests?status=PENDING`).catch(
    (err) => {
      errorToast(err.response.data?.message);
    }
  );
};

const getAllApproveCampaigns = async () => {
  return await ApiCall.get(`campaign/`).catch((err) => {
    errorToast(err.response.data?.message);
  });
};

const getAllRoleRequest = async () => {
  return await ApiCall.get(`/user/role-request`).catch((err) => {
    errorToast(err.response.data?.message);
  });
};

export {
  getCategory,
  createCampaign,
  getImpactCategory,
  createNFT,
  updateApproveCampaign,
  getAllPendingCampaigns,
  getAllApproveCampaigns,
  getAllRoleRequest,
  getSuperCategory,
  updateCampaign,
};
