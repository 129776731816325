import React from 'react';
import ApiCall from '../../../../../utils/axiosCall';
import { errorToast, successToast } from '../../../../../utils/toast';

const Card = ({ link, title, price, percentage, id, impactId, cb }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2em',
        background: '#fff',
        gap: '1em',
        borderRadius: '1em',
        boxShadow: '1px 1px 5px #ededed',
      }}
    >
      <img
        style={{
          height: '250px',
          width: '100%',
          objectFit: 'contain',
        }}
        src={`${process.env.REACT_APP_DOMAIN}${link}`}
        alt=''
      />
      <h2
        className='singleLineText'
        style={{
          fontSize: '20px',
          fontWeight: '700',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {title}
      </h2>
      <h5
        style={{
          fontSize: '16px',
        }}
      >
        Only for:{' '}
        <span
          style={{
            color: '#2db4d5',
            fontWeight: '700',
          }}
        >
          ${price}
        </span>{' '}
      </h5>
      <hr
        style={{
          width: '100%',
          background: '#000',
        }}
      />
      <h5
        style={{
          fontSize: '16px',
        }}
      >
        Contribution:{' '}
        <span
          style={{
            color: '#2db4d5',
            fontWeight: '700',
          }}
        >
          {percentage}%
        </span>{' '}
      </h5>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <button
          onClick={() => {
            ApiCall.post(
              `user/dashboard/accept-nft-contribution?impactId=${impactId}&assetId=${id}`
            )
              .then((res) => {
                successToast(res.data.message);
                cb();
              })
              .catch((err) => {
                errorToast(err.response.data.message);
              });
          }}
          style={{
            padding: '10px 20px',
            color: '#fff',
            textTransform: 'uppercase',
            background: '#fe9927',
            borderRadius: '7px',
            border: 'none',
          }}
        >
          Accept
        </button>
        <button
          onClick={() => {
            ApiCall.post(
              `user/dashboard/reject-nft-contribution?impactId=${impactId}&assetId=${id}`
            )
              .then((res) => {
                successToast(res.data.message);
                cb();
              })
              .catch((err) => {
                errorToast(err.response.data.message);
              });
          }}
          style={{
            padding: '10px 20px',
            color: '#000',
            textTransform: 'uppercase',
            background: '#ededed',
            borderRadius: '7px',
            border: 'none',
          }}
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default Card;
