import React from 'react';
import {
  GridContainer,
  GridItem,
  InputContainer,
  SelectContainer,
  TextAreaContainer,
} from '../../formStyle';
import { AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { BiHash, BiLink } from 'react-icons/bi';
import { GoMail } from 'react-icons/go';
import {
  freeRegex,
  validateEmail,
  validateSelectedFile,
  validateUrl,
} from '../../../../utils/validation';
import { formatPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import country from '../../../../utils/countryList.json';
import { useState } from 'react';
import { useEffect } from 'react';
import ApiCall from '../../../../utils/axiosCall';
import { UploadBtn } from '../../../../global/components/vetting/vetting-style';

const Form = ({ data, dataError, setData, setDataError, isActive = false }) => {
  const [categories, setCategories] = useState([]);
  const [state, setState] = useState({
    cover: '',
    logo: '',
  });
  useEffect(() => {
    ApiCall.get('app/categories?kind=BRAND').then((res) => {
      setCategories(res.data.categoriesList);
    });
  }, []);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            gap: '10px',
            height: '300px',
          }}
        >
          <label
            style={{
              width: '100%',
              height: '100%',
            }}
            htmlFor='cover'
          >
            <UploadBtn
              key={1221312231}
              style={{
                width: '100%',
                height: '100%',
                padding: '0px',
                objectFit: 'cover',
              }}
              isSet={typeof data?.coverUrl === 'string' || data.cover}
              isSelected={false}
              src={
                data.cover
                  ? state.cover
                  : typeof data.coverUrl === 'string'
                  ? `${process.env.REACT_APP_DOMAIN}${data.coverUrl}`
                  : require('../../../../assets/add.png')
              }
              alt=''
            />
          </label>
          <input
            accept='image/png, image/jpeg'
            type='file'
            id='cover'
            name='cover'
            hidden
            onChange={(e) => {
              validateSelectedFile(e.target.files[0], (file) => {
                setState({
                  ...state,
                  cover: URL.createObjectURL(file),
                });
                setData({
                  ...data,
                  cover: file,
                });
              });
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            gap: '10px',
            width: '100%',
            marginTop: '-60px',
          }}
        >
          <label htmlFor='logo'>
            <UploadBtn
              style={{
                padding: '0px',
                objectFit: 'contain',
                width: '120px',
                height: '120px',
              }}
              isSelected={false}
              src={
                typeof data.logoUrl === 'string'
                  ? `${process.env.REACT_APP_DOMAIN}${data.logoUrl}`
                  : data.logo
                  ? state.logo
                  : require('../../../../assets/add.png')
              }
              alt=''
            />
          </label>
          <input
            accept='image/png, image/jpeg'
            type='file'
            id='logo'
            name='logo'
            hidden
            onChange={(e) => {
              validateSelectedFile(e.target.files[0], (file) => {
                setState({
                  ...state,
                  logo: URL.createObjectURL(file),
                });
                setData({
                  ...data,
                  logo: file,
                });
              });
            }}
          />
        </div>
      </div>
      <GridContainer width={'100%'} columnCount='2'>
        <div>
          <GridItem className='single-input first'>
            <label htmlFor='name'>
              Brand/Organization Name <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.name !== ''}>
              <input
                disabled={isActive}
                value={data.name}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    name:
                      e.target.value === ''
                        ? 'Please provide the Brand/Organization name'
                        : '',
                  });
                }}
                id='name'
                type='text'
              />
              <AiOutlineUser className='input-icon' />
            </InputContainer>
            <span>{dataError.name}</span>
          </GridItem>
          <GridItem className='single-input two'>
            <label htmlFor='contactPerson'>
              Primary Contact Person <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.mainPersonName !== ''}>
              <input
                value={data.mainPersonName}
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    mainPersonName: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    mainPersonName:
                      e.target.value === ''
                        ? 'Please provide First and Last name'
                        : freeRegex(e.target.value)
                        ? ''
                        : 'No numbers allowed',
                  });
                }}
                id='contactPerson'
                type='text'
              />
              <AiOutlineUser className='input-icon' />
            </InputContainer>
            <span>{dataError.mainPersonName}</span>
          </GridItem>
          <GridItem className='single-input three'>
            <label htmlFor='phoneNumber'>
              Primary Phone number <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.mainPhoneNum !== ''}>
              <PhoneInput
                disabled={isActive}
                style={{ width: '100%' }}
                international
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    mainPhoneNum: e,
                  }));
                  setDataError({
                    ...dataError,
                    mainPhoneNum: !formatPhoneNumber(e)
                      ? 'Should only contain numbers'
                      : '',
                  });
                }}
                value={data.mainPhoneNum}
              />
              <AiOutlinePhone className='input-icon' />
            </InputContainer>
            <span>{dataError.mainPhoneNum}</span>
          </GridItem>
          <GridItem className='single-input four'>
            <label htmlFor='email'>
              Primary Email address (must be a professional, not personal email)
              <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.mainEmail !== ''}>
              <input
                value={data.mainEmail}
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    mainEmail: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    mainEmail:
                      e.target.value === ''
                        ? 'Please provide an Email address'
                        : validateEmail(e.target.value)
                        ? ''
                        : 'Invalid email format',
                  });
                }}
                id='email'
                type='email'
              />
              <GoMail className='input-icon' />
            </InputContainer>
            <span>{dataError.mainEmail}</span>
          </GridItem>
          <GridItem className='single-input five'>
            <label htmlFor=''>
              Secondary Contact Person <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.alternatePersonName !== ''}>
              <input
                value={data.alternatePersonName}
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    alternatePersonName: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    alternatePersonName:
                      e.target.value === ''
                        ? 'Please provide First and Last names'
                        : freeRegex(e.target.value)
                        ? ''
                        : 'Should only contain alphabet',
                  });
                }}
                type='text'
              />
              <AiOutlineUser className='input-icon' />
            </InputContainer>
            <span>{dataError.alternatePersonName}</span>
          </GridItem>
          <GridItem className='single-input six'>
            <label htmlFor=''>
              Secondary Phone number <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.alternatePhoneNum !== ''}>
              <PhoneInput
                disabled={isActive}
                style={{ width: '100%' }}
                international
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    alternatePhoneNum: e,
                  }));
                  setDataError({
                    ...dataError,
                    alternatePhoneNum:
                      e === ''
                        ? 'Please provide a Phone number'
                        : formatPhoneNumber(e)
                        ? ''
                        : 'Should only contain number',
                  });
                }}
                value={data.alternatePhoneNum}
              />
              <AiOutlinePhone className='input-icon' />
            </InputContainer>
            <span>{dataError.alternatePhoneNum}</span>
          </GridItem>
          <GridItem className='single-input seven'>
            <label htmlFor=''>
              Secondary Email address (must be a professional, not personal
              email) <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.alternateEmail !== ''}>
              <input
                value={data.alternateEmail}
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    alternateEmail: e.target.value,
                  }));
                  setDataError({
                    ...dataError,

                    alternateEmail:
                      e.target.value === ''
                        ? 'Please provide an Email address'
                        : validateEmail(e.target.value)
                        ? ''
                        : 'Invalid email format',
                  });
                }}
                type='email'
              />
              <GoMail className='input-icon' />
            </InputContainer>
            <span>{dataError.alternateEmail}</span>
          </GridItem>
          <GridItem className='single-input eight'>
            <label htmlFor=''>
              About the Brand/Organization ({data.about.length}/250)
              <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <TextAreaContainer
              disabled={isActive}
              value={data.about}
              error={dataError.about !== ''}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  about: e.target.value,
                }));
                setDataError({
                  ...dataError,

                  about:
                    e.target.value === ''
                      ? 'Please provide information on the brand/organization'
                      : e.target.value.length > 15
                      ? ''
                      : 'Please provide a minimum of 15 characters',
                });
              }}
              name=''
              id=''
              maxLength={250}
              cols='30'
              rows='20'
            />
            <span>{dataError.about}</span>
          </GridItem>
        </div>
        <div>
          <GridItem className='single-input nine'>
            <label htmlFor='cars'>
              Brand/Organization Country<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <SelectContainer
              disabled={isActive}
              value={data.country}
              error={dataError.country !== ''}
              name='cars'
              id='cars'
              form='carform'
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  country: e.target.value,
                }));
                setDataError({
                  ...dataError,
                  country:
                    e.target.value === '' ? 'Please provide country' : '',
                });
              }}
            >
              <option value=''>Choose country</option>
              {Object.keys(country).map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </SelectContainer>
            <span>{dataError.country}</span>
          </GridItem>
          <GridItem className='single-input ten'>
            <label htmlFor='cars'>
              Brand/Organization city
              <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <SelectContainer
              value={data.city}
              disabled={isActive}
              error={dataError.city !== ''}
              name='cars'
              id='cars'
              form='carform'
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  city: e.target.value,
                }));
                setDataError({
                  ...dataError,

                  city: e.target.value === '' ? 'Please provide city' : '',
                });
              }}
            >
              <option value=''>Choose city</option>
              {country[data.country] &&
                country[data.country].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </SelectContainer>
            <span>{dataError.city}</span>
          </GridItem>
          <GridItem className='single-input eleven'>
            <label htmlFor='cars'>
              Brand/Organization category <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <SelectContainer
              disabled={isActive}
              error={dataError.category !== ''}
              name='cars'
              id='cars'
              form='carform'
              value={data.category}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  category: e.target.value,
                }));
                setDataError({
                  ...dataError,

                  category:
                    e.target.value === '' ? 'Please provide category' : '',
                });
              }}
            >
              <option value=''>Choose category</option>
              {categories.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </SelectContainer>
            <span>{dataError.category}</span>
          </GridItem>
          <GridItem className='single-input twelve'>
            <label htmlFor='cars'>
              Brand/Organization website<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.websiteUrl !== ''}>
              <input
                value={data.websiteUrl}
                disabled={isActive}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    websiteUrl: e.target.value,
                  }));
                  setDataError({
                    ...dataError,

                    websiteUrl:
                      e.target.value === ''
                        ? 'Please provide website URL'
                        : validateUrl(e.target.value)
                        ? ''
                        : 'Invalid url',
                  });
                }}
                type='url'
              />
              <BiLink className='input-icon' />
            </InputContainer>
            <span>{dataError.websiteUrl}</span>
          </GridItem>
          <GridItem className='single-input thirteen'>
            <label htmlFor='cars'>Brand/Organization social media links</label>{' '}
            <br />
            <InputContainer error={dataError.socials !== ''}>
              <input
                disabled={isActive}
                value={data?.socials.toString()}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    socials: e.target.value.split(','),
                  }));
                  // setDataError({
                  //   ...dataError,

                  //   socials:
                  //     e.target.value === ''
                  //       ? 'Please provide socials'
                  //       : validateUrl(e.target.value)
                  //       ? ''
                  //       : 'Invalid url',
                  // });
                }}
                type='url'
              />
              <BiLink className='input-icon' />
            </InputContainer>
            <span>{dataError.socials}</span>
          </GridItem>
          <GridItem className='single-input fouteen'>
            <label htmlFor='cars'>
              How do you feel that demo will benefit you (
              {data.feelImpactoverse.length}/250)
              <b style={{ color: 'red' }}>*</b>
            </label>
            <br />
            <TextAreaContainer
              value={data.feelImpactoverse}
              disabled={isActive}
              error={dataError.feelImpactoverse !== ''}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  feelImpactoverse: e.target.value,
                }));
                setDataError({
                  ...dataError,
                  feelImpactoverse:
                    e.target.value === ''
                      ? 'Please provide the benefits you feel demo offers'
                      : e.target.value.length > 15
                      ? ''
                      : 'Please provide a minimum of 15 characters',
                });
              }}
              name=''
              id=''
              maxLength={250}
              cols='30'
              rows='30'
            />
            <span>{dataError.feelImpactoverse}</span>
          </GridItem>
          <GridItem>
            <label htmlFor=''>
              Brand / Organization Registration Number
              <b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <InputContainer error={dataError.registrationNum !== ''}>
              <input
                value={data.registrationNum}
                disabled={isActive}
                onChange={(e) => {
                  if (e.target.value.startsWith(0)) {
                    return;
                  }
                  setData((prev) => ({
                    ...prev,
                    registrationNum: e.target.value,
                  }));
                  setDataError({
                    ...dataError,
                    registrationNum:
                      e.target.value === ''
                        ? 'Please provide Brand/Organization registration number'
                        : e.target.value.length > 5
                        ? ''
                        : 'Please provide an valid registration number',
                  });
                }}
                type='number'
              />
              <BiHash className='input-icon' />
            </InputContainer>
            <span>{dataError.registrationNum}</span>
          </GridItem>
          <GridItem>
            <label htmlFor=''>
              Country of registration<b style={{ color: 'red' }}>*</b>
            </label>{' '}
            <br />
            <SelectContainer
              disabled={isActive}
              error={dataError.countryOfRegistration !== ''}
              value={data.countryOfRegistration}
              name='cars'
              id='cars'
              form='carform'
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  countryOfRegistration: e.target.value,
                }));
                setDataError({
                  ...dataError,
                  countryOfRegistration:
                    e.target.value === ''
                      ? 'Please provide country of registration'
                      : '',
                });
              }}
            >
              <option value=''>Select country</option>
              {Object.keys(country).map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </SelectContainer>
            <span>{dataError.countryOfRegistration}</span>
          </GridItem>
        </div>
      </GridContainer>
    </>
  );
};

export default Form;
