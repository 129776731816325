import React, { useState, useEffect } from 'react';
import DashboardContainer from '../../DashboardContainer';
import { Tab, Tabs } from '@blueprintjs/core';
import {
  getAllApproveCampaigns,
  getAllCampaigns,
  getAllPendingCampaigns,
} from '../../../../services/vetting';
import { Grid } from '@mui/material';
import Card from './components/Card';
import ApiCall from '../../../../utils/axiosCall';

const NftContributionRequest = () => {
  const [activeTabId, setActiveTabId] = useState(null);
  const [data, setData] = useState([]);
  const list = [
    { title: 'All', key: 'all' },
    { title: 'Portfolio', key: 'portfolio' },
    { title: 'Gifts Sent', key: 'giftedOut' },
    { title: 'Gifts Received', key: 'giftedIn' },
    { title: 'Purchased', key: 'purchased' },
    { title: 'Contributions', key: 'contributions' },
  ];
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const approve = await ApiCall('user/list/my-impact-initiatives');
    setActiveTabId(
      approve.data.impactInitiatives.length > 0
        ? approve.data.impactInitiatives[0]?.id
        : ''
    );
    setData(approve.data.impactInitiatives);
  };

  const [contribution, setContribution] = useState([]);
  useEffect(() => {
    if (activeTabId) {
      ApiCall.get(`user/list/contribution-requests?impactId=${activeTabId}`)
        .then((res) => {
          setContribution(res.data.ImpactAssets);
        })
        .catch((err) => {});
    }
  }, [activeTabId]);

  return (
    <DashboardContainer>
      <div
        style={{
          margin: '0 1em',
        }}
        className='nftContributionRequestContainer'
      >
        <h2 style={{}}>
          Contributed{' '}
          <span
            style={{
              color: '#FE9927',
            }}
          >
            NFTs
          </span>
        </h2>
        <Tabs
          onChange={(e) => {
            console.log(e);
            setActiveTabId(e);
          }}
          renderActiveTabPanelOnly
          selectedTabId={activeTabId}
          vertical={false}
          animate={true}
        >
          {data.map(({ id, name }, index) => (
            <Tab
              id={id}
              title={name}
              panel={
                <Grid marginTop={'2em'} container xs={12} spacing={'1em'}>
                  {contribution.map((item, index) => (
                    <Grid key={index} lg={3} md={4} sm={12}>
                      <Card
                        link={item?.imageUrl}
                        id={item?._id}
                        title={item.itemName}
                        percentage={item.percentageToImpact}
                        price={item.priceUsd}
                        impactId={activeTabId}
                        cb={() => {
                          ApiCall.get(
                            `user/list/contribution-requests?impactId=${activeTabId}`
                          )
                            .then((res) => {
                              setContribution(res.data.ImpactAssets);
                            })
                            .catch((err) => {});
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              }
            />
          ))}
        </Tabs>
      </div>
    </DashboardContainer>
  );
};

export default NftContributionRequest;
