import React from 'react';
import Vetting from '../../../vetting/Vetting';
import DashboardContainer from '../../DashboardContainer';

const VettingDashboard = () => {
  return (
    <DashboardContainer>
      <Vetting />
    </DashboardContainer>
  );
};

export default VettingDashboard;
