import styled from 'styled-components';

export const Btn = styled.button`
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  width: ${(props) => (props.width ? props.width : '250px')};
  font-weight: 600;
  margin-left: ${({ marginLeft }) => marginLeft || '5px'};
  margin-right: ${({ marginRight }) => marginRight || '5px'};
  cursor: pointer;
  background-color: ${(props) => props.color || '#FB8501'};
  border: none;
  border-radius: 5px;
  align-self: ${(props) => props.alignSelf};
  z-index: 1;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.textColor || '#fff'};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;

export const MainContainer = styled.div`
  padding: ${({ paddingVertical }) => paddingVertical || '0px'} 100px;
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  background-color: ${(props) => props.bgColor};
  min-height: ${(props) => props.height};
  width: ${(props) => props.width};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItem};
  position: ${({ position }) => position};
  z-index: ${({ zIndex }) => zIndex};
  transition: all 1s;
  @media (max-width: 1024px) {
    padding: ${({ paddingVertical }) => paddingVertical || '0px'} 70px;
  }
  @media (max-width: 960px) {
    padding: ${({ paddingVertical }) => paddingVertical || '0px'} 50px;
  }
  @media (max-width: 768px) {
    padding: ${({ paddingVertical }) => paddingVertical || '0px'} 20px;
  }
`;

export const Heading = styled.h2`
  color: #023047;
  font-size: 2.5em;
  align-self: center;
  font-weight: bold;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1em'};
  margin-top: ${({ marginTop }) => marginTop};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  text-transform: uppercase;
  @media (max-width: 1080px) {
    font-size: 2.3em;
  }
  @media (max-width: 768px) {
    font-size: 2em;
  }
  @media (max-width: 425px) {
    font-size: 1.5em;
  }
`;

export const BannerHeading = styled.h1`
  color: #fb8501;
  font-size: 3.2em;
  align-self: center;
  font-weight: bold;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1em'};
  margin-top: ${({ marginTop }) => marginTop};
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 1080px) {
    font-size: 3em;
  }
  @media (max-width: 768px) {
    font-size: 2.5em;
  }
  @media (max-width: 425px) {
    font-size: 2em;
  }
`;
export const SubHeading = styled.h4`
  color: ${({ color }) => color || '#023047'};
  font-size: 1.5em;
  align-self: center;
  font-weight: bold;
  align-self: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1em'};
  margin-top: ${({ marginTop }) => marginTop};
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 1080px) {
    font-size: 1.3em;
  }
  @media (max-width: 768px) {
    font-size: 1em;
  }
  @media (max-width: 425px) {
    font-size: 0.7em;
  }
`;
export const StatementHeading = styled.h3`
  color: #e67336;
  font-size: 2em;
  text-align: center;
  margin: 1em 1em 2em 1em;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const BoxShadow = styled.div`
  box-shadow: 20px 14px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  & > div > div > a {
    position: relative;
    z-index: 10;
  }
`;

export const CommonBtn = styled.button`
  color: ${({ isDisabled }) => (isDisabled ? 'darkgray' : 'white')};
  background-color: ${({ isDisabled }) => (isDisabled ? '#ededed' : '#fb8501')};

  border: none;
  padding: 0.5em 2em;
  border-radius: 5px;
  align-self: ${({ alignSelf }) => alignSelf || 'center'};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: fit-content;
  margin: ${({ margin }) => margin};
  text-transform: uppercase;
`;

export const HalfColoredContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor || '#f6f8fb'};
  position: relative;
  overflow: hidden;
  height: ${({ height }) => height || '100vh'};
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  &::after {
    content: '';
    background-color: ${({ color }) => color};
    position: absolute;
    left: -50%;
    right: -50%;
    top: 50%;
    height: 100%;
    transform: rotate(345deg);
  }
  &::before {
    content: '';
    position: absolute;
    background-image: url('../../assets/dotpattern.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    right: -2.5%;
    top: 10%;
    width: 150px;
    height: 150px;
    z-index: 1;
  }
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 75vh;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }
  & > h2 {
    position: relative;
    z-index: 10;
  }
`;
