import styled from 'styled-components';

export const BenefitContainer = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 80px;
  background: #219ebc;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 50px 20px;
  color: #ffffff;
  & > h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    width: 55%;
    margin: auto;
    margin-bottom: 30px;
  }
  & > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  & > div {
    display: flex;
    justify-content: space-between;

    & > ul {
      margin-top: 10px;
      & > li {
        list-style: none;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
        position: relative;
        margin-left: 20px;

        &:before {
          content: '•';
          content: '';
          position: absolute;
          background: #fce887;
          top: 10px;
          width: 10px;
          height: 10px;
          border-radius: 20px;
          left: -18px;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    & > h2 {
      font-size: 24px;
      line-height: 32px;
      width: 90%;
      margin-bottom: 15px;
    }
    & > p {
      font-size: 16px;
      line-height: 22px;
    }
    & > div {
      & > ul {
        padding-left: 0;
        & > li {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
`;

export const HowWeWork = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 80px;

  @media screen and (max-width: 992px) {
    padding: 0;
    height: auto;
    background-size: cover;
    & > h1 {
      font-size: 40px;
      line-height: 200px;
    }
  }
`;
