import React from "react";
import "./ToastMessages.css";
import { HiInformationCircle } from "react-icons/hi";
import { AiOutlineClose, AiFillCheckCircle } from "react-icons/ai";
import { MdError } from "react-icons/md";

const ToastMessages = () => {
  return (
    <>
      <div className="toast-message-box">
        <div className="box-border" style={{backgroundColor: '#007BC3'}}></div>
        <div className="toast-messages-container">
          <HiInformationCircle className="toast-icon" style={{color:"#007BC3"}}/>
          <div className="toast-message">
            <h6>Did you know?</h6>
            <span>Here is something that you might like to know.</span>
          </div>
          <AiOutlineClose className="close-icon"/>
        </div>
      </div>
      <div className="toast-message-box">
      <div className="box-border" style={{backgroundColor: '#EEA931'}}></div>
        <div className="toast-messages-container">
          <MdError className="toast-icon" style={{color:"#EEA931"}}/>
          <div className="toast-message">
            <h6>Uh oh, something went wrong</h6>
            <span>Sorry! There was a problem with your request.</span>
          </div>
          <AiOutlineClose className="close-icon"/>
        </div>
      </div>
      <div className="toast-message-box">
      <div className="box-border" style={{backgroundColor: '#1EA779'}}></div>
        <div className="toast-messages-container">
          <AiFillCheckCircle className="toast-icon" style={{color:"#1EA779"}}/>
          <div className="toast-message">
            <h6>Yay! Everything worked!</h6>
            <span>Congrats on the internet loading your request.</span>
          </div>
          <AiOutlineClose className="close-icon"/>
        </div>
      </div>
    </>
  );
};

export default ToastMessages;
