import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import pass from '../../../assets/new-nft-market-landing-page/pass.mp4';
import { CommonBtn } from '../../../global/styles/style-component';
const FeaturedInfluencer = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  return (
    <Box
      sx={{
        margin: { xs: '10px 20px', md: '100px ' },
        marginTop: '20px',
      }}
    >
      <Box
        sx={{
          p: { xs: '20px', md: '40px' },
          borderRadius: '38px',
          background: '#fff',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: { md: 'flex' },
            alignItems: 'center',
            gap: '40px',
            '&>div': { flex: 1 },
          }}
        >
          {/* lines */}

          {new Array(5).fill(null).map((el, i) => (
            <div
              key={i}
              style={{
                position: 'absolute',
                bottom: -50,
                left: `-${i * 20 + 320}px`,
                rotate: `-${(i + 1) * 10}deg`,
                width: '300px',
                height: '240px',
                borderRadius: '50px',
                border: `4px solid #${Math.floor(
                  100000 + Math.random() * 900000
                )}20`,
              }}
            />
          ))}

          <Box
            sx={{
              position: 'absolute',
              top: '80px',
              right: 0,
              display: 'grid',
              gridTemplateColumns: '10px 10px 10px',
              gap: '20px',
            }}
          >
            {new Array(15).fill(null).map((el, i) => (
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  border: `2px solid #${Math.floor(
                    100000 + Math.random() * 900000
                  )}80`,
                }}
              />
            ))}
          </Box>
          {/* left */}
          <div style={{ marginBlock: 40, position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: -20,
                bottom: -20,
                left: '10%',
                width: '80%',
                background: 'linear-gradient(129.59deg, #48bbbb, #66c48f70)',
                borderRadius: 20,
              }}
            />
            <img
              src={require('../../../assets/new-nft-market-landing-page/3EF8FEC8-0DD4-4D2D-A1AE-4FF92AC73E7F.gif')}
              alt=''
              style={{
                background: '#000',
                width: '100%',
                height: '50vh',
                objectFit: 'contain',
                borderRadius: '20px',
                position: 'relative',
              }}
              className='featured-influencer-video-gif'
            />
          </div>
          {/* right */}

          <div
            style={{
              position: 'relative',
              borderRadius: 40,
              background: '#fff',
              overflow: 'hidden',
              boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 40,
                left: 32,
                background: '#209AB7',
                width: '160px',
                height: '160px',
                borderRadius: '50%',
              }}
            ></div>

            <div
              style={{
                position: 'absolute',
                background: '#F8AA52',
                bottom: 40,
                right: 80,
                width: '160px',
                height: '160px',
                borderRadius: '50%',
              }}
            ></div>
            {/* content */}
            <div
              style={{
                background: '#219EBC90',
                backdropFilter: 'blur(32px)',
                padding: '50px',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: '#FFF',
                  fontSize: '24px',
                }}
              >
                Want to be our next featured influencer?
              </Typography>
              <Typography
                sx={{
                  color: '#023047',
                  textAlign: 'justify',
                  my: 3,
                }}
                className='typo-text-align'
              >
                Get closer to your fans, build community, support impact!
              </Typography>
              <CommonBtn
                onClick={() => {
                  if (isLoggedIn) {
                    navigate('/influencer-form');
                  } else {
                    navigate('/login');
                  }
                }}
              >
                GET STARTED
              </CommonBtn>
              <CommonBtn
                onClick={() => {
                  navigate('/info-hub-main');
                }}
                style={{ background: '#219EBC', marginLeft: 20 }}
              >
                LEARN MORE
              </CommonBtn>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedInfluencer;
