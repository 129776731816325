import React from 'react';
import { Heading, MainContainer } from '../../../global/styles/style-component';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import thinkOcean from '../../../assets/think-ocean.jpeg';
import saferways from '../../../assets/saferways.png';
import './OurPartners.css';

const OurPartners = () => {
  const images = [
    'https://thegirlsschool.org/wp-content/uploads/2017/03/GSA_Logo_opt2.png',
    saferways,
    thinkOcean,
    'https://petdropsnft.io/wp-content/uploads/2022/03/cropped-png-petdrops.png',
    'https://globalwomenforgood.com/wp-content/uploads/2021/04/rsz_woomen_logo_6_1.png',
    'https://aunuaacademy.files.wordpress.com/2020/12/cropped-stacked-logo-gradient-e1608549653756.png',
  ];
  return (
    <MainContainer bgColor="#d9d9d93a" paddingVertical="4em">
      <Heading>Our Partners</Heading>
      <Slide
        arrows={false}
        canSwipe={false}
        slidesToScroll={1}
        slidesToShow={4}
        responsive={[
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 300,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
        ]}
      >
        {images.map((img, index) => (
          <div key={index} className="companyIcon">
            <img src={img} alt="" style={{ objectFit: 'contain' }} />
          </div>
        ))}
      </Slide>
    </MainContainer>
  );
};

export default OurPartners;
