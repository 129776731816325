import React from 'react';
import welcome from '../../assets/images/1 23456679.png';
import { Heading, SubHeading } from '../../global/styles/style-component';

const WelcomeToHub = () => {
  return (
    <div className='welcome-to-hub-section'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='welcome-hub-left-sec'>
            {/* <h2>Welcome to our demo NFT How-To Hub!</h2>
            <p>
              <span style={{ color: '#F8991D' }}>Great news!</span> We are busy
              building the NFT platform and marketplace.
            </p> */}
            <SubHeading>
              {/* !<span style={{ color: '#F8991D' }}>Even more good news</span> -
              this gives you time to think about the most important part of
              creating NFTs for art - and that’s the art itself  */}
              Here is a little background if you are curious.
            </SubHeading>
          </div>
        </div>
        {/* <div className='col-md-4'>
          <div className='welcome-hub-right-sec'>
            <img src={welcome} alt='' />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default WelcomeToHub;
