import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { CommonBtn } from '../../../global/styles/style-component';
import { getNFT } from '../../../services/landing';
import { getCategory } from '../../../services/vetting';
import Select from 'react-select';

const FilterBar = ({ setData }) => {
  const [filter, setFilter] = useState({
    maxPrice: null,
    minPrice: null,
    maxPercentage: null,
    minPercentage: null,
    impactCategory: '',
    nftCategory: '',
  });

  const [categories, setCategories] = useState({
    impactCategory: [],
    industry: [],
  });

  const getFilter = async () => {
    const nftCategory = await getCategory('NFT');

    const campaignCategory = await getCategory('CAMPAIGN');

    setCategories({
      ...categories,
      impactCategory: campaignCategory.data.categoriesList.map((item) => ({
        value: item,
        label: item,
      })),
      industry: nftCategory.data.categoriesList.map((item) => ({
        value: item,
        label: item,
      })),
    });
  };

  useEffect(() => {
    getFilter();
  }, []);

  const fetchApi = () => {
    getNFT(
      10,
      0,
      filter.minPrice,
      filter.maxPrice,
      filter.minPercentage,
      filter.maxPercentage,
      filter.impactCategory?.label,
      filter.nftCategory?.label
    )
      .then((res) => {
        setData(res.data?.assetList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='nftMarketPlaceFilterBar'>
      <Grid container spacing={'1em'}>
        <Grid item xs={6}>
          <label htmlFor='minPrice'>MinPrice</label>
          <input
            value={filter.minPrice}
            onChange={(e) => {
              setFilter({
                ...filter,
                minPrice: e.target.value,
              });
            }}
            type='number'
            id='minPrice'
            placeholder='0'
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor='maxPrice'>MaxPrice</label>
          <input
            value={filter.maxPrice}
            onChange={(e) => {
              setFilter({
                ...filter,
                maxPrice: e.target.value,
              });
            }}
            type='number'
            id='maxPrice'
            placeholder='100'
          />
        </Grid>
      </Grid>
      <Grid container spacing={'1em'}>
        <Grid item xs={6}>
          <label htmlFor='minPercentage'>Min Percentage</label>
          <input
            value={filter.minPercentage}
            onChange={(e) => {
              setFilter({
                ...filter,
                minPercentage: e.target.value,
              });
            }}
            type='number'
            id='minPercentage'
            placeholder='0'
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor='maxPercentage'>Max Percentage</label>
          <input
            value={filter.maxPercentage}
            onChange={(e) => {
              setFilter({
                ...filter,
                maxPercentage: e.target.value,
              });
            }}
            type='number'
            id='maxPercentage'
            placeholder='100'
          />
        </Grid>
        <Grid item xs={12}>
          <label style={{ marginBottom: '10px' }}>Nft Category</label>
          <Select
            options={categories.industry}
            value={filter.nftCategory}
            placeholder='Browse by NFT Category'
            className='reactSelect'
            onChange={(e) => {
              if (filter.nftCategory.label === e.label) {
                setFilter({
                  ...filter,
                  nftCategory: null,
                });
              } else {
                setFilter({
                  ...filter,
                  nftCategory: e,
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <label style={{ marginBottom: '10px' }}>Impact Category</label>
          <Select
            options={categories.impactCategory}
            value={filter.impactCategory}
            placeholder='Browse by Impact Category'
            className='reactSelect'
            onChange={(e) => {
              setFilter({
                ...filter,
                impactCategory: e,
              });
            }}
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CommonBtn
          onClick={() => {
            setFilter({
              maxPrice: null,
              minPrice: null,
              maxPercentage: null,
              minPercentage: null,
              impactCategory: '',
              nftCategory: '',
            });
            getNFT(10, 0)
              .then((res) => {
                setData(res.data?.assetList);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          Clear
        </CommonBtn>
        <CommonBtn onClick={fetchApi}>Apply</CommonBtn>
      </div>
    </div>
  );
};

export default FilterBar;
