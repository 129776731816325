import React from 'react';
import { Heading, SubHeading } from '../../../global/styles/style-component';
import approachBg from '../../../assets/done-for-you-partTwo/approach-banner.png'

const DoneForYouApproach = () => {
    return (
        <div className='approach-container'>
            <div>
                <Heading>APPROACH</Heading>
                <img src={approachBg} alt="" />
            </div>

            <div>
                <div>
                <SubHeading>STEP 1</SubHeading>
                <p>We take care of end to end technology need when it comes to blockchain</p>
                </div>
                <div>
                <SubHeading>STEP 2</SubHeading>
                <p>We work with all levels of businesses, helping you grow and obtain a competitive advantage.</p>
                </div>
                <div>
                <SubHeading>STEP 3</SubHeading>
                <p>Provides continuous technological innovation for continued success</p>
                </div>
                <div>
                <SubHeading>STEP 4</SubHeading>
                <p>Helps you in scaling up your blockchain innovation with the right technical framework and user experience</p>
                </div>
                <div>
                <SubHeading>STEP 5</SubHeading>
                <p>Reduce delivery time & cost with our well-proven lean and agile development methods</p>
                </div>
            </div>
        </div>
    );
};

export default DoneForYouApproach;