import React from "react";
import "./NewDoneForYouServices.css";
import Container from "../Container";
import ServicesBanner from "./components/ServicesBanner";
import ServicesIntro from "./components/ServicesIntro";
import BookExploratoryCall from "./components/BookExploratoryCall";
import Advisors from "./components/Advisors";


const NewDoneForYouServices = () => {
  return (
    <Container>
        <ServicesBanner/>
        <ServicesIntro/>
        <BookExploratoryCall/>
        <Advisors/>
    </Container>
  );
};

export default NewDoneForYouServices;
