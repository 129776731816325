import styled from 'styled-components';

export const FormLeftSideContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  overflow: hidden;
  & > button {
    z-index: 1;
  }
  & > div {
    z-index: 1;
    width: 65%;
    display: flex;
    flex-direction: column;
    color: white;
    height: 80%;
    justify-content: space-around;

    & > div:first-child > span {
      text-align: center;
      font-weight: 500;
      font-size: 1em;
      margin-top: 1em;
    }

    & > div:first-child {
      display: flex;
      flex-direction: column;
    }

    & > div:last-child {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    & > div:last-child > img {
      width: 50px;
    }
  }
  &::before {
    content: '';
    background: ${({ bgColor }) =>
      bgColor || 'linear-gradient(135.83deg, #191654 -84.22%, #219EBC 98.97%)'};
    position: absolute;
    top: -100px;
    bottom: -100px;
    left: -100px;
    right: 5%;
    z-index: 0;
    transform: rotate(2deg);
  }
`;
