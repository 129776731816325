import React from 'react';
import {
  MainContainer,
  SubHeading,
} from '../../../global/styles/style-component';

const BrandDomination = () => {
  return (
    <MainContainer className='brand-domination-section'>
      <SubHeading>
        Here is the formula for futureproofing <br />
        brand domination of your sapce
      </SubHeading>
    </MainContainer>
  );
};

export default BrandDomination;
