import ApiCall from '../utils/axiosCall';
import { errorToast, successToast } from '../utils/toast';

const brandRegistration = (data, navigate) => (dispatch) => {
  ApiCall.post('/brand/register', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => {
      successToast(res.data.message);
      navigate('/dashboard/my-request');
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};
const resubmitImage = (type, id, data, cb) => {
  ApiCall.post(`/${type.toLowerCase()}/re-submit/update-images/${id}`, data)
    .then((res) => {
      successToast(res.data.message);
      cb();
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};
const brandResubmitRegistration = (id, data, cb) => {
  ApiCall.post(`/brand/re-submit/${id}`, data)
    .then((res) => {
      successToast(res.data.message);
      cb();
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};

const caseRegistration = (data, navigate) => (dispatch) => {
  ApiCall.post('/cause/register', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => {
      successToast(res.data.message);
      navigate('/dashboard/my-request');
    })
    .catch((err) => {
      errorToast(err?.response?.data.message);
    });
};

const caseResubmitRegistration = (id, data, cb) => {
  ApiCall.post(`/cause/re-submit/${id}`, data)
    .then((res) => {
      successToast(res.data.message);
      cb();
    })
    .catch((err) => {
      errorToast(err?.response?.data.message);
    });
};

const influencerSubmitInfo = (data, navigate) => (dispatch) => {
  ApiCall.post('/influencer/submit-info', data)
    .then((res) => {
      successToast(res.data.message);
    })
    .catch((err) => {
      errorToast(err?.response?.data.message);
    });
};

const influencerRegistration = (data, navigate) => (dispatch) => {
  ApiCall.post('/influencer/registration', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => {
      successToast(res.data.message);
      navigate('/dashboard');
    })
    .catch((err) => {
      errorToast(err?.response?.data.message);
    });
};

export {
  brandRegistration,
  brandResubmitRegistration,
  caseRegistration,
  caseResubmitRegistration,
  influencerSubmitInfo,
  influencerRegistration,
  resubmitImage,
};
