import { Remove } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { formatPhoneNumber } from 'react-phone-number-input';
import {
  validateEmail,
  validateName,
  validatePhone,
  validateUrl,
} from '../../../utils/validation';
import {
  Error,
  FieldContainer,
  FieldInnerContainer,
  FieldItem,
} from './vetting-style';

const Transparency = ({
  data = {
    transparency: {
      agreeForReporting: {
        value: '',
        error: '',
      },
      teamMembers: {
        value: [],
        error: '',
      },
      agreeToShareLessons: {
        value: '',
        error: '',
      },
    },
  },
  setData,
}) => {
  const [teamMember, setTeamMember] = useState({
    name: {
      value: '',
      error: '',
    },
    bioUrl: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
    phoneNum: {
      value: '',
      error: '',
    },
  });
  return (
    <FieldContainer noOfColumn={1}>
      <FieldInnerContainer>
        <span>
          List additional team members working on this campaign (Names, Links to
          bios, Emails, Phone numbers)
        </span>
        <div>
          <FieldItem icon={'\f406'}>
            <span>Name</span>
            <input
              onChange={(e) => {
                setTeamMember({
                  ...teamMember,
                  name: {
                    value: e.target.value,
                    error: !validateName(e.target.value)
                      ? 'Invalid Name Formate'
                      : '',
                  },
                });
              }}
              value={teamMember.name.value}
              type='text'
              name='name'
              id='name'
            />
            <Error>{teamMember.name.error}</Error>
          </FieldItem>
          <FieldItem icon={`\f0c1`}>
            <span>Bio Link</span>
            <input
              onChange={(e) => {
                setTeamMember({
                  ...teamMember,
                  bioUrl: {
                    value: e.target.value,
                    error: !validateUrl(e.target.value)
                      ? 'Invalid Url Formate'
                      : '',
                  },
                });
              }}
              value={teamMember.bioUrl.value}
              type='text'
              name='name'
              id='name'
            />
            <Error>{teamMember.bioUrl.error} </Error>
          </FieldItem>
          <FieldItem icon={'\f0e0'}>
            <span>Email Address</span>
            <input
              onChange={(e) => {
                setTeamMember({
                  ...teamMember,
                  email: {
                    value: e.target.value,
                    error: !validateEmail(e.target.value)
                      ? 'Invalid Email Formate'
                      : '',
                  },
                });
              }}
              value={teamMember.email.value}
              type='email'
              name='name'
              id='name'
            />
            <Error>{teamMember.email.error}</Error>
          </FieldItem>
          <FieldItem icon={'\f095'}>
            <span>Phone Number</span>
            <PhoneInput
              style={{ width: '100%' }}
              international
              onChange={(e) => {
                setTeamMember({
                  ...teamMember,
                  phoneNum: {
                    value: e,
                    error: !formatPhoneNumber(e) ? 'Invalid Phone Formate' : '',
                  },
                });
              }}
              value={teamMember.phoneNum.value}
            />
            <Error>{teamMember.phoneNum.error}</Error>
          </FieldItem>
          <button
            style={{
              color: '#fb8501',
              border: '1px solid #fb8501',
              backgroundColor: '#fff',
              width: '75px',
              fontSize: '12px',
            }}
            onClick={() => {
              if (
                validateName(teamMember.name.value) &&
                validateEmail(teamMember.email.value) &&
                validatePhone(teamMember.phoneNum.value) &&
                validateUrl(teamMember.bioUrl.value)
              ) {
                setData((prev) => ({
                  ...prev,
                  transparency: {
                    ...prev.transparency,
                    teamMembers: {
                      value: [
                        ...prev.transparency.teamMembers.value,
                        {
                          name: teamMember.name.value,
                          bioUrl: teamMember.bioUrl.value,
                          email: teamMember.email.value,
                          phoneNum: teamMember.phoneNum.value,
                        },
                      ],
                      error: '',
                    },
                  },
                }));
                setTeamMember({
                  name: {
                    value: '',
                    error: '',
                  },
                  bioUrl: {
                    value: '',
                    error: '',
                  },
                  email: {
                    value: '',
                    error: '',
                  },
                  phoneNum: {
                    value: '',
                    error: '',
                  },
                });
              } else {
                setTeamMember({
                  name: {
                    ...teamMember.name,
                    error: validateName(teamMember.name.value)
                      ? ''
                      : 'Invalid Name format',
                  },
                  bioUrl: {
                    ...teamMember.bioUrl,
                    error: validateUrl(teamMember.bioUrl.value)
                      ? ''
                      : 'Invalid Url format',
                  },
                  email: {
                    ...teamMember.email,
                    error: validateEmail(teamMember.email.value)
                      ? ''
                      : 'Invalid Email format',
                  },
                  phoneNum: {
                    ...teamMember.phoneNum,
                    error: validatePhone(teamMember.phoneNum.value)
                      ? ''
                      : 'Invalid Phone format',
                  },
                });
              }
            }}
          >
            {data.transparency.teamMembers.value.length > 0 ? 'Add' : 'Save'}
          </button>
        </div>
        {data.transparency.teamMembers.value.map(
          ({ name, bioUrl, email, phoneNum }, index) => (
            <div>
              <FieldItem icon={'\f406'}>
                <span>Name</span>
                <input
                  type='text'
                  name='name'
                  id='name'
                  disabled
                  value={name}
                />
              </FieldItem>
              <FieldItem icon={`\f0c1`}>
                <span>Bio Link</span>
                <input
                  disabled
                  value={bioUrl}
                  type='text'
                  name='name'
                  id='name'
                />
              </FieldItem>
              <FieldItem icon={'\f0e0'}>
                <span>Mailing Address</span>
                <input
                  disabled
                  value={email}
                  type='email'
                  name='name'
                  id='name'
                />
              </FieldItem>
              <FieldItem icon={'\f095'}>
                <span>Phone Number</span>
                <input disabled value={phoneNum} name='name' id='name' />
              </FieldItem>
              <IconButton
                style={{
                  color: '#fb8501',
                  border: '1px solid #fb8501',
                }}
                onClick={() => {
                  let team = data.transparency.teamMembers.value;
                  console.log(team);
                  setData((prev) => ({
                    ...prev,
                    transparency: {
                      ...prev.transparency,
                      teamMembers: {
                        value: team.filter((_, i) => i !== index),
                        error: '',
                      },
                    },
                  }));
                }}
              >
                <Remove />
              </IconButton>
            </div>
          )
        )}
        <Error>{data.transparency.teamMembers.error}</Error>
      </FieldInnerContainer>
      <FieldItem error={data.transparency.agreeForReporting.error !== ''}>
        <div className='radioContainer'>
          <input
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                transparency: {
                  ...prev.transparency,
                  agreeForReporting: {
                    value: e.target.checked,
                    error: e.target.checked ? '' : 'Response is required',
                  },
                },
              }));
            }}
            checked={data.transparency.agreeForReporting.value}
            type='checkbox'
            id='agreeForReporting'
            name='agreeForReporting'
            value='yes'
          />
          <label htmlFor='agreeForReporting'>
            I agree to reporting on activity and providing evidence of impact?
            (Reports on numbers impacted, activities, photo & video evidence,
            and testimonials)
          </label>
        </div>
        <Error>{data.transparency.agreeForReporting.error}</Error>
      </FieldItem>
      <FieldItem>
        <div className='radioContainer'>
          <input
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                transparency: {
                  ...prev.transparency,
                  agreeToShareLessons: {
                    value: e.target.checked,
                    error: e.target.checked ? '' : 'Response is required',
                  },
                },
              }));
            }}
            type='checkbox'
            id='yesAgreeToShareLesson'
            name='agreeToShareLesson'
            value='yes'
            checked={data.transparency.agreeToShareLessons.value}
          />
          <label htmlFor='yesAgreeToShareLesson'>
            I agree to share lessons learned to support knowledge sharing for
            others having an impact?
          </label>
        </div>
        <Error>{data.transparency.agreeToShareLessons.error}</Error>
      </FieldItem>
    </FieldContainer>
  );
};

export default Transparency;
