import { Box } from '@mui/material';
import React from 'react';
import { GrFavorite } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { addToFav } from '../../../services/campaign';
import { successToast } from '../../../utils/toast';

const SliderCard = ({ data, getNFT = () => {} }) => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(`/nft-details/${data._id}`)}
      sx={{
        padding: '20px',
        background: '#fff',
        mx: '16px',
        border: '1px solid lightgray',
        borderRadius: '24px',
        height: '100%',
        width: 'fit-content',
      }}
    >
      {/* image container */}
      <div
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 16,
            right: 16,
            borderRadius: 8,
            background: '#fff',
            padding: 8,
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.stopPropagation();
            addToFav(data?._id).then((res) => {
              successToast(res.data.message);
              getNFT();
            });
          }}
        >
          <GrFavorite size={16} />
          <span style={{ marginLeft: 8 }}>{data?.favouritesCount || 0}</span>
        </div>
        <img
          src={`${process.env.REACT_APP_DOMAIN}${data.imageUrl}`}
          alt=''
          style={{
            width: '100%',
            borderRadius: 20,
            height: '280px',
            objectFit: 'contain',
          }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: -16,
            left: 32,
            display: 'flex',
          }}
        >
          <img
            src='https://www.w3schools.com/howto/img_avatar.png'
            alt=''
            style={{
              width: 32,
              height: 32,
              border: '4px solid white',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
          <img
            src='https://www.w3schools.com/howto/img_avatar2.png'
            alt=''
            style={{
              width: 32,
              height: 32,
              border: '4px solid white',
              borderRadius: '50%',
              objectFit: 'cover',
              marginLeft: -10,
            }}
          />
        </div>
      </div>

      {/* content */}
      <div
        style={{
          marginTop: 32,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h5 className='singleLineText'>{data.itemName}</h5>
        {/* <IconButton>
            <BsThreeDots />
          </IconButton> */}
      </div>
      <p style={{ color: '#555' }}>{data.priceUsd}</p>
      <div
        style={{
          marginTop: 32,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <button
          style={{
            color: '#219EBC',
            fontWeight: 'bold',
            border: '0',
            background: 'transparent',
            fontSize: 18,
          }}
          onClick={() => navigate(`/nft-details/${data._id}`)}
        >
          Buy Now
        </button>
        {/* <button
            style={{
              fontWeight: 'bold',
              border: '0',
              background: 'transparent',
            }}
          >
            <RiHistoryFill size={20} color='#555' />
            <span style={{ marginLeft: 8 }}>View History</span>
          </button> */}
      </div>
    </Box>
  );
};

export default SliderCard;
