import HelpIcon from '@mui/icons-material/Help';
import { Modal, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CommonBtn } from '../../../../global/styles/style-component';
import {
  createNFT,
  getCategory,
  getImpactCategory,
} from '../../../../services/vetting';
import ApiCall from '../../../../utils/axiosCall';
import { errorToast, successToast } from '../../../../utils/toast';
import { validateSelectedFile } from '../../../../utils/validation';
import DashboardContainer from '../../DashboardContainer';
import './NFTMinting.css';
import { NFTPercentageBtn } from './nftStyled';
import PhoneEdit from './PhoneEdit';

const NFTMinting = () => {
  const fileTypes = [
    'jpg',
    'png',
    'jpeg',
    'svg',
    'bmp',
    // 'HEIC',
    'MP4',
    // 'WMV',
    'MOV',
    'AVI',
    // 'FLV',
    // 'MKV',
    'gif',
    'mp3',
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customPercentage, setCustomPercentage] = useState(false);

  function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'png':
        return true;
    }
    return false;
  }

  function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'mpg':
      case 'avi':
      case 'mp4':
      case 'wmv':
      case 'flv':
      case 'mkv':
      case 'mov':
        return true;
    }
    return false;
  }

  function isAudio(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'mp3':
      case 'avi':
        return true;
    }
    return false;
  }

  const [data, setData] = useState({
    purpose: '0',
    nft: {
      value: null,
      error: '',
    },
    itemName: {
      value: '',
      error: '',
    },
    description: {
      value: '',
      error: '',
    },
    priceUsd: {
      value: '',
      error: '',
    },
    nftCategory: {
      value: '',
      error: '',
    },
    impactCategory: {
      value: '',
      error: '',
    },
    impactCampaign: {
      value: '',
      error: '',
    },
    percentageToImpact: {
      value: '',
      error: '',
    },
    giftRecipientAddress: {
      value: '',
      error: '',
    },
  });

  const [nftCategory, setNftCategory] = useState([]);
  const [impactCategory, setImpactCategory] = useState([]);
  const [impactCampaign, setImpactCampaign] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const nft = await getCategory('NFT');
      const impact = await getCategory('CAMPAIGN');
      setImpactCategory(impact.data.categoriesList);
      setNftCategory(nft.data.categoriesList);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (data.impactCategory.value !== '') {
        const impact = await getImpactCategory(data.impactCategory.value);
        setImpactCampaign(impact.data.impactInitiatives);
      } else {
        setImpactCampaign([]);
      }
    };
    fetch();
  }, [data.impactCategory.value]);

  const [searchParma] = useSearchParams();
  useEffect(() => {
    if (searchParma.get('success')) {
      successToast('Successfully Gifted ');
    } else if (searchParma.get('success') == false) {
      errorToast('Unable to proceed transaction');
    }
    if (searchParma.get('type')) {
      const purpose = searchParma.get('type').toLowerCase();
      setData({
        ...data,
        purpose: purpose === 'gift' ? '1' : purpose === 'contribution' && '0',
      });
    }
  }, [searchParma]);

  const handleChange = (file) => {
    setData({
      ...data,
      nft: {
        value: file,
        error: '',
      },
    });
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const [openEditor, setOpenEditor] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  useEffect(() => {
    checkIfVerified();
  }, []);

  const checkIfVerified = () => {
    ApiCall.get('user/account-verification')
      .then((res) => {
        setIsVerified(res.data.success);
      })
      .catch((err) => setIsVerified(err.response?.data?.success));
  };

  const hintList = [
    'Create an NFT to gift to another user with an demo or Polygon Wallet Address',
    'Create an NFT to contribute it to an Impact Campaign. Choose to allocate 50% or more of the earnings to an Impact Initiative that you choose. ',
    'Create an NFT for your own portfolio. Choose a small % to benefit an Impact Campaign',
    'Create multiple NFTs to award to multiple users. Recipients should have an demo or Polygon Wallet ',
    'Pay based on the number of NFTs you want to create.',
  ];

  return (
    <DashboardContainer>
      {isVerified ? (
        <>
          <Modal open={openEditor} onClose={() => setOpenEditor(false)}>
            <PhoneEdit
              path={data.nft.value && URL.createObjectURL(data.nft.value)}
              setOpenEditor={setOpenEditor}
            />
          </Modal>
          <strong className='mt-3'>Mint NFT</strong>
          <div className='d-flex flex-column mintingDetails'>
            <b>
              Image, Video, Audio or 3D Model (*your NFT will be available in
              your demo wallet and can be viewed on your profile page and in
              your dashboard)
            </b>
            <br />
            <span>
              Supported file types supported: JPEG, PNG, SVG. BMP, HEIC, MP4,
              WMV, MOV, AVI, FLV, MKV, GIF Max Size: 15 MB
            </span>
            <hr />
            <div className='nftMintingInputs'>
              <div className='d-flex justify-content-between'>
                <div>
                  <div
                    className='d-flex flex-row me-5  mb-3'
                    style={{ gap: '5px' }}
                  >
                    <strong className=''>NFT PURPOSE</strong>
                    <Tooltip title={hintList[data.purpose]} arrow>
                      <HelpIcon />
                    </Tooltip>
                  </div>
                  <select
                    value={data.purpose}
                    onChange={(e) => {
                      setData({
                        ...data,
                        purpose: e.target.value,
                      });
                    }}
                  >
                    <option value={'0'}>CONTRIBUTION</option>
                    <option value={'1'}>GIFT</option>
                    <option value={'2'}>PORTFOLIO</option>
                  </select>
                </div>
              </div>
              <div
                style={{
                  color: data.itemName.error !== '' && 'red',
                  fontSize: '13px',
                }}
              >
                <label htmlFor='itemName'>Item Name</label>
                <input
                  style={{
                    outline: 'none',
                    borderColor: data.itemName.error !== '' ? 'red' : '',
                  }}
                  value={data.itemName.value}
                  type='text'
                  name='itemName'
                  id='itemName'
                  onChange={(e) => {
                    setData({
                      ...data,
                      itemName: {
                        value: e.target.value,
                        error:
                          e.target.value === '' ? 'Item name is required' : '',
                      },
                    });
                  }}
                />
                <span>{data.itemName?.error}</span>
              </div>
              {data.purpose === '1' && (
                <div
                  style={{
                    color: data.giftRecipientAddress.error !== '' && 'red',
                    fontSize: '13px',
                  }}
                >
                  <label htmlFor='receiverWalletAddress'>
                    Receiver Wallet Address
                  </label>
                  <input
                    style={{
                      outline: 'none',
                      borderColor:
                        data.giftRecipientAddress.error !== '' ? 'red' : '',
                    }}
                    value={data.giftRecipientAddress.value}
                    type='text'
                    name='receiverWalletAddress'
                    id='receiverWalletAddress'
                    onChange={(e) => {
                      setData({
                        ...data,
                        giftRecipientAddress: {
                          value: e.target.value,
                          error:
                            e.target.value === ''
                              ? 'Receiver Wallet address is required'
                              : '',
                        },
                      });
                    }}
                  />
                  <span>{data.giftRecipientAddress?.error}</span>
                </div>
              )}
              <FileUploader
                handleChange={(file) => {
                  validateSelectedFile(file, handleChange);
                }}
                name='file'
                classes='uploadFileContainer'
                types={fileTypes}
              >
                <div className='d-flex flex-column p-2 uploadFileContainer'>
                  <label
                    className='d-flex flex-column align-items-center'
                    htmlFor='nftFile'
                  >
                    {data.nft.value && isAudio(data.nft.value?.name) ? (
                      <audio controls autoplay>
                        <source
                          src={URL.createObjectURL(data.nft.value)}
                          type={`audio/${getExtension(data.nft.value.name)}`}
                        />
                      </audio>
                    ) : data.nft.value && isVideo(data.nft.value?.name) ? (
                      <video
                        onClick={(e) => e.stopPropagation()}
                        width='320'
                        height='240'
                        controls
                      >
                        <source
                          src={URL.createObjectURL(data.nft.value)}
                          type={`video/${getExtension(data.nft.value.name)}`}
                        />
                      </video>
                    ) : (
                      <img
                        src={
                          data.nft.value
                            ? URL.createObjectURL(data.nft.value)
                            : require('../../../../assets/uploadNFT.png')
                        }
                        alt=''
                      />
                    )}
                    <span>
                      Drag files to upload
                      <br />
                      or <b>Browse</b>
                    </span>
                  </label>
                  {data.purpose == '1' && (
                    <span>
                      <strong>Note :</strong>
                      <br /> We charge a platform fee of $4.99 to gift an NFT,
                      with 25% of that going to an impact initiative of your
                      choice
                    </span>
                  )}
                </div>
              </FileUploader>
              <div
                style={{
                  color: data.description?.error !== '' && 'red',
                  fontSize: '13px',
                }}
              >
                <label htmlFor='description'>
                  Description ({data.description.value.length}/599)
                </label>
                <textarea
                  name='description'
                  id='description'
                  cols='30'
                  minLength={'60'}
                  maxLength={'599'}
                  rows='7'
                  value={data.description.value}
                  style={{
                    outline: 'none',
                    borderColor: data.description?.error !== '' ? 'red' : '',
                  }}
                  onChange={(e) => {
                    setData({
                      ...data,
                      description: {
                        value: e.target.value,
                        error:
                          e.target.value === ''
                            ? 'Description is required'
                            : e.target.value.length > 15
                            ? ''
                            : 'Description should contain at least 15 characters',
                      },
                    });
                  }}
                ></textarea>
                <span>{data.description?.error}</span>
              </div>
              {data.purpose !== '1' && (
                <div
                  style={{
                    color: data.priceUsd?.error !== '' && 'red',
                    fontSize: '13px',
                  }}
                  className='salePriceContainer'
                >
                  <label htmlFor='salePrice'>Instant sale price</label>
                  <div>
                    <input
                      value={data.priceUsd.value}
                      onChange={(e) => {
                        setData({
                          ...data,
                          priceUsd: {
                            value: e.target.value,
                            error:
                              e.target.value === '' ||
                              parseFloat(e.target.value) < 9.99
                                ? 'Sale Price must be at least 10 $ is required'
                                : '',
                          },
                        });
                      }}
                      style={{
                        outline: 'none',
                        borderColor: data.priceUsd?.error !== '' ? 'red' : '',
                      }}
                      min={100}
                      step={0.01}
                      type='number'
                      name='salePrice'
                      id='salePrice'
                    />
                    <select>
                      <option>USD</option>
                    </select>
                  </div>
                  <span>{data.priceUsd?.error}</span>
                </div>
              )}
              <div
                style={{
                  color: data.nftCategory?.error !== '' && 'red',
                  fontSize: '13px',
                }}
              >
                <label htmlFor='nftCategory'>NFT Category</label>
                <select
                  onChange={(e) => {
                    setData({
                      ...data,
                      nftCategory: {
                        value: e.target.value,
                        error:
                          e.target.value === '' ? 'Select nft category' : '',
                      },
                    });
                  }}
                  value={data.nftCategory.value}
                  style={{
                    outline: 'none',
                    borderColor: data.nftCategory?.error !== '' ? 'red' : '',
                  }}
                  id='nftCategory'
                >
                  <option value=''>Select NFT category</option>
                  {nftCategory.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <span>{data.nftCategory?.error}</span>
              </div>
              <div
                style={{
                  gridRow: ' 6 / span 2',
                  gridColumn: '2',
                }}
                className='d-flex align-items-center gap-3 mt-4'
              >
                {data.purpose !== '1' && (
                  <>
                    <strong>% To impact</strong>

                    <div className='percentageContainer'>
                      <NFTPercentageBtn
                        onClick={() => {
                          setCustomPercentage(false);
                          setData({
                            ...data,
                            percentageToImpact: {
                              value: data.purpose === '0' ? 50 : 10,
                              error: '',
                            },
                          });
                        }}
                        active={
                          (data.percentageToImpact.value == 50 ||
                            data.percentageToImpact.value == 10) &&
                          !customPercentage
                        }
                      >
                        {data.purpose === '0' ? '50%' : '10%'}
                      </NFTPercentageBtn>
                      <NFTPercentageBtn
                        onClick={() => {
                          setCustomPercentage(false);
                          setData({
                            ...data,
                            percentageToImpact: {
                              value: data.purpose === '0' ? 55 : 15,
                              error: '',
                            },
                          });
                        }}
                        active={
                          (data.percentageToImpact.value == 55 ||
                            data.percentageToImpact.value == 15) &&
                          !customPercentage
                        }
                      >
                        {data.purpose === '0' ? '55%' : '15%'}
                      </NFTPercentageBtn>
                      <NFTPercentageBtn
                        onClick={() => {
                          setCustomPercentage(false);
                          setData({
                            ...data,
                            percentageToImpact: {
                              value: data.purpose === '0' ? 60 : 20,
                              error: '',
                            },
                          });
                        }}
                        active={
                          (data.percentageToImpact.value == 60 ||
                            data.percentageToImpact.value == 20) &&
                          !customPercentage
                        }
                      >
                        {data.purpose === '0' ? '60%' : '20%'}
                      </NFTPercentageBtn>
                      <NFTPercentageBtn
                        active={customPercentage}
                        onClick={() => setCustomPercentage(!customPercentage)}
                      >
                        Custom %
                      </NFTPercentageBtn>
                      {customPercentage && (
                        <input
                          id='customPercentage'
                          style={{
                            outline: 'none',
                            borderColor:
                              data.percentageToImpact?.error !== ''
                                ? 'red'
                                : '',
                          }}
                          type='number'
                          name='customPercentage'
                          onChange={(e) => {
                            if (data.purpose === '0') {
                              setData({
                                ...data,
                                percentageToImpact: {
                                  value: e.target.value,
                                  error:
                                    e.target.value >= 50 &&
                                    e.target.value <= 100
                                      ? ''
                                      : 'Percentage should grater then 50',
                                },
                              });
                            } else {
                              setData({
                                ...data,
                                percentageToImpact: {
                                  value: e.target.value,
                                  error:
                                    e.target.value >= 10 &&
                                    e.target.value <= 100
                                      ? ''
                                      : 'Percentage should grater then 10',
                                },
                              });
                            }
                          }}
                        />
                      )}
                      <span
                        style={{
                          color: data.percentageToImpact?.error !== '' && 'red',
                          fontSize: '13px',
                        }}
                      >
                        {data.percentageToImpact?.error}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  color: data.impactCategory?.error !== '' && 'red',
                  fontSize: '13px',
                }}
              >
                <label htmlFor='impactCategory'>Impact Category</label>
                <select
                  value={data.impactCategory.value}
                  onChange={(e) => {
                    setData({
                      ...data,
                      impactCategory: {
                        value: e.target.value,
                        error:
                          e.target.value === '' ? 'Select impact category' : '',
                      },
                    });
                  }}
                  style={{
                    outline: 'none',
                    borderColor: data.impactCategory?.error !== '' ? 'red' : '',
                  }}
                  id='impactCategory'
                >
                  <option value=''>Select Impact category</option>
                  {impactCategory.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <span>{data.impactCategory?.error}</span>
              </div>
              <div
                style={{
                  color: data.impactCampaign?.error !== '' && 'red',
                  fontSize: '13px',
                }}
              >
                <label htmlFor='impactCampaign'>Impact Initiative</label>
                <select
                  value={JSON.stringify(data.impactCampaign.value)}
                  onChange={(e) => {
                    setData({
                      ...data,
                      impactCampaign: {
                        value:
                          e.target.value !== ''
                            ? JSON.parse(e.target.value)
                            : '',
                        error:
                          e.target.value === ''
                            ? 'Select Impact Initiative'
                            : '',
                      },
                    });
                  }}
                  style={{
                    outline: 'none',
                    borderColor: data.impactCampaign?.error !== '' ? 'red' : '',
                  }}
                  id='impactCampaign'
                >
                  <option value=''>Select Impact Initiative</option>
                  {impactCampaign.map((item, index) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span>{data.impactCampaign?.error}</span>
              </div>
            </div>
            <button
              onClick={() => {
                if (
                  data.itemName.value === '' ||
                  parseFloat(data.priceUsd.value) === 0 ||
                  (data.purpose !== '1' && data.priceUsd.value === '') ||
                  data.impactCampaign.value === '' ||
                  data.nftCategory.value === '' ||
                  data.impactCategory.value === '' ||
                  (data.purpose !== '1' &&
                    data.percentageToImpact.value === '') ||
                  (data.purpose === '0' &&
                    !(
                      data.percentageToImpact.value >= 50 &&
                      data.percentageToImpact.value <= 100
                    )) ||
                  (data.purpose === '2' &&
                    !(
                      data.percentageToImpact.value >= 10 &&
                      data.percentageToImpact.value <= 100
                    )) ||
                  data.description.value.length < 15 ||
                  data.nft.value === null ||
                  (data.purpose === '1' &&
                    data.giftRecipientAddress.value === '')
                ) {
                  console.log(
                    data.itemName.value === '',
                    parseFloat(data.priceUsd.value) === 0,
                    data.purpose !== '1' && data.priceUsd.value === '',
                    data.impactCampaign.value === '',
                    data.nftCategory.value === '',
                    data.impactCategory.value === '',
                    data.purpose !== '1' &&
                      data.percentageToImpact.value === '',
                    data.purpose === '0' &&
                      !(
                        data.percentageToImpact.value >= 50 &&
                        data.percentageToImpact.value <= 100
                      ),
                    data.purpose === '2' &&
                      !(
                        data.percentageToImpact.value >= 10 &&
                        data.percentageToImpact.value <= 100
                      ),
                    data.description.value.length < 15,
                    data.nft.value === null,
                    data.purpose === '1' &&
                      data.giftRecipientAddress.value === ''
                  );
                  setData({
                    ...data,
                    itemName: {
                      ...data.itemName,
                      error:
                        data.itemName.value === ''
                          ? 'Item name is required'
                          : '',
                    },
                    giftRecipientAddress: {
                      ...data.giftRecipientAddress,
                      error:
                        data.giftRecipientAddress.value === ''
                          ? 'Receiver Wallet address is required'
                          : '',
                    },
                    priceUsd: {
                      ...data.priceUsd,
                      error:
                        data.priceUsd.value === '' ||
                        parseFloat(data.priceUsd.value) < 9.99
                          ? 'Sale Price must be at least 10 $ is required'
                          : '',
                    },
                    description: {
                      ...data.description,
                      error:
                        data.description.value === ''
                          ? 'Description is required'
                          : data.description.value.length > 15
                          ? ''
                          : 'Description should be longer then 15 character long',
                    },
                    impactCampaign: {
                      ...data.impactCampaign,
                      error:
                        data.impactCampaign.value === ''
                          ? 'Impact Campaign is required'
                          : '',
                    },
                    impactCategory: {
                      ...data.impactCategory,
                      error:
                        data.impactCategory.value === ''
                          ? 'Impact Category is required'
                          : '',
                    },
                    nftCategory: {
                      ...data.nftCategory,
                      error:
                        data.nftCategory.value === ''
                          ? 'NFT Category is required'
                          : '',
                    },
                    percentageToImpact: {
                      ...data.percentageToImpact,
                      error:
                        data.percentageToImpact.value === ''
                          ? 'Percentage is required'
                          : '',
                    },
                  });
                  if (data.nft.value === null) {
                    alert('File is required');
                  }
                } else {
                  const formdata = new FormData();
                  formdata.append('nft', data.nft.value);
                  formdata.append('itemName', data.itemName.value);
                  formdata.append('description', data.description.value);
                  formdata.append('priceUsd', data.priceUsd.value);
                  formdata.append('nftCategory', data.nftCategory.value);
                  formdata.append('impactCategory', data.impactCategory.value);
                  formdata.append(
                    'impactInitiativeName',
                    data.impactCampaign.value.name
                  );
                  formdata.append(
                    'impactCampaignType',
                    data.impactCampaign.value.type
                  );
                  formdata.append('impactId', data.impactCampaign.value.id);
                  formdata.append(
                    'percentageToImpact',
                    data.percentageToImpact.value
                  );
                  if (data.purpose === '1')
                    formdata.append(
                      'giftRecipientAddress',
                      data.giftRecipientAddress.value
                    );

                  dispatch(
                    createNFT(formdata, data.purpose, setData, navigate)
                  );
                }
              }}
              className='nftBtn align-self-center mt-3'
            >
              Create Now
            </button>
            <span className='align-self-center mt-2 p-2' role='button'>
              Cancel
            </span>
          </div>
        </>
      ) : (
        <CommonBtn
          onClick={() => {
            ApiCall.post('user/verify-account')
              .then((res) => {
                // checkIfVerified();
                window.open(res.data.url, '_self');
              })
              .catch((err) => errorToast(err.response?.data?.message));
          }}
        >
          verify your account
        </CommonBtn>
      )}
    </DashboardContainer>
  );
};

export default NFTMinting;
