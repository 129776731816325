import React from "react";
import { Heading } from "../../../global/styles/style-component";

const ShortHistoryContent = () => {
  return (
    <div className="short-history-container">
      <h4>Any poker fans out there? </h4>
      <p>
        Navigating technology's effects on society can feel like having a bunch
        of cards randomly dealt to you, leaving you to figure out a strategy to
        keep from folding in the first round. <br /> <br /> An invisible
        revolution is heating up behind the screens of your smartphone, laptop,
        and app-enabled devices popping up in your home and on your bod. These
        changes will affect every aspect of society in ways we have not seen
        since electricity had its day transforming human civilization. <br />{" "}
        <br /> A massive poker game is brewing for the most critical sectors of
        society — business, finance, and government. <br /> <br /> If you are
        crypto-curious, tired of tech companies peddling your private info,
        catch yourself yelling at Alexa across the room while you noodle around
        with your smartwatch, you are dipping your toe in this new ocean. <br />{" "}
        <br /> It's called Web 3.0, and it's upping the ante worldwide. Like all
        the great leaps forward dreamed up by humans, it will deliver utopia,
        chaos on a grand scale, and many points in between. <br /> <br /> For
        now, humans are still the boss of our gadgets. In free societies, we
        have a say in how these technologies alter our communities and lives.
        Lawmakers, influenced by public opinion and stakeholders with money,
        will figure out regulations in the next few years that impact topics
        from personal identity to new forms of currency and companies. <br />{" "}
        <br />
        Blockchain will challenge the very relevance of many functions of
        governments and institutions. <br /> <br /> In this module, we aim to
        help you understand the background that got us to this point. Read on
        for an overview of how we got to this Brave New World in the first
        place. If you have any questions, feel free to ask them in the private
        Facebook group.
      </p>

      <div className="industrial-revolution">
        <Heading>Four Industrial Revolutions</Heading>
        <p>
          According to economic historians, we are entering a period called the
          Fourth Industrial Revolution, or 4IR. Being in the 4IR implies that we
          have passed through three previous industrial revolutions. The
          Internet's development overlaps with 3IR and 4IR. We'll get to that in
          a bit. <br /> <br /> Below are the innovations marking the beginning
          of each economic revolution that brings us to the present day. Various
          sources provide different dates, so take these as general windows in
          time.
        </p>{" "}
        <br />
        <h4>First Industrial Revolution (1IR) — 1765</h4>
        <p>The steam engine, advances in weaving</p> <br />
        <h4>Second Industrial Revolution (2IR) — 1870</h4>
        <p>
          Electricity, science, and mass production (think Edison and Ford).
        </p>{" "}
        <br />
        <h4>Third Industrial Revolution (3IR) — 1969 </h4>
        <p>
          Computing, digital tech, and nuclear energy. Web 1.0 emerges in the
          1990s; see more detail below. Society has begun to move into a time
          when people cannot see or touch major pieces of the infrastructure
          that runs people's lives.
        </p>{" "}
        <br />
        <h4>Fourth Industrial Revolution (4IR) — 2000s </h4>
        <p>
          Like its predecessors, 4IR is rooted in technological advances. The
          distinguishing advances are smart technology integration via
          cloud-based computing, blockchain, and artificial intelligence.
        </p>{" "}
        <br />
      </div>

      <div className="the-evolution">
        <Heading>
          The Evolution of the World Wide Web 1.0–3.0, the 1990s — present
        </Heading>
        <p>The Evolution of the World Wide Web 1.0–3.0, the 1990s — present</p>{" "}
        <br />
        <h4>Web 1.0 — the 1990s</h4>
        <p>
          Web 1.0 was mostly a static collection of text and images with limited
          interactivity. Search engines were in their infancy. Users in this
          environment were consumers of information and some nascent e-commerce.
          Web 1.0 was revolutionary because anyone could create a webpage for
          very little money, and millions of people and businesses began to do
          so.{" "}
        </p>{" "}
        <br />
        <h4>Web 2.0–the 2000s</h4>
        <p>
          With Web 2.0, we saw the rise of interactivity, automation, social
          media, and many privacy concerns. Industry consolidation led to the
          Big Tech oligarchy companies Apple, Google, Amazon, Facebook, and
          Microsoft. <br /> <br /> Social media companies posted incredible
          growth rates. Censorship and privacy controversies flared as fake news
          proliferated. <br /> <br />
          On the back end, internet companies began collecting data about user
          behavior, giving rise to incredibly profitable online ad business
          models. Some people say that in Web 2.0, the user is the product.{" "}
          <br /> <br /> In some ways, that is true, but the other revolution of
          Web 2.0 is that companies and brands lost control of the brand
          narrative. Consumers now have very public forums to share their views.
          In web 2.0, we saw the rise of influencer marketing.
          <br /> <br />
        </p>{" "}
        <br />
        <h4>The Cloud Innovation </h4>
        <p>
          Companies hosted their intranet and data storage on their own servers
          in the early days. Over time, they moved hosting to the "cloud" —
          off-site data centers run by service providers. <br /> <br /> Of
          course, the word "cloud" doesn't refer to anything in the sky. The
          term was good marketing - hat tip to Compaq marketing exec George
          Favaloro in 1996 - to distinguish an off-site hosting model from the
          clunky servers in the closet or under the desk. <br /> <br /> The
          "cloud" is an earthbound collection of millions of servers in data
          centers worldwide. The cloud stores not only enterprise data but the
          reams of Big Data that companies collect about web surfers and
          customers and, increasingly, blockchain's peer-to-peer networks. And
          that leads us to Web 3.0. <br /> <br />
        </p>{" "}
        <br />
        <h4>Web 3.0–2016 </h4>
        <p>
          Web 3.0 is also known as web3. Significant changes from web 2.0
          include increasing decentralization of centralized institutions and
          databases, the rise of artificial intelligence and machine learning,
          and the continued integration of smart technology into the physical
          and biological environment. <br /> <br /> Most internet users now
          access the Web through the products and services of large, powerful
          tech companies. Privacy and data transparency proponents see Web 3.0
          as a way to break those monopolies by decentralizing data. <br />{" "}
          <br /> Blockchain technology is a crucial ingredient.
        </p>
        <br /> <br />
        <h4>Blockchain and Decentralization</h4>
        <p>
          The blockchain acts as a distributed ledger that transparently
          distributes "blocks" of information about transactions among various
          nodes on the chain. We will cover blockchain in more depth in the next
          module. Below is a quick overview of blockchain characteristics and
          why they matter.
        </p>
        <br /> <br />
        <div className="sub-content">
          <h4>Redundancy</h4>
          <p>
            Because the ledger is stored on multiple nodes, loss due to an
            accident or hacking of one database does not wipe out the whole
            record.
            <br /> <br />
          </p>
        </div>
        <div className="sub-content">
          <h4>Reduce the chance of tampering </h4>
          <p>
            An attempt to hack or alter one database alerts the chain, with
            unaltered copies available to cross-reference and identify incorrect
            information. <br /> <br /> Cryptocurrency is one of the best-known
            applications of blockchain. Yet, it's important to note that
            blockchain has many other potential applications. <br /> <br />{" "}
            Blockchain validators are people who control a "node" or server on
            the distributed database. They validate and record data sequentially
            in blocks that cannot be altered after adding them to the chain. In
            this way, the blockchain establishes a transparent sequencing of
            events. In cryptocurrency, validators are also called miners. <br />{" "}
            <br />
          </p>
        </div>
        <div className="sub-content">
          <h4>Artificial intelligence (AI) and machine learning</h4>
          <p>
            You may be wondering, what is AI, anyway? Broadly speaking,
            artificial intelligence is the process of machines simulating human
            intelligence.
            <br /> <br />
            If you don't remember life before AOL, it might surprise you to
            learn that AI has been around since the 1950s. They found floppy
            disk fossils next to dinosaur tracks in Texas. <br /> <br /> Just
            kidding. <br /> <br /> In 1953, a computer scientist for IBM, Arthur
            Samuels, landed on a new insight. He suggested that instead of
            teaching computers how to do every little thing, we should teach
            them how to learn for themselves. <br /> <br /> Machine learning
            (ML) says machines should offer solutions, gather feedback, adapt to
            the input, and make adjustments. Learn by doing. <br /> <br /> Does
            that sound familiar? It should, because that's how humans learn.
            Machine learning uses algorithms to mimic human learning.
            <br /> <br /> And what, you may be secretly wondering, is an
            algorithm?
            <br /> <br /> An algorithm is a set of instructions to do something
            — accomplish a task or solve a problem. A pre-internet example of an
            algorithm is a cherished family recipe, for example. Yep, your
            grandma could have worked for Facebook.
            <br /> <br /> The idea was to teach machines how to think and learn
            like people. This sparked many sci-fi movies and ongoing R&D in the
            pre-internet tech and scientific community.
            <br /> We are now seeing AI enter our lives in multiple ways. Just a
            few examples include devices like smart speakers (Alexa, Google
            Nest), translation apps, writing apps, and sensors in cars, homes,
            and bodies.
            <br /> <br /> We're seeing the rise of the bots, too, as a slew of
            "tip of the iceberg" early robotic offerings, including toys, are
            entering the mass consumer market. Not sure what grandma would think
            of bots as best friends for the kiddos, though.
            <br /> <br /> You still with me? We're almost down to the present
            day.
            <br /> <br /> Next up is the Internet of Things and the growth of
            smart device technology.
            <br /> <br />
          </p>
        </div>
        <div className="sub-content">
          <h4>The Internet of Things (IoT) </h4>
          <p>
            The Internet of things refers to all the devices ("things"), not
            just computers, that send data to the cloud. <br /> <br /> With the
            explosion of smart devices collecting data and sending it to the
            cloud, it doesn't take an imagination like Ray Bradbury's to see
            that the more "things" that send data to the cloud, the bigger Big
            Data will get. To infinity and beyond, right? <br /> <br /> Data is
            like oil, to borrow an analogy making the rounds on the interwebs.
            If it just sits there on a server, it's like unrefined crude oil.
            It's not fuel until it's processed.
            <br /> <br /> That's where AI and Machine Learning come in. They
            provide the technology that refines data from the IoT so we can do
            stuff with it. The amount of IoT data coming is like Spindletop for
            the AI-enabled future.
            <br /> <br /> Now that you know how the IoT and AI interact, what
            does that mean for the future? The short answer is that the IoT and
            related digital technologies will affect, well, everything.
            <br /> <br />
          </p>
        </div>
        <div className="sub-content">
          <h4>Welcome to the invisible life</h4>
          <p>
            The basic stuff of life administration, from money to communication
            to connection, is now invisible, run from your phone, and controlled
            by other companies and organizations. This trend started with
            electricity and accelerated with computer programming and cloud
            computing. <br /> <br /> It's a world of complex (at least to most
            people) computer coding, and the current advantage lies with
            companies and organizations that can hire people to prop up and
            develop that complexity.
            <br /> <br /> The very rich are (so far) enjoying a cascading
            windfall of wealth. A few billion other people on the planet are
            not. And about a third of the world's population — the poorest,
            skewing toward women and older adults — is not even online yet.
            <br /> <br /> The good news is that there is no cap on innovation
            and wealth creation. In fact, Web 3.0 company founders are a growing
            segment of the billionaire club.
            <br /> <br /> So here we sit, legs dangling over the edge of the
            tangible universe, looking out at the sunrise. The horizon has black
            storm clouds over to one side and a crystal blue sky on the other,
            with swirling clouds in between.
            <br /> <br /> How will you ride out the coming weather?
            <br /> <br /> You are fortunate you have an interest the coming
            changes because that means you will be able to benefit from them,
            and advocate for a better world.
            <br /> <br /> To do that, let's deepen your understanding of the
            tools and promise of Web 3, starting with blockchain in the next
            section.
            <br /> <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShortHistoryContent;
