import React from "react";
import { CommonBtn, Heading, SubHeading } from "../../../global/styles/style-component";
import Milestones from "./Milestones";
import Reports from "./Reports";

const ReportingDetails = () => {
  return (
    <div className="reporting-section">
      <Heading>REPORTING DETAILS</Heading>

      <div className="reporting-details-container">
        <div>
          <SubHeading>Tangible Need</SubHeading>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here
          </p>
        </div>
        <div>
          <SubHeading>SDGs addressed</SubHeading>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.{" "}
          </p>
        </div>
        <div>
          <SubHeading>Positive Impact on People</SubHeading>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here
          </p>
        </div>
        <div>
          <SubHeading>Positive Impact on Planet</SubHeading>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here
          </p>
        </div>

        <Reports />
        <Milestones />

        <div>
          <SubHeading>
            Opportunities for Collaborations to Scale Impact
          </SubHeading>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here
          </p>
        </div>
        <div>
          <SubHeading>Lessons Learned</SubHeading>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here
          </p>
        </div>

        <div style={{textAlign:'center', boxShadow:'none', padding:'0', background:'none', marginTop:"50px"}}>
        <CommonBtn>VIEW GALLERY</CommonBtn>
        </div>
      </div>
    </div>
  );
};

export default ReportingDetails;
