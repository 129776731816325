import React, { useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaLongArrowAltRight } from 'react-icons/fa';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import keyIcon from '../../assets/arrow-icon 1.png';
import logo from '../../assets/loginInWhite.png';
import '../../global/styles/LoginConfirmation.css';
import { userVerifyPhone } from '../../redux/user/actionCreator';
import { errorToast } from '../../utils/toast';
import '../login/PasswordReset.css';
import './EnterVerificationPhoneOtp.css';

const EnterVerificationPhoneOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { verificationToken } = useParams();
  const [otp, setOtp] = useState();
  return (
    <>
      <div className='login-confirmation-section'>
        <div className='login-confirmation-container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='confirmation-left'>
                <img src={logo} alt='' />
                <p></p>

                <div className='lets-get-started'>
                  <p>Let's Get Started</p>
                  <FaLongArrowAltRight className='forward-arrow' />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <img src={logo} className='responsive-logo' alt='' />
              <div className='reset-password-container new-password-container'>
                <img src={keyIcon} alt='' />
                <h5>Enter Verification Code</h5>
                <p style={{ marginBottom: '15px' }}>
                  We just sent a 4 digits verification code to your registered
                  Phone number. Kindly check it
                </p>

                <div className='enter-otp'>
                  <p>Enter OTP</p>
                  <OtpInput
                    value={otp}
                    onChange={(e) => setOtp(e)}
                    numInputs={6}
                    separator={<span>-</span>}
                    isInputNum={true}
                  />
                  <div className='forgot-password'>
                    <a href='_' onClick={() => {}}>
                      Send the code again?
                    </a>
                  </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                  <button
                    onClick={() => {
                      if (otp.length === 6) {
                        dispatch(
                          userVerifyPhone(verificationToken, otp, navigate)
                        );
                      }
                      errorToast('Invalid Otp');
                    }}
                    style={{ marginTop: '40px' }}
                  >
                    Verify
                  </button>
                </div>

                <div
                  onClick={() => navigate('/registration')}
                  className='back-to-login'
                >
                  <BiLeftArrowAlt className='back-arrow' />
                  <span>Back to log in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterVerificationPhoneOtp;
