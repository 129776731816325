import React from "react";
import "./DoneForYouPartTwo.css";
import Container from "../Container";
import DoneForYouBanner from "./components/DoneForYouBanner";
import DoneForYouIntro from "./components/DoneForYouIntro";
import DoneForYouTechnologies from "./components/DoneForYouTechnologies";
import DoneForYouBenefits from "./components/DoneForYouBenefits";
import DoneForYouApproach from "./components/DoneForYouApproach";
import Development from "./components/Development";
import DevelopersPlan from "./components/DevelopersPlan";

const DoneForYouPartTwo = () => {
  return (
    <Container>
        <DoneForYouBanner/>
        <DoneForYouIntro/>
        <DoneForYouTechnologies/>
        <DoneForYouBenefits/>
        <DoneForYouApproach/>
        <Development/>
        <DevelopersPlan/>
    </Container>
  );
};

export default DoneForYouPartTwo;
