import React from 'react';
import { PagerBanner } from '../Pager/PagerStyle';
import { BenefitContainer } from './NposCreatorStyle';
import workImg from '../../assets/images/npos_creator.png';
import workElips from '../../assets/images/Ellipse 19.png';
import workElips2 from '../../assets/images/Ellipse 20.png';
import elips from '../../assets/images/elips.png';
import elips2 from '../../assets/images/elips2.png';
import '../Influencer/Influencer.css';
import { Link } from 'react-router-dom';
import './NposCreatorStyle.css';
import Container from '../Container';
import { BannerHeading, Heading } from '../../global/styles/style-component';
const imgUrl = require('../../assets/nft-details/nft-details-banner.png');

const Npos = () => {
  return (
    <Container>
      <PagerBanner imgUrl={imgUrl}>
        <BannerHeading style={{ margin: '0' }}>
          OPPORTUNITY FOR NPOS and CAUSES{' '}
        </BannerHeading>
      </PagerBanner>

      <BenefitContainer>
        <h2>HOW WE WORK WITH NPO’S AND IMPACT INITIATIVES</h2>
        <p>
          demo makes it easy for NPOs to collaborate with brands and also to
          create their own advocacy and fundraising campaigns
        </p>{' '}
        <br />
        <p style={{ textAlign: 'left' }}>
          Benefits of the demo Ecosystem for NPOS and Impact Initiatives:
        </p>
        <div>
          <ul>
            <li>
              Showcase pages for NPOs and impact initiatives for cause
              collaborations. We look for a spectrum of impact organizations,
              from well-known to small and focused.{' '}
            </li>
            <li>
              Organization pages to showcase campaigns and build community.
            </li>
            <li>Minting tools for NFTs and tokenization.</li>
            <li>A growing community of diverse NPOs </li>
          </ul>

          <ul>
            <li>
              Artists and Creators for artistic collaborations. We showcase a
              diverse variety of creators, many from underserved areas or
              groups.
            </li>
            <li>Reporting on campaign performance and impact.</li>
            <li>
              What should we say about donation flow of funds, if anything?{' '}
            </li>
            <li>
              Coming soon: Our Impact Collab Lab - a resource hub where you can
              learn campaign best practices, tips, and lessons learned from
              experts and other NPOs.
            </li>
          </ul>
        </div>
      </BenefitContainer>
      <div className='HowWeWork-section' style={{ paddingTop: '20px' }}>
        <div className='row work-align'>
          <div className='col-md-5'>
            <div className='work-img'>
              <img className='work-main-img' src={workImg} alt='' />
              <img
                className='workElips'
                style={{ top: '555px', left: '-22px' }}
                src={workElips}
                alt=''
              />
              <img
                className='workElips2'
                style={{ top: '565px', left: '5px' }}
                src={workElips2}
                alt=''
              />
            </div>
          </div>
          <div className='col-md-7'>
            <div className='how-we-work-content'>
              <img className='contentElips' src={elips} alt='' />
              <img className='contentElips2' src={elips2} alt='' />

              <h4 style={{ lineHeight: '44px', marginTop: '30px' }}>
                ACCESS OUR ECOSYSTEM
              </h4>

              <div className='single-work-content'>
                <h4>DIY</h4>
                <p>
                  Once your NPO or initiative is approved, you can set up your
                  own campaigns on demo. To get started go{' '}
                  <Link
                    to='/registration'
                    style={{ color: '#FFB703', textDecoration: 'none' }}
                  >
                    here
                  </Link>
                  .
                </p>
              </div>
              <div className='single-work-content'>
                <h4>DONE FOR YOU</h4>
                <p>
                  We offer a done-for-you service to get you set up or to run
                  your campaign, too. Please contact us here to discuss your
                  project.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Npos;
