import styled from 'styled-components';

export const Menu = styled.div`
  transition: all 1s;
  height: ${({ active }) => (active ? 'fit-content' : '0px')};
  position: absolute;
  overflow: hidden;
  z-index: 1000;
  right: 0;
  padding: ${({ active }) => (active ? '0.8em 1em' : '0')};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: max-content;
  gap: 0.5em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  & > a {
    text-decoration: none;
    color: #000 !important;
    &:hover {
      color: #fff !important;
    }
  }
`;
