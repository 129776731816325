import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { getNFT } from '../../services/landing';
import Container from '../Container';
import FilterBar from './components/FilterBar';
import NftList from './components/NftList';
import { NftMarketPlaceContainer } from './style';

const NftMarketPlace = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = (offset = 0) => {
    getNFT(10, offset)
      .then((res) => {
        setData(res.data?.assetList);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Container>
      <NftMarketPlaceContainer>
        {/* <FilterTop /> */}
        <Grid container spacing={'1em'}>
          <Grid item xs={3}>
            <FilterBar setData={setData} />
          </Grid>
          <Grid item xs={9}>
            <NftList data={data} getNft={fetchApi} />
          </Grid>
        </Grid>
      </NftMarketPlaceContainer>
    </Container>
  );
};

export default NftMarketPlace;
