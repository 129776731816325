import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CommonBtn } from '../../../global/styles/style-component';
import { getAllComments, postComment } from '../../../services/campaign';
import { errorToast, successToast } from '../../../utils/toast';
import {
  CommentButton,
  CommentContainer,
  CommentDetailList,
  CommentDetails,
  CommentInput,
  CommentInputContainer,
} from './commentStyled';

const Comments = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState('');
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = () => {
    getAllComments(id)
      .then((res) => {
        setCommentList(res.data.campaignComments);
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  return (
    <CommentContainer className='border'>
      <CommentDetailList>
        {commentList.map(({ comment, username, _id, avatarUrl, createdAt }) => (
          <CommentDetails key={_id}>
            <div className='d-flex gap-2'>
              <img
                src={
                  avatarUrl
                    ? `${process.env.REACT_APP_DOMAIN}${avatarUrl}`
                    : require('../../../assets/dummyavatar.png')
                }
                alt=''
                width={'45px'}
                height={'45px'}
              />
              <div>
                <h6>{username}</h6>
                <p>{comment}</p>
                <span>{new Date(createdAt).toLocaleString()}</span>
              </div>
            </div>
          </CommentDetails>
        ))}
      </CommentDetailList>
      <CommentInputContainer>
        {isLoggedIn ? (
          <>
            <CommentInput
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className='border p-2'
              placeholder='Enter here.....'
            />
            <CommentButton
              onClick={() => {
                if (comment !== '' && isLoggedIn) {
                  postComment(id, comment)
                    .then((res) => {
                      successToast(res.data.message);
                      setComment('');
                      fetchApi();
                    })
                    .catch((err) => {
                      errorToast(err.response.data.message);
                    });
                } else {
                  successToast('Comment is required or you are not login');
                }
              }}
              className='border p-2'
            >
              Submit
            </CommentButton>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CommonBtn onClick={() => navigate('/login')}>Sign In</CommonBtn>
          </div>
        )}
      </CommentInputContainer>
    </CommentContainer>
  );
};

export default Comments;
