import React from "react";
import Container from "../Container";
import BrowseInfluencerBanner from "./components/BrowseInfluencerBanner";
import "./BrowseInfluencer.css";
import InfluencerCampaigns from "./components/InfluencerCampaigns";

const BrowseInfluencer = () => {
  return (
    <Container>
      <BrowseInfluencerBanner />
      <InfluencerCampaigns/>
    </Container>
  );
};

export default BrowseInfluencer;
