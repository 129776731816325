import React from 'react';
import {
  PagerBanner,
  PagerDescription,
  PagerDescriptionHeading,
  PagerDescriptionImage,
  PagerQueries,
  SingleQueries,
} from './PagerStyle';
import blockChainBg from '../../assets/images/blockchain-bg.png';
import Container from '../Container';
import { BannerHeading } from '../../global/styles/style-component';
const imgUrl = require('../../assets/images/blockchain-banner.jpg');

const BlockChainPager = () => {
  return (
    <Container>
      <PagerBanner imgUrl={imgUrl}>
        <BannerHeading>Blockchain Basics</BannerHeading>
      </PagerBanner>
      <PagerDescription>
        <PagerDescriptionHeading>
          <h2>Blockchain Basics</h2>
          <p>
            People describe a blockchain as a transparent distributed ledger of
            immutable transactions. Wut? Here we go, step by step:
          </p>
        </PagerDescriptionHeading>
        <PagerDescriptionImage>
          <img src={blockChainBg} alt='' />
        </PagerDescriptionImage>
      </PagerDescription>

      <PagerQueries>
        <SingleQueries>
          <h4>Ledger</h4>
          <p>
            A ledger records transactions, like your bank account entries. But
            then you knew that.
          </p>
        </SingleQueries>
        <SingleQueries>
          <h4>Distributed Ledger of Immutable Transactions</h4>
          <p>
            A distributed ledger is when a connected network of computers,
            called peer-to-peer, all store a copy of the ledger. Instead of one
            central database, the ledger is “distributed” among the network. All
            the nodes verify each transaction. The transactions are immutable
            because they cannot be changed. First of all, they are difficult to
            alter because they are encrypted, which also protects privacy.
            Secondly, since the transaction is copied on all the network nodes,
            to change one transaction you would have to change them all. While
            difficult, it’s theoretically possible. The key is it is impossible
            to do so secretly. This introduces another level of confidence in
            peer-to-peer transactions.
          </p>
        </SingleQueries>
        <SingleQueries>
          <h4>Blockchain Network Transparency</h4>
          <p>
            Anyone with access to the blockchain may see the ledger to verify
            transactions. This transparency creates trust and eliminates the
            need for a third party to validate or reconcile transactions. It
            also eliminates the need for an intermediary (like a bank) to
            validate or reconcile transactions.
          </p>
        </SingleQueries>
        <SingleQueries>
          <h4>Blockchain Network Transparency</h4>
          <p>
            Depending upon the use case, the blockchain may also have varying
            degrees of decentralization. Unlike distributed, which refers to
            geographic distribution, decentralized refers to shared control and
            governance. No one person or group controls what happens on the
            network, all participants have a say, depending upon the rules the
            community put in place. In reality, the degree of decentralization
            depends upon the purpose. For a Defi (decentralized finance)
            blockchain with lots of users who don’t know each other,
            decentralization is very important for trust in the network. A
            corporate supply chain blockchain has limited transparency for
            outsiders and less decentralization because the users have a
            pre-existing relationship and are vetted for access. The inventor of
            Bitcoin invented the concept of Blockchain to record Bitcoin
            transactions, but blockchain has many many uses beyond
            cryptocurrency. You can read more about Blockchain Basics here.
          </p>
        </SingleQueries>
      </PagerQueries>
    </Container>
  );
};

export default BlockChainPager;
