import React from 'react';
import nftBg from '../../assets/images/nft-bg.png';
import { BannerHeading } from '../../global/styles/style-component';
import Container from '../Container';
import {
  PagerBanner,
  PagerDescription,
  PagerDescriptionHeading,
  PagerDescriptionImage,
  PagerQueries,
  SingleQueries,
} from './PagerStyle';
const imgUrl = require('../../assets/nft-details/nft-details-banner.png');

const NftPager = () => {
  return (
    <Container>
      <PagerBanner imgUrl={imgUrl}>
        <BannerHeading>What Is An NFT?</BannerHeading>
      </PagerBanner>
      <PagerDescription>
        <PagerDescriptionHeading>
          <h2>What is an NFT?</h2>
          <p>
            An NFT is a unique digital record on the blockchain representing a
            unique asset that cannot be divided and hold its value.
          </p>

          <span>Key Points: </span>
          <ul>
            <li>
              NFTs are not digital art. They can represent a digital work of
              art, and they have many applications beyond creative works.
            </li>
            <li>
              NFTs are recorded on a distributed database called a Blockchain.
            </li>
            <li>NFTs have a unique value that cannot be divided.</li>
          </ul>
          <p>
            NFT stands for “non-fungible token.” <br />
            Non-fung-uh-huh?? One way to understand non-fungible is to look at
            its opposite.
          </p>
        </PagerDescriptionHeading>
        <PagerDescriptionImage>
          <img src={nftBg} alt='' />
        </PagerDescriptionImage>
      </PagerDescription>

      <PagerQueries>
        <SingleQueries>
          <h4>Fungible</h4>
          <p>
            It sounds like a type of mushroom but it’s not. Something is
            fungible if its value can be divided and is interchangeable.
            Example: Gold. You can divide a gold ingot, and it will hold value
            based on its weight. The value of gold depends on market forces, not
            changes in the piece of gold itself. Interchangeable: You can trade
            one gold coin for another for the same value. Other examples of
            fungible items are regular coins, crypto coins, stocks and
            commodities, and cartons of mushrooms.
          </p>
        </SingleQueries>
        <SingleQueries>
          <h4>Non-fungible</h4>
          <p>
            It sounds like a type of mushroom but it’s not. Something is
            fungible if its value can be divided and is interchangeable.
            Example: Gold. You can divide a gold ingot, and it will hold value
            based on its weight. The value of gold depends on market forces, not
            changes in the piece of gold itself. Interchangeable: You can trade
            one gold coin for another for the same value. Other examples of
            fungible items are regular coins, crypto coins, stocks and
            commodities, and cartons of mushrooms.
          </p>
        </SingleQueries>
        <SingleQueries>
          <h4>Non-fungible</h4>
          <p>
            One-of-a-kind, unique value is Key. Non-fungible things are unique.
            If you divide them, they will lose value. Example: a rare Porsche
            911 sportscar. (yes please!) Unlike a slice of a gold ingot, a piece
            of a rare Porsche doesn’t have a fractional value based on the whole
            car. If a thief steals the original trim and wheels, the owner will
            cry because the car’s value decreases by much more than the
            replacement value of the stolen items. A rare Porsche is one of a
            kind so you also cannot assume the value will be the same if you
            trade one rare Porsche for another. NFTs are a digital record on the
            blockchain that represents a unique asset that cannot be divided and
            still hold its value. You can read more about NFTs, blockchain,
            mushrooms, and dragons (because, gold ingots.) in this post.
          </p>
        </SingleQueries>
      </PagerQueries>
    </Container>
  );
};

export default NftPager;
