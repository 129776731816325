import React from 'react';
import Container from '../Container';
import ShowcaseSlider from '../landing/components/ShowcaseSlider';
import NftMarketBanner from '../newNftMarketlandingPage/components/NftMarketBanner';
import FeaturedBrand from './components/FeaturedBrand';
import FeaturedCampaign from './components/FeaturedCampaign';
import FeaturedInfluencer from './components/FeaturedInfluencer';
import InfoQuestions from './components/InfoQuestions';
import JoinImpactoverseCommunity from './components/JoinImpactoverseCommunity';
import './NewNftMarketlandingPage.css';

const NewNftMarketlandingPage = () => {
  // const [data, setData] = useState();
  // useEffect(() => {
  //   featureBrands()
  //     .then((res) => {})
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  return (
    <Container>
      <NftMarketBanner />
      <FeaturedCampaign />
      <FeaturedBrand />
      <ShowcaseSlider />
      <FeaturedInfluencer />
      <InfoQuestions />
      <JoinImpactoverseCommunity />
    </Container>
  );
};

export default NewNftMarketlandingPage;
