import { Grid } from '@mui/material';
import React from 'react';
import { Heading, SubHeading } from '../../../global/styles/style-component';
import framework1 from '../../../assets/done-for-you-partTwo/framework1.png'
import framework2 from '../../../assets/done-for-you-partTwo/framework2.png'
import framework3 from '../../../assets/done-for-you-partTwo/framework3.png'
import framework4 from '../../../assets/done-for-you-partTwo/framework4.png'
import framework5 from '../../../assets/done-for-you-partTwo/framework5.png'
import framework6 from '../../../assets/done-for-you-partTwo/framework6.png'
import framework7 from '../../../assets/done-for-you-partTwo/framework7.png'
import frontend1 from '../../../assets/done-for-you-partTwo/frontend1.png'
import frontend2 from '../../../assets/done-for-you-partTwo/frontend2.png'
import frontend3 from '../../../assets/done-for-you-partTwo/frontend3.png'
import frontend4 from '../../../assets/done-for-you-partTwo/frontend4.png'
import frontend5 from '../../../assets/done-for-you-partTwo/frontend5.png'
import frontend6 from '../../../assets/done-for-you-partTwo/frontend6.png'
import programmingLang1 from '../../../assets/done-for-you-partTwo/programming-lang1.png'
import programmingLang2 from '../../../assets/done-for-you-partTwo/programming-lang2.png'
import programmingLang3 from '../../../assets/done-for-you-partTwo/programming-lang3.png'
import programmingLang4 from '../../../assets/done-for-you-partTwo/programming-lang4.png'
import walletInt1 from '../../../assets/done-for-you-partTwo/wallet-int1.png'
import walletInt2 from '../../../assets/done-for-you-partTwo/wallet-int2.png'
import walletInt3 from '../../../assets/done-for-you-partTwo/wallet-int3.png'
import walletInt4 from '../../../assets/done-for-you-partTwo/wallet-int4.png'
import database1 from '../../../assets/done-for-you-partTwo/database1.png'
import database2 from '../../../assets/done-for-you-partTwo/database2.png'
import database3 from '../../../assets/done-for-you-partTwo/database3.png'
const DoneForYouTechnologies = () => {
    return (
        <div style={{background:'#F5F5F5', padding:'50px 0'}}>
        <div className='done-for-you-technologies-container'>
            <Heading>TECHNOLOGIES WE COVER IN BLOCKCHAIN</Heading>

            <div className="frameworks-technologies-content">
                <SubHeading>FRAMEWORKS</SubHeading>
                <Grid container>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={framework1} alt="" />
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={framework2} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={framework3} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={framework4} alt="" />
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={framework5} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={framework6} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={framework7} alt=""/>
                    </Grid>
                    
                </Grid>

                <SubHeading>Frontend / Other Technologies</SubHeading>
                <Grid container>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={frontend1} alt="" />
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={frontend2} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={frontend3} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={frontend4} alt="" />
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card javascript-icon">
                        <img src={frontend5} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card wms-icon">
                        <img src={frontend6} alt=""/>
                    </Grid>
                </Grid>


                <SubHeading>PROGRAMMING LANGUAGES</SubHeading>
                <Grid container>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={programmingLang1} alt="" />
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card rust-icon">
                        <img src={programmingLang2} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={programmingLang3} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card java-icon">
                        <img src={programmingLang4} alt="" />
                    </Grid>
                </Grid>

                <SubHeading>WALLET INTEGRATION</SubHeading>
                <Grid container>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={walletInt1} alt="" />
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={walletInt2} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={walletInt3} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={walletInt4} alt="" />
                    </Grid>
                </Grid>

                <SubHeading>DATABASE TECHNOLOGIES</SubHeading>
                <Grid container>
                    <Grid item xs={6} md={2} className="technologies-card ipfs-icon">
                        <img src={database1} alt="" />
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={database2} alt=""/>
                    </Grid>
                    <Grid item xs={6} md={2} className="technologies-card">
                        <img src={database3} alt=""/>
                    </Grid>
                </Grid>
            </div>
            </div>
        </div>
    );
};

export default DoneForYouTechnologies;