import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import {
  Btn,
  Heading,
  SubHeading,
} from '../../../global/styles/style-component';
import browseCard from '../../../assets/new-browse-campaign/browse-by-category.png';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllUserCampaign } from '../../../services/campaign';

const Campaigns = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [campaign, setCampaign] = useState([]);
  useEffect(() => {
    getAllUserCampaign(id)
      .then((res) => {
        setCampaign(res.data.userCampaigns);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className='influncer-campaign-section'>
      <Heading>OUR CAMPAIGNS</Heading>
      {/* <div style={{ textAlign: "center" }} className="browse-bttn">
        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Search By Name{" "}
        </Btn>
        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Browse By Super Campaign{" "}
        </Btn>

        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Browse by Impact Category {" "}
        </Btn>
        <Btn color={"#023047"} marginRight="15px" marginLeft="15px">
          {" "}
          <FiSearch className="browse-search-icon" /> Browse by Industry {" "}
        </Btn>
        <BiFilterAlt className="filter-icon" />
      </div> */}

      <div
        className='browse-cards'
        style={{
          width: '90%',
          margin: 'auto',
          marginTop: '60px',
          marginBottom: '50px',
        }}
      >
        <Grid container spacing={3}>
          {campaign.map(({ overview, _id }) => (
            <Grid item xs={12} md={6} lg={3}>
              <div
                style={{
                  height: '100%',
                }}
                onClick={() => {
                  navigate(`/campaign-details/${_id}`);
                }}
                className='browse-card-container'
              >
                <img
                  style={{
                    width: '100%',
                    height: '250px',
                    objectFit: 'contain',
                  }}
                  src={`${process.env.REACT_APP_DOMAIN}${
                    overview?.bannerImageUrl[0] || ''
                  }`}
                  alt=''
                />
                <SubHeading>{overview.campaignName}</SubHeading>
                <div className='browse-card-content'>
                  <span>Description</span>
                  <p className='globalDescription'>{overview.description}</p>
                  {/* <span>Ending In</span>
                  <p>08 h : 11 m : 22 s</p> */}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Campaigns;
