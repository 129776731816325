import React from "react";
import { Heading } from "../../../global/styles/style-component";
import check from '../../../assets/done-for-you-partTwo/check-symbol.png'
import dev1 from '../../../assets/done-for-you-partTwo/dev-icon1.png'
import dev2 from '../../../assets/done-for-you-partTwo/dev-icon2.png'
import dev3 from '../../../assets/done-for-you-partTwo/dev-icon3.png'
import dev4 from '../../../assets/done-for-you-partTwo/dev-icon4.png'
import dev5 from '../../../assets/done-for-you-partTwo/dev-icon5.png'
import devBg from '../../../assets/done-for-you-partTwo/dev-bg.png'

const Development = () => {
  return (
    <div className="done-for-you-dev-section">
      <div
        style={{
          position: "absolute",
          top: 135,
          right: 330,
          background: "#209AB7",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          background: "#F8AA52",
          top: 250,
          right: 160,
          width: "150px",
          height: "150px",
          borderRadius: "50%",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          background: "#F8AA52",
          top: 90,
          right: 170,
          width: "80px",
          height: "80px",
          borderRadius: "50%",
        }}
      ></div>
      {/* content */}
      <div
        style={{
          background: "#fff9",
          backdropFilter: "blur(45px)",
          padding: "40px",
          position: "relative",
        }}
      >
      <div className="done-for-you-dev-container">
      <div className="development-product">
        <Heading>THE WORLD’S ONLY FLAT-RATE DEVELOPMENT PRODUCT</Heading>
        <p>
          Helping companies scale their development teams without breaking the
          bank. Our products make it easy to request and manage your developers
          and scale up as you grow.
        </p>

        <div className="developer-container">
            <Heading>DEVELOPERS THAT ARE</Heading>
            <div>
            <div>
                <img src={check} alt="" />
                <span>Excellent at communication</span>
            </div>
            <div>
                <img src={check} alt="" />
                <span>Culturally fit </span>
            </div>
            <div>
                <img src={check} alt="" />
                <span>Technical sound</span>
            </div>
            <div>
                <img src={check} alt="" />
                <span>Available across time zones</span>
            </div>
            </div>
        </div>

        <div className="developer-cards">
          <div>
            <img src={devBg} alt="" />
            <div className="dev-card-content">
          <img src={dev1} alt="" />
          <h5>50,000+ Developers</h5>
          </div>
          </div>

          <div>
            <img src={devBg} alt="" />
            <div className="dev-card-content">
          <img src={dev2} alt="" />
          <h5>5 Days Onboarding</h5>
          </div>
          </div>

          <div>
            <img src={devBg} alt="" />
            <div className="dev-card-content">
          <img src={dev3} alt="" />
          <h5>Top 3.5%  Vetted Developers</h5>
          </div>
          </div>

          <div>
            <img src={devBg} alt="" />
            <div className="dev-card-content">
          <img src={dev4} alt="" />
          <h5>Upto 40% Less
Cost</h5>
          </div>
          </div>

          <div>
            <img src={devBg} alt="" />
            <div className="dev-card-content">
          <img src={dev5} alt="" />
          <h5>5+ Time Zone
Availability</h5>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Development;
