import React, { useState } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaLongArrowAltRight } from 'react-icons/fa';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import keyIcon from '../../assets/arrow-icon 1.png';
import logo from '../../assets/loginInWhite.png';
import '../../global/styles/LoginConfirmation.css';
import {
  recoverPasswordUsingEmail,
  verifyOtp,
} from '../../services/verification';
import '../login/PasswordReset.css';
import './EnterVerificationCode.css';

const EnterVerificationCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParm] = useSearchParams();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  return (
    <>
      <div className='login-confirmation-section'>
        <div className='login-confirmation-container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='confirmation-left'>
                <img src={logo} alt='' />
                <p></p>

                <div className='lets-get-started'>
                  <p>Let's Get Started</p>
                  <FaLongArrowAltRight className='forward-arrow' />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <img src={logo} className='responsive-logo' alt='' />
              <div className='reset-password-container new-password-container'>
                <img src={keyIcon} alt='' />
                <h5>Enter Verification Code</h5>
                <p style={{ marginBottom: '15px' }}>
                  We just sent a 6 digits verification code to your registered
                  Email. Kindly check it{' '}
                </p>

                <div className='enter-otp'>
                  <p>Enter OTP</p>
                  <OtpInput
                    hasErrored={error}
                    errorStyle={{
                      borderColor: 'red',
                    }}
                    value={otp}
                    onChange={(e) => {
                      setOtp(e);
                      setError(e.length < 6);
                    }}
                    numInputs={6}
                    separator={<span>-</span>}
                    isInputNum={true}
                  />
                  <br />
                  <div className='forgot-password'>
                    <span
                      role='button'
                      onClick={() => {
                        dispatch(
                          recoverPasswordUsingEmail(
                            {
                              email: searchParm.get('email'),
                            },
                            navigate
                          )
                        );
                      }}
                    >
                      Send the code again?
                    </span>{' '}
                    <br />
                    {/* <a href=''>Change Email Address</a> */}
                  </div>
                </div>

                <button
                  onClick={() => {
                    if (otp.length === 6) {
                      dispatch(
                        verifyOtp(
                          {
                            otp,
                          },
                          searchParm.get('token'),
                          navigate
                        )
                      );
                    } else {
                      setError(true);
                    }
                  }}
                  style={{ marginTop: '40px' }}
                >
                  Reset Password
                </button>

                <div
                  onClick={() => navigate('/login')}
                  className='back-to-login'
                >
                  <BiLeftArrowAlt className='back-arrow' />
                  <span>Back to log in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterVerificationCode;
