import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Heading, MainContainer } from '../../../global/styles/style-component';
import { getNFT } from '../../../services/landing';
import './showcaseSlider.css';
import SliderCard from './SliderCard';

const ShowcaseSlider = () => {
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = () => {
    getNFT()
      .then((res) => {
        setSliderData(res.data?.assetList);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: sliderData.length > 4 ? 4 : sliderData.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: sliderData.length > 3 ? 3 : sliderData.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: sliderData.length > 2 ? 2 : sliderData.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <MainContainer id='showCaseContainer'>
      <Box
        sx={{
          my: '50px',
          p: '3px',
          borderRadius: '40px',
          background: 'linear-gradient(129.59deg, #48bbbb, #66c48f70)',
          boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
          // mx: { xs: '20px', md: '40px' },
        }}
      >
        <Box
          sx={{
            p: { xs: '20px', md: '40px' },
            borderRadius: '38px',
            background: '#fff',
          }}
        >
          <Heading marginBottom='50px'>CAMPAIGN SHOWCASE</Heading>
          <div>
            <Slider {...settings}>
              {sliderData.map((data, i) => (
                <SliderCard
                  length={sliderData.length}
                  key={i}
                  data={data}
                  getNFT={fetchApi}
                />
              ))}
            </Slider>
          </div>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default ShowcaseSlider;
