import React from 'react';
import servicesIntro from '../../../assets/services-intro.png';

const ServicesIntro = () => {
  return (
    <div className='services-intro'>
      <div>
        <img src={servicesIntro} alt='' />
      </div>

      <div>
        <h5>
          Want to building a tech solution for impact but don't know where to
          start? No problem! We can help you get it done? Align with impact, and
          have your tech developments powered by demo. We use our tech expertise
          to help you develop projects that make a difference in the world!
        </h5>
      </div>
    </div>
  );
};

export default ServicesIntro;
