import React from 'react';
import CauseForm from '../../../form/components/causeForm';
import DashboardContainer from '../../DashboardContainer';

const Causes = () => {
  return (
    <DashboardContainer>
      <CauseForm />
    </DashboardContainer>
  );
};

export default Causes;
