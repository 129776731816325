import React from 'react';
import opportunitiesImg from '../../../assets/opportunities.png';
import { MainContainer } from '../../../global/styles/style-component';

const Opportunities = () => {
  return (
    <MainContainer bgColor={'#219ebc'}>
      <div className='opportunities-content'>
        <img src={opportunitiesImg} alt='' />
        <p>
          The opportunity in Web3 is to siphon off your best fans and customers
          from social platforms that currently own your relationship with them,
          as well as all their data.{' '}
        </p>
      </div>
    </MainContainer>
  );
};

export default Opportunities;
