import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';

const MarketplaceLandingPageBanner = ({ imgUrl }) => {
  return (
    <>
      <PagerBanner
        imgUrl={require('../../../assets/nft-details/nft-details-banner.png')}
        className='landing-page-banner'
      >
      </PagerBanner>
    </>
  );
};

export default MarketplaceLandingPageBanner;
