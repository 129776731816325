import React from 'react';
import { HalfColoredContainer } from '../../global/styles/style-component';
import Container from '../Container';
import InfluencerRegistrationForms from './components/InfluencerRegistrationForms';
import { CategoriesRegistrationHeader } from './formStyle';
import './InfluencerForm.css';

const InfluencerRegistrationForm = () => {
  return (
    <Container>
      <HalfColoredContainer
        height='auto'
        color='#219EBC'
        className='half-colored-container'
      >
        <CategoriesRegistrationHeader>
          <h2>Influencer Registration </h2>
        </CategoriesRegistrationHeader>
        <InfluencerRegistrationForms />
      </HalfColoredContainer>
    </Container>
  );
};

export default InfluencerRegistrationForm;
