import React from 'react';
import {
  PagerBanner,
  PagerDescription,
  PagerDescriptionHeading,
  PagerDescriptionImage,
  PagerQueries,
  SingleQueries,
} from './PagerStyle';
import nftBg from '../../assets/images/nft-bg.png';
import Container from '../Container';
import { BannerHeading } from '../../global/styles/style-component';
const imgUrl = require('../../assets/nft-details/nft-details-banner.png');

const CryptoWallet = () => {
  return (
    <Container>
      <PagerBanner imgUrl={imgUrl}>
        <BannerHeading>Intro To Crypto Wallets</BannerHeading>
      </PagerBanner>
      <PagerDescription>
        <PagerDescriptionHeading>
          <h2>What is a Crypto Wallet?</h2>
          <p>
            People need a place to put their money and credit cards, right?
            Cryptocurrency is the same - people need a place to store their
            cryptocurrency and other digital assets like tokens, loyalty points,
            digital tickets, NFTs, and more. A crypto wallet allows you to
            interact with the blockchain to complete transactions like buying,
            selling, investing, gifting, and receiving cryptocurrency and other
            digital assets.
          </p>
        </PagerDescriptionHeading>
        <PagerDescriptionImage>
          <img src={nftBg} alt='' />
        </PagerDescriptionImage>
      </PagerDescription>

      <PagerQueries>
        <SingleQueries>
          <h4>What Kinds of Crypto Wallets Are Available?</h4>
          <p>
            There are many different types of crypto wallets, but the most
            popular ones are software wallets, hardware wallets, and paper
            wallets. Software wallets are digital wallets that are stored on
            your computer or mobile device. Hardware wallets are physical
            devices that store your cryptocurrencies offline. Paper wallets are
            offline cold storage options that involve printing out your public
            and private keys on a piece of paper.
          </p>
        </SingleQueries>
        <SingleQueries>
          <h4>Keys are Key to Crypto Wallets</h4>
          <p>
            There are two types of keys in a crypto wallet: public keys and
            private keys. These keys are used to access your coins on the
            blockchain. Public keys are like a traditional bank account routing
            number. They allow people to send you cryptocurrencies. Private keys
            are like your PIN. They allow you to access your cryptocurrencies.
            You should never never never share your private key because anyone
            can use it to steal everything in your wallet, and you have no way
            of recovering your funds and digital assets.
          </p>
        </SingleQueries>
        <SingleQueries>
          <h4>How Does the Wallet Work?</h4>
          <p>
            Once you sign up for the wallet of your choice, you can “fund” your
            wallet by purchasing cryptocurrency. Or if you just want to use your
            credit card, for now, you can use your wallet to store your digital
            tokens, loyalty points, NFTs, and more. You can send money to
            sellers using their public keys. You can connect your wallet to a
            marketplace to purchase digital assets like art NFTs or tokens.
            Others can sell or give you digital assets or cryptocurrency by
            sending payments and assets to your public key. Don’t have a wallet
            yet? Go to demo.com to learn more and sign up for an demo wallet
            today!
          </p>
        </SingleQueries>
      </PagerQueries>
    </Container>
  );
};

export default CryptoWallet;
