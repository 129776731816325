import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getNftDetail } from '../../../../services/campaign';
import { getCategory, getImpactCategory } from '../../../../services/vetting';
import ApiCall from '../../../../utils/axiosCall';
import { errorToast, successToast } from '../../../../utils/toast';
import DashboardContainer from '../../DashboardContainer';
import './NFTMinting.css';
import { NFTPercentageBtn } from './nftStyled';

const ListMintedNft = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [customPercentage, setCustomPercentage] = useState(false);

  const [data, setData] = useState({
    purpose: '0',
    nft: {
      value: null,
      error: '',
    },
    itemName: {
      value: '',
      error: '',
    },
    description: {
      value: '',
      error: '',
    },
    priceUsd: {
      value: '',
      error: '',
    },
    nftCategory: {
      value: '',
      error: '',
    },
    impactCategory: {
      value: '',
      error: '',
    },
    impactCampaign: {
      value: '',
      error: '',
    },
    percentageToImpact: {
      value: '',
      error: '',
    },
  });

  // const [nftDetail, setNftDetail] = useState();

  const [nftCategory, setNftCategory] = useState([]);
  const [impactCategory, setImpactCategory] = useState([]);
  const [impactCampaign, setImpactCampaign] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const nft = await getCategory('NFT');
      const impact = await getCategory('CAMPAIGN');
      setImpactCategory(impact.data.categoriesList);
      setNftCategory(nft.data.categoriesList);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (data.impactCategory.value !== '') {
        const impact = await getImpactCategory(data.impactCategory.value);
        setImpactCampaign(impact.data.impactInitiatives);
      } else {
        setImpactCampaign([]);
      }
    };
    fetch();
  }, [data.impactCategory.value]);

  useEffect(() => {
    getNFT();
  }, []);

  const getNFT = () => {
    getNftDetail(id)
      .then((res) => {
        const {
          imageUrl,
          impactCategory,
          impactId,
          description,
          percentageToImpact,
          priceUsd,
          itemName,
          nftCategory,
        } = res.data.asset;
        setData({
          ...data,
          nft: {
            ...data.nft,
            value: `${process.env.REACT_APP_DOMAIN}${imageUrl}`,
          },
          description: {
            ...data.description,
            value: description,
          },
          itemName: {
            ...data.itemName,
            value: itemName,
          },
          priceUsd: {
            ...data.priceUsd,
            value: priceUsd,
          },
          percentageToImpact: {
            ...data.percentageToImpact,
            value: percentageToImpact,
          },
          nftCategory: {
            ...data.nftCategory,
            value: nftCategory,
          },
          impactCategory: {
            ...data.impactCategory,
            value: impactCategory,
          },
          impactCampaign: {
            ...data.impactCampaign,
            value: impactCampaign.find((i) => i.id === impactId),
          },
        });

        console.log(impactCampaign.find((i) => i.id === impactId));

        // setNftDetail(res.data.asset);
      })
      .catch((err) => errorToast(err.response.data.message));
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const hintList = [
    'Create an NFT to gift to another user with an demoet Address',
    'Create an NFT to contribute it to an Impact Campaign. Choose to allocate 50% or more of the earnings to an Impact Initiative that you choose. ',
    'Create an NFT for your own portfolio. Choose a small % to benefit an Impact Campaign',
    'Create multiple NFTs to award to multiple users. Recipients should have an demo or Polygon Wallet ',
    'Pay based on the number of NFTs you want to create.',
  ];

  return (
    <DashboardContainer>
      <>
        <strong className='mt-3'>LIST NFT</strong>
        <div className='d-flex flex-column mintingDetails'>
          <b>
            Image, Video, Audio or 3D Model (*your NFT will be available in your
            demo wallet and can be viewed on your profile page and in your
            dashboard)
          </b>
          <br />
          <span>
            Supported file types supported: JPEG, PNG, SVG. BMP, HEIC, MP4, WMV,
            MOV, AVI, FLV, MKV, GIF Max Size: 15 MB
          </span>
          <hr />
          <div className='nftMintingInputs'>
            <div className='d-flex justify-content-between'>
              <div>
                <div
                  className='d-flex flex-row me-5  mb-3'
                  style={{ gap: '5px' }}
                >
                  <strong className=''>NFT PURPOSE</strong>
                  <Tooltip title={hintList[data.purpose]} arrow>
                    <HelpIcon />
                  </Tooltip>
                </div>
                <select
                  value={data.purpose}
                  onChange={(e) => {
                    setData({
                      ...data,
                      purpose: e.target.value,
                    });
                  }}
                >
                  <option value={'0'}>CONTRIBUTION</option>
                  {/* <option value={'1'}>GIFT</option> */}
                  <option value={'2'}>PORTFOLIO</option>
                </select>
              </div>
            </div>
            <div
              style={{
                color: data.itemName.error !== '' && 'red',
                fontSize: '13px',
              }}
            >
              <label htmlFor='itemName'>Item Name</label>
              <input
                disabled
                style={{
                  outline: 'none',
                  borderColor: data.itemName.error !== '' ? 'red' : '',
                }}
                value={data.itemName.value}
                type='text'
                name='itemName'
                id='itemName'
                onChange={(e) => {
                  setData({
                    ...data,
                    itemName: {
                      value: e.target.value,
                      error:
                        e.target.value === '' ? 'Item name is required' : '',
                    },
                  });
                }}
              />
              <span>{data.itemName?.error}</span>
            </div>
            {data.purpose === '1' && (
              <div
                style={{
                  color: data.giftRecipientAddress.error !== '' && 'red',
                  fontSize: '13px',
                }}
              >
                <label htmlFor='receiverWalletAddress'>
                  Receiver Wallet Address
                </label>
                <input
                  style={{
                    outline: 'none',
                    borderColor:
                      data.giftRecipientAddress.error !== '' ? 'red' : '',
                  }}
                  value={data.giftRecipientAddress.value}
                  type='text'
                  name='receiverWalletAddress'
                  id='receiverWalletAddress'
                  disabled
                  onChange={(e) => {
                    setData({
                      ...data,
                      giftRecipientAddress: {
                        value: e.target.value,
                        error:
                          e.target.value === ''
                            ? 'Receiver Wallet address is required'
                            : '',
                      },
                    });
                  }}
                />
                <span>{data.giftRecipientAddress?.error}</span>
              </div>
            )}
            {/* <FileUploader name='file' classes='uploadFileContainer'> */}
            <div className='d-flex flex-column p-2 uploadFileContainer'>
              {/* <input
                type='file'
                hidden
                name='nftFile'
                id='nftFile'
                accept='image/png, image/jpeg'
                onChange={(e) => {
                  setData({
                    ...data,
                    nft: {
                      value: e.target.files[0],
                      error:
                        e.target.files.length > 0 ? '' : 'file is required',
                    },
                  });
                }}
              /> */}
              {/* <label
                  className='d-flex flex-column align-items-center'
                  htmlFor='nftFile'
                > */}
              <img src={data.nft.value} alt='' />
              {/* <span>
                    Drag files to upload
                    <br />
                    or <b>Browse</b>
                  </span>
                  {data.nft.value && (
                    <Button
                      onClick={() => {
                        setOpenEditor(true);
                      }}
                      className='mt-2'
                      variant='outlined'
                    >
                      Edit
                    </Button>
                  )}
                </label> */}
              {data.purpose == '1' && (
                <span>
                  <strong>Note :</strong>
                  <br /> We charge a platform fee of $4.99 to gift an NFT, with
                  25% of that going to an impact initiative of your choice
                </span>
              )}
            </div>
            {/* </FileUploader> */}
            <div
              style={{
                color: data.description?.error !== '' && 'red',
                fontSize: '13px',
              }}
            >
              <label htmlFor='description'>
                Description ({data.description.value.length}/599)
              </label>
              <textarea
                name='description'
                id='description'
                disabled
                cols='30'
                minLength={'60'}
                maxLength={'599'}
                rows='7'
                value={data.description.value}
                style={{
                  outline: 'none',
                  borderColor: data.description?.error !== '' ? 'red' : '',
                }}
                onChange={(e) => {
                  setData({
                    ...data,
                    description: {
                      value: e.target.value,
                      error:
                        e.target.value === ''
                          ? 'Description is required'
                          : e.target.value.length > 15
                          ? ''
                          : 'Description should contain at least 15 characters',
                    },
                  });
                }}
              ></textarea>
              <span>{data.description?.error}</span>
            </div>
            {data.purpose !== '1' && (
              <div
                style={{
                  color: data.priceUsd?.error !== '' && 'red',
                  fontSize: '13px',
                }}
                className='salePriceContainer'
              >
                <label htmlFor='salePrice'>Instant sale price</label>
                <div>
                  <input
                    value={data.priceUsd.value}
                    onChange={(e) => {
                      setData({
                        ...data,
                        priceUsd: {
                          value: e.target.value,
                          error:
                            e.target.value === '' ||
                            parseFloat(e.target.value) < 9.99
                              ? 'Sale Price must be at least 10 $ is required'
                              : '',
                        },
                      });
                    }}
                    style={{
                      outline: 'none',
                      borderColor: data.priceUsd?.error !== '' ? 'red' : '',
                    }}
                    min={100}
                    step={0.01}
                    type='number'
                    name='salePrice'
                    id='salePrice'
                  />
                  <select>
                    <option>USD</option>
                  </select>
                </div>
                <span>{data.priceUsd?.error}</span>
              </div>
            )}
            <div
              style={{
                color: data.nftCategory?.error !== '' && 'red',
                fontSize: '13px',
              }}
            >
              <label htmlFor='nftCategory'>NFT Category</label>
              <select
                disabled
                onChange={(e) => {
                  setData({
                    ...data,
                    nftCategory: {
                      value: e.target.value,
                      error: e.target.value === '' ? 'Select nft category' : '',
                    },
                  });
                }}
                value={data.nftCategory.value}
                style={{
                  outline: 'none',
                  borderColor: data.nftCategory?.error !== '' ? 'red' : '',
                }}
                id='nftCategory'
              >
                <option value=''>Select NFT category</option>
                {nftCategory.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span>{data.nftCategory?.error}</span>
            </div>
            <div
              style={{
                gridRow: ' 6 / span 2',
                gridColumn: '2',
              }}
              className='d-flex align-items-center gap-3 mt-4'
            >
              {data.purpose !== '1' && (
                <>
                  <strong>% To impact</strong>

                  <div className='percentageContainer'>
                    <NFTPercentageBtn
                      onClick={() => {
                        setCustomPercentage(false);
                        setData({
                          ...data,
                          percentageToImpact: {
                            value: data.purpose === '0' ? 50 : 10,
                            error: '',
                          },
                        });
                      }}
                      active={
                        (data.percentageToImpact.value == 50 ||
                          data.percentageToImpact.value == 10) &&
                        !customPercentage
                      }
                    >
                      {data.purpose === '0' ? '50%' : '10%'}
                    </NFTPercentageBtn>
                    <NFTPercentageBtn
                      onClick={() => {
                        setCustomPercentage(false);
                        setData({
                          ...data,
                          percentageToImpact: {
                            value: data.purpose === '0' ? 55 : 15,
                            error: '',
                          },
                        });
                      }}
                      active={
                        (data.percentageToImpact.value == 55 ||
                          data.percentageToImpact.value == 15) &&
                        !customPercentage
                      }
                    >
                      {data.purpose === '0' ? '55%' : '15%'}
                    </NFTPercentageBtn>
                    <NFTPercentageBtn
                      onClick={() => {
                        setCustomPercentage(false);
                        setData({
                          ...data,
                          percentageToImpact: {
                            value: data.purpose === '0' ? 60 : 20,
                            error: '',
                          },
                        });
                      }}
                      active={
                        (data.percentageToImpact.value == 60 ||
                          data.percentageToImpact.value == 20) &&
                        !customPercentage
                      }
                    >
                      {data.purpose === '0' ? '60%' : '20%'}
                    </NFTPercentageBtn>
                    <NFTPercentageBtn
                      active={customPercentage}
                      onClick={() => setCustomPercentage(!customPercentage)}
                    >
                      Custom %
                    </NFTPercentageBtn>
                    {customPercentage && (
                      <input
                        id='customPercentage'
                        style={{
                          outline: 'none',
                          borderColor:
                            data.percentageToImpact?.error !== '' ? 'red' : '',
                        }}
                        type='number'
                        name='customPercentage'
                        onChange={(e) => {
                          if (data.purpose === '0') {
                            setData({
                              ...data,
                              percentageToImpact: {
                                value: e.target.value,
                                error:
                                  e.target.value >= 50 && e.target.value <= 100
                                    ? ''
                                    : 'Percentage should grater then 50',
                              },
                            });
                          } else {
                            setData({
                              ...data,
                              percentageToImpact: {
                                value: e.target.value,
                                error:
                                  e.target.value >= 10 && e.target.value <= 100
                                    ? ''
                                    : 'Percentage should grater then 10',
                              },
                            });
                          }
                        }}
                      />
                    )}
                    <span
                      style={{
                        color: data.percentageToImpact?.error !== '' && 'red',
                        fontSize: '13px',
                      }}
                    >
                      {data.percentageToImpact?.error}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                color: data.impactCategory?.error !== '' && 'red',
                fontSize: '13px',
              }}
            >
              <label htmlFor='impactCategory'>Impact Category</label>
              <select
                value={data.impactCategory.value}
                onChange={(e) => {
                  setData({
                    ...data,
                    impactCategory: {
                      value: e.target.value,
                      error:
                        e.target.value === '' ? 'Select impact category' : '',
                    },
                  });
                }}
                style={{
                  outline: 'none',
                  borderColor: data.impactCategory?.error !== '' ? 'red' : '',
                }}
                id='impactCategory'
              >
                <option value=''>Select Impact category</option>
                {impactCategory.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span>{data.impactCategory?.error}</span>
            </div>
            <div
              style={{
                color: data.impactCampaign?.error !== '' && 'red',
                fontSize: '13px',
              }}
            >
              <label htmlFor='impactCampaign'>Impact Initiative</label>
              <select
                value={JSON.stringify(data.impactCampaign.value)}
                onChange={(e) => {
                  setData({
                    ...data,
                    impactCampaign: {
                      value:
                        e.target.value !== '' ? JSON.parse(e.target.value) : '',
                      error:
                        e.target.value === '' ? 'Select Impact Initiative' : '',
                    },
                  });
                }}
                style={{
                  outline: 'none',
                  borderColor: data.impactCampaign?.error !== '' ? 'red' : '',
                }}
                id='impactCampaign'
              >
                <option value=''>Select Impact Initiative</option>
                {impactCampaign.map((item, index) => (
                  <option key={index} value={JSON.stringify(item)}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span>{data.impactCampaign?.error}</span>
            </div>
          </div>

          <button
            onClick={() => {
              // if (
              //   data.itemName.value === '' ||
              //   parseFloat(data.priceUsd.value) === 0 ||
              //   (data.purpose !== '1' && data.priceUsd.value === '') ||
              //   data.impactCampaign.value === '' ||
              //   data.nftCategory.value === '' ||
              //   data.impactCategory.value === '' ||
              //   (data.purpose !== '1' &&
              //     data.percentageToImpact.value === '') ||
              //   (data.purpose === '0' &&
              //     !(
              //       data.percentageToImpact.value >= 50 &&
              //       data.percentageToImpact.value <= 100
              //     )) ||
              //   (data.purpose === '2' &&
              //     !(
              //       data.percentageToImpact.value >= 10 &&
              //       data.percentageToImpact.value <= 100
              //     )) ||
              //   data.description.value.length < 15 ||
              //   data.nft.value === null ||
              //   (data.purpose === '1' && data.giftRecipientAddress.value === '')
              // ) {
              //   console.log(
              //     data.itemName.value === '',
              //     parseFloat(data.priceUsd.value) === 0,
              //     data.purpose !== '1' && data.priceUsd.value === '',
              //     data.impactCampaign.value === '',
              //     data.nftCategory.value === '',
              //     data.impactCategory.value === '',
              //     data.purpose !== '1' && data.percentageToImpact.value === '',
              //     data.purpose === '0' &&
              //       !(
              //         data.percentageToImpact.value >= 50 &&
              //         data.percentageToImpact.value <= 100
              //       ),
              //     data.purpose === '2' &&
              //       !(
              //         data.percentageToImpact.value >= 10 &&
              //         data.percentageToImpact.value <= 100
              //       ),
              //     data.description.value.length < 15,
              //     data.nft.value === null,
              //     data.purpose === '1' && data.giftRecipientAddress.value === ''
              //   );
              //   setData({
              //     ...data,
              //     itemName: {
              //       ...data.itemName,
              //       error:
              //         data.itemName.value === '' ? 'Item name is required' : '',
              //     },
              //     giftRecipientAddress: {
              //       ...data.giftRecipientAddress,
              //       error:
              //         data.giftRecipientAddress.value === ''
              //           ? 'Receiver Wallet address is required'
              //           : '',
              //     },
              //     priceUsd: {
              //       ...data.priceUsd,
              //       error:
              //         data.priceUsd.value === '' ||
              //         parseFloat(data.priceUsd.value) < 9.99
              //           ? 'Sale Price must be at least 10 $ is required'
              //           : '',
              //     },
              //     description: {
              //       ...data.description,
              //       error:
              //         data.description.value === ''
              //           ? 'Description is required'
              //           : data.description.value.length > 15
              //           ? ''
              //           : 'Description should be longer then 15 character long',
              //     },
              //     impactCampaign: {
              //       ...data.impactCampaign,
              //       error:
              //         data.impactCampaign.value === ''
              //           ? 'Impact Campaign is required'
              //           : '',
              //     },
              //     impactCategory: {
              //       ...data.impactCategory,
              //       error:
              //         data.impactCategory.value === ''
              //           ? 'Impact Category is required'
              //           : '',
              //     },
              //     nftCategory: {
              //       ...data.nftCategory,
              //       error:
              //         data.nftCategory.value === ''
              //           ? 'NFT Category is required'
              //           : '',
              //     },
              //     percentageToImpact: {
              //       ...data.percentageToImpact,
              //       error:
              //         data.percentageToImpact.value === ''
              //           ? 'Percentage is required'
              //           : '',
              //     },
              //   });
              //   if (data.nft.value === null) {
              //     alert('File is required');
              //   }
              // } else {
              if (
                Object.values(data).every((i) => {
                  return typeof i === 'object'
                    ? typeof i.value === 'object'
                      ? i.value.name
                      : i.value
                    : i;
                })
              ) {
                ApiCall.post('user/marketplace/list-on-marketplace', {
                  priceUsd: data.priceUsd.value,
                  assetId: id,
                  impactId: data.impactCampaign.value.id,
                  impactCategory: data.impactCategory.value,
                  impactInitiativeName: data.impactCampaign.value.name,
                  impactCampaignType: data.impactCampaign.value.type,
                  percentageToImpact: data.percentageToImpact.value,
                })
                  .then((res) => {
                    successToast(res.data.message);
                    navigate(-1);
                  })
                  .catch((err) => {
                    errorToast(err?.response.data.message);
                  });
              } else {
                setData({
                  ...data,
                  impactCategory: {
                    ...data.impactCategory,
                    error:
                      data.impactCategory.value === ''
                        ? 'Please provide impact category'
                        : '',
                  },
                  impactCampaign: {
                    ...data.impactCampaign,
                    error: !data.impactCampaign?.value?.name
                      ? 'Please provide impact initiative'
                      : '',
                  },
                });
              }
              // }
            }}
            className='nftBtn align-self-center mt-3'
          >
            Create Now
          </button>
          <span className='align-self-center mt-2 p-2' role='button'>
            Cancel
          </span>
        </div>
      </>
    </DashboardContainer>
  );
};

export default ListMintedNft;
