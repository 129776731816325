import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import { BannerHeading } from '../../../global/styles/style-component';

const FaqBanner = () => {
  return (
    <PagerBanner
      imgUrl={require('../../../assets/nft-details/nft-details-banner.png')}
    >
      <BannerHeading>FAQ’s</BannerHeading>
    </PagerBanner>
  );
};

export default FaqBanner;
