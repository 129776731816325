import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { HalfColoredContainer } from '../../global/styles/style-component';
import { togglePopup } from '../../redux/popup/popUpReducer';
import ApiCall from '../../utils/axiosCall';
import { errorToast, successToast } from '../../utils/toast';
import './Popup.css';

const Popup = () => {
  const [selector, setSelector] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const [isVerified, setIsVerified] = useState(false);
  useEffect(() => {
    checkIfVerified();
  }, []);

  const checkIfVerified = () => {
    ApiCall.get('user/account-verification')
      .then((res) => {
        setIsVerified(res.data.success);
      })
      .catch((err) => setIsVerified(err.response?.data?.success));
  };

  return (
    <HalfColoredContainer
      id='popUpContainer'
      color='transparent'
      bgColor='transparent'
    >
      <div>
        <IconButton onClick={() => dispatch(togglePopup())}>
          <CloseIcon />
        </IconButton>
        <div className='popUpHeader'>
          <h2>WELCOME TO demo!</h2>
          <h4>
            We love that you are on board with our mission to scale impact!
          </h4>
        </div>
        <div className='popUpContent'>
          <span>
            To earn from the platform, you will need to verify your account with
            our third payment provider STRIPE.{' '}
            <span
              onClick={() => {
                if (isVerified) {
                  ApiCall.get('payments/connect-account/payment-dashboard')
                    .then((res) => {
                      window.open(res.data.url, '_blank');
                    })
                    .catch((err) => errorToast(err.response?.data?.message));
                } else {
                  ApiCall.post('user/verify-account')
                    .then((res) => {
                      // checkIfVerified();
                      window.open(res.data.url, '_self');
                    })
                    .catch((err) => errorToast(err.response?.data?.message));
                }
              }}
            >
              Click HERE to verify
            </span>
            . You can then create NFTs, register your brand/organization or
            nonprofit/cause and launch a campaign from your dashboard.
          </span>
        </div>
      </div>
      <Modal open={selector} onClose={() => setSelector(false)}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div id='registrationCategory'>
            <IconButton onClick={() => setSelector(false)}>
              <CloseIcon />
            </IconButton>
            <button
              onClick={() => {
                dispatch(togglePopup());
                navigate('/brand-agency-form');
                setSelector(false);
              }}
            >
              Brand & Agency
            </button>
            <button
              onClick={() => {
                dispatch(togglePopup());
                navigate('/influencer-form');
                setSelector(false);
              }}
            >
              Influencer
            </button>
            <button
              onClick={() => {
                dispatch(togglePopup());
                navigate('/noProfitCauses');
                setSelector(false);
              }}
            >
              Non-profit or Cause
            </button>
          </div>
        </div>
      </Modal>
    </HalfColoredContainer>
  );
};

export default Popup;
