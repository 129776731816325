import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 18%;
  padding: 1em;
  box-shadow: 0 5px 10px #ccc;
  & > img {
    flex: 0.5;
    object-fit: contain;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
  }
  & > .navItemContainer {
    flex: 3;
    margin-top: 30px;
  }
  & > .navItemBottom {
    flex: 0.5;
  }
`;

export const SidebarItem = styled.div`
  width: 100%;
  & > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    background: ${({ active }) =>
      active
        ? 'linear-gradient(144.95deg, #F53803 4.51%, #F5D020 99.13%);'
        : '#f6f8fb'};
    padding: 1em 0.7em;
    text-transform: capitalize;
    color: ${({ active }) => (active ? '#fff' : '#000')};
    border-radius: 5px;
    transition: all 0.5s;
    cursor: pointer;
    gap: 1em;
    &:hover {
      background: linear-gradient(144.95deg, #f53803 4.51%, #f5d020 99.13%);
      color: #fff;
      transform: translateX(10%);
    }
  }
`;
export const ProfileTab = styled.div`
  background: ${({ active }) =>
    active
      ? 'linear-gradient(144.95deg, #F53803 4.51%, #F5D020 99.13%);'
      : '#f6f8fb'};
  padding: 1em 0.7em;
  text-transform: capitalize;
  color: ${({ active }) => (active ? '#fff' : '#000')};
  border-radius: 5px;
  transition: all 0.5s;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  gap: 1em;
`;
