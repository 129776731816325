import React from 'react';
import qa from '../../assets/images/1 23456680.png';

const NftHubQA = () => {
  return (
    <div className='hub-qa-section'>
      <div className='row qa-align-items'>
        <div className='col-md-6'>
          <div className='qa-img'>
            <img src={qa} alt='' />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='qa-content'>
            <h4>Q</h4>
            <p>
              if the art work doesn’t look like a real object, doesn’t take
              advanced skill to create, and is so simple almost anyone with time
              and desire can copy it, how is it valuable?
            </p>

            <h4>A</h4>
            <p>
              Whether it's digital artwork for NFT drops or real tangible art,
              the value of what appears to be “easy” art derives from two
              things: the story behind the art and artist, and the community or
              movement around the artist.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftHubQA;
