import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Heading, SubHeading } from '../../../global/styles/style-component';
import {
  Comments,
  NftContribute,
  Reporting,
} from '../../campaignDetails/components';
import MilestoneCard from './MilestoneCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const WhySupportUs = ({ description, feasibility, impact }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='why-support-us-section'>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          className='support-us-tabs'
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='PROJECT DETAILS' {...a11yProps(0)} />
            <Tab label='NFT CONTRIBUTORS' {...a11yProps(1)} />
            <Tab label='COMMENTS' {...a11yProps(2)} />
            <Tab label='REPORTING' {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className='why-support-us-content'>
          <Heading>WHY SUPPORT US</Heading>
          <div className='single-support-container'>
            <SubHeading>Campaign Overview</SubHeading>
            <p>{description}</p>
          </div>
          <div className='single-support-container'>
            <SubHeading>Impact</SubHeading>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3em',
                margin: '0 3em',
              }}
            >
              {impact?.milestones.map(
                (
                  { description, heading, milestoneDate, milestoneKpis },
                  index
                ) => (
                  <MilestoneCard
                    description={description}
                    heading={heading}
                    milestoneDate={milestoneDate}
                    milestoneKpis={milestoneKpis}
                  />
                )
              )}
            </div>
            {/* <ul>
              {impact &&
                Object.keys(impact).map(
                  (item) =>
                    item !== 'agreeToCollaborate' && (
                      <li>
                        <span style={{ textTransform: 'capitalize' }}>
                          {item === 'sdgs'
                            ? item
                                ?.replace(/([A-Z])/g, ' $1')
                                .trim()
                                .slice(0, 3)
                                .toUpperCase() + 's'
                            : item === 'kpis'
                            ? 'Milestones'
                            : item?.replace(/([A-Z])/g, ' $1').trim()}
                          :
                        </span>{' '}
                        {impact[item].toString()}
                      </li>
                    )
                )}
            </ul> */}
          </div>
          <div className='single-support-container'>
            <SubHeading>Feasibility</SubHeading>
            <ul>
              {feasibility &&
                Object.keys(feasibility).map((item) => (
                  <li>
                    <span style={{ textTransform: 'capitalize' }}>
                      {item === 'circumstances'
                        ? 'Potential Pitfalls '
                        : item?.replace(/([A-Z])/g, ' $1').trim()}
                      :{' '}
                    </span>
                    {feasibility[item]?.toString()}
                  </li>
                ))}
            </ul>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NftContribute />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Comments />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Reporting />{' '}
        </TabPanel>
      </Box>
    </div>
  );
};

export default WhySupportUs;
