import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLoginGoogle } from '../../redux/user/actionCreator';

const LoginWithGoogle = ({ method = '' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const googleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     console.log(tokenResponse);
  //     dispatch(userLoginGoogle(tokenResponse, navigate));
  //   },
  //   onError: (errorResponse) => console.log(errorResponse),
  // });
  return (
    <div
      onClick={() => {
        dispatch(userLoginGoogle(navigate));
      }}
    >
      <img src={require('../../assets/google.png')} alt='' />
      <button>{method} in with Google</button>
    </div>
  );
};

export default LoginWithGoogle;
