import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import { verifyOtp } from '../../../services/verification';

const RecoverPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParm] = useSearchParams();
  useEffect(() => {
    dispatch(
      verifyOtp(
        {
          otp: searchParm.get('otp'),
        },
        searchParm.get('token'),
        navigate
      )
    );
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      Email Verification
      <DotLoader color='#36d7b7' />
    </div>
  );
};

export default RecoverPassword;
