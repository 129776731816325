import React from 'react';
import { HalfColoredContainer } from '../../global/styles/style-component';
import CauseForm from './components/causeForm';
import { CategoriesRegistrationHeader } from './formStyle';
import './NonProfitCauses.css';

const NonProfitCauses = () => {
  return (
    <HalfColoredContainer height='auto' color='#219EBC'>
      <CategoriesRegistrationHeader>
        <h2>Non Profit & Causes</h2>
        <p></p>
      </CategoriesRegistrationHeader>
      <CauseForm />
    </HalfColoredContainer>
  );
};

export default NonProfitCauses;
