import styled from 'styled-components';

export const NftMarketPlaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 100px;

  & .nftMarketPlaceFilterBar {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    & label {
      font-weight: bold;
    }
    & input {
      width: 100%;
      padding: 10px 15px;
      border-radius: 7px;
      border: 1px solid gray;
      margin-top: 10px;
    }

    & button {
      padding: 10px 15px;
      outline: none;
    }
  }
`;
