import React from 'react';
import { useDispatch } from 'react-redux';
import { userLoginFacebook } from '../../redux/user/actionCreator';

const LoginWithFacebook = ({ method }) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(userLoginFacebook());
      }}
    >
      <img src={require('../../assets/facebook.png')} alt='' />
      <button>{method} in with Facebook</button>
    </div>
  );
};

export default LoginWithFacebook;
