import { Tab, Tabs } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import ApiCall from '../../../../utils/axiosCall';
import { errorToast } from '../../../../utils/toast';
import DashboardContainer from '../../DashboardContainer';
import '@blueprintjs/core/lib/css/blueprint.css';
import NftLists from './components/NftLists';

const Portfolio = () => {
  const [activeTabId, setActiveTabId] = useState();
  const list = [
    { title: 'All', key: 'all' },
    { title: 'Portfolio', key: 'portfolio' },
    { title: 'Gifts Sent', key: 'giftedOut' },
    { title: 'Gifts Received', key: 'giftedIn' },
    { title: 'Purchased', key: 'purchased' },
    { title: 'Contributions', key: 'contributions' },
    { title: 'Favorite', key: 'favouriteAssets' },
  ];
  const [collection, setCollection] = useState({
    avatar: '',
    contributions: [],
    giftedOut: [],
    giftedIn: [],
    portfolio: [],
    purchased: [],
    favouriteAssets: [],
    username: '',
    walletAddress: '',
  });
  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = () => {
    ApiCall.get('user/list/my-collection')
      .then((res) => {
        setCollection(res.data.userCollection);
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };
  return (
    <DashboardContainer>
      <Tabs
        onChange={(e) => {
          setActiveTabId(e);
        }}
        renderActiveTabPanelOnly
        selectedTabId={activeTabId}
        vertical={false}
        animate={true}
      >
        {list.map(({ key, title }) => (
          <Tab
            id={key}
            title={title}
            panel={
              <NftLists
                fetchApi={fetchApi}
                type={key}
                items={
                  collection
                    ? key === 'all'
                      ? {
                          contributions: [...collection.contributions],
                          giftedOut: [...collection.giftedOut],
                          giftedIn: [...collection.giftedIn],
                          portfolio: [...collection.portfolio],
                          purchased: [...collection.purchased],
                        }
                      : { [key]: collection[key] }
                    : null
                }
              />
            }
          />
        ))}
      </Tabs>
    </DashboardContainer>
  );
};

export default Portfolio;
