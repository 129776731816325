import React from "react";
import Container from "../Container";
import "../../pages/browseInfluencers/BrowseInfluencer.css";
import NposAndCausesBanner from "./components/NposAndCausesBanner";
import NposAndCausesCampaign from "./components/NposAndCausesCampaign";

const BrowseNposAndCauses = () => {
  return (
    <Container>
      <NposAndCausesBanner/>
      <NposAndCausesCampaign/>
    </Container>
  );
};

export default BrowseNposAndCauses;
