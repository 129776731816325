import styled from 'styled-components';

export const DetailsBanner = styled.div`
  text-align: center;
  width: fit-content;
  position: relative;
  z-index: 5;
  & > div:nth-child(1) {
    background: #023047;
    margin: auto;
  }
  & > div:nth-child(1) > img {
    background: #fff;
    width: 94%;
    margin-top: 15px;
    height: 150px;
    object-fit: contain;
  }
  & > div:nth-child(1) > h2 {
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0;
  }
  & > div:nth-child(2) {
    background: #f8991d;
    margin: auto;
    text-align: left;
    padding: 10px 30px;
  }
  & > div:nth-child(2) > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
  }
  & > div:nth-child(2) h6 {
    margin: 0px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  & > div:nth-child(2) h6 span {
    color: #fff;
    text-transform: uppercase;
    margin-left: 10px;
  }
  /* @media (max-width: 1024px) {
    padding: ${({ paddingVertical }) => paddingVertical || '0px'} 70px;
  }
  @media (max-width: 960px) {
    padding: ${({ paddingVertical }) => paddingVertical || '0px'} 50px;
  } */
  @media (max-width: 768px) {
    & > div:nth-child(1),
    & > div:nth-child(2) {
      width: 80%;
    }
    & > div:nth-child(2) {
      padding: 10px 20px;
    }
    & > div:nth-child(2) h6 {
      font-size: 14px;
    }
    & > div:nth-child(2) h6 span {
      margin-left: 5px;
    }
  }
`;

export const ImpactBenefitsContainer = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 7px 7px 39px 9px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & > div > div:nth-child(1) {
    padding-right: 60px;
  }
  & > div > div:nth-child(1) > div {
    background: #ffffff;
    box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 20px;
  }
  & h4 {
    color: #219ebc;
    opacity: 0.75;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
  }
  & p {
    color: #023047;
    opacity: 0.75;
    line-height: 20px;
  }
  & svg {
    padding-right: 15px;
    color: #f8991d;
    border-right: 1px solid #d9d9d9;
    font-size: 30px;
  }
  & a {
    padding-left: 15px;
    color: #023047;
  }

  & > div > div:nth-child(2) > img {
    width: 100%;
  }

  @media (max-width: 768px) {
    & > div {
      display: block;
    }
    & > div > div:nth-child(1) {
      padding-right: 0px;
    }
  }
`;
