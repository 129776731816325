import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../../../../global/styles/style-component';
import CreatorCard from '../../../../creatorPortfolio/components/CreatorCard';

const NftLists = ({ type, items, fetchApi }) => {
  const navigate = useNavigate();
  return items ? (
    <Grid
      container
      sx={{
        marginTop: '2em',
      }}
      spacing={'1em'}
    >
      {Object.keys(items).map((i) =>
        items[i].map(
          ({
            owner,
            creator,
            _id,
            isSold,
            buyerId,
            isMinted,
            paymentStatus,
            saleType,
            priceUsd,
            itemName,
            description,
            imageUrl,
            nftCategory,
            isListed,
            impactId,
            impactInitiativeName,
            impactCategory,
            percentageToImpact,
            impactCampaignType,
          }) => (
            <Grid key={_id} item xs={12} sm={6} md={4} lg={3}>
              <CreatorCard
                type={type}
                isListed={isListed}
                fetchApi={fetchApi}
                img={`${process.env.REACT_APP_DOMAIN}${imageUrl}`}
                favCount={10}
                userList={[
                  `${process.env.REACT_APP_DOMAIN}${imageUrl}`,
                  `${process.env.REACT_APP_DOMAIN}${imageUrl}`,
                ]}
                btnTitle='View detail'
                btnClick={() => {
                  navigate(`/nft-details/${_id}`);
                }}
                key={_id}
                price={priceUsd}
                title={itemName}
                description={description}
                nftType={i}
                id={_id}
                percentageToImpact={percentageToImpact}
              />
              {/* <NFTCard
              onClick={() => {
                navigate(`/nft-details/${_id}`);
              }}
            >
              <img src={`${process.env.REACT_APP_DOMAIN}${imageUrl}`} alt='' />
              <div>
                <img
                  src={`${process.env.REACT_APP_DOMAIN}${imageUrl}`}
                  alt=''
                />
                <div>
                  <strong>{itemName}</strong>
                  <strong>{description}</strong>
                </div>
              </div>
            </NFTCard> */}
            </Grid>
          )
        )
      )}
    </Grid>
  ) : (
    <Heading marginTop={'3em'}>No Record Found</Heading>
  );
};

export default NftLists;
