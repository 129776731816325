import React from 'react';
import { PagerBanner } from '../../../../src/pages/Pager/PagerStyle';
import { BannerHeading } from '../../../global/styles/style-component';

const DoneForYouBanner = () => {
  return (
    <PagerBanner
      imgUrl={require('../../../assets/done-for-you-partTwo/banner.png')}
    >
      <BannerHeading>DEVELOPMENT TEAM PARTNERSHIPS </BannerHeading>
    </PagerBanner>
  );
};

export default DoneForYouBanner;
