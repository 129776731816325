import React, { useState, useEffect } from 'react';
import Container from '../Container';
import '../../pages/browseInfluencers/BrowseInfluencer.css';
import NposDetailsBanner from './components/NposDetailsBanner';
import WhatWeDo from './components/WhatWeDo';
import Campaigns from './components/Campaigns';
import { getAllCauses } from '../../services/campaign';
import { useParams } from 'react-router-dom';
import { errorToast } from '../../utils/toast';

const BrowseNposDetails = () => {
  const { id } = useParams();
  const [cause, setCause] = useState();
  useEffect(() => {
    getAllCauses()
      .then((res) => {
        setCause(res.data.message.find((i) => i.userId === id));
      })
      .catch((err) => errorToast(err.response.data.message));
  }, []);
  return (
    <Container>
      <NposDetailsBanner cause={cause} />
      {/* <WhatWeDo /> */}
      <Campaigns />
    </Container>
  );
};

export default BrowseNposDetails;
