import React, { useEffect, useState } from 'react';
import Container from '../Container';
import InfluencerAbout from './components/InfluencerAbout';
import InfluencerCampaign from './components/InfluencerCampaign';
import InfluencerShowcase from './components/InfluencerShowcase';
import InfluncerDetailsIntro from './components/InfluncerDetailsIntro';
import { useParams } from 'react-router-dom';
import './InfluencerDetails.css';
import { errorToast } from '../../utils/toast';
import { getAllInfluencers } from '../../services/campaign';

const InfluencerDetails = () => {
  const { id } = useParams();
  const [cause, setCause] = useState();
  useEffect(() => {
    getAllInfluencers()
      .then((res) => {
        setCause(res.data.message.find((i) => i.userId === id));
      })
      .catch((err) => errorToast(err.response.data.message));
  }, []);
  return (
    <Container>
      <InfluncerDetailsIntro influencer={cause} />
      <InfluencerAbout detail={cause?.influencerBio} />
      <InfluencerShowcase />
      <InfluencerCampaign />
    </Container>
  );
};

export default InfluencerDetails;
