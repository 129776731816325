import React, { useEffect, useState } from 'react';
import { getSuperCategory } from '../../../services/vetting';
import { validateSelectedFile } from '../../../utils/validation';
import { Error, FieldContainer, FieldItem, UploadBtn } from './vetting-style';

const OverView = ({
  data = {
    overview: {
      campaignName: {
        value: '',
        error: '',
      },
      isBeneficiary: {
        value: '',
        error: '',
      },
      beneficiaries: {
        value: [
          {
            firstName: '',
            lastName: '',
            walletAddress: '',
            earningAllocate: '',
          },
        ],
        error: '',
      },
      isSuperCampaign: {
        value: '',
        error: '',
      },
      isSubCampaign: {
        value: '',
        error: '',
      },
      superCampaignName: {
        value: '',
        error: '',
      },
      superCampaignId: {
        value: '',
        error: '',
      },
      description: {
        value: '',
        error: '',
      },
      startDate: {
        value: '',
        error: '',
      },
      endDate: {
        value: '',
        error: '',
      },
      projectMilestones: {
        value: '',
        error: '',
      },
      campaignType: {
        value: '',
        error: '',
      },
    },
  },
  setData,
  isEdit,
}) => {
  const [superCategory, setSuperCategory] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getSuperCategory('CAMPAIGN');
      setSuperCategory(data.superCampaignNames);
    })();
  }, []);

  return (
    <FieldContainer noOfColumn={2}>
      <div>
        <FieldItem>
          <span>Please confirm your campaign name</span>
          <input
            type='text'
            name='campaignName'
            id='campaignName'
            value={data.overview.campaignName?.value}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                overview: {
                  ...prev.overview,
                  campaignName: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'Invalid Campaign Name format'
                        : '',
                  },
                },
              }));
            }}
          />
          <Error>{data.overview.campaignName?.error}</Error>
        </FieldItem>
        <FieldItem
          row={'2 / span 2'}
          col={'1'}
          error={data.overview.description?.error !== ''}
        >
          <span>
            Describe your campaign - include details of why your campaign should
            be supported ({data.overview.description.value.length}
            /599)
          </span>
          <textarea
            name='campaignDescription'
            id='campaignDescription'
            rows='7'
            maxLength={'599'}
            value={data.overview.description?.value}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                overview: {
                  ...prev.overview,
                  description: {
                    value: e.target.value,
                    error:
                      e.target.value === ''
                        ? 'More than 15 characters required'
                        : e.target.value.length > 15
                        ? ''
                        : 'More than 15 characters required',
                  },
                },
              }));
            }}
          />
          <Error>{data.overview.description?.error}</Error>
        </FieldItem>
        <FieldItem error={data.overview.startDate?.error !== ''}>
          <span>When does your project aim to start</span>

          <input
            type='datetime-local'
            name='projectStart'
            id='projectStart'
            value={data.overview.startDate.value.substring(0, 19)}
            onChange={(e) => {
              const tempData = new Date();
              setData((prev) => ({
                ...prev,
                overview: {
                  ...prev.overview,
                  startDate: {
                    value: new Date(e.target.value).toISOString(),
                    error:
                      e.target.value === ''
                        ? 'Select a valid date in the future'
                        : tempData > new Date(e.target.value)
                        ? 'Select a valid date in the future'
                        : '',
                  },
                },
              }));
            }}
          />
          <Error>{data.overview.startDate?.error}</Error>
        </FieldItem>
        <FieldItem error={data.overview.endDate?.error !== ''}>
          <span>When does your project aim to end</span>

          <input
            type='datetime-local'
            name='projectEnd'
            id='projectEnd'
            value={data.overview.endDate.value.substring(0, 19)}
            onChange={(e) => {
              const tempData = new Date(data.overview.startDate.value);
              setData((prev) => ({
                ...prev,
                overview: {
                  ...prev.overview,
                  endDate: {
                    value: new Date(e.target.value).toISOString(),
                    error:
                      e.target.value === ''
                        ? 'Select a valid date after the start date '
                        : tempData > new Date(e.target.value)
                        ? 'Select a valid date after the start date '
                        : '',
                  },
                },
              }));
            }}
          />
          <Error>{data.overview.endDate?.error}</Error>
        </FieldItem>
        <FieldItem error={data.overview.campaignType?.error !== ''}>
          <span>
            Is this an Open or Closed Campaign? (An open campaign allows others
            to contribute NFTs to your campaign)
          </span>
          <select
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                overview: {
                  ...prev.overview,
                  campaignType: {
                    value: e.target.value,
                    error:
                      e.target.value === '' ? 'One option is required' : '',
                  },
                },
              }));
            }}
            value={data.overview.campaignType.value}
          >
            <option value=''>Select Campaign type</option>
            <option value='OPEN'>OPEN</option>
            <option value='CLOSED'>CLOSED</option>
          </select>
          <Error>{data.overview.campaignType?.error}</Error>
        </FieldItem>
        <div>
          {!isEdit && (
            <FieldItem>
              <span>
                Please provide banner image <b>(920 x 411 recommended) 15Mb</b>
              </span>
              <div style={{ display: 'flex', gap: '1em' }}>
                {Array(1)
                  .fill('')
                  .map((_, i) => (
                    <div>
                      <label htmlFor={`passport${i}`}>
                        <UploadBtn
                          isSelected={data.banner.value[i] != null}
                          isSet={data?.banner.value[i]}
                          src={
                            data?.banner.value[i] !== null
                              ? URL.createObjectURL(data?.banner.value[i])
                              : require('../../../assets/add.png')
                          }
                          alt=''
                        />
                      </label>
                      <input
                        id={`passport${i}`}
                        name={`passport${i}`}
                        type='file'
                        accept='image/png, image/jpeg'
                        hidden
                        onChange={(e) => {
                          validateSelectedFile(e.target.files[0], (file) => {
                            const tempBanner = data.banner.value;
                            tempBanner[i] = file;
                            setData((prev) => ({
                              ...prev,
                              banner: {
                                ...prev.banner,
                                value: tempBanner,
                              },
                            }));
                          });
                        }}
                      />
                    </div>
                  ))}
              </div>
              <Error>{data?.banner?.error}</Error>
            </FieldItem>
          )}
        </div>
      </div>
      <div>
        <FieldItem>
          <span>
            Is this a super campaign? (This allows sub-campaigns to be launched
            under the super campaign)
          </span>
          <div className='radioContainer'>
            <div>
              <input
                type='radio'
                id='isSuperCampaignYes'
                name='isSuperCampaign'
                value={'true'}
                checked={
                  data.overview.isSuperCampaign.value.toString().match('true')
                    ? true
                    : false
                }
                onChange={(e) => {
                  console.log(
                    e.target.value,
                    data.overview.isSuperCampaign.value,
                    data.overview.isSuperCampaign.value == true
                  );
                  setData((prev) => ({
                    ...prev,
                    overview: {
                      ...prev.overview,
                      isSuperCampaign: {
                        value: e.target.value,
                        error:
                          e.target.value === '' ? 'Response is compulsory' : '',
                      },
                    },
                  }));
                }}
              />
              <label htmlFor='isSuperCampaignYes'>Yes</label>
            </div>
            <div>
              <input
                type='radio'
                id='isSuperCampaignNo'
                name='isSuperCampaign'
                value={'false'}
                checked={
                  data.overview.isSuperCampaign.value.toString().match('false')
                    ? true
                    : false
                }
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    overview: {
                      ...prev.overview,
                      isSuperCampaign: {
                        value: e.target.value,
                        error:
                          e.target.value === '' ? 'Response is compulsory' : '',
                      },
                    },
                  }));
                }}
              />
              <label htmlFor='isSuperCampaignNo'>No</label>
            </div>
          </div>
          <Error>{data.overview.isSuperCampaign?.error}</Error>
        </FieldItem>
        {data?.overview?.isSuperCampaign?.value.toString().match('false') && (
          <FieldItem>
            <span>Is this a sub-campaign of a super campaign?</span>
            <div className='radioContainer'>
              <div>
                <input
                  type='radio'
                  id='isSubCampaignYes'
                  name='isSubCampaign'
                  value={'true'}
                  checked={
                    data.overview.isSubCampaign.value.toString().match('true')
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      overview: {
                        ...prev.overview,
                        isSubCampaign: {
                          value: e.target.value,
                          error:
                            e.target.value === ''
                              ? 'Response is compulsory'
                              : '',
                        },
                      },
                    }));
                  }}
                />
                <label htmlFor='isSubCampaignYes'>Yes</label>
              </div>
              <div>
                <input
                  type='radio'
                  id='isSubCampaignNo'
                  name='isSubCampaign'
                  value='false'
                  checked={
                    data.overview.isSubCampaign.value.toString().match('false')
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      overview: {
                        ...prev.overview,
                        isSubCampaign: {
                          value: e.target.value,
                          error:
                            e.target.value === ''
                              ? 'Response is compulsory'
                              : '',
                        },
                      },
                    }));
                  }}
                />
                <label htmlFor='isSubCampaignNo'>No</label>
              </div>
            </div>
            <Error>{data.overview.isSubCampaign?.error}</Error>
          </FieldItem>
        )}
        {data?.overview?.isSuperCampaign?.value.toString().match('false') &&
          data?.overview?.isSubCampaign?.value.toString().match('true') && (
            <FieldItem error={data.overview.superCampaignId?.error !== ''}>
              <span>Which super campaign?</span>
              <select
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    overview: {
                      ...prev.overview,
                      superCampaignId: {
                        value: e.target.value,
                        error: e.target.value === '' ? 'Response required' : '',
                      },
                      superCampaignName: {
                        value: superCategory.find(
                          (item) => item.superCampaignId === e.target.value
                        ).superCampaignName,
                        error: '',
                      },
                    },
                  }));
                }}
                value={data.overview.superCampaignId.value}
              >
                <option value=''>Choose super campaign</option>
                {superCategory.map((item, index) => (
                  <option key={index} value={item.superCampaignId}>
                    {item.superCampaignName}
                  </option>
                ))}
              </select>
              <Error>{data.overview.superCampaignId?.error}</Error>
            </FieldItem>
          )}
        <br />
        {/* 
        <FieldItem>
          <span>
            In addition to the chosen Impact Initiative, is there a third party
            beneficiary that will receive a % of funds from your earnings? (This
            is a great way to incentivize partnerships!
          </span>
          <div className='radioContainer'>
            <div>
              <input
                type='radio'
                id='isBeneficiaryYes'
                name='isBeneficiary'
                value={'true'}
                checked={
                  data.overview.isBeneficiary.value.toString().match('true')
                    ? true
                    : false
                }
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    overview: {
                      ...prev.overview,
                      isBeneficiary: {
                        value: e.target.value,
                        error: e.target.value === '' ? 'Please select' : '',
                      },
                    },
                  }));
                }}
              />
              <label htmlFor='isBeneficiaryYes'>Yes</label>
            </div>
            <div>
              <input
                type='radio'
                id='isBeneficiaryNo'
                name='isBeneficiary'
                value={'false'}
                checked={
                  data.overview.isBeneficiary.value.toString().match('false')
                    ? true
                    : false
                }
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    overview: {
                      ...prev.overview,
                      isBeneficiary: {
                        value: e.target.value,
                        error: e.target.value === '' ? 'Please select' : '',
                      },
                    },
                  }));
                }}
              />
              <label htmlFor='isBeneficiaryNo'>No</label>
            </div>
          </div>
          <Error>{data.overview.isBeneficiary?.error}</Error>
        </FieldItem>
        {data.overview.isBeneficiary.value.toString().match('true') && (
          <>
            <FieldItem>
              <span>
                <b>*</b> Please note that all benefiting third parties will have
                to be registered on demo and you will require their full
                name and demo Wallet Address
              </span>
              <br />
            </FieldItem>
            <h6>Beneficiary/ies Details</h6>
            <Grid container spacing={'1em'}>
              {data.overview.beneficiaries.value.map((item, index) => (
                <>
                  <Grid item xs={6}>
                    {' '}
                    <FieldItem>
                      <span>First Name</span>
                      <input
                        type='text'
                        name='campaignName'
                        id='campaignName'
                        onChange={(e) => {}}
                      />
                      <Error>{}</Error>
                    </FieldItem>
                  </Grid>
                  <Grid item xs={6}>
                    <FieldItem>
                      <span>Last Name</span>
                      <input
                        type='text'
                        name='campaignName'
                        id='campaignName'
                      />
                      <Error>{}</Error>
                    </FieldItem>
                  </Grid>
                  <Grid item xs={6}>
                    {' '}
                    <FieldItem>
                      <span>Wallet Address</span>
                      <input
                        type='text'
                        name='campaignName'
                        id='campaignName'
                      />
                      <Error>{}</Error>
                    </FieldItem>
                  </Grid>
                  <Grid item xs={6}>
                    {' '}
                    <FieldItem>
                      <span>Percentage Allocation</span>
                      <input
                        type='text'
                        name='campaignName'
                        id='campaignName'
                      />
                      <Error>{}</Error>
                    </FieldItem>
                  </Grid>
                </>
              ))}
              <Grid
                item
                xs={12}
                display='flex'
                alignItems={'center'}
                justifyContent='center'
              >
                <CommonBtn>Save Beneficiary</CommonBtn>
              </Grid>
            </Grid>
            <br />
          </>
        )} */}
      </div>
    </FieldContainer>
  );
};

export default OverView;
