import React, { useState } from 'react';
import {
  freeRegex,
  validateFullName,
  validateSelectedFile,
} from '../../../utils/validation';
import { Error, FieldContainer, FieldItem, UploadBtn } from './vetting-style';

const IdentityVerification = ({ setData, data, isEdit }) => {
  const [state, setState] = useState({
    one: '',
    second: '',
  });
  return (
    <FieldContainer noOfColumn={2}>
      <FieldItem error={data.identityVerification.fullName?.error !== ''}>
        <span>Please confirm your full name (First and Last name)</span>
        <input
          type='text'
          name='name'
          id='name'
          value={data.identityVerification.fullName?.value}
          onChange={(e) => {
            setData((prev) => ({
              ...prev,
              identityVerification: {
                ...prev.identityVerification,
                fullName: {
                  value: e.target.value,
                  error:
                    e.target.value === ''
                      ? 'First and Last name required'
                      : freeRegex(e.target.value)
                      ? ''
                      : 'First and Last name required',
                },
              },
            }));
          }}
        />
        <Error>{data.identityVerification.fullName?.error}</Error>
      </FieldItem>
      <FieldItem
        style={{
          justifyContent: 'center',
        }}
      >
        <div className='radioContainer'>
          <input
            type='checkbox'
            id='isDelegate'
            name='decision'
            value='isDelegate'
            checked={data.identityVerification.isDelegate.value}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                identityVerification: {
                  ...prev.identityVerification,
                  isDelegate: {
                    value: e.target.checked,
                    error: e.target.checked ? '' : 'Confirmation is required',
                  },
                },
              }));
            }}
          />
          <label htmlFor='isDelegate'>
            I'm authorized to act on behalf of this organization
          </label>
        </div>
        <Error>{data.identityVerification.isDelegate?.error}</Error>
      </FieldItem>
      {!isEdit && (
        <FieldItem>
          <span>Please upload proof of identity</span>
          <select>
            <option value='none'>Passport</option>
            <option value='Drivers License'>Drivers License</option>
          </select>
          <label htmlFor='passport'>
            <UploadBtn
              isSelected={data.identityImages.value[0] != null}
              src={
                data.identityImages.value[0]
                  ? state.one
                  : require('../../../assets/add.png')
              }
              alt=''
            />
          </label>
          <input
            id='passport'
            name='passport'
            type='file'
            accept='image/png, image/jpeg'
            hidden
            onChange={(e) => {
              validateSelectedFile(e.target.files[0], (file) => {
                setState({
                  ...state,
                  one: URL.createObjectURL(file),
                });
                setData((prev) => ({
                  ...prev,
                  identityImages: {
                    value: {
                      ...prev.identityImages.value,
                      0: file,
                    },
                  },
                }));
              });
            }}
          />
        </FieldItem>
      )}
      {!isEdit && (
        <FieldItem>
          <span>Please upload proof of address</span>
          <select>
            <option value='none'>Utility Bills</option>
            <option value='Drivers License'>Drivers License</option>
          </select>
          <label htmlFor='bills'>
            <UploadBtn
              isSelected={data.identityImages.value[1] != null}
              src={
                data.identityImages.value[1] != null
                  ? state.second
                  : require('../../../assets/add.png')
              }
              alt=''
            />
          </label>
          <input
            accept='image/png, image/jpeg'
            type='file'
            id='bills'
            name='bills'
            hidden
            onChange={(e) => {
              validateSelectedFile(e.target.files[0], (file) => {
                setState({
                  ...state,
                  second: URL.createObjectURL(file),
                });
                setData((prev) => ({
                  ...prev,
                  identityImages: {
                    value: {
                      ...prev.identityImages.value,
                      1: file,
                    },
                  },
                }));
              });
            }}
          />
        </FieldItem>
      )}
      {!isEdit && (
        <FieldItem>
          <Error>{data.identityImages?.error}</Error>
        </FieldItem>
      )}
    </FieldContainer>
  );
};

export default IdentityVerification;
