import React, { useEffect, useState } from 'react';
import { CommonBtn, Heading } from '../../../../global/styles/style-component';
import {
  brandResubmitRegistration,
  caseResubmitRegistration,
  resubmitImage,
} from '../../../../services/categoriesRegistration';
import { getAllRoleRequest } from '../../../../services/vetting';
import { default as BrandForm } from '../../../form/components/brandForm/Form';
import { default as CauseForm } from '../../../form/components/causeForm/Form';
import { CategoryFromContainer } from '../../../form/formStyle';
import DashboardContainer from '../../DashboardContainer';

const TYPELIST = ['RESUBMITTED', 'PENDING', 'REJECTED'];

const MyRequest = () => {
  const [data, setData] = useState({});
  const [dataError, setDataError] = useState({
    logo: '',
    cover: '',
    name: '',
    mainPersonName: '',
    mainPhoneNum: '',
    mainEmail: '',
    alternatePersonName: '',
    alternatePhoneNum: '',
    alternateEmail: '',
    about: '',
    country: '',
    city: '',
    category: '',
    websiteUrl: '',
    socials: '',
    feelImpactoverse: '',
    isRegistered: '',
    registrationNum: '',
    countryOfRegistration: '',
    groupAgenda: '',
    groupActivities: '',
    previousActivity: '',
    sinceWhen: '',
    history: '',
  });
  const [type, setType] = useState('');
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const res = await getAllRoleRequest();
    setType(res.data.requestType);
    setData(res.data.requestDetails);
  };

  useEffect(() => {
    if (data?.logo || data?.cover) {
      const formData = new FormData();
      if (data.logo) formData.append('logo', data.logo);
      if (data.cover) formData.append('cover', data.cover);
      resubmitImage(type, data?._id, formData, () => {
        setData({
          ...data,
          logo: '',
          cover: '',
        });
      });
    }
  }, [data, type]);

  return (
    <DashboardContainer>
      <h3 className='mt-2'>
        My Request ({type} {data?.registrationStatus}){' '}
      </h3>
      {type === 'REJECTED' && (
        <h6>
          REASON OF REJECTION:{' '}
          <b
            style={{
              color: 'red',
            }}
          >
            {data?.reasonOfRejection}
          </b>
        </h6>
      )}
      {type ? (
        <CategoryFromContainer>
          {type.toLowerCase() === 'brand' ? (
            <BrandForm
              data={data}
              setData={setData}
              dataError={dataError}
              setDataError={setDataError}
              isActive={!TYPELIST.includes(data.registrationStatus)}
            />
          ) : (
            <CauseForm
              data={data}
              setData={setData}
              dataError={dataError}
              setDataError={setDataError}
              isActive={!TYPELIST.includes(data.registrationStatus)}
            />
          )}
          <br />
          <br />
          {TYPELIST.includes(data.registrationStatus) && (
            <CommonBtn
              onClick={() => {
                if (data?.socials.length === 1 && !data?.socials[0]) {
                  delete data?.socials;
                }
                if (type.toLowerCase() === 'brand') {
                  brandResubmitRegistration(data._id, data, fetch);
                } else {
                  caseResubmitRegistration(data._id, data, fetch);
                }
              }}
            >
              Resubmit
            </CommonBtn>
          )}
        </CategoryFromContainer>
      ) : (
        <Heading marginTop='1em'>No Record Found</Heading>
      )}
    </DashboardContainer>
  );
};

export default MyRequest;
